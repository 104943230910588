import { getGridDateOperators, getGridNumericOperators, getGridStringOperators } from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import UiModel from './ui-model';
import dateUtil from '../utils/date';
import constants from '../utils/constants';

const startDateFilter = dayjs().subtract(7, 'day').format("YYYY-MM-DD");
const endDateFilter = dayjs().format("YYYY-MM-DD");
const dateFilterOperators = getGridDateOperators().filter((operator) => operator.value === 'after' || operator.value === 'before');
const stringFilterOperators = getGridStringOperators().filter((operator) => operator.value === 'contains');
const numberFilterOperators = getGridNumericOperators().filter((operator) => operator.value === '=');

const acostaReportListModel = new UiModel({
    title: "Acosta Report",
    defaultSort: 'AlertId DESC',
    idProperty: 'id',
    readOnly: true,
    addCreatedModifiedColumns: false,
    showOnlyExcelExport: true,
    defaultFilters: [{ field: 'StatusDate', operator: 'after', value: startDateFilter }, { field: 'StatusDate', operator: 'before', value: endDateFilter }],
    showHeaderFilters: false,
    sort: 'AlertId',
    sortDir: 'DESC',
    preferenceId: 'playbookAcostaReport',
    exportSize: 10000,
    isElasticScreen: true,
    isAcostaController: true,
    columns: [
        {
            field: "AlertId", label: "AlertId", type: 'string', link: true, width: 150, filterable: true, sortable: false, customCellClick: true, filterOperators: numberFilterOperators, renderCell: (params) => {
                return <div className="acosta-hylink" >{params?.value}</div>
            }
        },
        { field: "AcostaAlertId", label: "Acosta AlertId", type: 'number', width: 220, hide: true, filterable: false, sortable: false },
        {
            field: "AlertText", label: "Alert Text", type: 'string', width: 500, filterable: false, sortable: false, renderCell: (values) => {
                const { value } = values;
                return <Tooltip title={value} placement="bottom" >
                    <div className='display-initial'>{value}</div>
                </Tooltip>
            }
        },
        { field: "SerialNumber", label: "Asset Serial #", type: 'string', width: 200, filterable: false, sortable: false, filterOperators: stringFilterOperators },
        { field: "AcostaAlertTypeId", label: "Alert Type Id", type: 'string', width: 200, filterable: false, sortable: false },
        { field: "SkuUpcCode", label: "UPC Code", type: 'string', width: 200, filterable: false, sortable: false, hide: true },
        { field: "Product", label: "Product", type: 'string', width: 400, filterable: false, sortable: false, hide: true },
        {
            field: "StatusId", label: "Closed/Open", type: 'string', width: 220, filterable: false, sortable: false, hide: true, renderCell: (values) => {
                const { value } = values;
                return constants.acostaStatus[value]
            }
        },
        { field: "Comments", label: "Acosta Comments", type: 'string', width: 300, filterable: false, sortable: false, hide: true },
        {
            field: "AcostaVisited", label: "Acosta Visited", type: 'string', width: 400, hide: true, filterable: false, sortable: false, renderCell: (values) => {
                let { row } = values;
                const value = row?.Answer.trim();
                return value === "" || value.length === 0 ? "No" : "Yes";
            }
        },
        { field: "Status", label: "Acosta Status", type: 'string', width: 150, hide: true, filterable: false, sortable: false },
        {
            field: "Question", label: "Question", type: 'string', width: 500, filterable: false, sortable: false, hide: true, renderCell: (values) => {
                const { value } = values;
                return <Tooltip title={value} placement="bottom" >
                    <div className='display-initial'>{value}</div>
                </Tooltip>
            }
        },
        {
            field: "Answer", label: "Answer", type: 'string', filterable: false, sortable: false, width: 500, hide: true, renderCell: (values) => {
                let { value } = values;
                value = value.trim();
                return <Tooltip title={value} placement="bottom" >
                    <div>{value}</div>
                </Tooltip>
            }
        },
        { field: "ValidateReason", label: "Validate Reason", type: 'string', width: 200, hide: true, filterable: false, sortable: false },
        { field: "ValidatedByUser", label: "Validated By", type: 'string', width: 200, hide: true, filterable: false, sortable: false },
        { field: "MarketName", label: "Market", type: 'string', width: 200, filterOperators: stringFilterOperators, filterable: false, sortable: false },
        { field: "Name", label: "Location", type: 'string', width: 300, hide: true, filterable: false, sortable: false },
        { field: "Country", label: "Country", type: 'string', width: 200, hide: true, filterable: false, sortable: false },
        { field: "RetailerRelationshipCode", label: "Retailer Relationship Code", type: 'string', width: 300, hide: true, filterable: false, sortable: false },
        { field: "CallId", label: "CallId", type: 'string', width: 220, hide: true, filterable: false, sortable: false },
        { field: "Store", label: "Store", type: 'string', width: 350, hide: true, filterable: false, sortable: false },
        { field: "Client", label: "Client", type: 'string', width: 280, hide: true, filterable: false, sortable: false },
        { field: "SubCategory", label: "Sub Category", type: 'string', width: 150, hide: true, filterable: false, sortable: false },
        { field: "SubBanner", label: "SubBanner", type: 'string', width: 250, hide: true, filterable: false, sortable: false },
        { field: "Priority", label: "Priority", type: 'string', width: 150, hide: true, filterable: false, sortable: false },
        { field: "AlertAge", label: "Alert Age", type: 'string', width: 300, hide: true, filterable: false, sortable: false },
        {
            field: "StatusDate", label: "Status Date", type: 'string', width: 250, filterOperators: dateFilterOperators, sortable: false, renderCell: (params) => dateUtil.dateMatch(params.value)
        },
        {
            field: "DatePlanned", label: "Date Planned", type: 'string', width: 250, hide: true, filterable: false, sortable: false, renderCell: (params) => dateUtil.dateMatch(params.value)
        },
        {
            field: "CompletedOnLocal", label: "Completed On Local", type: 'string', width: 250, filterable: false, sortable: false, hide: true, renderCell: (params) => dateUtil.dateMatch(params.value)
        },
        {
            field: "StartDate", label: "Start Date", type: 'string', width: 250, hide: true, filterable: false, sortable: false, renderCell: (params) => dateUtil.dateMatch(params.value)
        },
        {
            field: "EndDate", label: "End Date", type: 'string', width: 250, hide: true, filterable: false, sortable: false, renderCell: (params) => dateUtil.dateMatch(params.value)
        },
        {
            field: "LastEdited", label: "Last Edited", type: 'string', width: 250, hide: true, filterable: false, sortable: false, renderCell: (params) => dateUtil.dateMatch(params.value)
        },
        { field: "AlertAgeInMin", label: "Alert Age (in min)", type: 'string', width: 250, hide: true, filterable: false, sortable: false },
        {
            field: "AlertSentOn", label: "Alert SentOn", type: 'string', width: 250, hide: true, filterable: false, sortable: false, renderCell: (params) => dateUtil.dateMatch(params.value)
        }
    ]
});

export default acostaReportListModel;