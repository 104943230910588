import React, { useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useSelector } from "react-redux";
import inFieldStatusModel from '../in-field-status';
import { apis } from "../../httpUtil";
import { transport } from '../../httpUtil/httpRequest';
import constants from '../../utils/constants';
import useMobile from '../../utils/useMobile';
import utils from '../../utils';
import { useTranslation } from 'react-i18next';

const TotalImage = require('../../assets/images/InFieldStatusIcons/Total.png');
const NoHardwareInstalledImage = require('../../assets/images/InFieldStatusIcons/NoHardwareInstalled.png');
const HardwareInstalledImage = require('../../assets/images/InFieldStatusIcons/HardwareInstalled.png');
const WorkingHardwareImage = require('../../assets/images/InFieldStatusIcons/WorkingHardware.png');
const NotWorkingHardwareImage = require('../../assets/images/InFieldStatusIcons/NotWorkingHardware.png');

const renderItemsEnum = [
    { key: 'Total', value: '...', right: true, ml: 10 },
    { key: 'NoHardwareInstalled', value: '...', left: true, ml: 5 },
    { key: 'HardwareInstalled', value: '...', right: true, left: true, ml: 5 },
    { key: 'WorkingHardware', value: '...', left: true, right: true, ml: 5 },
    { key: 'NotWorkingHardware', value: '...', ml: 5 }
]

const cardIcons = {
    Total: { icon: TotalImage, text: constants.inFieldStatusEnums.Total, showRightBorder: true },
    NoHardwareInstalled: { icon: NoHardwareInstalledImage, text: constants.inFieldStatusEnums.NoCameraAssigned },
    HardwareInstalled: { icon: HardwareInstalledImage, text: constants.inFieldStatusEnums.CameraAssigned, showRightBorder: true },
    WorkingHardware: { icon: WorkingHardwareImage, text: constants.inFieldStatusEnums.WorkingHardware },
    NotWorkingHardware: { icon: NotWorkingHardwareImage, text: constants.inFieldStatusEnums.NotWorkingHardware }
}

const RenderCell = ({ props, isFirstCard }) => {
    const { key, value, right = false, left = false, ml } = props;
    const { icon, text, showRightBorder = false } = cardIcons[key];
    const t = utils.t
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    let className = 'infieldstatuscard';
    if (left) {
        className += ' infieldstatus-left-margin '
    }
    if (right) {
        className += ' infieldstatus-right-margin '
    }
    return (
        <>
            <Card className={className}>
                <CardContent className='justify-content-center width-100'>
                    <img src={icon} className='max-width150 ml17' key={key} alt={key} />
                    <Typography className='text-align-center'>
                        <Typography variant="h5" className='height-40 fs20 mb-2 fw-500 infieldstatus-text-margin' >
                            {t(text, tOpts)}
                        </Typography>
                        <Typography >
                            <Typography variant='h4' className='fw-500 fs-xxl'>
                                {value}
                            </Typography>
                            <Typography variant='h6' className='fs-m'>
                                {t('Assets', tOpts)}
                            </Typography>
                        </Typography>
                    </Typography>
                </CardContent>
            </Card>
            {showRightBorder && <div className='infieldstatus-border' ></div>}
        </>
    )
}

const InFieldStatus = () => {
    const isMobile = useMobile();
    const [cardDetails, setCardDetails] = useState(renderItemsEnum);
    const userData = useSelector(state => state.appReducer.userData);
    const { ClientId } = userData?.tags ? userData.tags : 0;

    useEffect(() => {
        if (!isMobile.mobile) {
            async function getInvoiceMonths() {
                const headers = {};
                let params = {
                    url: `${apis.InFieldStatusCount}`,
                    method: 'POST',
                    data: { size: 0, limit: 0, getCardCounts: true, ClientId },
                    headers: {
                        "Content-Type": "application/json",
                        ...headers
                    },
                    credentials: 'include'
                };
                const response = await transport(params);
                if (response?.data) {
                    const countValues = [...cardDetails];
                    countValues.forEach(ele => {
                        ele.value = response.data[ele.key];
                    })
                    setCardDetails(countValues);
                }
            }
            getInvoiceMonths();
        }
    }, [])

    return (
        <div>
            {!isMobile.mobile &&
                <div className='display-flex'>
                    {cardDetails?.length && cardDetails.map((ele, key) => {
                        return (
                            <RenderCell props={ele} isFirstCard={key === 0} />
                        )
                    })}
                </div>
            }
            <Grid item className='width-100 mt1'>
                <inFieldStatusModel.Grid />
            </Grid>
        </div >
    )
}

export default InFieldStatus;