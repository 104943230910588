import { CameraAlt, LinkedCamera } from '@material-ui/icons';
import { ArrowDropDownCircle, CenterFocusStrongOutlined, Check, CheckBox, Event, FormatListNumbered, PhotoLibrary, RadioButtonChecked, TextFields, ViewWeek } from '@mui/icons-material';
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';

function renderQuestionBasedOnType(questionType) {
    const { type, subType } = questionType;
    switch (type) {
        case 'Boolean':
            return (
                <FormControl sx={{ width: '100%' }}>
                    <RadioGroup
                        aria-labelledby="yes-no-select-label"
                        name="controlled-radio-buttons-group"
                    // value={value}
                    // onChange={handleChange}
                    >
                        <FormControlLabel value="No" control={<Radio />} label="False" />
                        <FormControlLabel value="Yes" control={<Radio />} label="True" />
                    </RadioGroup>
                </FormControl>
            );
        case 'Multiple Choice':

            if (subType === "Single answer") {
                return (
                    <FormControl>
                        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                        // value={value}
                        // onChange={handleChange}
                        >
                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                            <FormControlLabel value="other" control={<Radio />} label="Other" />
                            <FormControlLabel
                                value="custom"
                                control={<Radio />}
                                label={
                                    <TextField
                                        label="Option"
                                    // value={textInputValue}
                                    // onChange={handleTextInputChange}
                                    />
                                }
                            // onChange={handleOptionChange}
                            />
                        </RadioGroup>
                    </FormControl>
                )
            }
            else if (subType === "Multiple answers") {
                return (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }} >

                        <FormControlLabel
                            control={
                                <Checkbox
                                    // checked={checkbox1}
                                    // onChange={handleCheckboxChange}
                                    name="checkbox1"
                                    sx={{
                                        color: '#E0E0E0',
                                        '&.Mui-checked': {
                                            color: '#1976D3',
                                        },
                                    }}
                                />
                            }
                            label="Checkbox 1"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    // checked={checkbox2}
                                    // onChange={handleCheckboxChange}
                                    name="checkbox2"
                                    sx={{
                                        color: '#E0E0E0',
                                        '&.Mui-checked': {
                                            color: '#1976D3',
                                        },
                                    }}
                                />
                            }
                            label="Checkbox 2"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    // checked={checkbox3}
                                    // onChange={handleCheckboxChange}
                                    name="checkbox3"
                                    sx={{
                                        color: '#E0E0E0',
                                        '&.Mui-checked': {
                                            color: '#1976D3',
                                        },
                                    }}
                                />
                            }
                            label="Checkbox 3"
                        />
                        <FormControlLabel
                            value="custom"
                            control={<Radio />}
                            label={
                                <TextField
                                    label="Option"
                                // value={textInputValue}
                                // onChange={handleTextInputChange}
                                />
                            }
                        // onChange={handleOptionChange}
                        />
                    </Box>
                )
            };
            break;
        case 'Number input':
            return <TextField
                type="number"
                label="Number Input"
                sx={{ width: '50%' }}
            // value={value}
            // onChange={handleChange}
            />;
        case 'Date Input':
            return <TextField
                type="date"
                label="Date Input"
                sx={{ width: '50%' }}
                // value={value}
                // onChange={handleChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />;
        case 'Text Input':
            return <TextField
                label="Text Input"
                sx={{ width: '50%' }}
            // value={value}
            // onChange={handleChange}
            />;
        case 'Dropdown':
            return (
                <Select
                    //   value={value}
                    //   onChange={handleChange}
                    sx={{ width: '50%' }}
                >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="option1">Option 1</MenuItem>
                    <MenuItem value="option2">Option 2</MenuItem>
                    <MenuItem value="option3">Option 3</MenuItem>
                </Select>
            );
        default:
            return <div>Default Case</div>;
    }
}

const renderIcon = (questionType) => {
    const { displayName } = questionType;
    switch (displayName) {
        case 'date':
            return (
                <Event />
            );
        case 'checkbox':
            return (
                <CheckBox />
            );
        case 'boolean':
            return (
                <Check />
            );
        case 'radio':
            return (
                <RadioButtonChecked />
            );
        case 'Text Input (Single line)':
            return (
                <TextFields />
            );
        case 'Text Input (Multiple lines)':
            return (
                <TextFields />
            );
        case 'number':
            return <FormatListNumbered />
        case 'Asset Barcode Capture':
            return <ViewWeek />
        case 'General Image Capture':
            return <CameraAlt />
        case 'Product Image Capture':
            return <LinkedCamera />
        case 'POS materials image capture':
            return <CenterFocusStrongOutlined />;
        case 'Dropdown':
            return <ArrowDropDownCircle />
        case 'Multifile Image Capture':
            return <PhotoLibrary />
        default:
            return null;
    }
}

export { renderQuestionBasedOnType, renderIcon }