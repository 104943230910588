import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import utils from '../../utils';

export default function XPieChart({ data }) {
    if (!data) return null;
    const series = [{ data }];
    const palette = Object.values(utils.analysisColorCodes);
    const pieParams = { height: 200, margin: { right: 5 } };
    return (
        <PieChart
            series={series}
            colors={palette}
            {...pieParams}
        />
    );
}
