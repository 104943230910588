import React from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: { '& .MuiInputBase-input': { textAlign: 'center' } }
});
const SuffixTextField = ({ value, onChange, suffix, inputProps, error, helperText, ...rest }) => {
    const classes = useStyles();

    const handleChange = (e) => {
        let newValue = e.target.value;
        newValue = newValue.replace(/[^0-9.]/g, '');
        const parts = newValue.split('.');
        if (parts.length > 2) {
            newValue = parts[0] + '.' + parts.slice(1).join('');
        }
        if (parts[0].length > 1 && parts[0].startsWith('0') && !parts[0].startsWith('0.')) {
            parts[0] = parts[0].substring(1);
        }
        newValue = parts.join('.');
        if (parts[1] && parts[1].length > 1) {
            newValue = parts[0] + '.' + parts[1].substring(0, 1);
        }
        if (parseFloat(newValue) > 100) {
            newValue = value;
        }

        onChange(newValue);
    };

    const displayValue = value ? `${value}${suffix}` : '';
    const displayPlaceholder = value ? '' : '-';

    return (
        <TextField
            {...rest}
            value={displayValue}
            placeholder={displayPlaceholder}
            onChange={handleChange}
            InputProps={{
                ...inputProps,
                sx: { textAlign: 'center' }
            }}
            className={classes.root}
            error={error}
            helperText={helperText}
        />
    );
};

export default SuffixTextField;
