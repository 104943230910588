import React from 'react';
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import GridBase from "../GridBase";
import utils from '../../utils'
import { useTranslation } from "react-i18next";

const t = utils.t;


const defaultFilter = utils.defaultFilter;

const SensorReadings = React.memo(props => {

    const { columns, data, getDashboardDetail, totalCount, childFilters, filterReady, isSensorAlerts = false, isSensorReading = false } = props;
    const [filterModel, setFilterModel] = React.useState({ items: [] });
    const [filter, setFilters] = React.useState(defaultFilter);
    const [oldFilter, setOldFilters] = React.useState({ ...defaultFilter, linkOperator: "AND" });
    const onFilterModelChange = utils.onFilterModelChange({ setFilters });
    const page = filter?.pagination?.page || 0;
    const pageSize = filter?.pagination.pageSize;
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const setPage = page => {
        setFilters(prev => ({ ...prev, isGridFilter: true, pagination: { ...prev.pagination, page } }));
    }
    const setPageSize = pageSize => {
        setFilters(prev => ({ ...prev, isGridFilter: true, pagination: { ...prev.pagination, pageSize, page: 0 } }));
    }

    const commonGridOptions = {
        filterMode: 'server',
        sortingMode: 'server',
        filterModel: filterModel,
        page: page,
        paginationMode: 'server',
        onPageChange: setPage,
        pageSize: pageSize,
        onPageSizeChange: setPageSize,
        onFilterModelChange: setFilterModel,
        disableSelectionOnClick: false,
        disableMultipleSelection: true,
        density: "compact",
        hideFooter: false,
    }

    React.useEffect(() => {
        if (filterReady) {
            onFilterModelChange({ ...filterModel, pagination: { ...(filter?.pagination || {}), page: 0 } })
        }
    }, [filterModel])

    React.useEffect(() => {
        const pagination = JSON.stringify(filter.pagination) === JSON.stringify(oldFilter.pagination),
            sort = JSON.stringify(filter.sort) === JSON.stringify(oldFilter.sort),
            where = JSON.stringify(filter.where) === JSON.stringify(oldFilter.where),
            operator = JSON.stringify(filter.linkOperator) === JSON.stringify(oldFilter.linkOperator);

        if (filter.isGridFilter && (!pagination || !sort || !where || !operator)) {
            getDashboardDetail({ extra: filter, filter: true, isSensorReading, isSensorAlerts });
            setOldFilters(utils.deepCloneObject(filter));
        }
    }, [filter]);

    React.useEffect(() => {
        getDashboardDetail({ isSensorReading, isSensorAlerts })
    }, [childFilters, isSensorReading, isSensorAlerts]);

    const clearFilter = () => {
        setFilterModel({
            pagination: { ...filter?.pagination, page: 0 },
            linkOperator: "or",
            items: []
        })
    };

    return <>
        <Grid container>
            <Grid item md={12} sm={12} lg={12} className="mt-2 gridbase-wraper">
                <Card>
                    <CardContent>
                        <Grid container >
                            <GridBase
                                clearFilter={clearFilter}
                                customClass={'mt-2 data-grid-font-12'}
                                columns={childFilters ? columns.filter(col => col.isVisible !== 'isParent') : columns}
                                data={data}
                                rowsPerPage={[10, 20, 50, 100]}
                                movePagination={false}
                                onSortModelChange={onFilterModelChange}
                                onFilterModelChange={setFilterModel}
                                showTitleColumn={true}
                                otherOptions={{
                                    ...commonGridOptions,
                                    rowCount: totalCount
                                }} />
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </>
});

export default SensorReadings;