import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import useMobile from '../../utils/useMobile';
import { useTranslation } from 'react-i18next';
import actions from '../../redux/actions';
import PageTitle from '../../components/PageTitle'
import CardRouter from '../../components/GenericCard';
import enums from '../../utils/constants'
import utils from "../../utils"
const { childRoutes } = enums;
const cardData = childRoutes.Home;
const { t } = utils;

const Home = () => {
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const dispatch = useDispatch();
  const userData = useSelector(state => state.appReducer.userData);
  const { FirstName = "", LastName = "" } = userData && userData.tags ? userData.tags : "";
  const isMobile = useMobile();

  useEffect(() => {
    dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle titleHeading={'Hello'} name={` ${FirstName} ${LastName},`} titleDescription={"Here are some suggestions for you"} icon='' title={t("Home", tOpts)} classTitle="home-header-container" /> });
    dispatch({
      type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
        filterButton: "",
        filterButtonName: "",
        printButtonName: "",
        clear: null,
        apply: null,
        hidden: { search: true, operation: true, export: true, print: true, filter: true }
      }
    })
    utils.removeBackButton(dispatch);
    return () => {
      dispatch({
        type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
      })
    }
  }, [])

  return (
    <Grid className={`mb-1 ${!isMobile.tablet ? (isMobile.portrait ? "mt-2" : "") : (isMobile.tablet && isMobile.portrait ? "mt-1" : "mt-n2")}`} container spacing={1}>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography className={(isMobile.mobile && !isMobile.tablet && isMobile.portrait) ? "ml-4" : ''} variant="h6">{t("Pages", tOpts)}</Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CardRouter childObj={cardData} isArray={true} noRibbon={true} />
      </Grid>

    </Grid>
  )
}

export default Home