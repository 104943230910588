import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import actions from '../../redux/actions';
import { useDispatch } from 'react-redux';

export default function SplitButton({ options, groupBy }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const anchorRef = React.useRef(null);
  const [selectedOption, setSelectedOption] = React.useState({});

  React.useEffect(() => {
    if (Array.isArray(options) && groupBy) {
      const selectedObj = options.find(option => option.key === groupBy);
      setSelectedOption(selectedObj);
    }
  }, [groupBy])

  const handleMenuItemClick = (event, index) => {
    dispatch({ type: actions.SET_DATA_GROUP_BY, groupBy: options[index]?.key });
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };


  return (
    <React.Fragment>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="Toggle filter"
        sx={{
          zIndex: 2,
          '& .MuiButton-outlined': {
            color: 'black',
            borderColor: 'gray',
            '&:hover': {
              borderColor: 'black',
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          },
        }}
      >
        <Button>{selectedOption?.label || ''}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="Toggle filter"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 3
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundary: 'viewport',
            },
          },
        ]}
        disablePortal={false}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              style={{
                zIndex: 4
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem 
                >
                  {options?.map((groupBy, index) => {
                    const { key, label } = groupBy;
                    return <MenuItem key={key} value={key}
                      onClick={(event) => handleMenuItemClick(event, index)}>{label}</MenuItem>
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
