import React, { useState } from 'react';

const UserContext = React.createContext([{}, () => { }]);

const initialState = {};

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(initialState);

    return (
        <UserContext.Provider value={[user, setUser]}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };