import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, FormControl, MenuItem, Hidden } from '@material-ui/core';
import { List, AutoSizer, InfiniteLoader, CellMeasurerCache } from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once
import { request, apis } from "../../httpUtil";
import actions from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { useMedia } from 'the-platform';
import VistaCameraCard from './VistaCameraCard';
import VistaSearch from '../VistaLocationCardList/VistaSearch';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import utils from '../../utils';

let history, dispatch, loadingDetail = false, initialLimit = { startIndex: 0, stopIndex: 10 };
const cache = new CellMeasurerCache({ defaultHeight: 550, fixedWidth: true, fixedHeight: false, minHeight: 400 });

const VistCamerasCardList = React.memo(function VistCamerasCardList(props) {
    const smallDevice = useMedia('(max-width: 400px)');
    const mediumDevice = useMedia({ minWidth: 401, maxWidth: 960 });
    const tOpts = { t: props.t, i18n: props.i18n };
    const t = utils.t;
    const listRef = useRef(null);
    dispatch = useDispatch();
    history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [locationSearch, setLocationSearch] = useState(0);
    const [locations, setOptions] = React.useState([]);
    const loading = open && locations.length === 0;



    const getComboData = useCallback(() => {
        if (!loading) {
            return undefined;
        }
        setComboValues();
    }, [loading]);

    const setComboValues = useCallback(
        async (input) => {
            let params = { start: 0, limit: 100, comboType: 'Location', asArray: 0 }
            if (input) {
                params = { ...params, ...{ query: input } }
            }
            const response = await request({ url: apis.Combo, params, history, dispatch });
            setOptions(Object.keys(response.records).map((key) => response.records[key]));
        });

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    React.useEffect(() => {
        if (open) {
            getComboData();
        }
    }, [loading])


    // State   
    const vistaSearch = useSelector(state => state.appReducer.vistaSearch);
    const userData = useSelector(state => state.appReducer.userData);
    const vistaData = useSelector(state => state.appReducer.vistaData);
    const [fetchIndex, setFetchIndex] = useState(0);
    const { ClientId } = userData && userData.tags ? userData.tags : "";
    const [searchDebounce] = useDebounce(vistaSearch, 800)

    const isRowLoaded = ({ index }) => !!vistaData.records[index];

    const getFilter = useCallback((params) => {
        if (vistaSearch) {
            params = { ...params, ...{ assetOrLocationName: vistaSearch, isForRank: true } }
        }
        if (locationSearch) {
            params = { ...params, ...{ locationId: locationSearch } }
        }
        return params;
    }, [vistaSearch, locationSearch])

    const resetAll = useCallback(() => {
        cache.clearAll();
        listRef && listRef.current && listRef.current.recomputeRowHeights();
    }, [listRef])

    //fetch data
    const getData = useCallback(
        async ({ startIndex = 0, stopIndex }) => {
            if (!loadingDetail) {
                loadingDetail = true;

                if (startIndex !== 0 && fetchIndex === startIndex) { return }
                setFetchIndex(startIndex)
                let params = {
                    action: "list", asArray: 0, limit: 10, start: startIndex, showAllImages: false, isFetchCoolerData: true
                };
                params = getFilter(params);
                if (Number(ClientId) > 0) {
                    params = { ...params, ...{ clientId: Number(ClientId) } }
                }
                const response = await request({ url: apis.assetLatestImages, params, history, dispatch });
                if (response && response.records) {
                    let isNeedsAttention = false;
                    let firstFilter = false;
                    if ((vistaSearch || locationSearch) && !startIndex) {
                        firstFilter = true;
                        dispatch({ type: actions.RESET_VISTA_DATA, vistaData: { records: [], recordCount: 0, TotalCount: 0, NeedAttentionCount: 0 } })
                    }
                    dispatch({ type: actions.SET_VISTA_DATA, vistaData: { records: response.records, recordCount: response.recordCount, TotalCount: response.TotalCount, NeedAttentionCount: response.NeedAttentionCount, startIndex, firstFilter, isNeedsAttention } })
                }
                resetAll()
                loadingDetail = false;
            }
        },
        [vistaSearch, ClientId, getFilter, fetchIndex, resetAll],
    )

    const onComboInputChange = useCallback(_.debounce(setComboValues, 400), []);

    const onComboItemChange = useCallback((event, value) => {
        setLocationSearch((value && value.LookupId) || value);
    }, locationSearch)

    const _onResize = () => cache.clearAll();

    useEffect(() => {
        if (searchDebounce || searchDebounce === '' || locationSearch) {
            getData(initialLimit);
        }
    }, [searchDebounce, locationSearch]);

    let recordCount = vistaData && vistaData.recordCount ? vistaData.recordCount : 1
    let recordCountLst = vistaData && vistaData.records.length ? vistaData.records.length : 1

    const memomizedHidden = React.useMemo(function () {

        return <div className="vista-search-wrapper">
            <div className="vista-search-select w-100">
                <FormControl variant="outlined" className="w-100 m-1">
                    <Select className="w-100 search-select font-weight-bold" style={{ color: '#4873FB', fontFamily: 'Roboto', lineHeight: '19px', FontSize: '16px', width: '191px' }} value={"ShowLastWeek"}>
                        <MenuItem key={"ShowLastWeek"} value={"ShowLastWeek"}>{t("Show Last Week", tOpts)}</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className="vista-search-select w-100 m-1">
                <FormControl variant="outlined" className="w-100">
                    <Autocomplete
                        id="asynchronous-demo"
                        style={{ width: 300 }}
                        className="search-select-location w-100"
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        getOptionSelected={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.DisplayValue}
                        options={locations}
                        loading={loading}
                        onInputChange={(e) => onComboInputChange(e.target.value)}
                        onChange={onComboItemChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("Select Location", tOpts)}
                                variant="outlined"
                                size="medium"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </FormControl>
            </div>
            <div className="vista-search-select w-100">
                <FormControl variant="outlined" className="w-100">
                    <Select className="search-select w-100" value={"PrioritySort"} style={{ width: '184px' }}>
                        <MenuItem key={"LocationASC"} value={"PrioritySort"}>{t("Sort by Priority", tOpts)}</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    }, [])

    useEffect(() => {
        dispatch({ type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: { filterButton: memomizedHidden, clear: () => { }, apply: () => { }, searchComponent: <VistaSearch /> } });
        return () => {
            dispatch({ type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: { filterButton: null, clear: null, apply: null } });
        }
    }, [])

    return (<div className="virtual-list-wrapper">

        <InfiniteLoader
            loadMoreRows={getData}
            isRowLoaded={isRowLoaded}
            rowCount={recordCount}>
            {({ onRowsRendered, registerChild }) => {
                return <AutoSizer onResize={_onResize}>
                    {({ height, width }) => {
                        return (
                            <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', flexShrink: 1 }} ref={registerChild}>
                                <List
                                    ref={(listref) => {
                                        registerChild(listref);
                                        listRef.current = listref;
                                    }}
                                    style={{ outline: 'none', '&>div': { display: 'flex', justifyContent: 'space-around' } }}
                                    scrollToAlignment="start"
                                    onRowsRendered={onRowsRendered}
                                    deferredMeasurementCache={cache}
                                    height={height - (smallDevice ? 10 : mediumDevice ? 24 : 120)}
                                    rowCount={recordCountLst}
                                    rowHeight={cache.rowHeight}
                                    rowRenderer={({ key, index, style, parent }) => <VistaCameraCard key={index} keyCard={key} index={index} style={style} parent={parent} cache={cache} />}
                                    width={width}
                                /></div>
                        )
                    }}
                </AutoSizer>
            }}
        </InfiniteLoader>
    </div>
    );
});

export default withTranslation()(VistCamerasCardList);
