export default function MobileList({elements =[]}) {    
    return <>    
        <div className="d-flex flex-column  w-100 p-3 justify-content-between shadow ">
            {elements.map(({className, Components}, i) => {
                return <div key={i} className={`d-flex ${className} mt-1`}>
                       <Components />
                    </div>
            })}

          </div>    
    </>
} 