
import { UiModel } from '@durlabh/dframework-ui';
import utils from '../utils';
const assetList = new UiModel({
    title: "Installations",
    linkColumn: 'SerialNumber',
    defaultSort: 'Installation DESC',
    titleDescription: 'Keep track of your installations',
    readOnly: true,
    idProperty: 'AssetId',
    module: 'Installations',
    api: 'InstallationAsset',
    addCreatedModifiedColumns: false,
    showPivotExportBtn: true,
    pivotAPI: 'Asset.ashx',
    template: 'installation-pivot',
    configFileName: 'definitions',
    hideBackButton: true,
    preferenceId: 'playbookAssetList',
    columns: [
        { field: "AssetId", type: 'number', hide: true, width: 80, label: "Asset ID" },
        { field: "SerialNumber", type: 'string', label: "Asset Serial #", required: true, width: 160, pinned: true },
        { field: "AssetType", type: 'string', label: "Asset Type", required: true, width: 150, pinned: true },
        { field: "IsSmart", type: 'boolean', label: "Is Smart", required: true, width: 100, pinned: true },
        { field: "SmartDeviceSerialNumber", type: 'string', label: "Camera Serial #", required: false, width: 120 },
        { field: "SmartDeviceType", type: 'string', label: "Camera Type", required: false, width: 150 },
        { field: "MDMSerialNumber", type: 'string', label: "Hub Serial #", required: false, width: 150 },
        {
            field: "Installation", type: 'date', label: "Installed At", required: false, width: 120, valueFormatter: params => {
                return utils.formatDate(params.value, false, true)
            }, keepUTC: true
        },
        { field: "InstallerUser", type: 'string', label: "Installed By", required: false, width: 150 },
        { field: "Location", type: 'string', label: "Outlet Name", required: false, width: 150 },
        { field: "LocationCode", type: 'string', label: "Outlet Code", required: false, width: 150 },
        { field: "Street", type: 'string', hide: true, label: "Street", required: false, width: 150 },
        { field: "City", type: 'string', hide: true, label: "City", required: false, width: 100 },
        { field: "State", type: 'string', hide: true, label: "State", required: false, width: 100 },
        { field: "PostalCode", type: 'string', hide: true, label: "Postal Code", required: false, width: 100 },
        { field: "PrimaryRepName", type: 'string', hide: true, label: "Primary Sales Rep", required: false, width: 150 },
        { field: "PrimarySalesRep", type: 'string', hide: true, label: "Primary Sales Rep E-mail", required: false, width: 150 },
        { field: "SecondaryRepName", type: 'string', hide: true, label: "Secondary Sales Rep", required: false, width: 150 },
        { field: "LocationRoute", type: 'string', hide: true, label: "Delivery Route", required: false, width: 90 },
        { field: "PlanogramName", type: 'string', hide: true, label: "Planogram", required: false, width: 150 },
        { field: "OutletActive", type: 'boolean', label: "Outlet Active", required: false, width: 100 }
    ]
});

export default assetList; 