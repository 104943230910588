import React from 'react';
import { HashRouter } from 'react-router-dom';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import Routes from './routes';
import './assets/css/base.scss';
import './App.scss';
import "./utils/i18n";
import CssBaseline from '@material-ui/core/CssBaseline';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faSignal, faAngleRight, faWrench } from '@fortawesome/free-solid-svg-icons';
import { Toaster } from 'react-hot-toast';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { LicenseInfo } from '@mui/x-license-pro';
import utils from './utils';
import { UserProvider } from './utils/orderContext';
import { RouterProvider } from "@durlabh/dframework-ui";
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import ErrorBoundary from './ErrorBoundary';

library.add(faAngleDown);
library.add(faSignal);
library.add(faAngleRight);
library.add(faWrench);
LicenseInfo.setLicenseKey(utils.MUI_License);
function InnerComponent() {
  const location = useLocation();
  const history = useHistory();
  const contextValue = {
    pathname: location.pathname,
    navigate: history.push,
    useParams
  };
  return (
    <ErrorBoundary>
      <UserProvider>
        <RouterProvider value={contextValue}>
          <Routes />
        </RouterProvider>
      </UserProvider>
    </ErrorBoundary>
  );
}
function App() {
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Toaster
          position="bottom-right"
          toastOptions={
            {
              style: {
                background: '#e8850a',
                color: '#fff'
              },
              duration: 5000
            }
          }
        />
        <HashRouter basename="/">
          <CssBaseline />
          <InnerComponent />
        </HashRouter>
      </MuiPickersUtilsProvider>
    </Provider>
  )
}

export default App;
