import utils from "../utils/index";
import dayjs from "dayjs";

const surveyGridConfig = {

    title: 'Survey Dashboard',
    defaultSort: 'EndDate desc',
    controller: "Survey",
    rangeFilter: "EndDate",
    columns: [
        { field: "RowNumber", width: 50, export: false },
        { field: "SurveyId", width: 50, export: false, },
        { field: "SurveyTypeId", width: 50, export: false },
        { field: "SurveyName", headerName: 'Survey Name', width: 200, showInFilter: true, export: true },
        { field: 'SurveyType', headerName: 'Questionnaires', width: 200, showInFilter: true, export: true },
        {
            field: 'OutletName',
            headerName: 'Location Name',
            width: 200,
            showInFilter: true,
            export: true
        },
        {
            field: 'OutletCode',
            headerName: 'Location Code',
            width: 150,
            showInFilter: true,
            export: true
        },
        { field: "Latitude", headerName: 'Latitude', width: 100, export: true },
        { field: "Longitude", headerName: 'Longitude', width: 100, export: true },
        {
            field: 'SurveyCategory',
            headerName: 'Survey Category',
            width: 150,
            showInFilter: true,
            export: true
        },

        {
            field: "EndDate", headerName: "Survey Date Time", width: 200, type: "dateTime", showInFilter: true, export: true, valueFormatter: (item) => {
                return utils.formatDate(item.value, false);
            }
        },
        { field: "ModifiedByUser", headerName: "User", width: 300, showInFilter: true, export: true }
    ]


}

export default surveyGridConfig;


