import { Box, Button, Chip, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MobileDatePicker } from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
    select: {
        width: 200
    },
    filterStyles: {
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "auto"
    },
}));
export default function HeaderFilterModelForm({ modelConfig, value, disabled, required, field, fieldLabel, error, setParentFilters, handleHeaderFilters, searchFilters, parentFilters, setFiltersModel, onFilterModelChange, ...otherProps }) {
    const dateFormat = "MM/DD/YYYY";
    const classes = useStyles();
    const combos = useSelector(state => state.appReducer.comboData);
    const [isCollapsed, setIsCollapsed] = useState(true);
    let headerFilterCount = modelConfig.reduce((count, item) => item.headerFilter ? count + 1 : count, 0);
    let renderedFilterCount = 0;

    function processData(combos, modelConfig) {
        let result = {};
        let columnMappings = {
            'MarketName': 'Market',
            'Classification': 'LocationClassification',
            "FrequencyTypeString": "Frequency",
            "PriorityString": "Priority",
            "SurveyStatusString": "SurveyStatus"

        };
        modelConfig.forEach((config) => {
            let field = config.field;
            if (columnMappings.hasOwnProperty(field)) {
                field = columnMappings[field];
            }
            if (combos.hasOwnProperty(field)) {
                result[config.field] = combos[field].map(item => item.DisplayValue);
            }
        });

        return result;
    }
    let options = processData(combos, modelConfig);


    let cols = modelConfig.map((item, index) => {
        if (item.headerFilter && item.headerFilter !== undefined) {
            if (isCollapsed && renderedFilterCount >= 4) {
                return null;
            }
            renderedFilterCount++;
            switch (item.type) {
                case 'string':
                case 'text':
                    return (
                        <Grid item>
                            <TextField
                                type="string"
                                size='small'
                                name={item.field}
                                value={searchFilters[item.field] || ''}
                                label={item.headerName}
                                variant="outlined"
                                sx={{ width: `${item.minWidth}px` }}
                                onChange={handleHeaderFilters}
                            />
                        </Grid>
                    )
                case 'number':
                    return (
                        <Grid item>
                            <TextField
                                type='number'
                                size='small'
                                name={item.field}
                                value={searchFilters[item.field] || ''}
                                label={item.headerName}
                                variant="outlined"
                                sx={{ width: `${item.minWidth}px` }}
                                onChange={handleHeaderFilters}
                            />
                        </Grid>
                    )
                case 'date':
                    return (
                        <Grid item>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <MobileDatePicker
                                    inputFormat={dateFormat}
                                    defaultValue={dateFormat}
                                    sx={{ ml: 1 }}
                                    renderInput={(params) => (
                                        <TextField size="small" {...params} />
                                    )}
                                    name={item.field}
                                    label={item.headerName}
                                    value={searchFilters[item.field]}
                                    onChange={(date) => handleHeaderFilters({ target: { value: date, name: item.field } })}
                                />
                            </LocalizationProvider>
                        </Grid>
                    )
                case "radio":
                    let uniqueRadioOptions = options[item.field] || [];
                    return (
                        <Grid item>
                            <FormControl component={Grid} error className="inputWrapper" variant="standard">
                                <Box>
                                    {<FormLabel required={required}>{fieldLabel}</FormLabel>}
                                    <RadioGroup
                                        name={item.field}
                                        value={searchFilters[item.field] || ''}
                                        onChange={handleHeaderFilters}
                                    >
                                        {
                                            uniqueRadioOptions.map((option, i) => (
                                                <FormControlLabel
                                                    value={option}
                                                    control={<Radio />}
                                                    label={option}
                                                    disabled={disabled}
                                                    key={`${item.field}-option-${i}`}
                                                />
                                            ))
                                        }
                                    </RadioGroup>
                                </Box>
                            </FormControl>
                        </Grid>
                    )

                case "select":
                    let uniqueOptions = options[item.field] || [];
                    return (
                        <Grid item key={item.field}>
                            <FormControl fullWidth>
                                <InputLabel size='small'>{item.headerName}</InputLabel>
                                <Select
                                    label={item.headerName}
                                    size='small'
                                    className={classes.select}
                                    name={item.field}
                                    value={searchFilters[item.field] || ''}
                                    onChange={handleHeaderFilters}
                                >
                                    <MenuItem value=''>
                                        {"None"}
                                    </MenuItem>
                                    {uniqueOptions.map((option, index) => (
                                        <MenuItem value={option} key={index}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )

            }
        }
    });

    return (
        <Grid container spacing={2} className={classes.filterStyles}>
            {cols}
            {headerFilterCount > 4 && (
                <Grid item>
                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                        {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                    </IconButton>
                </Grid>
            )}
        </Grid>
    )
}
