import UiModel from './ui-model';

const questionnaireModel = new UiModel({
    title: "Questionnaire",
    defaultSort: 'SurveyTypeName ASC',
    linkColumn: 'SurveyTypeName',
    titleDescription: 'Build flexible questionnaires for use in surveys',
    api: 'SurveyType',
    module: 'Questionnaire',
    pageTitle: "Questionnaires Overview",
    customAddTextTitle: "Create Questionnaire",
    overrideFileName: 'Questionnaires',
    showHiddenColumn: true,
    columns: [
        { field: "SurveyTypeId", type: 'number', hide: true, width: 80, label: "ID", fieldLabel: null, pinned: false },
        { field: "SurveyTypeName", type: "string", label: "Name", width: 240, pinned: true, link: true },
        { field: "Description", type: "string", label: "Description", width: 240 },
        { field: "IsActive", type: "boolean", label: "Active", width: 80, defaultValue: true }
    ],
});

export default questionnaireModel;