import UiModel from './ui-model'

const modelConfig = new UiModel({
    title: "Client User",
    defaultSort: 'FirstName ASC',
    doubleClicked: false,
    columns: [
        { field: "ClientUserId", type: 'number', hide: true, width: 80, label: "User ID" },
        { field: "FirstName", type: "string", label: "First Name", width: 240, pinned: true },
        { field: "LastName", type: "string", label: "Last Name", width: 240 },
        { field: "Role", type: "string", label: "Role", width: 240 },
        { field: "PrimaryEmail", type: "string", label: "Email", width: 300 },
    ]
});

export default modelConfig;