import * as React from 'react';
import { ResponsiveChartContainer, ChartsXAxis, ChartsYAxis, ChartsTooltip, ChartsLegend, LinePlot, MarkPlot, PiePlot, BarPlot, useXScale, useYScale } from '@mui/x-charts';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import utils from '../../../utils';
import { animated } from '@react-spring/web';
import { color as d3Color } from 'd3-color';

const theme = createTheme();
const StyledToolTip = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    fontSize: theme.typography.body2.fontSize,
    marginLeft: '16px',
}));

const ToolTip = ({ value, series, isBarCode, colors }) => {
    return (
        <StyledToolTip>
            {value.map((val, index) => (
                <div key={index} style={{ marginBottom: 4 }}>
                    {isBarCode &&
                        <span style={{
                            display: 'inline-block',
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            backgroundColor: colors[index],
                            marginRight: 10
                        }}></span>
                    }
                    <span style={{ marginRight: 20 }}>{isBarCode ? series[index] : series}</span>
                    <span>{val}%</span>
                </div>
            ))}
        </StyledToolTip>
    );
};

function Indicators({ values }) {
    const xScale = useXScale();
    const yScale = useYScale();
    return values.map((value, index) => {
        const x = xScale(value);
        const y = yScale(yScale.domain()[index]);
        const bandwidth = yScale.bandwidth();
        return (
            <React.Fragment key={index} >
                <text x={x} y={y} fill="black" dy="-5" textAnchor="middle" fontSize="12px"  > {value}%   </text>
                <line x1={x} y1={y} x2={x} y2={y + bandwidth} stroke="black" stroke-width="3px" />
            </React.Fragment>
        );
    });
}

export const Bar = styled(animated.rect)(({ ownerState }) => {
    return {
        fill: ownerState?.isHighlighted
            ? d3Color(ownerState?.color).brighter(0.5).formatHex()
            : ownerState?.color,
        transition: 'opacity 0.2s ease-in, fill 0.2s ease-in',
        opacity: ownerState?.isFaded ? 0.3 : 1,
        strokeWidth: 4,
        stroke:
            ownerState?.categories[ownerState?.dataIndex] === 'Average'
                ? 'black'
                : 'none',
    };
});

const ChartComponent = ({ type, categories, xLabel, yLabel, series, data, trigger, layout = 'vertical', height, barLabel, width, margin = {}, title, rangi, custom = false, isDate = false, xAxisConfigFromProps = {}, isCompliance = false, isBarCode, isSoS = false }) => {
    const palette = Object.values(utils.analysisColorCodes);
    let colors = rangi || palette;
    let slots, slotProps;
    let xAxisConfig = {
        data: categories,
        scaleType: (type === 'line' && isDate) ? 'time' : 'band',
        ...xAxisConfigFromProps
    };
    let yAxisConfig = {
        scaleType: layout === 'horizontal' ? 'band' : 'linear'
    };
    if ((isCompliance && data?.length > 0) || isBarCode) {
        slots = {
            axisContent: (props) => {
                return (
                    <ToolTip
                        value={isCompliance ? [data[props.dataIndex]] : series.map(serie => serie.data[props.dataIndex])}
                        series={isCompliance ? props.axisValue : series.map(serie => serie.label)}
                        isBarCode={isBarCode}
                        colors={isBarCode ? colors : undefined}
                    />
                );
            },
        };

        if (isCompliance) {
            xAxisConfig = {};
            yAxisConfig.scaleType = 'band';
            yAxisConfig.data = categories;
        }
    }
    if (layout === 'horizontal') {
        xAxisConfig = {
            tickMinStep: 1,
        };
        yAxisConfig.data = categories;
    }
    if (custom) {
        colors = palette;
    }
    const commonProps = {
        xAxis: [xAxisConfig],
        yAxis: [yAxisConfig],
        height: height,
        width: width,
        margin: margin
    };
    //Commenting this as one point awaits response from mui forum. discussed with QA
    // if (type === 'bar' && !isCompliance) {
    //     slots = {
    //         bar: (props) => <Bar {...props} series={series} />,
    //     }
    //     slotProps = {
    //         bar: {
    //             ownerState: {
    //                 categories,
    //             }
    //         }
    //     }
    // }

    const chartTypes = {
        bar: <BarPlot layout={layout} barLabel={barLabel} />,
        line: <>
            <LinePlot />
            <MarkPlot />
        </>,
        pie: <PiePlot />,
    };
    return (
        <ThemeProvider theme={theme}>
            < div style={{ width: '100%', height: height || 400 }} >
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    fontWeight: 'bold'
                }}
                    className={(isCompliance || isSoS) ? `compliance-title` : ''} >
                    {title || ""}
                </div>
                <ResponsiveChartContainer
                    series={series}
                    colors={colors}
                    {...commonProps}
                >
                    {chartTypes[type]}
                    {type !== 'pie' && <ChartsXAxis label={xLabel} position="bottom" />}
                    {type !== 'pie' && <ChartsYAxis label={yLabel} position="left" />}
                    <ChartsTooltip trigger={trigger} slots={slots} />
                    <ChartsLegend />
                    {type === 'bar' && data && <Indicators values={data} />}
                </ResponsiveChartContainer>
            </div>
        </ThemeProvider>
    );
};

export default ChartComponent;
