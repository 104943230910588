import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import logo from '../../assets/images/logo.png';
import { Link, useParams } from "react-router-dom";
import { apis, request } from '../../httpUtil'
import useMobile from '../../utils/useMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperHeader: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#3D4977'
  },
  paperDetails: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    marginLeft: "5px",
    marginRight: "5px"
  },
  paperGrid: {
    padding: theme.spacing(2),
    marginBottom: "10px",
    textAlign: 'left',
    color: theme.palette.text.secondary,
    marginLeft: "5px",
    marginRight: "5px"
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginLeft: "5px",
    marginRight: "5px"
  }
}));

export default function FullWidthGrid() {

  const { GUID, fromNotification } = useParams();
  const classes = useStyles();
  const stitchedImage = `${apis.imgBaseUrl}/Controllers/CoolerImagePreview.ashx?ImageType=Stitched&Guid=${GUID}`;
  const inStoreImage = `${apis.imgBaseUrl}/Controllers/CoolerImagePreview.ashx?ImageType=InStore&Guid=${GUID}`;
  const planogramImage = `${apis.imgBaseUrl}/Controllers/CoolerImagePreview.ashx?ImageType=Planogram&Guid=${GUID}`;
  const tableData = [], productData = "";

  const [divStyle, setDivStyle] = useState({});
  const { mobile } = useMobile();

  useEffect(() => {
    window.addEventListener("resize", setStitchedImageDiv, false);
    return () => {
      window.removeEventListener("resize", setStitchedImageDiv, false);
    }
  }, []);

  function setStitchedImageDiv() {
    const element = document.getElementById("planogram-card"), style = {};
    if (element) {
      style.height = element.clientHeight;
      style.width = element.clientWidth;
    }
    setDivStyle(style);
  }

  return (
    <div className={classes.root}>
      <Grid container xs={12} spacing={3} className="p-0 m-0">
        <Grid item xs={12} className="p-0 m-0">
          <Paper elevation={3} className={classes.paperHeader} variant="outlined" square>
            <Link to="/login">   <img src={logo} alt="logo" className="mr-2" width={97} height={29} /></Link>
          </Paper>
        </Grid>
        {productData &&
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paperDetails}>
              <p><b>Location</b>: Tom Thumb #3617</p>
              <p><b>Purity Percentage</b>: 100%</p>
              <p><b>Stock Percentage</b>: 100%</p>
              <p><b>Planogram Percentage</b>: 23%</p>
              <p><b>Product</b>: FRESHPET® SELECT ROASTED MEALS® GRAIN FREE CHICKEN RECIPE WITH GARDEN VEGETABLES FOR DOGS 1.75 lbs</p>
            </Paper>
          </Grid>}
        {tableData.length > 0 &&
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paperGrid}>
              <TableContainer component={Paper} elevation={0} variant="outlined" square>
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">ProductSKU</TableCell>
                      <TableCell align="center">Product Name</TableCell>
                      <TableCell align="center">Facings In Store</TableCell>
                      <TableCell align="center">Facing In Planogram</TableCell>
                      <TableCell align="center">Facing In Planogram</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell align="center" component="th" scope="row">
                          {row.sku}
                        </TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.facingInStore}</TableCell>
                        <TableCell align="center">{row.facingInPlanogram}</TableCell>
                        <TableCell align="center">{row.percentage}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>}
        <Grid item xs={(fromNotification && mobile) ? 12 : 4} alignContent='center' alignItems='center'>
          <Paper elevation={0} className={classes.paper} style={divStyle}>
            {(fromNotification || (Object.keys(divStyle).length > 0)) && <img src={stitchedImage} alt="logo" className="mr-2 stitched-image-card asset-report-image"/>}
          </Paper>
        </Grid>
        {!fromNotification && <>
          <Grid item xs={4} >
            <Paper elevation={0} className={classes.paper} >
              <img src={inStoreImage} alt="logo" className="mr-2 planogram-card" />
            </Paper>
          </Grid>
          <Grid item xs={4} >
            <Paper elevation={0} className={classes.paper} id="planogram-card">
              <img src={planogramImage} alt="logo" className="mr-2 planogram-card" onLoad={() => setStitchedImageDiv()} />
            </Paper>
          </Grid>
        </>}
      </Grid>
    </div>
  );
}