import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CommonChartsConfig } from './CommonChartsConfig';
import dayjs from 'dayjs';

const MultiLineChart = ({ title, subtitle, yLabel, xLabel, categories, series, inverted, chartType, type, custom, format, isPercentage, isCustom }) => {
    CommonChartsConfig();
    const plotOptionsConfig = custom ? {
        series: {
            pointStart: Date.UTC(2023, 4, 1),
            pointInterval: 24 * 3600 * 1000,
        }
    } : {
        series: {
            pointStart: 0
        }
    };
    const options = {
        chart: {
            type: chartType || 'line',
            inverted: inverted
        },
        title: {
            text: title,
            style: {
                fontWeight: 'bold'
            }
        },
        subtitle: {
            text: subtitle
        },
        yAxis: isCustom ? {
            title: {
                text: yLabel,
            },
            allowDecimals: false
        } :
            {
                min: 0,
                max: 100,
                title: {
                    text: yLabel,
                },
                allowDecimals: false
            },
        xAxis: custom ? {
            type: 'datetime',
            dateTimeLabelFormats: { month: format },
            labels: {
                formatter: function () {
                    return dayjs(this.x).format(format);
                }
            },
        } : {
            categories: categories,
            allowDecimals: false,
            title: {
                text: xLabel
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },
        plotOptions: plotOptionsConfig,
        series: series,
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        },
        credits: {
            enabled: false
        }
    };
    if (custom) {
        options.tooltip = {
            formatter: function () {
                return dayjs(this.x).format(format) + ': <b>' + this.y + '%' + '</b>';
            }
        };
    }

    if (isPercentage) {
        options.tooltip = {
            formatter: function () {
                return this.x + ': <b>' + this.y + '%' + '</b>';
            }
        };
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default MultiLineChart;
