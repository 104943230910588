import React, { useEffect } from 'react';
import PageTitle from '../../components/PageTitle'
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions';
import InFieldHardware from '../../components/UnileverComponents/inFieldHardware';
import AlertHistory from '../../components/Alerts/AlertHistory';
import AlertOverview from '../../components/Alerts/AlertOverview';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import enums from '../../utils/constants'
import utils from "../../utils"
import InFieldStatusWithCharts from '../InFieldStatusWithCharts';
const { childRoutes, wrongAction } = enums;
const { t, isModuleExists } = utils;

export default function BusinessKPI() {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const dispatch = useDispatch();
    const userData = useSelector(state => state.appReducer.userData) || {};
    const childPages = childRoutes.Operations;
    const { page } = useParams();

    useEffect(() => {
        dispatch({
            type: actions.SET_PAGE_TITLE_DETAILS,
            pageTitleDetails: <PageTitle titleHeading={t(childPages[page]?.title, tOpts)} titleDescription={t(childPages[page]?.description, tOpts)} icon="" title={t(childPages[page]?.title, tOpts)} />
        });
        return () => {
            dispatch({
                type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
            })
        }
    }, [])

    return (<>
        {
            (page === childPages.AlertOverview.page && isModuleExists(userData, childPages.AlertOverview.moduleName)) ? <AlertOverview /> :
                <div>{t(wrongAction, tOpts)}</div>}
    </>)
}