
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { withTranslation } from 'react-i18next';
import utils from '../../utils';
const t = utils.t;


const VistaSearch = React.memo(function VistaSearch({ vistaSearch, dispatch, t: translate, i18n }) {
    const [localValue, setLocalValue] = useState(vistaSearch);
    const tOpts = { t: translate, i18n };
    const handleChange = (event) => {
        const { value = '' } = event.target;
        setLocalValue(value);
        dispatch({ type: 'SET_VISTA_SEARCH', vistaSearch: value });
    }
    const handleKeyDown = (event) => {
        if (event.keyCode == 13) {
        }
    }
    const clearSearch = () => {
        setLocalValue(''); // Clear local value
        dispatch({ type: 'SET_VISTA_SEARCH', vistaSearch: '' }); // Clear search value in store
    };

    const loaderOpen = useSelector(state => state.appReducer.loaderOpen)
    return <div className="normal-filter d-flex justify-content-between outlet-filter">
        <div className="vista-search-input">
            <TextField
                size="small"
                className="search-background search m-1"
                variant="outlined"
                placeholder={t("Filter by Location name, Code or Asset", tOpts)}
                value={localValue ? localValue : vistaSearch}
                onChange={handleChange}
                fullWidth={true}
                disabled={loaderOpen}// Should be disabled till request completes
                InputProps={{
                    'aria-label': t('Filter by Location', tOpts),
                    startAdornment: (<InputAdornment position="start"><CloseIcon style={{ cursor: 'pointer' }} onClick={clearSearch} className="icon-search" /></InputAdornment>),
                    endAdornment: (<InputAdornment position="end"><SearchIcon className="icon-search" /></InputAdornment>)
                }}
            />
        </div>
    </div>
}, (preProps, nextProps) => preProps.vistaSearch === nextProps.vistaSearch);


const mapStateToProps = state => ({
    vistaSearch: state.appReducer.vistaSearch,
});

export default connect(mapStateToProps)(withTranslation()(VistaSearch));