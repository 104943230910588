import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { request } from "../../httpUtil";
import actions from "../../redux/actions";
import {
    Card, CardContent, Grid, Paper, Typography
} from "@material-ui/core";
import GridBase from "../GridBase";
import { PadLineChart, PadBarChart } from "./padCharts";
import FilterPanel from '../filterPanel';
import utils from "../../utils";
import { withTranslation } from "react-i18next";
import InfoBar from './infoBar'
import dayjs from "dayjs";
const isMobile = utils.isMobile();

const columns = {
    "productAvailability": [
        { id: 'Product', label: 'Product', flex: 1, minWidth: 400 },
        { id: 'SKU', label: 'SKU', flex: 0, minWidth: 200 },
        {
            id: 'ProductCategoryName', label: 'Product Category', flex: 0, minWidth: 200, valueGetter: (value) => value.row.ProductCategoryName,
            format: (item) => {
                return item.value || "-"
            }
        },
        {
            id: 'ProductCompliance', label: '%', headerAlign: 'center', align: 'center', flex: 0, minWidth: 200, valueGetter: (value) => value.row.ProductCompliance,
            format: (item) => {
                return Number(item.value).toFixed(1);
            }
        }
    ],
    "productDistribution": [
        { id: 'Product', label: 'Product', flex: 1, minWidth: 400 },
        { id: 'SKU', label: 'SKU', flex: 0, minWidth: 200 },
        {
            id: 'ProductCategoryName', label: 'Product Category', flex: 0, minWidth: 200, valueGetter: (value) => value.row.ProductCategoryName,
            format: (item) => {
                return item.value || "-"
            }
        },
        {
            id: 'ProductDistribution', label: '%', headerAlign: 'center', align: 'center', flex: 0, minWidth: 200, valueGetter: (value) => value.row.ProductDistribution,
            format: (item) => {
                return Number(item.value).toFixed(1);
            }
        }
    ]
}
const exportColumns = {
    productAvailability: [
        { field: 'Product', type: 'string', headerName: 'Product', width: 400 },
        { field: 'SKU', type: 'string', headerName: 'SKU', width: 200 },
        {
            field: 'ProductCategoryName',
            type: 'string',
            headerName: 'Product Category',
            width: 200,
            valueGetter: (params) => params.row.ProductCategoryName,
            format: (params) => params.value || '-'
        },
        {
            field: 'ProductCompliance',
            type: 'number',
            headerName: '%',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            valueGetter: (params) => params.row.ProductCompliance,
            format: (params) => Number(params.value).toFixed(1)
        }
    ],
    productDistribution: [
        { field: 'Product', type: 'string', headerName: 'Product', width: 400 },
        { field: 'SKU', type: 'string', headerName: 'SKU', width: 200 },
        {
            field: 'ProductCategoryName',
            type: 'string',
            headerName: 'Product Category',
            width: 200,
            valueGetter: (params) => params.row.ProductCategoryName,
            format: (params) => params.value || '-'
        },
        {
            field: 'ProductDistribution',
            type: 'decimal',
            headerName: '%',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            valueGetter: (params) => params.row.ProductDistribution,
            format: (params) => Number(params.value).toFixed(1)
        }
    ]
};


const columnsVisibility = {
    "productAvailability":
    {
        'Product': true,
        'SKU': true,
        'ProductCategoryName': true,
        'ProductCompliance': true
    },
    "productDistribution":
    {
        'Product': true,
        'SKU': true,
        'ProductCategoryName': true,
        'ProductDistribution': true
    }
}


const dateFilterMaxDate = new Date(new Date().setDate(new Date().getDate() - 1));

const daysDropDown = [
    { LookupId: "-1", DisplayValue: "Custom" },
    { LookupId: "7:day", DisplayValue: "Last 7 Days" },
    { LookupId: "30:day", DisplayValue: "Last 30 Days" },
    { LookupId: "90:day", DisplayValue: "Last 90 Days" },
    { LookupId: "1:year", DisplayValue: "Last Year" },
    { LookupId: "0:currentMonth", DisplayValue: "Month to Date" },
    { LookupId: "0:currentQuarter", DisplayValue: "Quarter to Date" },
    { LookupId: "0:currentYear", DisplayValue: "Year to Date" },
]

function ProductReports(props) {
    const { t } = utils;
    const { config, mappedFilterValue } = props
    const tOpts = { t: props.t, i18n: props.i18n };
    const history = useHistory();
    const dispatch = useDispatch();
    const comboData = useSelector(state => state.appReducer.comboData);
    const filterValues = useSelector(state => state.appReducer.filterValues);
    const filterValuesRef = useRef(filterValues);
    const comboDataRef = React.useRef(comboData);
    const [data, setData] = useState({ gridData: [] });
    const [loading, setLoading] = useState({ filter: false, api: false });
    const [localCombo, setLocalCombo] = useState(comboData);
    const [summaryTittle, setSummaryTittle] = useState({
        "Market": [],
        "Classification": [],
        "Channel": [],
        "Distributor": []
    });

    const [columnVisibilityModel, setColumnVisibilityModel] = useState(columnsVisibility[config.reportKey]);

    function handleFilterModelChange(newModel) {
        getProductReport({ filterModel: newModel });
        dispatch({ type: actions.SET_FILTER_VALUES, filterValues: newModel });
        return (()=>{
            dispatch({ type: actions.SET_FILTER_VALUES, filterValues: {} });
        })
    }


    useEffect(() => {
        dispatch({
            type: actions.SET_FILTER_VALUES, filterValues: {
                daysFilter: "7:day",
                fromDate: dayjs().subtract(7, 'day'),
                toDate: dateFilterMaxDate
            }
        });
        setLoading({ ...loading, filter: true });

        return () => {
            clearFilters();
            dispatch({
                type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                    filterButton: null,
                    clear: null,
                    apply: null,
                    hidden: { search: false, operation: false, export: false, print: false, filter: false }
                }
            });
        }

    }, [])

    useEffect(() => {
        if (Object.keys(comboData).length) {
            setLocalCombo(prev => ({ ...prev, ...comboData, productCategory: mappedFilterValue.productCategory }));
        }
    }, [comboData, mappedFilterValue])

    useEffect(() => {
        const { channelIds = [], classificationIds = [], marketIds = [], changedKey = '' } = filterValues || {}
        const { Market, LocationClassification, LocationType } = comboData;
        const { market, classification, location } = mappedFilterValue;
        if (!(market && classification && location)) {
            return;
        }
        if (!(classificationIds.length || marketIds.length || channelIds.length)) {
            setLocalCombo(prev => ({ ...prev, ...{ Market, LocationClassification, LocationType } }));
            return;
        }
        if (classificationIds[0] === "-1" && marketIds[0] === "-1" && channelIds[0] === "-1") {
            setLocalCombo(prev => ({ ...prev, ...{ Market, LocationClassification, LocationType } }));
            return;
        }

        let tempMarkets = {}, tempLocation = {}, tempClassification = {};
        if (marketIds[0] !== "-1") {
            for (let marketId of marketIds) {
                const record = Market.find((value) => value.LookupId === marketId)
                if (record) {
                    const { LocationType: locationList = [], Classification = [] } = market[record.DisplayValue] || {};
                    for (let item of LocationType) {
                        if (!locationList.includes(item.DisplayValue)) {
                            continue
                        }
                        tempLocation[item.DisplayValue] = item;
                    }
                    for (let item of LocationClassification) {
                        if (!Classification.includes(item.DisplayValue)) {
                            continue
                        }
                        tempClassification[item.DisplayValue] = item;
                    }
                }
            }
        }
        if (classificationIds[0] !== "-1") {
            for (let classificationId of classificationIds) {
                const record = LocationClassification.find((value) => value.LookupId === classificationId)
                if (record) {
                    const { LocationType: locationList = [], MarketName = [] } = classification[record.DisplayValue] || {};
                    for (let item of LocationType) {
                        if (!locationList.includes(item.DisplayValue)) {
                            continue
                        }
                        tempLocation[item.DisplayValue] = item;
                    }
                    for (let item of Market) {
                        if (!MarketName.includes(item.DisplayValue)) {
                            continue
                        }
                        tempMarkets[item.DisplayValue] = item;
                    }
                }
            }
        }
        if (channelIds[0] !== "-1") {
            for (let channelId of channelIds) {
                const record = LocationType.find((value) => value.LookupId === channelId)
                if (record) {
                    const { MarketName = [], Classification = [] } = location[record.DisplayValue] || {};
                    for (let item of Market) {
                        if (!MarketName.includes(item.DisplayValue)) {
                            continue
                        }
                        tempMarkets[item.DisplayValue] = item;
                    }
                    for (let item of LocationClassification) {
                        if (!Classification.includes(item.DisplayValue)) {
                            continue
                        }
                        tempClassification[item.DisplayValue] = item;
                    }
                }
            }
        }

        //market
        if (changedKey !== "marketIds") {
            const useAllValues = (!classificationIds[0] || classificationIds[0] === "-1") && (!channelIds[0] || channelIds[0] === "-1");
            localCombo["Market"] = useAllValues ? Market : Object.values(tempMarkets);
        }
        //Classification
        if (changedKey !== "classificationIds") {
            const useAllValues = (!marketIds[0] || marketIds[0] === "-1") && (!channelIds[0] || channelIds[0] === "-1");
            localCombo["LocationClassification"] = useAllValues ? LocationClassification : Object.values(tempClassification);
        }
        //channels
        if (changedKey !== "channelIds") {
            const useAllValues = (!marketIds[0] || marketIds[0] === "-1") && (!classificationIds[0] || classificationIds[0] === "-1");
            localCombo["LocationType"] = useAllValues ? LocationType : Object.values(tempLocation);
        }
        setLocalCombo(prev => ({ ...prev, ...localCombo }));
    }, [filterValues, comboData])

    useEffect(() => {
        if (filterValues) {
            filterValuesRef.current = filterValues;
        }
        if (comboData) {
            comboDataRef.current = comboData;
        }

        if (filterValues && Object.keys(filterValues).length && loading.filter && !loading.api) {
            setLoading({ ...loading, api: true });
            getProductReport();
        }
    }, [filterValues,data])

    useEffect(() => {
        dispatch({
            type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                filterButton: <FilterPanel filtersConfig={filtersConfig} getData={null} daysDropDown={daysDropDown} dateFilterMaxDate={dateFilterMaxDate} customDate={true} />,
                filterButtonName: "Options",
                clear: clearFilters,
                apply: getProductReport,
                hidden: { search: true, operation: true, export: false, print: false, filter: false }
            }
        });
    }, [comboData, localCombo])


    function getFilterTittle() {
        const { Market = [], LocationClassification = [], Distributor = [], SalesPerson = [], LocationType = [] } = comboDataRef.current;
        const { channelIds = [], distributorIds = [], classificationIds = [], managerSelectedValue = [], marketIds = [], productCategoryIds = [] } = filterValuesRef?.current;
        const marketNames = [], distributorNames = [], classificationNames = [], channelNames = [], managerNames = [], productCategory = [];
        Market.map((item) => {
            if (marketIds.includes(item.LookupId)) { marketNames.push(item.DisplayValue) }
            return null
        })
        LocationClassification.map((item) => {
            if (classificationIds.includes(item.LookupId)) { classificationNames.push(item.DisplayValue) }
            return null
        })
        LocationType.map((item) => {
            if (channelIds.includes(item.LookupId)) { channelNames.push(item.DisplayValue) }
            return null
        })
        Distributor.map((item) => {
            if (distributorIds.includes(item.LookupId)) { distributorNames.push(item.DisplayValue) }
            return null
        })
        SalesPerson.map((item) => {
            if (managerSelectedValue.includes(item.LookupId)) { managerNames.push(item.DisplayValue) }
            return null
        })
        localCombo?.productCategory && localCombo.productCategory.map((item) => {
            if (productCategoryIds.includes(item.LookupId)) { productCategory.push(item.DisplayValue) }
            return null
        })

        const filterText = {
            "Market": marketNames.filter(n => n),
            "Classification": classificationNames.filter(n => n),
            "Channel": channelNames.filter(n => n),
            "Distributor": distributorNames.filter(n => n)
        }

        setSummaryTittle(filterText)
    }

    function getFilters(filterObj) {
        let filterParams = { where: {} };
        const { marketIds = [], classificationIds = [], channelIds = [], distributorIds = [], fromDate, toDate, managerSelectedValue = [], productCategoryIds = [] } = filterObj;

        if (fromDate) {
            filterParams.where.startDate = { value: new Date(new Date(fromDate).setHours(0, 0, 0, 0)) };
        }
        if (toDate) {
            filterParams.where.endDate = { value: new Date(new Date(toDate).setHours(23, 59, 59, 999)) };
        }
        if (classificationIds.length && classificationIds.join(",") !== "-1" && config.filterKeys.classification) {
            Object.assign(filterParams.where, { [config.filterKeys.classification]: { value: classificationIds } });
        }
        if (marketIds.length && marketIds.join(",") !== "-1" && config.filterKeys.market) {
            Object.assign(filterParams.where, { [config.filterKeys.market]: { value: marketIds } });
        }
        if (channelIds.length && channelIds.join(',') !== '-1' && config.filterKeys.channel) {
            Object.assign(filterParams.where, { [config.filterKeys.channel]: { value: channelIds } });
        }
        if (distributorIds.length && distributorIds.join(',') !== '-1' && config.filterKeys.distributor) {
            Object.assign(filterParams.where, { [config.filterKeys.distributor]: { value: distributorIds } });
        }
        if (managerSelectedValue && managerSelectedValue.length && config.filterKeys.salesManager) {
            let salesManagerIds = managerSelectedValue.map(m => m.LookupId || m);
            if (salesManagerIds.join(',') !== '-1') {
                Object.assign(filterParams.where, { [config.filterKeys.salesManager]: { value: salesManagerIds } });
            }
        }
        if (productCategoryIds.length && productCategoryIds.join(',') !== '-1' && config.filterKeys.productCategory) {
            Object.assign(filterParams.where, { [config.filterKeys.productCategory]: { value: productCategoryIds } });
        }

        return filterParams;
    }

    function flattenObject(obj, parentKey = '', result = {}) {
        for (let key in obj) {
            if (!obj.hasOwnProperty(key)) continue;
            if (key === 'where') {
                result[key] = obj[key];
                continue;
            }
            const newKey = parentKey ? `${parentKey}.${key}` : key;
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
                flattenObject(obj[key], newKey, result);
            } else {
                result[newKey] = obj[key];
            }
        }
        return result;
    }

    async function getProductReport({ columns = [], contentType = '', isForExport = false, filterModel = {} } = {}) {
        columns = columns || [];
        const payload = getFilters(filterValuesRef.current);
        let filters;
        if ((filterModel && Object.entries(filterModel)?.length > 0 && filterModel?.items) ) {
            filters = filterModel?.items || {};
        } else if (filterValuesRef?.current?.items ) {
            filters = filterValuesRef?.current?.items;
        } else {
            filters = {};
        }   
        payload.dataTypes = config.dataTypes;
        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 7);
        const defaultStartDate = { value: new Date(sevenDaysAgo.setHours(21, 0, 0, 0)).toISOString() };
        const defaultEndDate = { value: new Date(today.setHours(20, 59, 59, 999)).toISOString() };
        if ((!payload.where || Object.keys(payload.where).length === 0)) {
            payload.where = {
                startDate: filterValues?.fromDate || defaultStartDate,
                endDate: filterValues?.toDate || defaultEndDate,
            };
        }
        let reqParams = {
            isForExport: isForExport,
            contentType: contentType,
            responseType: contentType,
            columns: columns,
            showExport: isForExport,
            gridFilters: filters
        };
        reqParams = { ...reqParams, ...flattenObject(payload) };

        const template = null;
        let url = `${config.route}?isForExport=true&contentType=${contentType}`;
        if (contentType) {
            const form = document.createElement("form");
            form.setAttribute("method", "POST");
            form.setAttribute("id", "exportForm");
            form.setAttribute("target", "_blank");
            if (template === null) {
                for (const key in reqParams) {
                    let v = reqParams[key];
                    if (v === undefined || v === null) {
                        continue;
                    } else if (typeof v !== 'string') {
                        v = JSON.stringify(v);
                    }
                    let hiddenTag = document.createElement('input');
                    hiddenTag.type = "hidden";
                    hiddenTag.name = key;
                    hiddenTag.value = v;
                    form.append(hiddenTag);
                }
            }
            form.setAttribute('action', url);
            document.body.appendChild(form);
            form.submit();
            setTimeout(() => {
                document.getElementById("exportForm").remove();
            }, 3000);
            return;
        }
        const data = await request({ url: config.route, params: reqParams, history, dispatch, jsonPayload: true });
        if (data) {
            getFilterTittle();
            setData(data);
        }
    }

    const filtersConfig = [
        {
            name: "Filters",
            config: [
                {
                    name: "marketIds",
                    label: "Market",
                    type: "select",
                    multiSelect: true,
                    checkUncheck: true,
                    options: localCombo.Market || [],
                    size: 'small',
                    hidden: config.filterKeys.market ? false : true
                },
                {
                    name: "classificationIds",
                    label: "Classification",
                    type: "select",
                    multiSelect: true,
                    checkUncheck: true,
                    options: localCombo.LocationClassification || [],
                    size: 'small',
                    hidden: config.filterKeys.classification ? false : true
                },
                {
                    name: "channelIds",
                    label: "Channel",
                    type: "select",
                    multiSelect: true,
                    checkUncheck: true,
                    options: localCombo.LocationType || [],
                    size: 'small',
                    hidden: config.filterKeys.channel ? false : true
                },
                {
                    name: "distributorIds",
                    label: "Distributor",
                    type: "select",
                    multiSelect: true,
                    checkUncheck: true,
                    options: localCombo.Distributor || [],
                    size: 'small',
                    hidden: config.filterKeys.distributor ? false : true
                },
                {
                    name: "date",
                    type: "date",
                    size: 'small',
                    maxDate: dateFilterMaxDate,
                    hidden: false,
                    hideMenuLabel: true
                }
            ],
            openDefault: true
        }
    ]

    function clearFilters() {
        dispatch({
            type: actions.SET_FILTER_VALUES, filterValues: {
                fromDate: dayjs().subtract(7, 'day'),
                toDate: dateFilterMaxDate,
                daysFilter: "7:day",
            }
        });
        setLocalCombo(prev => ({ ...prev, ...comboData, productCategory: mappedFilterValue.productCategory }));
    }


    function formatCellColor(params) {
        if (params.field === "ProductCompliance" || params.field === "ProductDistribution") {
            if (params.value >= 75) {
                return 'grid-bg-green';
            } else if (params.value >= 50) {
                return "grid-bg-yellow";
            } else if (params.value >= 0) {
                return 'grid-bg-red';
            }
        }
    }

    const lineChartEnable = config.components.includes('dateChart');
    const barChartEnable = config.components.includes('monthChart');

    function onColumnVisibilityModelChange(visibleModel) {
        if (Object.values(visibleModel).length === 0) {
            setColumnVisibilityModel(columnsVisibility[config.reportKey]);
            return visibleModel;
        }
        const colsVisibilityModel = { ...columnVisibilityModel, ...visibleModel };
        const allColumnsVisible = Object.values(colsVisibilityModel).findIndex(e => e === true);
        if (allColumnsVisible === -1) {
            colsVisibilityModel[Object.keys(colsVisibilityModel)[0]] = true;
        }
        setColumnVisibilityModel(colsVisibilityModel);
    }

    const onExportClick = () => {
        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const isForExport = true;
        const cols = config?.reportKey && utils.generateColunms(exportColumns[config.reportKey]);
        getProductReport({ columns: cols, contentType, isForExport });
    };

    useEffect(() => {
        dispatch({ type: actions.SET_OPERATIONS_INFORMATION, operationsCheckedData: { onExportClick } });
    }, []);
    return <>
        <InfoBar infoBarData={data?.infoBar} summaryTittle={summaryTittle} t={t} tOpts={tOpts} />

        <Grid spacing={0} container className="">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card>
                    <CardContent className="p-2" style={{ height: "600px" }}>
                        <GridBase
                            columns={columns[config.reportKey]}
                            data={data?.gridData}
                            movePagination={false}
                            autoHeight={false}
                            pagination={false}
                            otherOptions={{
                                disableSelectionOnClick: true, density: "compact", getCellClassName: formatCellColor,
                                columnVisibilityModel, onColumnVisibilityModelChange
                            }}
                            filterMode="server"
                            onFilterModelChange={handleFilterModelChange}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

        {(lineChartEnable || barChartEnable) &&
            <Paper elevation={2} sx={{ maxWidth: 500 }} className="pl-3 pr-3 pb-3 mt-2">
                <Grid container spacing={2} className="mt-3">
                    {lineChartEnable &&
                        <Grid item xs={barChartEnable ? 6 : 12} sm={barChartEnable ? 6 : 12} md={barChartEnable ? 6 : 12} lg={barChartEnable ? 6 : 12}>
                            <Typography className="portfolio-chart-title" variant={isMobile ? "h5" : "h4"} gutterBottom component="div">{t(config.reportName, tOpts)} {t('by date', tOpts)}</Typography>
                            {data?.dateChartData ? <PadLineChart data={data.dateChartData} defaultTooltip={false} /> : <p>{t('No Data', tOpts)}</p>}
                        </Grid>}
                    {barChartEnable &&
                        <Grid item xs={lineChartEnable ? 6 : 12} sm={lineChartEnable ? 6 : 12} md={lineChartEnable ? 6 : 12} lg={lineChartEnable ? 6 : 12}>
                            <Typography variant={isMobile ? "h5" : "h4"} gutterBottom component="div">{t(config.reportName, tOpts)} {t('by month', tOpts)}</Typography>
                            {data?.monthChartData ? <PadBarChart data={data.monthChartData} /> : <p>{t('No Data', tOpts)}</p>}
                        </Grid>}
                </Grid>
            </Paper>}
    </>
}

export default withTranslation()(ProductReports)