import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import utils from '../../utils';
import dateUtil from '../../utils/date';
import VistaPhotoAction from './VistaPhotoAction'
import { useSelector } from 'react-redux';
import imageView from '../../assets/images/vista-images/imageview.png';
import { withTranslation } from 'react-i18next';
const t = utils.t;
const VistaPhotoTake = React.memo(function VistaPhotoTake(props) {
    const { data, t: translate, i18n } = props;
    const tOpts = { t: translate, i18n };
    const { RowNumber = '', PurityDateTime = '', TimeSlotSettings = '' } = data;
    const loginData = useSelector(state => state.appReducer.userData) || {};
    const clientId = loginData && loginData.tags && loginData.tags.ClientId ? Number(loginData.tags.ClientId) : null
    let purityDateTime = PurityDateTime ? dateUtil.dateMatch(PurityDateTime) : 'N/A';
    let timeSlots = TimeSlotSettings ? JSON.parse(TimeSlotSettings) : {}
    timeSlots = timeSlots.ImageTimeSlot ? timeSlots.ImageTimeSlot.split(',') : []


    return <Box className='vista-box-mid mt-3 mb-2'>
        <Grid container wrap="nowrap" spacing={1}>
            <Grid item>
                <div className="icon-size">
                    <img src={imageView} alt={`img${RowNumber}`} />
                </div>
            </Grid>
            <Grid item xs>
                <Typography variant="body2" gutterBottom className='vista-photo-take'> {t("Photo Taken",tOpts)}</Typography>
                <Typography variant="h4" gutterBottom>{purityDateTime}</Typography>
                <hr />
                {(utils.batteryPermissionClientIds.includes(clientId)) && <>
                    <Typography variant="body2" gutterBottom className='vista-photo-take'>{t("Scheduled time slots",tOpts)} </Typography>
                    <div className="schedule-time-list"><ul>{timeSlots.length ? timeSlots.map((item) => { return (item && <li >{item.trim()}</li>) }) : <b>No Time Schedule is Set</b>}</ul></div>
                    <hr />
                </>}
                <div className="vista-photo-take-link">
                    <VistaPhotoAction data={data} />
                </div>
            </Grid>
        </Grid>
    </Box>
}, ({ data: prvData }, { data: nextData }) => {
    return (prvData.RowNumber === nextData.RowNumber)
        && (prvData.PurityDateTime === nextData.PurityDateTime)
        && (prvData.AssetId === nextData.AssetId)
        && (prvData.StitchedImageURL === nextData.StitchedImageURL)
        && (prvData.SmartDeviceId === nextData.SmartDeviceId)
        && (prvData.TimeSlotSettings === nextData.TimeSlotSettings)
        && (prvData.SmartDeviceTypeId === nextData.SmartDeviceTypeId)
})

export default withTranslation()(VistaPhotoTake);