
import React, { forwardRef } from 'react';
import {
    Button
} from "@material-ui/core";
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@material-ui/core/Collapse';
import { ORDER_STATUS } from '../../constants';
import { apis } from '../../httpUtil';

const OrderSummary = forwardRef(({
    onConfirm,
    products,
    isOrderCollapsed,
    orderStatusId,
    editOrder,
    outletData,
    t, tOpts,
    gridData,
    onScheduledIgnoreClick
}, ref) => {
    const userData = useSelector(state => state.appReducer.userData);
    const { PlaybookHideInTransitButton, PlaybookOrderingTab = {} } = userData?.modules ? userData.modules : {};
    const hideInTransitButton = !Number(userData?.tags?.IsSuperAdmin) > 0 && PlaybookHideInTransitButton.Module;

    const orderQuantityEnum = {
        Increase: 'increase',
        Decrease: 'decrease'
    };

    if (!products) {
        return
    }
    let totalPrice = 0;
    for (const product of products) {
        totalPrice += (Number(product.UnitPrice) * Number(product.UnitsNeeded));
    }

    return (
        <div className="ordering-tab-table-container">
            <div className="ordering-tab-sticky-header">
                <span className="order-summary-heading f-f-ebrima">{t('Order Products', tOpts)}</span>
            </div>
            <Collapse in={!isOrderCollapsed} >
                <div className="ordering-tab-table-scroll" ref={ref}>
                    <table className="ordering-tab-table">
                        <tbody>
                            {products.map((product, index) => (
                                <tr key={index}>
                                    <div className="ordering-tab-table-cell">
                                        <td>
                                            <div className="d-flex mr-1">
                                                {product.ProductId ? (
                                                    <img alt='...' src={`${apis.imgBaseUrl}/controllers/thumbnail.ashx?imageType=Thumbnail&ImagePath=${product.ProductId}.png&maxWidth=30`} className="expanded-row-card-product py-2" />
                                                ) : (
                                                    ''
                                                )}
                                                <div className="d-flex flex-column ml-2">
                                                    <p className="ordering-tab-product-name fw-bold">{product.ProductName}</p>
                                                    <p className="ordering-tab-case-size">{product.ProductCategoryName || 'No Category'}</p>
                                                    <p className="ordering-tab-case-size fw-bold">Case of {product.CaseSize}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <button className="ordering-tab-table-button-left" onClick={e => { onConfirm(e, index, orderQuantityEnum.Decrease) }}>-</button>
                                                <span className="ordering-tab-table-quantity">{product.UnitsNeeded}</span>
                                                <button className="ordering-tab-table-button-right" onClick={e => { onConfirm(e, index, orderQuantityEnum.Increase) }}>+</button>
                                            </div>
                                        </td>
                                    </div>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="ordering-tab-button-container p-1">
                    <Button size="small" onClick={editOrder} ><AddIcon className="background-theme-green-order-list-add-btn" /></Button>
                </div>
                <div className="ordering-tab-total-container">
                    <div className="ordering-tab-total">
                        <strong>{t('Total', tOpts)}: </strong>{outletData?.userCurrencySymbol}{totalPrice.toFixed(2)}
                    </div>
                    {orderStatusId === ORDER_STATUS.INBOX &&
                        <>
                            <Button size="small" variant="contained" color='warning' className="background-theme-red sub-header-button Location-Reorder-red mr-1" onClick={(e) => onScheduledIgnoreClick(e, ORDER_STATUS.IGNORED, gridData.data.records[0])}>{t("Ignore", tOpts)}</Button>
                            <Button size="small" variant="contained" color='primary' className="background-theme-green sub-header-button Location-Reorder-green" onClick={(e) => onScheduledIgnoreClick(e, ORDER_STATUS.SCHEDULED, gridData.data.records[0])}>{t("Schedule", tOpts)}</Button>
                        </>
                    }

                    {orderStatusId === ORDER_STATUS.SCHEDULED &&
                        <>
                            {!hideInTransitButton && <Button size="small" className="background-theme-green sub-header-button Location-Reorder-green mr-1" variant="contained" color='primary' onClick={(e) => onScheduledIgnoreClick(e, ORDER_STATUS.INTRANSIT, gridData.data.records[0])}>{t("In Transit", tOpts)}</Button>}
                            <Button size="small" variant="contained" color='warning' className="background-theme-red sub-header-button Location-Reorder-red" onClick={(e) => onScheduledIgnoreClick(e, ORDER_STATUS.INBOX, gridData.data.records[0])}>{t("Inbox", tOpts)}</Button>
                        </>
                    }

                    {orderStatusId === ORDER_STATUS.INTRANSIT &&
                        <Button size="small" variant="contained" color='primary' className="background-theme-green sub-header-button Location-Reorder-green" onClick={(e) => onScheduledIgnoreClick(e, ORDER_STATUS.INBOX, gridData.data.records[0])}>{t("Delivered", tOpts)}</Button>
                    }
                    {orderStatusId === ORDER_STATUS.IGNORED &&
                        <Button size="small" variant="contained" color='primary' className="background-theme-green sub-header-button Location-Reorder-green" onClick={(e) => onScheduledIgnoreClick(e, ORDER_STATUS.SCHEDULED, gridData.data.records[0])}>{t("Schedule", tOpts)}</Button>
                    }
                </div>
            </Collapse>
        </div>
    );
});


export default OrderSummary;