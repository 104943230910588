import UiModel from './ui-model';
import { getGridStringOperators } from '@mui/x-data-grid-premium';
const stringFilterOperators = getGridStringOperators().filter((operator) => operator.value === 'contains');

const masterUserModel = new UiModel({
    title: "Master Data",
    titleDescription: "Check how your master data is set",
    defaultSort: 'FirstName ASC',
    api: 'ClientUser',
    idProperty: 'ClientUserId',
    readOnly: true,
    addCreatedModifiedColumns: false,
    preferenceId: 'playbookMasterUser',
    columns: [
        { field: "FirstName", type: 'string', width: 150, label: "First Name" },
        { field: "LastName", type: 'string', width: 150, label: "Last Name" },
        { field: "PrimaryEmail", type: "string", width: 250, label: "Email" },
        { field: "PrimaryPhone", type: 'string', width: 150, label: "Phone" },
        { field: "EmployerName", type: 'string', width: 150, label: "Employer" },
        { field: "Role", type: 'string', width: 150, label: " Role" },
        { field: "PreferedNotificationType", type: 'string', width: 150, label: "Preferred Notification Type", hide: true, sortable: false, filterOperators: stringFilterOperators },
        { field: "MarketName", type: 'string', width: 150, label: "Market", hide: true, sortable: false, filterOperators: stringFilterOperators },
        { field: "KeyAccountName", type: 'string', width: 150, label: "Key Account", hide: true, sortable: false, filterOperators: stringFilterOperators },
        { field: "ClassificationName", type: 'string', width: 150, label: "Classification", hide: true, sortable: false, filterOperators: stringFilterOperators },
        { field: "DistributorName", type: 'string', width: 150, label: "Distributor", hide: true, sortable: false, filterOperators: stringFilterOperators }

    ]
});

export default masterUserModel;