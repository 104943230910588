import React, { useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Header, Sidebar } from '../../components';
import { isIOS } from 'react-device-detect';
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import useMobile from '../../utils/useMobile';

const LeftSidebar = props => {
  const { children } = props;
  const isMobile = useMobile(true);
  const [open, setOpen] = useState(false)
  const sidebarToggle = useSelector(state => state.appReducer.sidebarToggle);
  const sidebarFixed = useSelector(state => state.appReducer.sidebarFixed);
  const { twoOptionsButton = false} = useSelector(state => state.appReducer.filtersInHeader) || {};
  const [appContent,setAppContent] = useState('app-content');
  const userData = useSelector(state => state.appReducer.userData);
  const { ScheduledOrderId = 0 } = userData && userData.tags ? userData.tags : 0;

  // TODO: DETECT PRINT POPUP AND DEACTIVATE app-content CLASS
  /*
  let beforePrint = function() {
    setAppContent("")
  };

  let afterPrint = function() {
    setAppContent("app-content")
  }

  window.onbeforeprint = beforePrint;
  window.onafterprint = afterPrint;
  */

  return (
    <div className={'app-wrapper'}>
      <Header />
      <div className={clsx('app-main', { 'app-main-sidebar-static': !false })}>
        {/* Hiding the Sidebar if the Schedule Order Id is greater than 0 */}
        {!Number(ScheduledOrderId) > 0 && <Sidebar />}
        <div className={clsx(`${appContent}`, {
          'app-content-sidebar-fixed': sidebarFixed && !isIOS,
          'app-content-sidebar-fixed-IOS': sidebarFixed && isIOS,
          'app-content-sidebar-collapsed': sidebarToggle,
          'app-content-sub-menu-toggle-width': !isMobile && sidebarToggle,
          'app-content-sub-menu-mobile': isMobile
        })}>
          <SecondaryHeader open={open} setOpen={setOpen} />
          <div className="app-content--inner">
            <div className="app-content--inner__wrapper">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSidebar;