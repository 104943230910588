import React from 'react';
import { RxCross2 } from 'react-icons/rx';
import { Modal, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import utils from '../../utils';

const t = utils.t;
function AlertPopUp({ onClose, UpadatePage }) {
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  return (
    <Modal open={true} onClose={onClose}>
      <Paper className="alert-modal-content">
        <div className="alert-popup-cross-icon-div">
          <RxCross2 className='alert-popup-icon' onClick={onClose} />
        </div>
        <div>
          <div className="alert-popup-icon-image-div">
            <img src={require(`../../assets/images/alert-popup-icon2.png`)} height="80" alt={'Export'} />
          </div>
          <div className="alert-popup-text-div">
            <p>
              {t("This page will be discontinued by January 24, 2024.",tOpts)+" "}
            {t("Discover enhanced features and the latest updates",tOpts) +" "}
           {`${t("on our",tOpts)} ${t(UpadatePage,tOpts)} ${t("Page",tOpts)+"!"}`}       
            </p>
          </div>
        </div>
      </Paper>
    </Modal>
  );
}

export default AlertPopUp;