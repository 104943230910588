import React, { useState, useCallback } from 'react';
import { Grid, Card, Typography, Box, CardContent } from '@material-ui/core';
import utils from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../redux/actions';
import VistaGoodBad from './VistaGoodBad';
import VistaPhotoAction from './VistaPhotoAction';
import { useSwipeable } from 'react-swipeable';
import { request, apis } from "../../httpUtil";
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import VistaImage from '../VistaLocationCardList/VistaImage';
import dateUtil from '../../utils/date';
import { useTranslation } from 'react-i18next';

import imageView from '../../assets/images/vista-images/imageview.png';
import locationPin from '../../assets/images/vista-images/locationpin.png';
import ImageNotFound from '../../assets/images/vista-images/imagenotfound.jpg';
import { automationModel } from '../../constants';

const { COMPASS } = automationModel;
const t = utils.t;

const VistaCardMobile = React.memo(function VistaCardMobile(props) {
    const [loading, setLoading] = useState(true);
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n }; 
    const [automationValues, setAutomationValues] = useState({ totalScoreAvg: 0, checked: false, fullCount: 0, lowCount: 0, emptyCount: 0, automationData: {} });
    const dispatch = useDispatch();
    const history = useHistory();
    const { data } = props;
    const { RowNumber = '', StitchedImageURL = ImageNotFound, PurityDateTime = '', AssetSerialNumber = '', LocationName = '', FullAddress = '', AssetPurityId = '', AssetId = '' } = data;
    let purityDateTime = PurityDateTime ? dateUtil.dateMatch(PurityDateTime) : 'N/A';
    const loginData = useSelector(state => state.appReducer.userData) || {};
    const clientId = loginData && loginData.tags && loginData.tags.ClientId ? Number(loginData.tags.ClientId) : null
    const imageLoaded = () => {
        setLoading(false);
    }
    const onSwipe = useCallback(async (isNext) => {
        setLoading(true);
        let params = {
            action: "list", asArray: 0, showAllImages: false, isFetchCoolerData: true, AssetPurityId: AssetPurityId ? AssetPurityId : 0, AssetId: AssetId
        };
        Object.assign(params, { PrevNext: isNext ? 1 : -1 });
        const response = await request({ url: apis.assetLatestImages, params, history, dispatch });
        if (response && response.records && response.records.length > 0) {
            dispatch({ type: 'SET_PREV_NEXT_VISTA_DATA_RECORD', vistaData: { UpdatedRecord: response.records[0], rowId: RowNumber } });
        } else {
            setLoading(false);
            toast(isNext ? "Already showing latest image." : "There is no previous image available.");
        }
    }, [AssetPurityId, AssetId, RowNumber, dispatch, history])

    const handlers = useSwipeable({
        onSwipedLeft: () => onSwipe(true),
        onSwipedRight: () => onSwipe(false),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const handleSwitchChange = async (preAiData, event) => {
        const toggleValue = event.target.checked;
        if (!toggleValue || Object.keys(preAiData).length > 0) {
            setAutomationValues((prevState) => ({ ...prevState, checked: toggleValue }));
            return;
        }
        const params = { action: 'loadImage', clientId: clientId, currentId: Number(event.target.value), reProcess: true, modelName: COMPASS };
        const response = await request({ url: apis.ImageProcessing, params, history, dispatch });
        let aiData = {};
        if (response) {
            const parseResponse = JSON.parse(response);
            if (parseResponse && parseResponse.ImageProcessResult) {
                aiData = JSON.parse(parseResponse.ImageProcessResult).AutomationData;
                let totalScore = 0, lowCount = 0, emptyCount = 0, fullCount = 0, score = 0, totalScoreAvg = 0;
                aiData.scores.forEach(scores => {
                    score = scores ? Number((scores * 100).toFixed(0)) : 0;
                    totalScore += score;
                    if (score <= 50) {
                        emptyCount++;
                    }
                    else if (score > 50 && score <= 75) {
                        lowCount++;
                    }
                    else if (score > 75) {
                        fullCount++;
                    }
                });
                totalScoreAvg = totalScore > 0 ? totalScore / aiData.scores.length : 0;
                totalScoreAvg = Number(totalScoreAvg.toFixed(0));
                setAutomationValues({ totalScoreAvg: totalScoreAvg, checked: toggleValue, emptyCount: emptyCount, fullCount: fullCount, lowCount: lowCount, automationData: aiData });
            } else {
                setAutomationValues({ totalScoreAvg: 0, checked: toggleValue, emptyCount: 0, fullCount: 0, lowCount: 0, automationData: aiData });
                toast("No response from API, Please contact to admin");
            }
        } else {
            setAutomationValues({ checked: toggleValue, automationData: aiData });
            toast("Something went wrong. Please try again later");
        }
    };

    return <Box ><Grid container item xs={12}>
        <Card className="card-box-hover-rise" style={{ width: '100%' }}>
            <div style={{ height: "400px" }}>
                <VistaImage data={data} isToggleChecked={automationValues.checked} aiData={automationValues.automationData} />
            </div>
            <CardContent className="mobile-content-padding card-body">
                <div>
                    <Box className='vista-box'>
                        <Typography variant="h4" title={AssetSerialNumber} gutterBottom className="vista-address-mobile"> {AssetSerialNumber}</Typography>
                    </Box>
                    <div className="divider my-4" />
                    <Box className='vista-box'>
                        <Grid container wrap="nowrap" spacing={2} item xs={12}>
                            <Grid item>
                                <div className="icon-size">
                                    <img src={locationPin} alt={`pin${RowNumber}`} />
                                </div>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" title="test me" gutterBottom className="vista-address-mobile ellipsis-text"> {LocationName} {FullAddress}</Typography>
                                <span className="red" style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: actions.SET_VISTA_SEARCH, vistaSearch: LocationName })}>{t("Show all at this address", tOpts)}</span>
                            </Grid>
                        </Grid>
                    </Box>
                    <div className="divider my-4" />
                    <Box className='vista-box'>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>
                                <div className="icon-size">
                                    <img src={imageView} alt={`img${RowNumber}`} />
                                </div>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" gutterBottom>{`Taken ${purityDateTime}`}</Typography>
                                <div className="vista-photo-take-link-mobile">
                                    <VistaPhotoAction isMobile={true} data={{ ...data }} labels={{ takePhotoLabel: 'take new', scheduleLabel: 'set schedule' }} />
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                    <div className="divider my-4" />
                    {(utils.batteryPermissionClientIds.includes(clientId)) && <>
                        <Box display="flex">
                            <Box flexGrow={0.3} m={1}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={automationValues.checked}
                                            onChange={handleSwitchChange.bind(this, automationValues.automationData)}
                                            name="checked"
                                            value={data.AssetPurityId}
                                        />}
                                    label="Analytics"
                                    labelPlacement='bottom'
                                />
                            </Box>
                            <Box flexGrow={0.3} alignItems="center" display="flex">
                                {/* replace the values in {} with dynamic value from API */}
                                <Box component="h2">
                                    {automationValues.totalScoreAvg}%
                                </Box>
                            </Box>
                            <Box flexGrow={0.4} display="flex" justifyContent="flex-end" flexDirection="column" m={1}>
                                <p className="p-0 m-0"><span><FontAwesomeIcon icon={faCircle} size="lg" className="text-success pr-2" /></span>{automationValues.fullCount} {t("Full", tOpts)}</p>
                                <p className="p-0 m-0"><span><FontAwesomeIcon icon={faCircle} size="lg" className="text-warning pr-2" /></span>{automationValues.lowCount} {t("Low", tOpts)}</p>
                                <p className="p-0 m-0"><span><FontAwesomeIcon icon={faCircle} size="lg" className="text-danger pr-2" /></span>{automationValues.emptyCount} {t("Empty", tOpts)}</p>
                            </Box>
                        </Box>
                        <div className="divider my-4" />
                    </>}
                    <Grid container wrap="nowrap" className="text-center"  >
                        <Grid item xs={12}>
                            <VistaGoodBad data={{ ...data }} />
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    </Grid>
    </Box>
})

export default VistaCardMobile;