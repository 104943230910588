import UiModel from './ui-model';

const outletactions = new UiModel({
    title: "Outlet Action",
    defaultSort: 'PlannedDate DESC',
    linkColumn: 'Status',
    api: 'LocationAction',
    module: "OutletActions",
    gridTitle: "Outlets Actions",
    titleDescription: 'Outlet Actions',
    pageTitle: "Outlet Actions",
    showAddIcon: false,
    breadCrumbs: "Outlet Actions",
    overrideFileName: 'Outlet Actions',
    overrideLoaderOnInitialRender: true,
    addCreatedModifiedColumns: false,
    showCopy: true,
    showCreateButton: true,
    backURL: '/OutletActions',
    preferenceId: 'playbookOutletActions',
    columns: [
        { field: "Code", type: 'string', label: "Outlet Code", width: 100, showOnForm: false },
        { field: "Name", type: 'string', label: "Outlet Name", width: 100, showOnForm: false },
        { field: "Street", type: 'string', label: "Street", width: 100, showOnForm: false },
        { field: "City", type: 'string', label: "City", width: 100, showOnForm: false },
        { field: "PostalCode", type: 'string', label: "Postal Code", width: 80, showOnForm: false },
        { field: "State", type: 'string', label: "State", width: 80, showOnForm: false, hide: true },
        { field: "Country", type: 'string', label: "Country", width: 90, showOnForm: false },


        { field: "PlannedDate", type: 'date', label: "Planned Date", width: 150, allowNull: true },
        { field: "CompletionDate", type: 'date', label: "Completion Date", width: 150, allowNull: true },
        {
            field: "AssignedToUser", allowNull: true, filterField: "AssignedToUser", type: 'singleAutocomplete', label: "Assigned To User", width: 200, lookup: 'ClientUserLookup', selectField: "AssignedToUserId", useFilterField: true, singleSelect: true, renderCell: (params) => {
                const { row } = params;
                return row.AssignedToUser
            }
        },
        { field: "Status", type: 'string', label: "Status", width: 100, showOnForm: false },
        { field: "ActionTypeId", type: 'radio', label: "Outlet Action", width: 100, lookup: 'OutletActionType', showOnForm: false },
        { field: "ClientName", type: 'string', label: "Client", width: 150, showOnForm: false }
    ]
});

export default outletactions;
