import React, { useEffect } from 'react';
import { Box, TextField, Button, Typography, IconButton, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import FormGenerator from './FormGenerator';
import { useDispatch, useSelector } from 'react-redux';
import utils from '../../utils';
import { useTranslation } from 'react-i18next';
import HelpIcon from '@mui/icons-material/Help';
import { apis, request } from '../../httpUtil';
import { useHistory } from 'react-router';
import { useSnackbar } from '@durlabh/dframework-ui';
import { makeStyles } from '@material-ui/core';
import * as yup from 'yup';
import enums from '../../utils/constants';
import PageTitle from '../PageTitle';
import actions from '../../redux/actions';

const useStyles = makeStyles({
    boldStyle: { fontWeight: 'bold !important' },
    name: {
        width: '30% !important',
        backgroundColor: 'white'
    },
    reportBtn: {
        marginLeft: '20px !important',
        backgroundColor: '#8497B0 !important'
    },
    saveBtn: {
        backgroundColor: '#172EB5 !important'
    }
})
const getValidationSchema = (t, tOpts) => {
    return yup.object().shape({
        name: yup.string()
            .trim()
            .required(t('Name is required', tOpts))
            .max(50, t('Name should not be more than 50 characters', tOpts)),
        kpiValues: yup.array().of(
            yup.object())
            .test('values-and-weights', t('Please fill the values on the edited rows', tOpts), function (kpiValues) {
                for (let kpi of kpiValues) {
                    const hasValue = kpi.value && kpi.value.trim() !== '';
                    const hasWeight = kpi.weight && kpi.weight.trim() !== '';
                    if ((hasValue && !hasWeight) || (!hasValue && hasWeight)) {
                        return false;
                    }
                }
                return true;
            })
            .test('total-weight-100', t('The conditions could not be saved. The weight has to be summed to 100%.', tOpts), function (kpiValues) {
                const totalWeight = kpiValues.reduce((sum, kpi) => sum + parseFloat(kpi.weight || '0'), 0);
                return totalWeight === 100;
            })
    });
};
const { temperatureUnit } = enums;

const getSuffix = ({ tags }) => {
    if (!tags || tags.TempratureUnitId === undefined) {
        return 'Unit not set';
    }
    const temperatureUnitValue = parseInt(tags.TempratureUnitId) !== temperatureUnit.Celsius ? '°F' : '°C';
    return temperatureUnitValue;
}


const MyKPIConfigurator = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const userData = useSelector(state => state.appReducer.userData);
    const snackbar = useSnackbar();
    const classes = useStyles();
    let totalWeight = 0;
    const kpiConfig = {};
    const suffix = getSuffix({ tags: userData?.tags });
    const validationSchema = getValidationSchema(t, tOpts);
    const initialKpiData = [
        { kpi: 'Own Share of Shelf', is: 'Greater than', value: '', weight: '', suffix: '%', dataIndex: 'OwnShareOfShelf' },
        { kpi: 'Foreign Share of Shelf', is: 'Less than', value: '', weight: '', suffix: '%', dataIndex: 'ForeignShareOfShelf' },
        { kpi: 'Empty Share of Shelf', is: 'Less than', value: '', weight: '', suffix: '%', dataIndex: 'EmptyShareOfShelf' },
        { kpi: 'Own Shelf Availability', is: 'Greater than', value: '', weight: '', suffix: '%', dataIndex: 'OwnShelfAvailability' },
        { kpi: 'Assortment Depth Numeric', is: 'Greater than', value: '', weight: '', suffix: '%', dataIndex: 'AssortmentDepthNumeric' },
        { kpi: 'Assortment Depth Compliance', is: 'Greater than', value: '', weight: '', suffix: '%', dataIndex: 'AssortmentDepthCompliance' },
        { kpi: 'Target Portfolio Compliance', is: 'Greater than', value: '', weight: '', suffix: '%', dataIndex: 'TargetPortfolioCompliance' },
        { kpi: 'Planogram Compliance', is: 'Greater than', value: '', weight: '', suffix: '%', dataIndex: 'PlanogramCompliance' },
        { kpi: 'Displacement', is: 'Less than', value: '', weight: '', suffix: 'm', dataIndex: 'Displacement' },
        { kpi: 'Temperature', is: 'Less than', value: '', weight: '', suffix: suffix, dataIndex: 'Temperature' }
    ];

    const initialValues = {
        name: '',
        kpiValues: initialKpiData
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { ...initialValues },
        validationSchema: validationSchema,
        validateOnBlur: false,
        onSubmit: async (values) => {
            values.kpiValues.forEach(kpi => {
                if (kpi.weight) {
                    totalWeight += parseInt(kpi.weight, 10);
                }

                if (kpi.value && kpi.weight) {
                    kpiConfig[kpi.dataIndex] = { value: kpi.value, weight: kpi.weight };
                }
            });

            const ClientId = userData?.tags?.ClientId ?? undefined;
            const reqParams = {
                ClientId: ClientId,
                KPIName: values.name,
                KPIConfiguration: kpiConfig
            }
            try {
                const response = await request({ url: apis.MyKPIConfigurator, params: reqParams, history, dispatch, jsonPayload: true });
                if (response.success) {
                    snackbar.showMessage(t("Submitted successfully", tOpts));
                }
            } catch (error) {
                snackbar.showError(error.message);
            }
        }
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { errors } = formik;
        if (errors.name) {
            snackbar.showError(errors.name);
            return;
        }
        if (errors.kpiValues) {
            snackbar.showError(errors.kpiValues);
            return;
        }
        const isValid = formik.values?.kpiValues.every(item => item.value !== '0' && item.weight !== '0');
        if (!isValid) {
            snackbar.showError(t('Value or weight cannot be zero.', tOpts));
            const updatedKpiValues = formik?.values?.kpiValues.map(item => ({
                ...item,
                value: item.value === '0' ? '' : item.value,
                weight: item.weight === '0' ? '' : item.weight
            }));

            formik.setFieldValue('kpiValues', updatedKpiValues);
            return;
        }
        formik.handleSubmit();
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { ClientId } = userData && userData.tags ? userData.tags : {};
                const { success, data } = await request({ url: apis.MyKPIConfigurator, params: { ClientId }, history, dispatch, jsonPayload: true });
                if (success && data) {
                    const fetchedKPIs = data.KPIConfiguration ? JSON.parse(data.KPIConfiguration) : {};
                    const updatedKpiValues = initialKpiData.map(kpi => {
                        const fetchedKpi = fetchedKPIs && fetchedKPIs[kpi.dataIndex];
                        return {
                            ...kpi,
                            value: fetchedKpi && fetchedKpi.value ? fetchedKpi.value : kpi.value,
                            weight: fetchedKpi && fetchedKpi.weight ? fetchedKpi.weight : kpi.weight
                        };
                    });
                    formik.setValues({
                        name: data.KPIName || '',
                        kpiValues: updatedKpiValues
                    });
                }
            } catch (error) {
                snackbar.showError(error.message || t('Failed to fetch KPI data', tOpts));
            }
        };

        fetchData();
    }, [userData]);



    const handleNameChange = (event) => {
        let value = event.target.value;
        value = value.replace(/^\s+/, '');
        value = value.replace(/\s{2,}/g, ' ');
        formik.setFieldValue('name', value);
    };

    useEffect(() => {
        dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle titleHeading={''} titleDescription={""} icon='' title={t("", tOpts)} classTitle="home-header-container" /> });
        utils.removeBackButton(dispatch);
        return () => {
            dispatch({
                type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
            })
        }
    }, [])

    return (
        <div>
            <Typography className={classes.boldStyle}>{t("My KPI Name", tOpts)}</Typography>
            <TextField
                type="text"
                name="name"
                label="Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formik.values.name}
                onChange={handleNameChange}
                onBlur={formik.handleBlur}
                className={classes.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
            />
            <Typography className={classes.boldStyle}>{t("Calculation", tOpts)}
                <Tooltip title={t("My Kpis", tOpts)}>
                    <IconButton>
                        <HelpIcon color='primary' />
                    </IconButton>
                </Tooltip>
            </Typography>
            <FormGenerator
                kpiValues={formik.values.kpiValues}
                setFieldValue={formik.setFieldValue}
                errors={formik.errors.kpiValues}
                touched={formik.touched.kpiValues}
            />
            <Box mt={2}>
                <Button variant="contained" size="small" className={classes.saveBtn} type="submit" onClick={handleSubmit}>{t("Save", tOpts)}</Button>
                <Button variant="contained" size="small" className={classes.reportBtn}>{t("Go to My KPI report", tOpts)}</Button>
            </Box>
        </div>
    );
};

export default MyKPIConfigurator;
