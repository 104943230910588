import React, { useEffect, useState } from "react";
import { useDispatch, } from "react-redux";
import { useHistory } from "react-router";
import { apis, request } from "../../httpUtil";
import {
    Card, CardContent, Grid, CardHeader, Typography,
    Accordion, AccordionSummary, AccordionDetails, Chip,
    Switch, Tabs, Tab
} from "@material-ui/core";
import swal from 'sweetalert';
import actions from "../../redux/actions";
import utils from "../../utils";
import GridBase from "../GridBase";
import CloseIcon from '@material-ui/icons/Close';
import { BarChart } from './chartsCommon'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from "react-i18next";
import useMobile from "../../utils/useMobile";
import AlertPopUp from "../../pages/VistaLocationDetails2/AlertPopup";
const t = utils.t
const colors = utils.chartColorsD;

const statusCards = [
    { name: "Installed", key: "Installed", image: "installed.png", color: colors[1] },
    { name: "Modem Not Pinging", key: "ModemNotPinging", image: "modem.png", color: colors[3] },
    { name: "Camera Not Pinging", key: "CameraNotPinging", image: "camera.png", color: colors[3] },
    { name: "Camera Not Taking Images", key: "CameraNotTakingImages", image: "takePhoto.png", color: colors[3] },
    { name: "Planogram Not Assigned", key: "PlanogramNotAssigned", image: "freezerWithProduct.png", color: colors[3] },
    { name: "Working", key: "Working", image: "checked.png", color: colors[2] }
]

const pieCharts = [
    { name: 'Market', dataKey: 'market', colorIndex: 1 },
    { name: 'Classification', dataKey: 'classification', colorIndex: 5 },
    { name: 'Channel', dataKey: 'channel', colorIndex: 8 },
]

const gridTables = [
    { name: 'Set Up Required', dataKey: 'SetupRequired', image: 'setupRequired', description: "The problems listed can be fixed with a configuration change. Please contact CoolR support." },
    { name: 'Back-End Action', dataKey: 'BackendActions', image: 'backendAction', description: "The problems listed can only be fixed by an intervention from CoolR Back End Team." },
    { name: 'In-Field Action', dataKey: 'InFieldActions', image: 'inFieldAction', description: "The problems listed can only be fixed with an in-field visit." },
]

const columns = {
    "SetupRequired": [
        { id: "ActionReason", label: "Action Reason", flex: 0, minWidth: 320, valueGetter: (value) => value.row.ActionReason },
        { id: "SerialNumber", label: "Asset Serial Number", flex: 0, minWidth: 180, valueGetter: (value) => value.row.SerialNumber },
        { id: "ModemSN", label: "Modem Serial Number", flex: 0, minWidth: 180, valueGetter: (value) => value.row.ModemSN },
        { id: "CameraSN", label: "Camera Serial Number", flex: 0, minWidth: 180, valueGetter: (value) => value.row.CameraSN },
        { id: "OutletCode", label: "Outlet Code", flex: 0, minWidth: 130, valueGetter: (value) => value.row.OutletCode },
        { id: "OutletName", label: "Outlet Name", flex: 0, minWidth: 230, valueGetter: (value) => value.row.OutletName }
    ],
    "BackendActions": [
        { id: "IssueAge", label: "Issue Age", minWidth: 100, flex: 0, valueGetter: (value) => value.row.IssueAge },
        { id: "ActionReason", label: "Action Reason", flex: 0, minWidth: 320, valueGetter: (value) => value.row.ActionReason },
        { id: "SerialNumber", label: "Asset Serial Number", flex: 0, minWidth: 180, valueGetter: (value) => value.row.SerialNumber },
        { id: "ModemSN", label: "Modem Serial Number", flex: 0, minWidth: 180, valueGetter: (value) => value.row.ModemSN },
        { id: "CameraSN", label: "Camera Serial Number", flex: 0, minWidth: 180, valueGetter: (value) => value.row.CameraSN },
        { id: "OutletCode", label: "Outlet Code", flex: 0, minWidth: 130, valueGetter: (value) => value.row.OutletCode },
        { id: "OutletName", label: "Outlet Name", flex: 0, minWidth: 230, valueGetter: (value) => value.row.OutletName }
    ],
    "InFieldActions": [
        { id: "IssueAge", label: "Issue Age", minWidth: 100, flex: 0, valueGetter: (value) => value.row.IssueAge },
        { id: "ActionReason", label: "Action Reason", flex: 0, minWidth: 220, valueGetter: (value) => value.row.ActionReason },
        { id: "SerialNumber", label: "Asset Serial Number", flex: 0, minWidth: 180, valueGetter: (value) => value.row.SerialNumber },
        { id: "ModemSN", label: "Modem Serial Number", flex: 0, minWidth: 180, valueGetter: (value) => value.row.ModemSN },
        { id: "CameraSN", label: "Camera Serial Number", flex: 0, minWidth: 180, valueGetter: (value) => value.row.CameraSN },
        { id: "OutletCode", label: "Outlet Code", flex: 0, minWidth: 130, valueGetter: (value) => value.row.OutletCode },
        { id: "OutletName", label: "Outlet Name", flex: 0, minWidth: 230, valueGetter: (value) => value.row.OutletName },
        { id: "Street", label: "Street", flex: 0, minWidth: 230, valueGetter: (value) => value.row.Street },
        { id: "City", label: "City", flex: 0, minWidth: 230, valueGetter: (value) => value.row.City },
        { id: "PostalCode", label: "Postal Code", flex: 0, minWidth: 230, valueGetter: (value) => value.row.PostalCode },
        { id: "Country", label: "Country", flex: 0, minWidth: 300, valueGetter: (value) => value.row.Country }
    ]
}
const gridOptions = { disableSelectionOnClick: true, density: "compact", hideFooter: false }
const defaultFilter = utils.defaultFilter;

function InFieldHardware() {

    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const history = useHistory();
    const dispatch = useDispatch();
    const [cardData, setCardData] = useState({});
    const [tableData, setTableData] = useState({});
    const [drillData, setDrillData] = useState({});
    const [drillDataSwitch, setDrillDataSwitch] = useState({ Market: 'value', Classification: 'value', Channel: 'value' });
    const [accordStatus, setAccordStatus] = useState({ SetupRequired: false, BackendActions: false, InFieldActions: false });
    const [activeTab, setActiveTab] = React.useState(0);
    const [exportColumns, setExportColumns] = useState([]);
    const [filter, setFilters] = useState({});
    const [hideAllColumns, setHideAllColumns] = useState(false)
    const [oldFilter, setOldFilters] = useState({});
    const [showModal, setShowModal] = useState(true);
    let isGrid;
    const [filterModal, setFilterModal] = useState({
        'Set Up Required': { items: [] },
        'Back-End Action': { items: [] },
        'In-Field Action': { items: [] }
    });
    const isMobile = useMobile()

    useEffect(() => {
        let filterL = {};
        for (let item of gridTables) {
            filterL[item.name] = { ...defaultFilter, pagination: null, linkOperator: 'AND' };
        }
        setFilters(filterL);
        setOldFilters(utils.deepCloneObject(filterL));
    }, [])

    useEffect(() => {
        dispatch({
            type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                filterButton: null,
                hidden: { search: true, operation: true, export: true, print: false, filter: true }
            }
        });

        getInFieldHardware({ dataTypes: ['status'], actionArray: [] })
        getInFieldHardware({ dataTypes: ['action'], loader: true })

        return () => {
            dispatch({
                type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                    filterButton: null,
                    clear: null,
                    apply: null,
                    hidden: { search: false, operation: false, export: false, print: false, filter: false }
                }
            });
        }
    }, [])

    useEffect(() => {
        const key = filter?.item;
        const currentFilter = filter[key] || {}, currentFilterOld = oldFilter[key] || {};
        isGrid = currentFilter.isGridFilter
        const pagination = JSON.stringify(currentFilter.pagination) === JSON.stringify(currentFilterOld.pagination),
            sort = JSON.stringify(currentFilter.sort) === JSON.stringify(currentFilterOld.sort),
            where = JSON.stringify(currentFilter.where) === JSON.stringify(currentFilterOld.where),
            operator = JSON.stringify(currentFilter.linkOperator) === JSON.stringify(currentFilterOld.linkOperator);

        if (currentFilter?.isGridFilter && (!pagination || !sort || !where || !operator)) {
            getInFieldHardware({ dataTypes: ["action"], actionArray: [key], extra: currentFilter });
            setOldFilters(utils.deepCloneObject(filter));
        }
    }, [filter])

    async function getInFieldHardware({ action = "", dataTypes, actionArray, extra = {}, loader = true }) {
        const payload = {
            action: action,
            dataTypes: dataTypes || ['status', 'action'],
            actionArray: actionArray || ['Back-End Action', 'Set Up Required', 'In-Field Action'],
            ...extra
        }
        const additionalParams = {}
        if (action === 'export') {
            payload["exportData"] = true;
            payload["exportColumns"] = exportColumns
        }
        const response = await request({ url: apis.InFieldHardware, params: payload, history, dispatch, jsonPayload: true, additionalParams, disableLoader: !loader });

        if (response && action === "drillDown") {
            const selected = extra?.drillDownObj?.status === "Not Installed" ? "Installed" : extra?.drillDownObj?.status;
            let icon = statusCards.filter((item) => { return item.name === selected });
            icon = icon[0].image;
            setDrillData({ selected: selected, icon: icon, data: response });
        } else if (response) {
            if (response.operationalStatus) {
                let total = 0;
                for (const key in response.operationalStatus) {
                    if (key !== 'NotInstalled') {
                        total = total + Number(response.operationalStatus[key]);
                    }
                }
                response.operationalStatus.Installed = total;
                setCardData(response.operationalStatus);
            } else {
                setTableData(prev => (isGrid ? { ...prev, ...response.data } : { ...prev, ...response }))
            }
            return;
        }
    }

    function exportExcel(action) {
        const key = filter?.item;
        const currentFilter = filter[key] || {};
        if (exportColumns.length === 0 && hideAllColumns) {
            swal({
                title: '',
                text: t("You cannot export while all columns are hidden... please show at least 1 column before exporting", tOpts),
                icon: "warning",
                dangerMode: false
            });
        } else {
            getInFieldHardware({
                action: "export",
                dataTypes: ['action'],
                actionArray: [action],
                extra: {
                    "responseType": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "fileName": action.replace(/ /g, ""),
                    ...currentFilter
                }
            })
        }
    }

    const handleExportColumnsChange = (columns) => {
        const columnsCloned = columns;
        const newColumns = [];
        Object.keys(columnsCloned).forEach(key => {
            if (columnsCloned[key] !== false) {
                newColumns.push(key);
            }
        });
        setExportColumns(newColumns);
        setHideAllColumns(true)
    }

    function cardDoubleClick(status) {
        const statusL = (status === "Installed" ? "Not Installed" : status);
        if (drillData.selected === status) {
            setDrillData({ selected: "", icon: "", data: {} });
            return;
        }

        getInFieldHardware({
            action: "drillDown",
            dataTypes: ['drillDown'],
            actionArray: [],
            extra: {
                drillDownObj: { status: statusL, opr: (status === "Installed" ? "<>" : "=") }
            }
        })
    }

    function formatCellColor(params) {
        if (params.field === "IssueAge") {
            if (params.value === 'New') {
                return 'grid-bg-green';
            } else if (params.value === 'Overdue') {
                return "grid-bg-yellow";
            }
        }
    }

    function clearFilter(name) {
        let filterL = { ...filter };
        filterL[name].where = {};
        setFilters(filterL)
        setFilterModal({ ...filterModal, [name]: { items: [] } })
    }

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
        setHideAllColumns(false)
    };
    return <div className={`mb-1 p-0 hardware-card-subheader-gap ${!isMobile.tablet && isMobile.portrait ? "mt-4" : (isMobile.tablet && isMobile.portrait ? "mt-3" : "mt-1")}`} >
       {showModal && <AlertPopUp   onClose={() => setShowModal(false)}  UpadatePage="In Field Status"/>}
        <Card className="transparent-bg">
            <CardHeader className="p-0" title={<Typography className="pl-2" variant="h4" component="div">{t('Status', tOpts)}</Typography>} />
            <CardContent className="pt-1 pb-3 fixed-card-scroll">
                <Grid container spacing={1} style={{ minHeight: "300px" }} className="fixed-card-container">
                    {statusCards.map((item) => {
                        return <Grid key={item.key} item xs={2} sm={2} md={2} lg={2}>
                            <Card className="cursor_pointer ifh-status-card" style={{ borderBottom: `${'4px solid ' + item.color}`, boxShadow: `${drillData.selected === item.name ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : ""}` }} onClick={() => { cardDoubleClick(item.name) }}>
                                <CardContent>
                                    <div className="text-center">
                                        <div style={{ "minHeight": "120px" }}>
                                            <img src={require(`../../assets/images/${item.image}`)} height='100' alt={item.name} />
                                        </div>
                                        <div style={{ "minHeight": "80px" }}>
                                            <Typography variant="h4" component="div">{t(item.name, tOpts)}</Typography>
                                        </div>
                                        <div style={{ "minHeight": "50px" }}>
                                            <Typography variant="h1" component="div" style={{ color: `${item.color}` }}>{(cardData) ? (cardData[item.key] || 0) : '--'}</Typography>
                                        </div>
                                        <div>
                                            <Typography variant="h5" component="div">{t('Cameras', tOpts)}</Typography>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    })}
                </Grid>
            </CardContent>
        </Card>

        {drillData.selected ? <>
            <Card className='mt-3 pt-0 pl-2 pr-2 pb-2 transparent-bg'>
                <CardHeader className="p-2"
                    avatar={<img src={require(`../../assets/images/${drillData.icon}`)} height='50' alt={"Setup Required"} />}
                    title={<Typography className="" variant="h4" component="div">{
                        drillData.selected == 'Installed' ? `${t(`${drillData.selected} Distribution`, tOpts)}` : `${t(`Detailed break-down of ${drillData.selected}`, tOpts)}`
                    }</Typography>}
                    action={<CloseIcon onClick={() => { setDrillData({ selected: "", icon: "", data: {} }) }} className="no-print icon-search cursor_pointer mt-3 mr-3" />} />
                <CardContent className="p-2">
                    <Card className="mb-2">
                        <CardContent className="p-0" >
                            <Tabs value={activeTab} onChange={handleChangeTab} centered variant="fullWidth">
                                {pieCharts.map((item) => { return (<Tab iconPosition="start" label={t(item.name, tOpts)} />) })}
                            </Tabs>
                        </CardContent>
                    </Card>
                    <Grid container spacing={2} >
                        {pieCharts.map((item, index) => {
                            return (<>{(activeTab === index) &&
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Card>
                                        <CardHeader className="pt-2 pb-2"
                                            action={<div>
                                                <span>#</span>
                                                <span>
                                                    <Switch
                                                        defaultChecked
                                                        checked={drillDataSwitch[item.name] === 'value' ? false : true}
                                                        onChange={(event) => { setDrillDataSwitch({ ...drillDataSwitch, [item.name]: event.target.checked ? 'percentage' : 'value' }) }} />
                                                </span>
                                                <span>%</span>
                                            </div>} />
                                        <CardContent className="pt-0 pb-0 pl-2 pr-2">
                                            <div className="ifh-chart-container-outer m-2">
                                                <BarChart key={`distribution-bar-chart-${drillDataSwitch[item.name]}`} data={drillData?.data[item.dataKey] || []} config={{
                                                    labelName: item.name,
                                                    dataName: drillDataSwitch[item.name] === 'value' ? "DefectCount" : 'DefectPercentage',
                                                    chartName: t(drillData.selected, tOpts),
                                                    xAxisName: '',
                                                    yAxisName: '',
                                                    tab: item.name,
                                                    chartContainerClass: "ifh-chart-container-inner",
                                                    fontSize: 14,
                                                    toolTipToggle: false,
                                                    defaultToggle: "onlyValuesBar",
                                                    gridLines: { x: false, y: true },
                                                    legend: 'hidden',
                                                    colorIndex: item.colorIndex,
                                                    unit: drillDataSwitch[item.name] === 'value' ? "" : '%',
                                                }} />
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>}</>)
                        })}
                    </Grid>
                </CardContent>
            </Card>
        </> : null}

        <Card className="mt-3 p-2 transparent-bg">
            <CardHeader className="p-2" title={<Typography className="pl-2 pt-2" variant="h4" component="div">{t('Actions', tOpts)}</Typography>} />
            <CardContent className="p-2 pb-4">
                {gridTables.map((item) => {
                    const { totalCount, data: result = [] } = tableData[item.dataKey] || {};
                    return (<Accordion key={item.dataKey} expanded={accordStatus[item.dataKey]} className={`accordion`}>
                        <AccordionSummary
                            className="accordion-summary"
                            style={{ cursor: "default" }}
                            expandIcon={<ExpandMoreIcon fontSize='large' className="hover-cursor-pointer" onClick={() => { setAccordStatus({ ...accordStatus, [item.dataKey]: !accordStatus[item.dataKey] }) }} />}>

                            <CardHeader className="w-100 pt-2 pb-2 pl-2 accordion-card-header"
                                avatar={<img src={require(`../../assets/images/${item.image}.png`)} height='50' alt={item.name} className="accordion-image" />}
                                title={<Typography className="" variant="h4" component="div">
                                    {t(item.name, tOpts)}
                                    <span className="ml-2 accordion-chip">
                                        <Chip id="red-chip" label={totalCount || result?.length} />
                                    </span>
                                    <br />
                                    <span className="ml-5 accordion-caption">
                                        <Typography variant="caption">{t(item.description, tOpts)}</Typography>
                                    </span>
                                </Typography>}
                                action={<img className={`ifh-export-icon accordion-export-icon`} onClick={() => { exportExcel(item.name) }} src={require(`../../assets/images/excel.png`)} height='35' alt={"Export"} />}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="igh-action-table w-100">
                                {tableData[item.dataKey] ?
                                    <GridBase
                                        customClass={'data-grid-font-12'}
                                        columns={columns[item.dataKey]}
                                        data={tableData[item.dataKey].data}
                                        pagination={false}
                                        autoHeight={false}
                                        onSortModelChange={utils.onFilterModelChange({ setFilters, setFilterModal, filterModalP: filterModal, item: item?.name, dataKey: item.dataKey })}
                                        showTitleColumn={true}
                                        clearFilter={() => clearFilter(item.name)}
                                        otherOptions={{
                                            filterModel: filterModal[item.name],
                                            filterMode: 'server',
                                            sortingMode: 'server',
                                            rowCount: tableData[item.dataKey].totalCount,
                                            onColumnVisibilityModelChange: (newModel) => handleExportColumnsChange(newModel),
                                            onFilterModelChange: utils.onFilterModelChange({ setFilters, setFilterModal, filterModalP: filterModal, item: item?.name, dataKey: item.dataKey }),
                                            ...gridOptions, getCellClassName: formatCellColor
                                        }} /> :
                                    t('No Data', tOpts)}
                            </div>
                        </AccordionDetails>
                    </Accordion>)
                })}
            </CardContent>
        </Card>
    </div>
}

export default InFieldHardware;