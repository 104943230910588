import React, { useEffect } from 'react';
import PageTitle from '../../components/PageTitle'
import CardRouter from '../../components/GenericCard';
import { useDispatch } from 'react-redux';
import actions from '../../redux/actions';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import enums from '../../utils/constants'
import utils from "../../utils"
import useMobile from '../../utils/useMobile';
import { UserContext } from '../../utils/orderContext';
const { childRoutes } = enums;
const { t } = utils;

const Replenishment = () => {
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const dispatch = useDispatch();
  const [userContext] = React.useContext(UserContext);
  const childPages = childRoutes.Replenishment;
  const isMobile = useMobile();

  useEffect(() => {
    if (!userContext.isOrderInTransit) {
      dispatch({
        type: actions.SET_PAGE_TITLE_DETAILS,
        pageTitleDetails: <PageTitle
          titleHeading={t("Replenishment", tOpts)}
          titleDescription={t("Keep full track of your ordering system", tOpts)}
          icon=""
          title={t("Replenishment", tOpts)}
          classTitle="home-header-container" />
      });
      dispatch({
        type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
          filterButton: "",
          filterButtonName: "",
          printButtonName: "",
          clear: null,
          apply: null,
          hidden: { search: true, operation: true, export: true, print: true, filter: true }
        }
      });
    }

    return () => {
      dispatch({
        type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
      })
    }
  }, [])

  return (
    !userContext.isOrderInTransit && <Grid className={`mb-1 ${!isMobile.tablet ? (isMobile.portrait ? "mt-2" : "") : (isMobile.tablet && isMobile.portrait ? "mt-2" : "mt-n2")}`} container spacing={1}>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography className={`custom-replenishment-subheader ${(isMobile.mobile && !isMobile.tablet && isMobile.portrait) ? "ml-4" : ''}`} variant="h6">{t("Pages", tOpts)}</Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CardRouter childObj={childPages} />
      </Grid>

    </Grid>
  )
}

export default Replenishment;