import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import utils from '../../utils';

const BarChart = ({ titleColor, seriesData, seriesName, minBarHeight, CurrencySymbol, t , tOpts }) => {

    seriesData.sort((a, b) => b.Data - a.Data);
    const chartOptions = (titleColor, seriesData, seriesName) => ({
        chart: {
            type: 'column',
            backgroundColor: null,
            height: 230
        },
        tooltip: {
            formatter: function () {
                if (seriesName === utils.chartOptionEnum.potentialName) {
                    const formattedValue = Math.round(this.point.y).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return this.key + '<br>' + t(this.series.name,tOpts) + `: <b>${CurrencySymbol}` + ' ' + formattedValue + '</b>';
                } else {
                    const formattedValue = this.point.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
                    return this.key + '<br>' + t(this.series.name,tOpts) + ': <b>' + formattedValue + '</b>';
                }
            }
        },
        title: {
            text: null
        },
        plotOptions: {
            column: {
                borderWidth: 0,
                pointPadding: 0.1,
                groupPadding: 0.2,
                minPointLength: minBarHeight
            }
        },
        credits: {
            enabled: false
        },
        legend: { enabled: false },
        colors: [titleColor],
        xAxis: {
            categories: seriesData?.map(item => item.Categories),
            labels: {
                enabled: false
            }
        },
        yAxis: {
            title: {
                text: null
            },
            labels: {
                enabled: false
            }
        },
        series: [{
            name: seriesName,
            data: seriesData?.map(item => item.Data)
        }]
    });

    return (
        <div style={{ width: '100%' }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions(titleColor, seriesData, seriesName)} />
        </div>
    )
}

export default BarChart