import * as React from 'react';
import { Paper, Box } from '@mui/material';
import { BarPlot } from '@mui/x-charts/BarChart';
import { ResponsiveChartContainer, ChartsXAxis, ChartsYAxis, ChartsTooltip } from '@mui/x-charts';
export default function XBarChart({ data, categories, xLabel, yLabel }) {
    const series = [{ type: 'bar', data: data }];
    return (
        <Box sx={{ width: '100%', height: '60vh' }} className="main-chart-container">
            <Paper sx={{ width: '100%', height: '100%', padding: 0, margin: 0 }} elevation={3}>
                <ResponsiveChartContainer
                    series={series}
                    xAxis={[{
                        id: 'barCategories',
                        scaleType: 'band',
                        data: categories
                    }]}
                    yAxis={[{
                        id: 'barYAxis',
                        scaleType: 'linear',
                        label: 'Value'
                    }]}
                >
                    <BarPlot tooltip={{ trigger: 'axis' }} />
                    <ChartsXAxis label={xLabel} position="bottom" axisId="barCategories" />
                    <ChartsYAxis label={yLabel} position="left" axisId="barYAxis" />
                    <ChartsTooltip />
                </ResponsiveChartContainer>
            </Paper>
        </Box>
    );
}
