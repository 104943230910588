import { Autocomplete, Box, Button, Card, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { apis, request } from '../../httpUtil';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { defaultSettings } from './config';
import utils from '../../utils';
import { DataGridPro } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import DropDownMenu from '../Common/DropDownMenu';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PageTitle from '../PageTitle';
import { makeStyles } from '@material-ui/core';

const { t, numberWithCommas } = utils;

const useStyles = makeStyles((theme) => ({
    filtersMain: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}));

function FrequencySummary() {
    const userData = useSelector((state) => state.appReducer.userData);
    const dispatch = useDispatch();
    const history = useHistory();
    const [data, setData] = useState([]);
    const location = useLocation();
    const locationSurveyMasterId = location?.state?.id || location?.pathname.split('/').pop();
    const [cyclesCount, setCyclesCount] = useState(0);
    const [localData, setLocalData] = useState([]);
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const [recurrenceOpt, setRecurrenceOpt] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [recurrenceCheckOpt, setRecurrenceCheckOpt] = useState(new Date());
    const [isApplied, setIsApplied] = useState(false);
    const [recurrenceAutoComplete, setRecurrenceAutoComplete] = useState([]);
    const classes = useStyles();

    const fetchSurveyFrequencyCycleData = useCallback(async () => {
        const reqParams = {
            ids: locationSurveyMasterId ? locationSurveyMasterId : null,
            isFilter: isApplied
        }
        if (isApplied) {
            if (Number(recurrenceOpt) > 0) {
                reqParams["recurrencePeriod"] = Number(recurrenceOpt);
            }
            else if (startDate && endDate) {
                reqParams["startDate"] = dayjs(startDate).format("YYYY-MM-DD");
                reqParams["endDate"] = dayjs(endDate).format("YYYY-MM-DD");
            }
        }
        const response = await request({
            url: apis.getSurveyStatus,
            params: reqParams,
            history,
            dispatch,
            jsonPayload: true,
        });

        if (response.success) {
            const dataMaster = response.data[0][0];
            const dataCalendar = response.data[1];

            const masterDataWithCalendarData = {
                ...dataMaster,
                calendarData: dataCalendar
            };

            setCyclesCount(response.totalCount.surveyCalendarTotalCount);
            setLocalData(masterDataWithCalendarData);
            setData(masterDataWithCalendarData);
        }
    }, [isApplied, startDate, endDate, recurrenceOpt, locationSurveyMasterId, request]);


    useEffect(() => {
        fetchSurveyFrequencyCycleData();
    }, [])
    const getFrequencyType = (val) => {
        switch (val) {
            case "V":
                return "At every visit";
            case "O":
                return "Every outlet only once";
            case "W":
                return "Once per week";
            case "M":
                return "Once per month";
            case "Q":
                return "Once per quarter";
            case "Y":
                return "Once per Year";
            default:
                return "";
        }
    }
    const getPriorityType = (val) => {
        switch (val) {
            case 1:
                return "Low";
            case 2:
                return "Medium";
            case 3:
                return "High";
        }
    }
    const getAvgTarget = (surveysConducted, totalUsers) => {
        let avgTarget;
        if (surveysConducted > 0) {
            avgTarget = (surveysConducted / totalUsers).toFixed(2);
            return avgTarget;
        }
    }

    let cycles = localData?.calendarData;
    if (!Array.isArray(cycles)) {
        cycles = [];
    }

    let columns = [
        { field: 'Title', headerName: 'Recurrence Period', width: 160 },
        ...Array.from({ length: cycles.length }, (_, i) => ({
            field: `Cycle ${i + 1}`,
            headerName: `${utils.getRecurrenceName(location?.state?.FrequencyType || localData?.FrequencyType)} ${i + 1}`,
            width: 130
        })),
    ];
    let rows = [
        {
            id: 1,
            Title: "Start Date",
            ...(Array.isArray(localData?.calendarData) ?
                Object.fromEntries(localData.calendarData
                    .sort((a, b) => new Date(a.SurveyStartDate) - new Date(b.SurveyStartDate))
                    .map((cycle, i) => {
                        let startDate = dayjs(cycle?.SurveyStartDate).format("MM/DD/YYYY");
                        return [`Cycle ${i + 1}`, startDate];
                    })
                )
                : {}
            )
        },
        {
            id: 2,
            Title: "Number of Outlets",
            ...(Array.isArray(localData?.calendarData) ?
                Object.fromEntries(localData.calendarData.map((cycle, i) => {
                    return [`Cycle ${i + 1}`, cycle?.LocationsAssigned];
                }))
                : {}
            )
        },
        {
            id: 3,
            Title: "Total Users",
            ...(Array.isArray(localData?.calendarData) ?
                Object.fromEntries(localData.calendarData.map((cycle, i) => {
                    return [`Cycle ${i + 1}`, cycle?.UsersAssigned];
                }))
                : {}
            )
        },
        {
            id: 4,
            Title: "Surveys Conducted",
            ...(Array.isArray(localData?.calendarData) ?
                Object.fromEntries(localData.calendarData.map((cycle, i) => {
                    return [`Cycle ${i + 1}`, cycle?.TotalSurveyDone];
                }))
                : {}
            )
        },
        {
            id: 5,
            Title: "Avg Target Achieved",
            ...(Array.isArray(localData?.calendarData) ?
                Object.fromEntries(localData.calendarData.map((cycle, i) => {
                    return [`Cycle ${i + 1}`, getAvgTarget(cycle?.TotalSurveyDone, cycle?.UsersAssigned)];
                }))
                : {}
            )
        },
        {
            id: 6,
            Title: "Completion Status",
            ...(Array.isArray(localData?.calendarData) ?
                Object.fromEntries(localData.calendarData.map((cycle, i) => {
                    const today = dayjs();
                    const startDate = dayjs(cycle?.SurveyStartDate);
                    if (startDate.isAfter(today)) {
                        return [`Cycle ${i + 1}`, `To Do`];
                    } else {
                        return [`Cycle ${i + 1}`, cycle?.SurveyStatusString || 'No Status'];
                    }
                }))
                : {}
            )
        }
    ];

    const radioFilters = {
        name: "",
        options: [
            "Actual Cycle", "Weekly Cycle", "Monthly Cycle"
        ]
    }

    const recurrenceOptions = Array.from({ length: cyclesCount }, (_, i) => ({
        DisplayValue: `${utils.getRecurrenceName(location?.state?.FrequencyType || localData?.FrequencyType)} ${i + 1}`,
        LookupId: `${i + 1}`,
    }));

    const handleDateChange = (date, field) => {
        if (field === 'startDate') {
            setStartDate(date || null);
        } else if (field === "endDate") {
            setEndDate(date || null);
        }
    };

    const handleInputChange = (e, value, field) => {
        if (field === "recurrenceRadioGroup") {
            setRecurrenceCheckOpt(e.target.value);
        } else if (field === "recurrenceAutoComplete") {
            if (value[0]) {
                setRecurrenceOpt(value[0].LookupId);
            }
            setRecurrenceAutoComplete(value);
        }
    }

    const onApplyFilterClick = () => {
        setIsApplied(true)
        fetchSurveyFrequencyCycleData();
    }
    const onClearFilterClick = () => {
        setIsApplied(false)
        setStartDate(null)
        setEndDate(null)
        setRecurrenceAutoComplete([])
        fetchSurveyFrequencyCycleData();
    }

    const disableDateInput = () => {
        if (recurrenceOpt) {
            if (recurrenceOpt === "-1" || recurrenceOpt === "") {
                return false
            } else {
                return true
            }
        }
        return false
    }
    useEffect(() => {
        let backRoute = "/Survey/Survey";
        dispatch({
            type: actions.SET_PAGE_BACK_BUTTON,
            pageBackButton: { status: true, backRoute: backRoute },
        });
    }, []);
    useEffect(() => {
        dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle icon="" titleHeading={localData?.SurveyName} titleDescription={localData?.SurveyDescription} title={localData?.SurveyName} /> });
    }, [localData, recurrenceOpt])

    return (
        <>
            {Object.entries(data).length ?

                <Box sx={{ mt: 0 }}>
                    {/* Commenting as is not needed for now  */}
                    {/* <Card sx={{ mb: 1, p: 2, backgroundColor: defaultSettings[categoryLocal]?.cardBackgroundColor }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={7}>
                            <Typography className='recurrence-title'> {localData[0]?.SurveyName}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className='recurrence-activity'> {"Activity: 1 Day to go"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button className='recurrence-category-btn' variant="contained"> {resultData?.category}</Button>
                        </Grid>
                    </Grid>
                </Card> */}
                    <Card>
                        <Grid container alignItems="center">
                            <Grid item xs={2}>
                                <Typography sx={{ mt: 1, pl: 1 }} className='recurrence-table-title'>{t("Recurrence Calendar", tOpts)}</Typography>
                                <Typography sx={{ mt: 1, pl: 1 }} className='recurrence-table-description'>{t("Intervals in days at which the survey has to be conducted", tOpts)}</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.filtersMain}>
                                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            multiple
                                            id='recurrence-autocomplete'
                                            options={recurrenceOptions || []}
                                            getOptionLabel={(option) => option.DisplayValue || ''}
                                            defaultValue
                                            onChange={(e, value) => handleInputChange(e, value, "recurrenceAutoComplete")}
                                            renderInput={(params) => <TextField {...params} label="Recurrence Period" margin="normal" />}
                                            disabled={false}
                                            value={recurrenceAutoComplete}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <KeyboardDatePicker
                                            size="medium"
                                            className="mt-2"
                                            id="date-picker-dialog"
                                            label={t("Start Date", tOpts)}
                                            name="startDate"
                                            format={"MM/dd/yyyy"}
                                            defaultValue={null}
                                            KeyboardButtonProps={{ "aria-label": "change date" }}
                                            disableFuture={true}
                                            onChange={(e) => handleDateChange(e, "startDate")}
                                            value={startDate}
                                            invalidDateMessage={t("Invalid Date Format", tOpts)}
                                            minDateMessage={t('Date should not be before minimal date',tOpts)}
                                            maxDateMessage={t('Date should not be after maximal date',tOpts)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <KeyboardDatePicker
                                            size="medium"
                                            className="mt-2"
                                            id="date-picker-dialog"
                                            label={t("End Date", tOpts)}
                                            name="endDate"
                                            format={"MM/dd/yyyy"}
                                            defaultValue={null}
                                            KeyboardButtonProps={{ "aria-label": "change date" }}
                                            value={endDate}
                                            onChange={(e) => handleDateChange(e, "endDate")}
                                            invalidDateMessage={t("Invalid Date Format", tOpts)}
                                            minDateMessage={t('Date should not be before minimal date',tOpts)}
                                            maxDateMessage={t('Date should not be after maximal date',tOpts)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} className={classes.filtersMain}>
                                <Grid container spacing={1} sx={{ m: 1, mr: 1, pr: 1 }}>
                                    <Grid item xs={6}>
                                        <Button variant="contained" onClick={onApplyFilterClick} fullWidth>{t("Filter", tOpts)}</Button> </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="contained" onClick={onClearFilterClick} fullWidth>{t("Clear", tOpts)}</Button> </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Card className='recurrence-table-container' sx={{ m: 1, p: 1 }}>
                            <Grid container rowSpacing={1} className='recurrence-table-header' columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={5}>
                                    <Typography> {`Recurrence Type: ${getFrequencyType(localData.FrequencyType)}`}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography> {`No of questions: 8`}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography> {`Multiple Survey Allowed: Yes`}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography> {`No of questionnaires: ${localData?.SurveyTypeId?.split(',').length || 0}`}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>{`Avg target for each user: ${localData.TeamTarget}`}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography> {`Priority: ${getPriorityType(localData.Priority)}`}</Typography>
                                </Grid>
                            </Grid>
                            <div className='recurrence-grid-container'>
                                <DataGridPro
                                    rows={rows}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    autoHeight={true}
                                    getCellClassName={(params) => {
                                        if (params?.value === 'Active') {
                                            return 'recurrent-progress-highlight';
                                        } else if (params?.value === 'Expired') {
                                            return 'recurrent-incomplete-highlight';
                                        } else if (params?.value === 'To Do') {
                                            return 'recurrent-todo-highlight';
                                        } else if (params?.value === 'Complete') {
                                            return 'recurrent-complete-highlight';
                                        }
                                        return '';
                                    }}
                                />
                            </div>
                        </Card>
                    </Card>
                </Box >
                : null
            }
        </>
    );




}
export default FrequencySummary;