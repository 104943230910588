import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { ScaleLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { useStateContext } from "@durlabh/dframework-ui";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: '#fff',
    },
}));


const Loader = props => {
    const { stateData } = useStateContext();
    const loaderOpen = useSelector(state => state.appReducer.loaderOpen) || stateData?.loaderOpen;
    const classes = useStyles();
    return (loaderOpen ? <Backdrop className={classes.backdrop} open={loaderOpen}>
        <ScaleLoader color={'#ffffff'} loading={loaderOpen} />
    </Backdrop> : null)

}
export default Loader;