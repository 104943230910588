import React, { useState, useCallback, useMemo } from 'react';
import ImageNotFound from '../../assets/images/vista-images/imagenotfound.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { request, apis } from "../../httpUtil";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import dateUtil from '../../utils/date';
import toast from 'react-hot-toast';
import utils from '../../utils';
import { useTranslation } from 'react-i18next';

let history, dispatch;

const VistaImages = React.memo(function VistaImages({
    data: { RowNumber = '', IsStitched = -1, StitchedImageURL = ImageNotFound, AssetPurityId = '', AssetId = '', AssetSerialNumber = '', Children = null, PurityDateTime = '', Image1Size, Image2Size, ImageCount },
    isFromVistaDetail = false,
    setReplenishmentDataTable,
    index,
    hidePrevNext,
    isSingleImage = false,
    idx,
    leftImageDateTime,
    rightImageDateTime,
    isOutletCard = false,
    setImageData = null }) {
    dispatch = useDispatch();
    history = useHistory();
    const vistaData = useSelector(state => state.appReducer.vistaData) || [];
    const [loading, setLoading] = useState(true);
    
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    let imageURL = "";
    let leftcam1Image1, leftcam1Image2;
    let isLeftImageStitched = IsStitched === 1;
    if (isLeftImageStitched) {
        imageURL = StitchedImageURL;
    } else {
        leftcam1Image1 = `${StitchedImageURL.split("ImageType")[0]}ImageType=cam1`;
        leftcam1Image2 = `${StitchedImageURL.split("ImageType")[0]}ImageType=cam2`;
    }
    const imageURLMin = imageURL + "&maxWidth=600";
    const onLoadImg = () => {
        setLoading(false);
    }
    let purityDateTime = PurityDateTime ? dateUtil.dateMatch(PurityDateTime) : 'N/A';
    const userData = useSelector(state => state.appReducer.userData);
    let { ClientId } = userData && userData.tags ? userData.tags : "";

    const hasChild = Children && Children.length ? true : false;
    let child_imageURL = ImageNotFound, child_purityDateTime, child_AssetPurityId, childPurityDateTime, childAssetId, child_imageURLMin;
    let rightCam1Image1, rightCam1Image2;
    let isRightImageStitched = Children && Children[0].IsStitched === 1;
    if (hasChild) {
        if (isRightImageStitched) {
            child_imageURL = Children[0].StitchedImageURL
        } else {
            rightCam1Image1 = `${Children[0].StitchedImageURL.split("ImageType")[0]}ImageType=cam1`;
            rightCam1Image2 = `${Children[0].StitchedImageURL.split("ImageType")[0]}ImageType=cam2`;
        }
        child_imageURLMin = child_imageURL + "&maxWidth=600";
        child_purityDateTime = Children && Children[0].PurityDateTime ? dateUtil.dateMatch(Children && Children[0].PurityDateTime) : null;
        childPurityDateTime = Children && Children[0].PurityDateTime;
        child_AssetPurityId = Children && Children[0].AssetPurityId;
        childAssetId = Children && Children[0].AssetId;
    }
    const getReplenishTableData = async (AssetPurityId) => {
        if(!AssetPurityId){
            return
        }
        let params = { action: 'list', otherAction: 'ProductDetail', AssetPurityId: AssetPurityId , asArray: 1, isReplenish: true}
        const response = await request({ url: apis.ProductOutOfStock, params, history, dispatch });
        if (response && response.records) {
            setReplenishmentDataTable(response.records)
        }
      }
    
    const onSwipe = useCallback(async (isNext) => {
        setLoading(true)
        let params = {
            // action: list | GetPrevNextImage
            action: "GetPrevNextImage", asArray: 0, showAllImages: false, isFetchCoolerData: true,
            AssetId: AssetId,
            LeftVisibleImageDateTime: leftImageDateTime,
            RightVisibleImageDateTime: rightImageDateTime,
        };
        if (Number(ClientId) > 0) {
            Object.assign(params, { clientId: Number(ClientId) });
        }
        // TO-DO: will remove after testing on live, keep it for reference
        // if (hasChild) {
        //     params = { ...params, ...{ ChildAssetPurityId: child_AssetPurityId, PurityDateTime: PurityDateTime, ChildPurityDateTime: childPurityDateTime, ChildAssetId: childAssetId } };
        // }
        Object.assign(params, { PrevNext: isNext ? 1 : -1 });
        const response = await request({ url: apis.assetLatestImages2, params, history, dispatch });
        if (response && response.records && response.records.length > 0) {
            let records = response.records[0];
            getReplenishTableData(response?.records[0]?.AssetPurityId)
            // data from redux
            const imageRecord = vistaData.records;
            // parent record
            const assetParentData = imageRecord && imageRecord.filter(row => row.RowNumber == RowNumber);
            // child data
            let oldChildData = assetParentData && assetParentData.length > 0 && assetParentData[0].Children || [];
            let newChildData = (records && records.IsParentAsset) ? records.Children : [records];
            let childData = newChildData && newChildData.length > 0 ? newChildData : oldChildData;
            let tempRecord = {};
            // if getting parent
            if (records.AssetId && records.IsParentAsset) {
                tempRecord = records;
            } else {
                tempRecord = assetParentData.length === 0 ? records : assetParentData[0];
            }
            if (childData && childData.length > 0) {
                tempRecord.Children = childData;
            }
            setLoading(false);

            if (isOutletCard) {
                setImageData({ ...tempRecord, RowNumber })
            }
            dispatch({ type: 'SET_PREV_NEXT_VISTA_DATA_RECORD', vistaData: { UpdatedRecord: tempRecord, rowId: RowNumber } });
        } else {
            setLoading(false);
            toast(isNext ? "Already showing latest image" : "There is no previous image available.");
        }
    }, [AssetPurityId, AssetId, setLoading, child_AssetPurityId, rightImageDateTime, leftImageDateTime])

    const memorizedPrev = useMemo(() => <div className="prev-button" onClick={() => onSwipe(false)} id={idx + "-Prev-Button"}>
        <FontAwesomeIcon icon={faChevronLeft} />
    </div>, [onSwipe]);
    const memorizedNext = useMemo(() => <div className="next-button" onClick={() => onSwipe(true)} id={idx + "-Next-Button"}>
        <FontAwesomeIcon icon={faChevronRight} />
    </div>, [onSwipe]);

    return <div className={`hero-wrapper bg-composed-wrapper ${utils.isMobile() ? "height40" : 'h-100'} rounded-right `} id={idx + '-hero-wrapper'}>

        <div className="flex-grow-1 w-100 d-flex align-items-end" id={idx + '-flex-grow'}>
            <div className="bg-composed-wrapper--image rounded-right opacity-9 bg-composed-filter-rm" id={idx + '-composed-wrapper'}>
                <div className="card-image-container" id={idx + '-card-image-container'} style={{ background: isFromVistaDetail ? '#fff' : '#000', overflow: 'hidden' }}>
                    {!hidePrevNext && <div className="image-next-previous">
                        {memorizedPrev}
                        {memorizedNext}
                    </div>}

                    <div style={{ width: "fit-content", height: 'inherit' }} id={idx + '-image-div'}>
                        {isLeftImageStitched ?
                            <img className="location_body_card_1_img"
                                style={{ height: '100%' }}
                                onLoad={onLoadImg}
                                alt=''
                                id={`image-view-${idx}`}
                                src={imageURLMin}
                                onClick={() => dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: imageURL } })} /> :

                            <div style={{ display: "flex", flexDirection: "column", height: '100%' }}>
                                {
                                    [Image1Size, Image2Size].map((val, i) => {
                                        return (ImageCount === 1 || val) && <img className="location_body_card_1_img"
                                            style={{ height: '100%' }}
                                            onLoad={onLoadImg}
                                            alt=''
                                            id={`image-view-${idx}`}
                                            src={`${eval(`leftcam1Image${i + 1}`)}&maxWidth=300`}
                                            onClick={() => dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: `${eval(`leftcam1Image${i + 1}`)}` } })} />
                                    })
                                }

                            </div>
                        }

                    </div>
                    {(!isSingleImage && hasChild) && <div style={{ width: "fit-content", height: 'inherit' }} id={idx + '-image-div-child'}>
                        {isRightImageStitched ?
                            <img className="location_body_card_1_img"
                                style={{ height: '100%' }}
                                onLoad={onLoadImg}
                                alt=''
                                id={`image-view-${index}`}
                                src={child_imageURLMin}
                                onClick={() => dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: child_imageURL } })} /> :
                            <div style={{ display: "flex", flexDirection: "column", height: '100%' }}>
                                {
                                    [Image1Size, Image2Size].map((val, i) => {
                                        return (ImageCount === 1 || val) && <img className="location_body_card_1_img"
                                            style={{ height: '100%' }}
                                            onLoad={onLoadImg}
                                            alt=''
                                            id={`image-view-${index}`}
                                            src={`${eval(`rightCam1Image${i + 1}`)}&maxWidth=300`}
                                            onClick={() => dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: `${eval(`rightCam1Image${i + 1}`)}` } })} />
                                    })
                                }
                            </div>
                        }
                    </div>}
                </div>
            </div>
        </div>
    </div>
})

export default VistaImages;