import React, { useEffect, useState } from 'react';
import SelectYear from './SelectYear';
import SelectMonth from './SelectMonth';
import SelectDay from './SelectDay';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next';
import utils from "../../utils"

const t = utils.t

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const countYears = 3;
const DateRange = React.memo(function DateRange(props) {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const dispatch = useDispatch();
    let { isFromDate = false, isToDate = false, selectedDate, } = props;
    const [yearOption, setYearOption] = useState([]);
    const [monthOption, setMonthOption] = useState([])
    const [dayOption, setDayOption] = useState([])

    const [selectedYear, setSelectedYear] = useState(new Date(selectedDate).getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date(selectedDate).getMonth());
    const [selectedDay, setSelectedDay] = useState(new Date(selectedDate).getDate());
    let initialDate = isFromDate ? new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() -7) : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    const getDaysOfMonths = (year, month) => {
        const dayOptionCopy = [];

        let days = new Date(year, month + 1, 0).getDate();
        //lets create the days of that month
        for (let d = 1; d <= days; d++) {
            dayOptionCopy.push(d)
        }
        setDayOption(dayOptionCopy);

    }
    useEffect(() => {

        setFilterValue(selectedYear, selectedMonth, selectedDay);

    }, [selectedYear, selectedMonth, selectedDay]);

    const setFilterValue = (selectedYear, selectedMonth, selectedDay) => {
        let dateValue = new Date(selectedYear, selectedMonth, selectedDay)
        if (isFromDate) {
            dispatch({ type: 'SET_FROM_DATE_FILTER', fromDateFilterValue: dateValue });
        } else if (isToDate) {
            dispatch({ type: 'SET_TO_DATE_FILTER', toDateFilterValue: dateValue });
        }

        if (selectedMonth && selectedYear) {
            getDaysOfMonths(selectedYear, selectedMonth)
        }
    }

    useEffect(() => {
        selectedDate = !selectedDate ? initialDate : selectedDate
        if (selectedDate) {
            let currentYear = new Date(selectedDate).getFullYear();
            let currentMonth = new Date(selectedDate).getMonth();
            let currentDay = new Date(selectedDate).getDate();
            const yearOptionCopy = [];
            for (let y = countYears; y >= 0; y--) {
                if (y === 0) {
                    yearOptionCopy.push(new Date(initialDate).getFullYear())
                    continue;
                }
                yearOptionCopy.push(new Date(initialDate).getFullYear() - y)
            }
            setYearOption(yearOptionCopy)

            const monthOptionCopy = []
            for (let m = 0; m < 12; m++) {
                let monthNum = new Date(currentYear, m).getMonth();
                let month = monthNames[monthNum];
                monthOptionCopy.push({ month, monthNum })
            }
            setMonthOption(monthOptionCopy);

            getDaysOfMonths(currentYear, currentMonth, currentDay)
            setSelectedYear(currentYear);
            setSelectedMonth(currentMonth);
            setSelectedDay(currentDay);
        }
    }, [selectedDate])

    const handleChange = (e) => {
        const { name, value } = e.target || e.currentTarget;

        switch (name) {
            case 'day':
                setSelectedDay(value);
                break;
            case 'month':
                setSelectedMonth(value);
                break;
            case 'year':
                setSelectedYear(value);
                break;
            default:
                return;
        }
    }

    return <>
        <Grid container spacing={2}>
            {selectedDate && <>
                <Grid item md={5}>
                    <SelectMonth options={monthOption} selected={selectedMonth} handelSelectMonth={handleChange} />
                </Grid>
                <Grid item md={3}>
                    <SelectDay options={dayOption} selected={selectedDay} handelSelectDay={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <SelectYear options={yearOption} selected={selectedYear} handelSelectYear={handleChange} />
                </Grid> </>
            }
        </Grid>
    </>
})

export default DateRange;