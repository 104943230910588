import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery20Icon from '@material-ui/icons/Battery20';

const VistaBatteryStatus = React.memo(function VistaLocation({ data }) {
  let batteryLevel = data.ImageEventBatteryValue;
  const [batteryIcon, setBatteryIcon] = useState(<BatteryUnknownIcon className="battery-grey" />);
  const [batteryStatus, setBatteryStatus] = useState("N/A");

  useEffect(() => {
    if (batteryLevel) {
      batteryLevel = (Number(batteryLevel) / 1000);
      batteryLevel = batteryLevel.toFixed(2);
      if (batteryLevel >= 2.7) {
        setBatteryIcon(<BatteryFullIcon className="battery-green" />);
        setBatteryStatus("Excellent Health");
      } else if (batteryLevel < 2.7 && batteryLevel > 2.1) {
        setBatteryIcon(<Battery50Icon className="battery-yellow-orange" />);
        setBatteryStatus("Good Health");
      } else {
        setBatteryIcon(<Battery20Icon className="battery-red" />);
        setBatteryStatus("Poor Health");
      }
    }
  }, [batteryLevel])

  return (
    <Box className='vista-box mt-1 mb-1'><Grid container wrap="nowrap" spacing={1}>
      <Grid item>
        <div className="icon-size">
          {batteryIcon}
        </div>
      </Grid>
      <Grid item xs>
        <Typography variant="body2" gutterBottom className="vista-address mt-1">
          {batteryStatus}
        </Typography>
      </Grid>
    </Grid>
    </Box>
  )
});

export default VistaBatteryStatus;