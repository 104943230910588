export const handleGlobalError = async function ({ url, errorMsg, errorObj, appName }) {
  if (!navigator.onLine) {
    return;
  }

  const isLocal = /^(https?:\/\/)?(localhost|127\.0\.0\.1|::1)(:\d+)?(\/|$)/i.test(document.URL);
  if (isLocal) {
    return;
  }

  let isExternalError = true;
  if (errorObj?.stack) {
    const stackLines = errorObj.stack.split('\n');
    const domainRegex = new RegExp(`https://${window.location.host}`);
    const extensionRegex = /^(chrome-extension|moz-extension|ms-browser-extension|safari-extension):\/\//;
    for (const line of stackLines) {
      if (domainRegex.test(line)) {
        isExternalError = false;
        break;
      } else if (extensionRegex.test(line)) {
        isExternalError = true;
        break;
      }
    }
  }

  const params = {
    rawUrl: window.location.href.replace(/&/g, "_"),
    stack: errorObj?.stack ?? (errorObj || errorMsg),
    fileName: appName
  };

  if (!isExternalError) {
    try {
      await fetch(`${url}`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(params), // Ensuring params is correctly stringified
        credentials: 'include'
      });
    } catch (error) {
      console.error('Failed to send error log:', error.message);
    }
  }
  return false;
};

export const initialize = function (url, appName) {
  // catch errors from browser extensions
  window.addEventListener('message', event => {
    if (event.origin !== window.location.origin) return;
    if (event.data.type === 'FROM_EXTENSION_ERROR') {
      const errorDetails = {
        url,
        errorMsg: event.data.error.message,
        errorObj: {
          stack: event.data.error.stack,
          message: event.data.error.message
        },
        appName
      };
      handleGlobalError(errorDetails);
    }
  });
  window.onerror = (errorMsg, rawUrl, line, col, errorObj) =>
    handleGlobalError({ url, errorMsg, errorObj, appName });
  window.onunhandledrejection = (event) =>
    handleGlobalError({ url, errorMsg: event.reason?.message, errorObj: event.reason, appName });
};
