import React, { useEffect } from 'react';
import PageTitle from '../../components/PageTitle'
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions';
import { useParams } from "react-router-dom";
import OrderStatus from '../../components/Replenishment/Replenishment';
import ScheduledOrderEmailLogs from '../Logs/ScheduledOrder';
import { useTranslation } from 'react-i18next';
import enums from '../../utils/constants'
import utils from '../../utils';
import SalesHistoryPage from '../SalesHistory';
import orderSuggestionHistoryModel from '../order-suggestion-history';
const { childRoutes, wrongAction } = enums;
const { t, isModuleExists } = utils;

export default function BusinessKPI() {
    const dispatch = useDispatch();
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const userData = useSelector(state => state.appReducer.userData) || {};
    const childPages = childRoutes.Replenishment;
    const { page } = useParams();

    useEffect(() => {
        dispatch({
            type: actions.SET_PAGE_TITLE_DETAILS,
            pageTitleDetails: <PageTitle titleHeading={t(childPages[page]?.title, tOpts)} titleDescription={t(childPages[page]?.description, tOpts)} icon="" title={t(childPages[page]?.title, tOpts)} />
        });

        return () => {
            dispatch({
                type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
            })
        }
    }, [])

    return (<>
        {(page === childPages.LocationReorder.page && isModuleExists(userData, childPages.LocationReorder.moduleName)) ? <OrderStatus /> :
            (page === childPages.ScheduledOrderEmailLogs.page && isModuleExists(userData, childPages.ScheduledOrderEmailLogs.moduleName)) ? <ScheduledOrderEmailLogs /> :
                (page === childPages.SalesHistory.page && isModuleExists(userData, childPages.SalesHistory.moduleName)) ? <SalesHistoryPage /> :
                    (page === childPages.OrderSuggestionHistory.page && isModuleExists(userData, childPages.OrderSuggestionHistory.moduleName)) ? <orderSuggestionHistoryModel.Grid /> :
                        <div>{t(wrongAction, tOpts)}</div>}
    </>)
}