//NPM Packages
import React, { useState, useMemo, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { FormControl, TextField, InputAdornment, Button, Grid, Container, Card, CardContent } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import clsx from "clsx";
import swal from 'sweetalert';
import { useTranslation } from "react-i18next"
import utils from "../../utils"
import ClearIcon from '@mui/icons-material/Clear';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { request, apis } from "../../httpUtil/";
const t = utils.t

const forgotPasswordMessages = {
    2: "Duplicate User",
    3: "Invalid Email",
    5: "A new OTP has been sent to the primary email address.",
    6: "Invalid OTP.",
    7: "Valid OTP",
    8: "OTP Expired",
    9: "Password changed successfully",
    10: "New password cannot match with in the last 5 passwords."
}

const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
const passwordRegex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,20}$/);
const forgotPasswordTimeInSeconds = utils.forgotPasswordTimeInSeconds
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #fff',
        boxShadow: theme.shadows[2],
        borderRadius: 10,
        padding: theme.spacing(1, 2, 2),
    },
    root: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function ForgotPassword() {
    const dispatch = useDispatch(), history = useHistory();
    const [textValue, setTextValue] = useState([]);
    const classes = useStyles();
    const [error, setError] = useState({});
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isOtpValid, setIsOtpValid] = useState(false);
    const [isPasswordValid, setPasswordValid] = useState(false);
    const [isPasswordMatching, setPasswordMatching] = useState(false);
    const [canSetNewPassword, SetCanSetNewPassword] = useState(false);
    const [timer, setTimer] = useState(forgotPasswordTimeInSeconds);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const { t: translate, i18n } = useTranslation()
    const [isVerificationButtonClicked, setIsVerificationButtonClicked] = useState(false);
    const [isOTPVerificationButtonClicked, setIsOTPVerificationButtonClicked] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const tOpts = { t: translate, i18n };
    const isMobile = utils.isMobile()?.length;

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const handleCloseModal = () => {
        history.push('/login');
    };

    const showErrorMessage = (message = '') => {
        swal({ title: t('Missing data', tOpts), text: t(message, tOpts), icon: "warning" });
        return
    }

    const getOTP = async event => {
        let params = Object.assign({});
        if (event) event.preventDefault();
        const { Email = '' } = textValue;
        params = { action: 'recoverpassword', Email: Email }
        const { success, moreInfo, error, info } = await request({ url: apis.login, params, history, dispatch });
        if (error) {
            showErrorMessage(forgotPasswordMessages[Number(info)]);
            return
        }
        if (success) {
            if (info) {
                if (info === utils.correctEmailResponseEnum) {
                    setIsVerificationButtonClicked(true)
                } else {
                    showErrorMessage(forgotPasswordMessages[Number(info)]);
                }
            }
        }

    };

    const verifyOTP = async event => {
        let params = Object.assign({});
        if (event) event.preventDefault();
        const { Email = '', tokenKey } = textValue;
        params = { action: 'recoverpassword', Email: Email, tokenKey: tokenKey }
        const { success, moreInfo, error, info } = await request({ url: apis.login, params, history, dispatch });
        if (error) {
            showErrorMessage(forgotPasswordMessages[Number(info)]);
            return
        }
        if (info == '7') {
            setIsOTPVerificationButtonClicked(true)
        } else {
            swal({ title: t('Invalid', tOpts), text: t("The verification code entered is incorrect", tOpts), icon: "warning" });
            return false;
        }
    };

    const resetPassword = async event => {
        let params = Object.assign({});
        if (event) event.preventDefault();
        const { Email = '', tokenKey, newPassword, ConfirmPassword } = textValue;
        params = { action: 'recoverpassword', Email: Email, tokenKey: tokenKey, newPassword: newPassword, ConfirmPassword: ConfirmPassword }
        const { success, moreInfo, error, info } = await request({ url: apis.login, params, history, dispatch });
        if (error) {
            showErrorMessage(forgotPasswordMessages[Number(info)]);
            return
        }
        if (info == '9') {
            swal({
                icon: 'success',
                title: t('Password saved successfully', tOpts),
                timer: 2000
            });
            handleCloseModal()
        }
        if (info == '10') {
            swal({ title: t('Invalid', tOpts), text: t(moreInfo, tOpts), icon: "warning" });
            return false;
        }
    };

    const confirmEmailButton = useMemo(() => <Button className={`f-f-ebrima ${isEmailValid ? 'login-btn' : 'login-disbaled'}`} disabled={!isEmailValid} onClick={getOTP} color="primary" variant="contained" size="large" > {t("SEND VERIFICATION CODE", tOpts)}</Button>, [translate, textValue, isEmailValid]);
    const memoizedBackButton = useMemo(() => <Button type="submit" className="login-back-btn f-f-ebrima" onClick={handleCloseModal} color="primary" variant="contained" size="large" >{t("BACK TO LOGIN", tOpts)}</Button>, [translate]);
    const verifyOTPButton = useMemo(() => <Button type="submit" className={`f-f-ebrima ${isOtpValid ? 'login-btn' : 'login-disbaled'}`} disabled={!isOtpValid} onClick={verifyOTP} color="primary" variant="contained" size="large" >{t("VERIFY", tOpts)}</Button>, [translate, textValue, isOtpValid]);
    const confirmNewPasswordButton = useMemo(() => <Button type="submit" className={`f-f-ebrima ${canSetNewPassword ? 'login-btn' : 'login-disbaled'}`} disabled={!canSetNewPassword} onClick={resetPassword} color="primary" variant="contained" size="large" >{t("CONFIRM", tOpts)}</Button>, [translate, textValue, canSetNewPassword]);

    const handleChange = (event) => {
        const { name, value } = event?.target;
        if (name === 'tokenKey') {
            if (!/^\d*$/.test(value)) {
                return; // Only allow numeric input
            }
        }
        setTextValue((textValue) => ({ ...textValue, [name]: value }));
        validate({ [name]: value });
    };

    function validate(fieldValue) {
        let msg = { ...error };
        const { Email, ConfirmPassword, newPassword, tokenKey } = fieldValue
        if ("Email" in fieldValue) {
            if (!Email) {
                setIsEmailValid(false)
                return
            }
            const emailValidation = emailRegex.test(Email);
            setIsEmailValid(emailValidation)
            msg[Object.keys(fieldValue)] = emailValidation ? "" : t("Invalid Email", tOpts);
        }
        if ("tokenKey" in fieldValue) {
            setIsOtpValid(tokenKey.length === 6)
        }
        SetCanSetNewPassword(false)
        if ("newPassword" in fieldValue) {
            if (newPassword) {
                const isValid = passwordRegex.test(newPassword);
                msg.newPassword = isValid ? "" : t("Password must contain minimum 7 and 20 maximum characters and have at least one upper, small character, number and special character.", tOpts);
                setPasswordValid(isValid);
            }
            if (textValue.ConfirmPassword) {
                const isValid = passwordRegex.test(newPassword);
                msg.ConfirmPassword = newPassword !== textValue.ConfirmPassword ? t("Password and confirm password should be same.", tOpts) : "";
                setPasswordMatching(newPassword === textValue.ConfirmPassword);
                SetCanSetNewPassword(newPassword === textValue.ConfirmPassword && isValid)
            }
        }
        if ("ConfirmPassword" in fieldValue) {
            if (ConfirmPassword) {
                const isValid = passwordRegex.test(textValue.newPassword);
                msg.ConfirmPassword = textValue.newPassword !== ConfirmPassword ? t("Password and confirm password should be same.", tOpts) : "";
                setPasswordMatching(textValue.newPassword === ConfirmPassword);
                SetCanSetNewPassword(textValue.newPassword === ConfirmPassword && isValid)
            }
        }
        setError({ ...msg });
    };

    useEffect(() => {
        if (!isVerificationButtonClicked) {
            return
        }
        let interval;
        // Update the timer every second
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            setIsResendDisabled(false);
        }
        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, [timer, isVerificationButtonClicked]);

    const handleResendClick = () => {
        if (timer > 0) {
            return
        }
        setIsResendDisabled(true);
        setTimer(forgotPasswordTimeInSeconds);
        getOTP();
    };

    return (
        <div className="app-wrapper min-vh-100">
            <div className="app-main app-main-align app-bg flex-column">
                <div className="bg-overlay">
                </div>
                <div className={clsx("app-content  p-0 min-vh-100", {
                    'w-100': isMobile
                })}>
                    <div className="app-content--inner d-flex align-items-center">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                            <div className="bg-composed-wrapper--content" >
                                <Container className='login-container'>
                                    <Grid container className="login-container w-100">
                                        <Card className="m-0 w-100 p-0 border-0" style={{ borderRadius: '20px' }}>
                                            <CardContent className="p-3">
                                                <div className={`${isMobile ? '' : "px-5"}`}>
                                                    <p className="f-f-ebrima mt-4 forgot-password-title">{isOTPVerificationButtonClicked ? t("Reset Password", tOpts) : t("Forgot Password", tOpts)}</p>
                                                    {!isVerificationButtonClicked &&
                                                        <p className="f-f-ebrima forgot-password-UI-messages">{t("Enter your email to receive 6 digit verification code to reset your password", tOpts)}.</p>
                                                    }
                                                    {isVerificationButtonClicked && !isOTPVerificationButtonClicked &&
                                                        <p className="f-f-ebrima forgot-password-UI-messages">{t("Enter the 6 digits verification code sent to your registered email", tOpts)}.</p>
                                                    }
                                                    {!isVerificationButtonClicked &&
                                                        <FormControl className="w-100 mt-4">
                                                            <TextField
                                                                id="standard-basic"
                                                                variant="standard"
                                                                name="Email"
                                                                required
                                                                label={t("Email", tOpts)}
                                                                error={error.Email}
                                                                onChange={handleChange}
                                                                value={textValue.Email}
                                                                {...(error['Email'] && {
                                                                    error: true,
                                                                    helperText: error['Email']
                                                                })}
                                                            />
                                                        </FormControl>
                                                    }
                                                    {isVerificationButtonClicked && !isOTPVerificationButtonClicked &&
                                                        <FormControl className="w-100 mt-4">
                                                            <TextField
                                                                id="standard-basic"
                                                                variant="standard"
                                                                name="tokenKey"
                                                                type="text"
                                                                required
                                                                value={textValue.tokenKey ? textValue.tokenKey : ''}
                                                                label={t("6 digit code", tOpts)}
                                                                error={error.tokenKey}
                                                                onChange={handleChange}
                                                                inputProps={{
                                                                    inputMode: 'numeric',
                                                                    pattern: '[0-9]*',
                                                                    maxLength: 6,
                                                                }}
                                                                {...(error['tokenKey'] && {
                                                                    error: true,
                                                                    helperText: error['tokenKey']
                                                                })}
                                                            />
                                                            <div className="d-flex justify-content-end mt-1">
                                                                <p className="f-f-ebrima">{t(`Haven’t received?`, tOpts)}</p>
                                                                {isResendDisabled &&
                                                                    <span style={{ color: 'blue' }} className="f-f-ebrima ml-1">{t('Resend in', tOpts)}{timer >= 1 ? ` ${timer}s` : ''}</span>
                                                                }
                                                                {!isResendDisabled &&
                                                                    <span className="f-f-ebrima ml-1 forgot-pass" style={{ color: 'blue' }} onClick={handleResendClick}>
                                                                        {t(`Resend Now`, tOpts)}
                                                                    </span>
                                                                }
                                                            </div>
                                                        </FormControl>
                                                    }
                                                    {isOTPVerificationButtonClicked &&
                                                        <>
                                                            <FormControl className="w-100 mt-4">
                                                                <TextField
                                                                    id="standard-basic"
                                                                    variant="standard"
                                                                    name="newPassword"
                                                                    required
                                                                    label={t("New Password", tOpts)}
                                                                    error={error.newPassword}
                                                                    onChange={handleChange}
                                                                    type={`${showPassword ? 'input' : 'password'}`}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment className='hover-cursor-pointer' onClick={handleClickShowPassword} position="end"> {showPassword ? <Visibility /> : <VisibilityOff />} </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    {...(error['newPassword'] && {
                                                                        error: true,
                                                                        helperText: error['newPassword']
                                                                    })}
                                                                />
                                                            </FormControl>
                                                            <FormControl className="w-100 mt-3">
                                                                <TextField
                                                                    id="standard-basic"
                                                                    variant="standard"
                                                                    required
                                                                    name="ConfirmPassword"
                                                                    label={t("Confirm Password", tOpts)}
                                                                    error={error.ConfirmPassword}
                                                                    onChange={handleChange}
                                                                    type={`${showConfirmPassword ? 'input' : 'password'}`}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment className='hover-cursor-pointer' onClick={handleClickShowConfirmPassword} position="end"> {showConfirmPassword ? <Visibility /> : <VisibilityOff />} </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    {...(error['ConfirmPassword'] && {
                                                                        error: true,
                                                                        helperText: error['ConfirmPassword']
                                                                    })}
                                                                />
                                                            </FormControl>
                                                        </>
                                                    }
                                                    {isOTPVerificationButtonClicked && !error.newPassword &&
                                                        <p className="f-f-ebrima mt-3 forgot-password-UI-messages">{t("Password must contain minimum 7 and 20 maximum characters and have at least one upper, small character, number and special character.", tOpts)}</p>
                                                    }
                                                    <div className={classes.root} style={{ marginTop: '40px' }}>
                                                        {!isVerificationButtonClicked && confirmEmailButton}
                                                        {!isVerificationButtonClicked && memoizedBackButton}
                                                        {isVerificationButtonClicked && !isOTPVerificationButtonClicked && verifyOTPButton}
                                                        {isOTPVerificationButtonClicked && confirmNewPasswordButton}
                                                    </div>


                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}