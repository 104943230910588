import Button from '@mui/material/Button';
import utils from '../utils';

const suggestionHistory = {

    title: 'Order Suggestion History',
    controller: "LocationOrderHistory",
    defaultSort: 'TimeStamp desc',
    rangeFilter: "ModifiedOn",
    columnVisibilty : {
        TimeStamp:true,
        LocationTypeID:false,
        LocationName:true,
        LocationCode:true,
        TotalOrder:true,
        TotalCase:true,
        OrderStatusId:true
    },
    pinnedColumns : {left: ['TimeStamp'],
    right: ['TotalOrder', 'TotalCase', 'OrderStatusId']},
    columns: [
        {
            field: 'TimeStamp',
            headerName: 'Status Date',
            width: 250,
            type: "dateTime",
            showInFilter: true,
			format: (item) => {
			      return utils.formatDate(item.value, true);
			}
        },
        {
            field: 'LocationTypeID',
            headerName: 'Channel',
            width: 250,
            showInFilter: true
        },
        {
            field: 'LocationName',
            headerName: 'Outlet Name',
            width: 250,
            showInFilter: true
        },
        {
            field: 'LocationCode',
            headerName: 'Outlet Code',
            width: 250,
            showInFilter: true
        },
        {
            field: 'TotalOrder',
            headerName: 'Value ($)',
            width: 150,
            showInFilter: true
        },
        {
            field: 'TotalCase',
            headerName: 'Cases',
            width: 150,
            showInFilter: true
        },
        { field: 'OrderStatusId', headerName: 'Status', width: 130, showInFilter: true, renderCell: (params) => {
            const orderStatusId = params.value;
            let statusText = '';
            let statusColor = '';
        
            if (orderStatusId === 0) {
                statusText = 'Inbox';
                statusColor = 'primary';
            } else if (orderStatusId === 5485) {
                statusText = 'Schedule';
                statusColor = 'warning';
            } else if (orderStatusId === 5484) {
                statusText = 'In Transit';
                statusColor = 'success';
            } else if (orderStatusId === 5486) {
                statusText = 'Ignored';
                statusColor = 'error';
            }
        
            return (
                <div className="custom-button">
                    <Button
                        variant="outlined"
                        color={statusColor}
                        className="custom-button"
                    >
                        {statusText}
                    </Button>
                </div>
            );
        }},  
    ]
}

export default suggestionHistory;