import React, { useState, useEffect, useRef } from 'react';
import utils from '../../utils';
import {
  Card, CardContent
} from "@material-ui/core";
import { GoogleMap, OverlayView, useLoadScript, InfoWindow } from '@react-google-maps/api';
import { request, apis } from "../../httpUtil";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useMobile from '../../utils/useMobile';
const freezer = require('../../assets/images/freezerWithProduct.png');
const shop = require('../../assets/images/shop.png')
const { Google_ApiKey, distanceBTWCoordinates, chartColorsD, t } = utils;

const OutletMap = (props) => {

  const { locationId } = props;
  const t = utils.t
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMobile();
  const mapStyles = { height: (isMobile.mobile && !isMobile.tablet) ? "700px" : "500px", width: "100%" };
  const mapDataRef = useRef({});
  const [loader, setLoader] = useState(true);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [statusInfo, setStatusInfo] = useState({
    inRangeAsset: [],
    inRangeAssetCount: '0',
    infoText: '',
    statusBarColor: chartColorsD[3],
    outletMarkerColor: chartColorsD[3],
    assetMarkerColor: chartColorsD[3]
  });

  const { isLoaded, } = useLoadScript({
    googleMapsApiKey: Google_ApiKey,
    language: i18n.language
  })

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const response = await request({ url: apis.OutletCardView, params: { locationId, dataTypes: ['map'] }, history, dispatch, jsonPayload: true });
      if (response.MapInfo) {
        mapDataRef.current = response.MapInfo;
      }
    }

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(() => { setLoader(false) })
      .finally(() => { setLoader(false) });
  }, [])

  function coordinateNA(value) {
    return value === null || value === undefined ? true : false;
  }

  async function mapMarkersGenerator(mapInstance) {
    try {
      const markersL = [], mapData = mapDataRef.current;
      const infoObject = {
        inRangeAssetCount: mapData?.assets.length === 0 ? 0 : (mapData?.assets.length || '-'),
        inRangeAsset: [],
        infoText: '',
        statusBarColor: chartColorsD[0],
        outletMarkerColor: chartColorsD[0],
        assetMarkerColor: chartColorsD[0]
      }
      let outletLoc = null, linesArray = [], registered = true;;
      if (mapData.outlet) {
        if (coordinateNA(mapData.outlet.Latitude) && coordinateNA(mapData.outlet.Longitude)) {
          infoObject.infoText = t("Please add outlet latitude and longitude on the information tab", tOpts);
          infoObject.statusBarColor = chartColorsD[3];
          registered = false;
        } else {
          markersL.push({
            latitude: mapData.outlet.Latitude,
            longitude: mapData.outlet.Longitude,
            name: mapData.outlet.Name,
            type: 'outlet'
          })
          outletLoc = { latitude: mapData.outlet.Latitude, longitude: mapData.outlet.Longitude }
        }
      }
      if (mapData.assets && mapData.assets.length > 0 && registered) {
        for (const asset of mapData.assets) {
          if (!(coordinateNA(asset.Latitude) && coordinateNA(asset.Longitude))) {
            const distance = distanceBTWCoordinates(outletLoc, { latitude: asset.Latitude, longitude: asset.Longitude });
            if (distance > Number(asset.DisplacementThreshold)) {
              markersL.push({
                latitude: asset.Latitude,
                longitude: asset.Longitude,
                name: asset.SerialNumber,
                distance: distance,
                type: 'asset'
              })

              linesArray.push(new window.google.maps.Polyline({
                path: [new window.google.maps.LatLng(outletLoc.latitude, outletLoc.longitude), new window.google.maps.LatLng(asset.Latitude, asset.Longitude)],
                geodesic: false,
                strokeColor: 'red',
                strokeOpacity: 1.0,
                strokeWeight: 3,
              }))

              infoObject.inRangeAssetCount--;
            } else {
              infoObject.inRangeAsset.push(asset.SerialNumber);
            }
          } else {
            infoObject.inRangeAsset.push(asset.SerialNumber);
          }
        }
      }

      if (markersL.length) {
        if (markersL.length === 1 && markersL[0].latitude === outletLoc.latitude && markersL[0].longitude === outletLoc.longitude) {
          if (mapData.assets.length) {
            infoObject.infoText = t("Your assets are in the correct outlet location", tOpts)
            infoObject.statusBarColor = chartColorsD[2];
            infoObject.outletMarkerColor = chartColorsD[2];
            infoObject.assetMarkerColor = chartColorsD[2];
          } else {
            infoObject.infoText = t("There is no asset in this location", tOpts)
            infoObject.statusBarColor = chartColorsD[0];
            infoObject.outletMarkerColor = chartColorsD[0];
            infoObject.assetMarkerColor = chartColorsD[0];
          }
        } else {
          infoObject.infoText = t("Some of the assets are not in the correct location", tOpts)
        }
        setMapMarkers(markersL);
      }

      if (markersL.length === 1) {
        mapInstance.setCenter(new window.google.maps.LatLng(markersL[0].latitude, markersL[0].longitude));
      } else if (markersL.length) {
        const bounds = new window.google.maps.LatLngBounds();
        for (const marker of markersL) {
          const point = new window.google.maps.LatLng(marker.latitude, marker.longitude)
          bounds.extend(point)
        }
        for (const line of linesArray) {
          line.setMap(mapInstance);
        }
        mapInstance.fitBounds(bounds);
        mapInstance.panToBounds(bounds);
      }
      setStatusInfo(infoObject)

    } catch (error) {
      return;
    }
  }

  const getPixelPositionOffsetMarker = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  })


  return (
    <Card className='m-0 p-0'>
      <CardContent className='m-0 p-0'>
        {isLoaded && !loader && <div className='outlet-map-info-container  text-center'>
          <div className='outlet-map-info' style={{ backgroundColor: statusInfo.statusBarColor }}><span>{t(statusInfo.infoText, tOpts)}</span> </div>
        </div>}
        {isLoaded && !loader ?
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={13}
            onLoad={mapMarkersGenerator}
            options={{ gestureHandling: 'greedy', minZoom: 2, clickableIcons: false, language: i18n.language }}>
            {mapMarkers.map((marker) => {
              const overlayStyle = { backgroundColor: (marker.type === 'outlet' ? statusInfo.outletMarkerColor : statusInfo.assetMarkerColor) };
              if (marker.type === 'outlet') {
                overlayStyle.zIndex = '50';
              }
              return <OverlayView
                key={`${marker.latitude}-${marker.longitude}`}
                position={new window.google.maps.LatLng(marker.latitude, marker.longitude)}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={getPixelPositionOffsetMarker}>
                <div className='overlay-image-container' style={overlayStyle} onClick={() => setSelectedMarker(marker)}>
                  <img src={marker.type === 'outlet' ? shop : freezer} height='32' width='32' alt="icon" />
                  {marker.type === 'outlet' && <div className='overlay-counter-container'>{statusInfo.inRangeAssetCount}</div>}
                </div>
              </OverlayView>
            })}
            {selectedMarker && <InfoWindow
              options={{ pixelOffset: new window.google.maps.Size(0, -55) }}
              position={new window.google.maps.LatLng(selectedMarker.latitude, selectedMarker.longitude)}
              clickable={true}
              onCloseClick={() => setSelectedMarker(null)}
              className="info-window" >
              <div>
                <div className='text-center'>
                  <p> {selectedMarker.name} </p>
                </div>
                {selectedMarker.type === 'outlet' ?
                  <div>
                    {statusInfo.inRangeAsset.length > 0 ?
                      <>
                        <p>{t('Following Assets are in Range', tOpts)}</p>
                        <p>{statusInfo.inRangeAsset.join(', ')}</p>
                      </> :
                      <>
                        <p>{t('None of the Assets are in Range', tOpts)}</p>
                      </>}
                  </div> :
                  <div>
                    <p>{`${t("Distance from registered location is", tOpts)} ${Math.round(selectedMarker.distance)} ${t("meters", tOpts)}`}</p>
                  </div>}
              </div>
            </InfoWindow>}
          </GoogleMap> :
          <div style={mapStyles} className='p-5'>
            <div className='outlet-map-info-container text-center'>
              <div className='outlet-map-info' style={{ backgroundColor: chartColorsD[1] }}><span>{t("Loading Map data ...", tOpts)}</span> </div>
            </div>
          </div>
        }
      </CardContent>
    </Card>
  );
}

export default OutletMap;