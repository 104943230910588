import React from 'react';
import * as JsError from './JsError';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
       return { hasError: true };
    }

    componentDidCatch(error, info) {
        JsError.handleGlobalError('Error Boundary Error', '', 0, 0, error);
    }

    render() {
     
        return this.props.children;
    }
}

export default ErrorBoundary;
