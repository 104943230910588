import React from 'react';
import {
    Grid, Typography, Dialog, DialogActions, DialogContent, DialogTitle
} from "@material-ui/core";
import { Button } from '@mui/material';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import useMobile from '../../utils/useMobile';
import utils from '../../utils';
import { Tooltip } from '@material-ui/core';
export default function DialogModal(props) {
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const isMobile = useMobile(true);
    const { open, onClose, dialogTitle = "", maxWidth = "lg", onSubmit, className = "", submitBtnTitle = "", dividers = false, hideFooter = false, headerClassName = '', footerTitle = '',
        hideCancel = false, closeBtnTitle = '', closeBtnCls = 'background-theme-blue', closeBtnColor = "primary", submitBtnDisabled = false, disableStyle = false, hideCloseIcon = false,
        disableBackdropClick = false, contentStyle = { display: 'flex' }, submitBtnColor = "primary", actionButtonsCls = '', normalHeight = true } = props;
    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={maxWidth}
                open={open}
                onClose={onClose}
                aria-labelledby="max-width-dialog-title"
                className={!disableStyle && (normalHeight ? `vista-model-bx ${className}` : ` ${className} `)}
                disableEnforceFocus
                disableBackdropClick={disableBackdropClick}
            >
                <DialogTitle id="max-width-dialog-title" className={`${isMobile ? 'p-2' : 'p-3'} ${headerClassName}`}>
                    <Grid container spacing={1}>
                        <Grid item xs={11} sm={11} md={11} lg={11}>
                            <Tooltip title={dialogTitle} style={{ maxWidth: 1000 }}>
                                <Typography className="wrapped-text-outlet-box" variant={isMobile ? "h6" : "h4"}>
                                    {t(dialogTitle, tOpts)}
                                </Typography>
                            </Tooltip>
                            {footerTitle && <Typography variant="subtitle2" style={{ marginTop: '4px' }}>{t(footerTitle)}</Typography>}
                        </Grid>
                        {!hideCloseIcon &&
                            <Grid item className="text-right" xs={1} sm={1} md={1} lg={1}>
                                <CloseIcon className='cursor_pointer' onClick={onClose} />
                            </Grid>
                        }
                    </Grid>
                </DialogTitle>
                <DialogContent dividers={true} style={contentStyle}>
                    {props.children}
                </DialogContent>
                {!hideFooter && <DialogActions className={actionButtonsCls}>
                    {!hideCancel && <Button onClick={onClose} className={closeBtnCls} color={closeBtnColor} variant='contained'>
                        {t(closeBtnTitle || "Close")}
                    </Button>}
                    {onSubmit &&
                        <Button onClick={onSubmit} disabled={submitBtnDisabled} color={submitBtnColor} variant='contained'>{submitBtnTitle || t('Submit')} </Button>
                    }
                </DialogActions>}
            </Dialog>
        </React.Fragment >
    );
}

DialogModal.propTypes = {
    open: propTypes.bool,
    onClose: propTypes.func
};