import utils from "../../../utils";
import constants from "../../../utils/constants";

/*
  @questionJson : param of type object. Contains the jsonobject selected for the survey
  @repeatCount: param of type integer. Contains the number of time the recursive function is executed
  deeClone(): function used to copy an object erasing all reference
  getRepeatedQuestions(): function used to extract the information about the question (s) that need to be repeated
  createIndex(): function to generate the id of repeated question
  createNewDependency(): function to generate the dependency array for a repeated question
*/
const separator = "~";
const deepClone = (objectpassed) => {
    if (objectpassed === null || typeof objectpassed !== 'object') {
        return objectpassed;
    }
    // give temporary-storage the original obj's constructor
    const temporaryStorage = objectpassed.constructor();
    for (const key in objectpassed) {
        temporaryStorage[key] = deepClone(objectpassed[key]);
    }
    return temporaryStorage;
}

const getRepeatedQuestions = (section) => {
    let repeated = {};
    section?.questions.forEach((question) => {
        if (question.repeat) {
            repeated = {};
            repeated["questions"] = question.repeat;
            repeated["position"] = question.id;
            repeated["answerkey"] = question.repeatkey;
        }
    })
    return repeated;
}

const createIndex = ({ question, repeatCount, idQuestAfterWhichToInject, qIndex, includeProductInfo }) => {
    const oldId = question.id;
    if (includeProductInfo) {
        return { oldId, newId: `${oldId}${utils.USER_REPEAT_COUNT_SEPARATOR}${repeatCount}` };
    } else {
        let idRef = Number(idQuestAfterWhichToInject.split(separator)[1]);
        let splittedCurrId = question.id.split(separator);
        return { oldId, newId: `${splittedCurrId[0]}${separator}${idRef + qIndex + 1}${separator}${splittedCurrId[2]}` };
    }

}
const createNewDependency = (dependency, idDictionary) => {
    let newDependency = dependency.map((item) => {
        item.question = idDictionary[item.question];
        return item;
    })
    return newDependency;
}
export function addRepeatedQuestions(questionJson, repeatCount, showHiddenQuestion = false) {
    if (!questionJson || !Object.keys(questionJson).length) {
        return;
    }
    const paramToInject = getRepeatedQuestions(questionJson);
    if (Object.keys(paramToInject) && !Object.keys(paramToInject).length) {
        return questionJson;
    }
    const questionJsonCopy = deepClone(questionJson);
    let newQuestions = {}, secPos = {}, questPos = {}, includeProductInfo = false, noListFoundOnDynamicOption = {};

    if (questionJson) {
        const section = questionJson;
        const sectionId = section["id"]
        const idQuestAfterWhichToInject = paramToInject["position"]; //question after which repeated questions will be added
        const answerkey = paramToInject["answerkey"];
        if (paramToInject) {
            secPos = 0;
            const getQuestionToinject = paramToInject["questions"];  // will get questions of a given section
            const idDictionary = {};
            if (section?.includeProductInfo) {
                includeProductInfo = true;
            }
            for (const [qIndex, question] of section["questions"].entries()) {
                const questionCopy = deepClone(question);
                const { oldId, newId } = createIndex({ question: questionCopy, repeatCount, qIndex, idQuestAfterWhichToInject, includeProductInfo });
                idDictionary[oldId] = newId;
                if (questionCopy["id"] == idQuestAfterWhichToInject) {
                    questPos = qIndex;
                }

                if (getQuestionToinject.includes(questionCopy["id"])) {
                    questionCopy.id = newId;
                    questionCopy.name = newId;
                    questionCopy.questionUniqueId = newId;
                    if (questionCopy["dependentQuestion"]) {
                        const newDependency = createNewDependency(questionCopy["dependentQuestion"], idDictionary)
                        questionCopy["dependentQuestion"] = newDependency;
                    } else {
                        questionCopy["dependentQuestion"] = [];
                    }

                    if (questionCopy.defaultDisplay !== "hidden") {
                        questionCopy.dependentQuestion = questionCopy.dependentQuestion || [];
                        questionCopy.dependentQuestion.push({
                            question: idQuestAfterWhichToInject,
                            behaviour: "show",
                            answerkey: answerkey,
                        });
                    } else if (questionCopy["dependentQuestion"]) {
                        questionCopy["dependentQuestion"].forEach((que) => {
                            if (!getQuestionToinject.includes(que.question.split(utils.USER_REPEAT_COUNT_SEPARATOR)[0])) {
                                questionCopy.dependentQuestion.push({
                                    question: idQuestAfterWhichToInject,
                                    behaviour: "show",
                                    answerkey: answerkey,
                                });
                            }
                        });
                    }

                    if (questionCopy.dynamicOptions) {
                        if (!questionCopy?.options || questionCopy?.options.length === 0) {
                            const optionLayout = JSON.stringify(questionCopy.dynamicOptions.layout);
                            const qOption = JSON.parse(optionLayout);
                            const type = qOption.value.split("{")[1]?.split("}")[0];
                            noListFoundOnDynamicOption = { ...noListFoundOnDynamicOption, [questionCopy.id]: { type: type, sectionId: sectionId } }
                        }
                    }


                    if (!showHiddenQuestion) {
                        questionCopy.defaultDisplay = "hidden";
                    }

                    questionCopy.repeated = true;

                    if (newQuestions[sectionId]) {
                        newQuestions[sectionId].push(questionCopy);
                    } else {
                        newQuestions[sectionId] = [];
                        newQuestions[sectionId].push(questionCopy);
                    }
                    continue;
                }

            }
        }

    }


    Object.keys(paramToInject) && Object.keys(paramToInject).length && Object.keys(paramToInject).map((sectionId, key) => {
        if (questionJsonCopy) {
            const oldQuestions = questionJsonCopy["questions"];
            questionJsonCopy["questions"].splice(questPos + 1, oldQuestions.length);
            const newQuestionsKeys = Object.keys(newQuestions);
            newQuestionsKeys.forEach(sectionId => {
                if (Array.isArray(newQuestions[sectionId])) {
                    const sectionQuestions = newQuestions[sectionId];
                    questionJsonCopy["questions"] = [
                        ...questionJsonCopy["questions"],
                        ...sectionQuestions
                    ];
                }
            });
        }
    })

    return { noListFound: noListFoundOnDynamicOption, questionJsonCopy: questionJsonCopy };

}
