import React from 'react';
import { Select, FormControl, MenuItem } from '@material-ui/core';

const SelectDay = React.memo(function SelectDay({ options, selected, handelSelectDay }) {
    return (
        <FormControl variant="outlined" fullWidth={true}>
            <Select name="day" MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                getContentAnchorEl: null
            }} className="filter-select days" value={selected || (options.length > 0 && options[0])} onChange={handelSelectDay}>
                {options.map(d => <MenuItem key={d} value={d}>{d}</MenuItem>)}
            </Select>
        </FormControl>
    )

})

export default SelectDay;