import UiModel from './ui-model';
import enums from '../utils/constants';
import { useSelector } from 'react-redux';

const { smartDevicesType, temperatureUnit } = enums;

const RenderTemperature = ({ value }) => {
    const { tags } = useSelector(state => state.appReducer.userData);
    value = Number(value);
    const temperatureUnitValue = parseInt(tags.TempratureUnitId) !== temperatureUnit.Celsius ? '°F' : '°C';
    return `${value} ${temperatureUnitValue}`;
}

const DeviceTrackingSmartDeviceModel = new UiModel({
    api: "DeviceTrackingSmartDevice",
    defaultSort: 'SerialNumber ASC',
    idProperty: 'SmartDeviceId',
    readOnly: true,
    addCreatedModifiedColumns: false,
    module: 'DeviceTrackingSmartDevice',
    addCreatedOnColumn: true,
    addCreatedByColumn: true,
    addModifiedOnColumn: true,
    addModifiedByColumn: true,
    hideBackButton: true,
    isChildGrid: true,
    preferenceId: 'playbookDeviceTrackingSmartDevice',
    columns: [
        { field: "SmartDeviceType", type: 'string', width: 250, label: "Device Type" },
        { field: "MacAddress", type: "string", width: 150, label: "Mac Address" },
        { field: "SerialNumber", type: "string", width: 150, label: "Serial Number" },
        { field: "ManufacturerSerialNumber", type: "string", width: 150, label: "Manufacturer Serial Number" },
        { field: "Status", type: "string", width: 150, label: "Status", hide: true },
        { field: "Imei", type: "string", width: 150, label: "IMEI" },
        { field: "SimNum", type: "string", width: 150, label: "Sim#" },
        { field: "DataUsage", type: "number", width: 150, label: "Data Usage(MB)" },
        { field: "LastPing", type: "dateTime", width: 150, label: "Last Ping", keepUTC: true },
        { field: "LastPingUTC", type: "dateTime", width: 150, label: "Last Ping UTC", keepUTC: true },
        { field: "LatestImageDateTime", type: "dateTime", width: 150, label: "Latest Image Date Time" },
        { field: "LatestRssi", type: "number", width: 150, label: "Latest RSSI" },
        { field: "FirmwareVersion", type: "string", width: 150, label: "Firmware Version" },
        { field: "Version2", type: "string", width: 150, label: "Version2" },
        { field: "ProductionToolVersion", type: "string", width: 150, label: "Production Tool Version" },
        { field: "IBeaconUuid", type: "string", width: 150, label: "IBeacon UUID", hide: true },
        { field: "Notes", type: "string", width: 150, label: "Internal Notes" },
        {
            field: "IBeaconMajor", type: "string", width: 150, label: "IBeacon Major/ Minor", hide: true, renderCell: (params) => {
                const { IBeaconMajor, IBeaconMinor } = params.row;
                return IBeaconMajor ? `${IBeaconMajor}/${IBeaconMinor}` : '';
            }
        },
        { field: "HardwareVersion", type: "string", width: 150, label: "Hw Version" },
        { field: "InventoryLocation", type: "string", width: 150, label: "Inventory Location" },
        { field: "TrackingNumber", type: "string", width: 150, label: "Tracking#" },
        { field: "Client", type: 'string', width: 200, label: "CoolR Client" },
        { field: "AssetType", type: "string", width: 150, label: "Asset Type" },
        { field: "DoorHandleLocationType", type: "string", width: 150, label: "Door Handle Location" },
        { field: "ChestCoolerType", type: "string", width: 150, label: "Orientation Type" },
        { field: "Asset", type: "string", width: 150, label: "Linked with Asset" },
        { field: "LinkedDevice", type: "string", width: 150, label: "Linked Smart Device" },
        { field: "IsMissing", type: "boolean", width: 150, label: "Missing" },
        { field: "LocationName", type: "string", width: 150, label: "Outlet" },
        { field: "LocationCode", type: "string", width: 150, label: "Outlet Code" },
        { field: "Street", type: "string", width: 150, label: "Street" },
        { field: "City", type: "string", width: 150, label: "City" },
        { field: "State", type: "string", width: 150, label: "State" },
        { field: "Country", type: "string", width: 150, label: "Country" },
        { field: "TimeZone", type: "string", width: 150, label: "Time Zone" },
        { field: "LatestHealthRecordEventTime", type: "dateTime", width: 150, label: "Latest Health Record Event Time" },
        { field: "LightIntensity", width: 150, label: "Light Intensity" },
        {
            field: "FinalTemperature", width: 150, label: "Temperature", renderCell: (params) => {
                const { row: { SmartDeviceTypeId }, value } = params;
                if (SmartDeviceTypeId === smartDevicesType.SmartHub) {
                    return 'N/A';
                } else {
                    return <RenderTemperature value={value} />
                }
            }
        },
        { field: "BatteryLevel", type: "number", width: 150, label: "Battery Level" },
        { field: "ImageEventBatteryValue", type: "number", width: 150, label: "Image Battery Value" },
        { field: "AdvertisementURL", type: "string", width: 150, label: "Advertisement URL" },
        { field: "SubscriptionStart", type: "dateTime", width: 150, label: "Subscription Start" },
        { field: "SubscriptionPaidUpto", type: "dateTime", width: 150, label: "Subscription Paid Upto" },
        { field: "ExpiredOn", type: "dateTime", width: 150, label: "Subscription Expired On" },
        { field: "HubLocation", type: "string", width: 150, label: "Hub Location in Cooler" },
        { field: "LatestLatitude", type: "float", width: 150, label: "Latest Latitude" },
        { field: "LatestLongitude", type: "float", width: 150, label: "Latest Longitude" },
        { field: "ShippingDate", type: "dateTime", width: 150, label: "Shipping Date" },
        { field: "DeliveryDate", type: "dateTime", width: 150, label: "Delivery Date" },
        { field: "InstallationDate", type: "dateTime", width: 150, label: "Installation Date", hide: true },
        { field: "PaidDataDate", type: "dateTime", width: 150, label: "Paid Data Date" },
        { field: "WarrantyEndDate", type: "dateTime", width: 150, label: "Warranty End Date" },
        { field: "LastCountry", type: "string", width: 150, label: "Last Country" },
        { field: "LastOperator", type: "string", width: 150, label: "Last Operator" },
        { field: "WifiLastPing", type: "dateTime", width: 150, label: "Wifi Last Ping" },
        { field: "IsCustomDFUEnabled", type: "boolean", width: 150, label: "Custom DFU Enabled" },
        { field: "CustomDFUFirmware", type: "string", width: 150, label: "Custom DFU Firmware" },
        { field: "Description", type: "string", width: 150, label: "Description" },
        { field: "HardwareStatus", type: "string", width: 150, label: "Hardware Status", hide: true },
        { field: "HardwareIssue", type: "string", width: 150, label: "Hardware Issue", hide: true },
        { field: "InstallAsset", type: "dateTime", width: 150, label: "Asset Installation Date" },
        { field: "Tags", type: "string", width: 150, label: "Tags", sortable: false, filterable: false },
    ]
});

export default DeviceTrackingSmartDeviceModel;