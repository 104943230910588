import { CalendarViewMonthOutlined, CenterFocusStrongOutlined, GridViewOutlined, PhotoCamera, WindowOutlined } from "@mui/icons-material";
import {
  Box,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers-pro';
import constants from "../../../utils/constants";
import BarcodeImg from "../../../../src/assets/images/barcode.svg";
import { makeStyles } from "@material-ui/core";
import utils from "../../../utils";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { CameraAlt, GridOnSharp, LinkedCamera } from "@material-ui/icons";

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles((theme) => ({
  photoCameraDisplayType: {
    display: "flex",
    width: "100%"
  },
  dashedBorder: {
    border: "1px dashed rgba(2, 136, 209, 0.5)",
    borderRadius: "6px",
    width: "72px",
    height: "72px",
    margin: theme.spacing(2)
  },
  cameraButton: {
    width: "72px",
    height: "72px",
    backgroundColor: "rgba(25, 118, 210, 0.5)"
  },
  matrixButtonContainer: {
    width: "100px",
    height: "100px"
  },
  matrixButton: {
    fontSize: "80px !important",
  },  
  matrixDisplayType: {
    display: 'grid',
    gap: theme.spacing(2)
  },
  matrixDashedBorder: {
    border: '2px dashed #ccc',
    padding: theme.spacing(2)
  },
  matrixCameraButton: {
    width: '100%',
    height: '100%'
  },
  customBarcode: {
    width: 300,
    height: 72,
    border: "1px dashed rgba(2, 136, 209, 0.5)",
    borderRadius: "6px",
    color: "rgba(25, 118, 210, 0.5)",
    alignItems: "center",
    position: "relative"
  },
  barcodeImageContainer: {
    position: "absolute",
    left: "40%",
    top: "10%",
    zIndex: "9"
  },
  textArea: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  }
}));
const {QUESTION_TYPES, CREATOR_QUESTION_DISPLAYNAME} = constants;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const PhotoCameraDisplayType = ({ question, editMode }) => {
  const classes = useStyles();
  const { displayName = '', type } = question || {};

  const RenderIcon = () => {
    switch(displayName || type) {
      case CREATOR_QUESTION_DISPLAYNAME.GENERAL_CAPTURE: //'General Image Capture':
        return <CameraAlt />;
      case CREATOR_QUESTION_DISPLAYNAME.PRODUCT_CAPTURE: //'Product Image Capture':
        return <LinkedCamera />
      case CREATOR_QUESTION_DISPLAYNAME.POS_CAPTURE: //'POS materials image capture':
        return <CenterFocusStrongOutlined />
      default:
        return null;
    }
  }

  return (
    <div className={classes.photoCameraDisplayType}>
      <div className={classes.dashedBorder}>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          disabled={editMode}
          className={classes.cameraButton}
        >
          <input hidden accept="image/*" type="file" />
          <RenderIcon />
        </IconButton>
      </div>
    </div>
  );
};

const MatrixIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    fill="none"
    stroke="#1976D2"
    color="primary"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3" y="3" width="6" height="6" />
    <rect x="11" y="3" width="6" height="6" />
    <rect x="19" y="3" width="6" height="6" />

    <rect x="3" y="11" width="6" height="6" />
    <rect x="11" y="11" width="6" height="6" />
    <rect x="19" y="11" width="6" height="6" />

    <rect x="3" y="19" width="6" height="6" />
    <rect x="11" y="19" width="6" height="6" />
    <rect x="19" y="19" width="6" height="6" />
  </svg>
);


const MatrixDisplayType = ({ editMode }) => {
  const classes = useStyles();
  const handleImageClick = () => {
    if (editMode) return;
    const fileInput = document.getElementById("matrixFileInput");
    fileInput?.click();
  };

    return (
      <div>
        <div>
          <IconButton
            aria-label="upload picture"
            component="label"
            onClick={handleImageClick}
            className={classes.matrixButtonContainer}
          >
            <input hidden accept="image/*" type="file" />
            <MatrixIcon />
          </IconButton>
        </div>
      </div>
    );
  };


export const CustomBarcode = ({ editMode }) => {
  const classes = useStyles();

  const handleImageClick = () => {
    if (editMode) return;
    const fileInput = document.getElementById("barcodeFileInput");
    fileInput?.click();
  };

  return (
    <Box className={classes.customBarcode} onClick={handleImageClick}>
      <div className={classes.barcodeImageContainer}>
        <input id="barcodeFileInput" hidden accept="image/*" type="file" />
        <img
          src={BarcodeImg}
          color={"rgba(25, 118, 210, 0.5)"}
          width={50}
          height={50}
          alt="Barcode"
        />
      </div>
    </Box>
  );
};

const RenderInputField = ({ type, ...props }) => {
  const classes = useStyles();
  switch (type) {
    case "text":
      return <TextField variant="standard" {...props} />;
    case "number":
      return (
        <TextField
          type="number"
          sx={{ width: "100%" }}
          variant="standard"
          {...props}
        />
      );
    case "Text Input (Single line)":
      return (
        <TextField
          id="standard-basic"
          label="Text Input (Single line)"
          variant="standard"
          {...props}
        />
      );
    case "Text Input (Multiple lines)":
      return (
        <TextField
          id="standard-multiline-flexible"
          label="Multiline"
          multiline
          maxRows={4}
          variant="standard"
          {...props}
        />
      );
    case "date":
      return (
        <DatePicker
          type="date"
          sx={{ width: "100%" }}
          InputLabelProps={{ shrink: true }}
          format={utils.systemDateTimeFormat(true)}
          variant="standard"
          {...props}
        />
      );
    case "textarea":
      return (
        <CardContent
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <TextField
            sx={{
                width: "100%"
            }}
            multiline
            rows={1}
            maxRows={2}
            variant="standard"
            {...props}
          />
        </CardContent>
      );
    default:
      return null;
  }
};

export function renderQuestionOptions(q, handleChange, qid) {
  return q.options.map((option, i) => (
    <MenuItem key={option.id} value={option.key}>
      <span data-qid={qid} option-id={i}>
        {option?.value?.length ? option.value : constants.defaultOptionText}
      </span>
    </MenuItem>
  ));
}

export function renderFormControl(
  q,
  handleChange,
  qid,
  sectionId,
  item,
  getAnswerValue,
  handleMultipleSelectChange,
  t,
  tOpts,
  section,
  handleCheckboxOptionChange
) {
  const handleSelectChange = (event) => {
    const selectedValues = event.target.value || []; 
    handleMultipleSelectChange(
      selectedValues,
      sectionId,
      item?.SerialNumber,
      item?.Product,
      q.id,
      item,
      section
    );
  };
  return (
    <FormControl>
      {q.subType === "Multiple Choice" ? (
        <FormControl sx={{ width: 300 }}>
          <Select
            id="demo-multiple-name"
            multiple
            value={
              getAnswerValue(
                sectionId,
                item?.SerialNumber,
                item?.Product,
                q.id
              ) || []
            }
            input={<OutlinedInput />}
            onChange={handleSelectChange}
            renderValue={(selectedKeys) =>
              selectedKeys
                .map((key) =>
                  q.options.find((option) => option.key === key)?.value || key
                )
                .join(", ")
            }
            MenuProps={MenuProps}
          >
            {q.options.map((option, o) => (
              <MenuItem key={option.value} value={option.key}>
                <Checkbox
                  checked={getAnswerValue(
                    sectionId,
                    item?.SerialNumber,
                    item?.Product,
                    q.id
                  ).includes(option.key)}
                />
                <ListItemText primary={option.value} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <FormControl sx={{ width: 300 }}>
          <Select
            id="demo-simple-select"
            value={getAnswerValue(
              sectionId,
              item?.SerialNumber,
              item?.Product,
              q.id
            )}
            onChange={(e) =>
              handleChange(
                e,
                sectionId,
                item?.SerialNumber,
                item?.Product,
                q.id,
                item,
                section
              )
            }
            gutterBottom
          >
            {renderQuestionOptions(q, handleChange, qid)}
          </Select>
        </FormControl>
      )}
    </FormControl>
  );
}

const RenderMediaCapture = ({ question, type, displayName, editMode, imageCount }) => {
  return (
    <div style={{ display: "flex" }}>
      {displayName === utils.AssetBarCodeCapture ? (
        <CustomBarcode editMode={editMode} />
      ) : (
        !imageCount && type === 'scene' ? <MatrixDisplayType editMode={editMode} /> : <PhotoCameraDisplayType question={question} editMode={editMode} />
      )}
    </div>
  );
};


const getLabelBySequence = (imageLabels, targetSequence) => {
  const matchingLabelObj = imageLabels?.find(labelObj => labelObj.sequence === targetSequence);
  return matchingLabelObj ? matchingLabelObj.label : '';
};

export const renderQuestionDetails = (
  q,
  qid,
  handleChange,
  sectionId,
  item,
  getAnswerValue,
  handleCheckboxOptionChange,
  editMode,
  handleMultipleSelectChange,
  handleDateChange,
  t,
  tOpts,
  section
) => {
  const imageQuestionTypes = [
    "scene",
    "multiFileStitch",
    "General Image Capture",
    "Product Image Capture",
    "POS materials image capture",
    "Asset Barcode Capture",
    "Multifile Image Capture"
  ];
  const radioQuestionTypes = ["boolean", "radio"];
  if (radioQuestionTypes.includes(q.displayName || q.type)) {
    return (
      <FormControl>
        <RadioGroup
          defaultValue=""
          name={q.displayName}
          value={getAnswerValue(
            sectionId,
            item?.SerialNumber,
            item?.Product,
            q.id
          )}
          onChange={(e) =>
            handleChange(
              e,
              sectionId,
              item?.SerialNumber,
              item?.Product,
              q.id,
              item,
              section
            )
          }
          sx={{ marginLeft: 2 }}
        >
          {(q.options || []).map((option, i) => (
            <FormControlLabel
              name={q.name}
              key={option.key}
              value={option.key}
              control={<Radio />}
              disabled={false}
              label={
                <span data-qid={qid} option-id={i}>
                  {option.value || constants.defaultOptionText}
                </span>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  } else if (q.displayName === CREATOR_QUESTION_DISPLAYNAME.CHECKBOX || q.type === QUESTION_TYPES.CHECKBOX) {
    return (
      <FormControl sx={{ marginLeft: 2 }}>
        {(q.options || []).map((option, i) => (
          <FormControlLabel
            key={option.id}
            disabled={false}
            control={
              <Checkbox
                name={option.name}
                disabled={option.disabled}
                checked={getAnswerValue(
                  sectionId,
                  item?.SerialNumber,
                  item?.Product,
                  q.id
                ).includes((option.key.toString()))}
                onChange={(e) =>
                  handleCheckboxOptionChange(
                    e,
                    sectionId,
                    item?.SerialNumber,
                    item?.Product,
                    q.id,
                    item,
                    section
                  )
                }
                value={option.key}
              />
            }
            label={
              <span data-qid={qid} option-id={i}>
                {option.value || constants.defaultOptionText}
              </span>
            }
          />
        ))}
      </FormControl>
    );
  } else if (q.displayName === CREATOR_QUESTION_DISPLAYNAME.DROPDOWN) {
    return renderFormControl(
      q,
      handleChange,
      qid,
      sectionId,
      item,
      getAnswerValue,
      handleMultipleSelectChange,
      t,
      tOpts,
      section,
      handleCheckboxOptionChange
    );
  } else if (imageQuestionTypes.includes(q.displayName || q.type)) {
    if (q.imageCount) {
      return q.imageCount && q.imageCount > 0 && (
        <Grid container spacing={2}>
          {[...Array(q.imageCount)].map((_, index) => (
                <Grid item xs={3} key={index}>
                  {RenderMediaCapture({ question: q, type: q.type, displayName: q.displayName, editMode, imageCount: q.imageCount })}
                  <Typography>{getLabelBySequence(q.imageLabels, constants.initialImageLabelSequence + (index+1))}</Typography>
                </Grid>
          ))}
          </Grid>
      );
    } else {
      return RenderMediaCapture({ question: q, type: q.type, displayName: q.displayName, editMode, imageCount: q.imageCount });
    }
  } else if (q.displayName === CREATOR_QUESTION_DISPLAYNAME.DATE || q.type === QUESTION_TYPES.DATE) {
    const selectedValue = getAnswerValue(
      sectionId,
      item?.SerialNumber,
      item?.Product,
      q.questionUniqueId
    );
    const inputFieldProps = {
      value: selectedValue ? dayjs(selectedValue) : null,
      onChange: (value) => {
        const adjustedDate = dayjs(value).hour(12);
        const isoString = adjustedDate.toISOString();
        handleDateChange(
          isoString,
          sectionId,
          item?.SerialNumber,
          item?.Product,
          q.questionUniqueId,
          item
        )}
    };
    if (q.fromDate) {
      inputFieldProps.minDate = dayjs(q.fromDate);
    }
    if (q.toDate) {
      inputFieldProps.maxDate = dayjs(q.toDate);
    }
    return <RenderInputField type={q.displayName || q.type} {...inputFieldProps} />;
  }
   else {
      const inputFieldProps = {
        value: getAnswerValue(
          sectionId,
          item?.SerialNumber,
          item?.Product,
          q.questionUniqueId
        ),
        onChange: (e) =>
          handleChange(
            e,
            sectionId,
            item?.SerialNumber,
            item?.Product,
            q.questionUniqueId,
            item
          ),
      };
      
      if (q.displayName === CREATOR_QUESTION_DISPLAYNAME.NUMBER || q.type === QUESTION_TYPES.NUMBER) {
        inputFieldProps.onKeyDown = (evt) => {
          const allowedKeys = ["e", "E", "+", "-"];
          const isNumber = /^\d$/.test(evt.key);
          const inputValue = evt.target.value + (isNumber ? evt.key : "");
          let isOutOfRange = false;
          if (q.min && q.max) {
            isOutOfRange = inputValue < q.min || inputValue > q.max;
          }
          if ( allowedKeys.includes(evt.key) || (isNumber && isOutOfRange)) {
            evt.preventDefault();
          }
        };
        if (q.min && q.max) {
          inputFieldProps.inputProps = {
              min: q.min,
              max: q.max
          }
        }
      }
    
    return <RenderInputField type={q.displayName || q.type} {...inputFieldProps} />;
  }
};
