import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import actions from '../../redux/actions';
import { useDispatch } from 'react-redux';
import { request, apis } from "../../httpUtil";
import SocialShare from "../SocialShare";
import toast from 'react-hot-toast';
import { withTranslation } from 'react-i18next';
import utils from '../../utils';
import * as JsError from '../../JsError';
const t = utils.t;

const VistaPhotoAction = React.memo(function VistaPhotoAction(props) {
    const { data, isMobile, labels = {}, t: translate, i18n } = props;
    const tOpts = { t: translate, i18n }
    const { RowNumber, AssetId, StitchedImageURL, SmartDeviceId, TimeSlotSettings, SmartDeviceTypeId } = data;
    const { takePhotoLabel = '', scheduleLabel = '' } = labels
    const dispatch = useDispatch(), history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleDialogOpen = useCallback(() => dispatch({ type: actions.SET_TIMESLOT_DIALOG_STATE, timeSlotDialogState: true, scheduleData: { SmartDeviceId, TimeSlotSettings, RowNumber } }), [SmartDeviceId, TimeSlotSettings, RowNumber, dispatch]);

    const SmartDeviceType = { CoolRSmartVista: 31, CoolRWIFIVista: 79 };

    const AddRemoteCommand = useCallback(async () => {
        let cmdValue = { command: "5010", customStringValue: "31", data: [], dataTypes: [] };
        let params = {};
        params.action = 'insertSmartDeviceCommand';
        params.isFromDashboard = true;
        params.otherAction = 'insertSmartDeviceCommand';
        params.SmartDeviceTypeCommandId = utils.isWIFIVistaOrVistaZ(SmartDeviceTypeId) ? 2682 : 2507;
        params.SmartDeviceTypeCommandCode = "5010";
        params.assetId = Number(AssetId);
        params.CommandValue = JSON.stringify(cmdValue);
        const response = await request({ url: apis.smartDevice, params, history, dispatch });
        if (response && response.success) {
            toast(t('Scheduled image taking.', tOpts));
        } else {
            toast(t('Something went wrong, Please try again later.', tOpts));
        }
    }, [AssetId, dispatch, history, SmartDeviceTypeId, SmartDeviceType, translate])


    const onShare = useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, [])


    const shareButton = useCallback((e) => {
        if (navigator.share) {
            navigator.share({
                title: t('Share Image', tOpts),
                url: StitchedImageURL
            }).then(() => {
                toast(t('Thanks for sharing!', tOpts));
            })
                .catch((error) => JsError.handleGlobalError('Error Sharing image', '', 57, 0, error));
        } else {
            setAnchorEl(e.currentTarget);
        }
    }, [StitchedImageURL]);

    //memorized function
    const memorizedTakeNewPhoto = useMemo(() => <button className='vista-photo-take-link' onClick={() => AssetId ? AddRemoteCommand() : null}>{takePhotoLabel || t('Take New Photo', tOpts)}</button>, [AssetId, AddRemoteCommand, takePhotoLabel, translate])
    const memorizedChangeSchedule = useMemo(() => <button className='vista-photo-take-link' onClick={handleDialogOpen}>{scheduleLabel || t('Change Schedule', tOpts)}</button>, [handleDialogOpen, scheduleLabel, translate])
    const memorizedShare = useMemo(() => <button className='vista-photo-take-link' onClick={isMobile ? shareButton : onShare}>{t("Share", tOpts)}</button>, [onShare, shareButton, isMobile, translate])


    const closeShareLink = () => setAnchorEl(null);

    return <>
        {memorizedTakeNewPhoto}
        <div className="divider-v divider-v-sm" />
        {memorizedChangeSchedule}
        <div className="divider-v" />
        {memorizedShare}
        <SocialShare data={data} anchorEl={anchorEl} closeShareLink={closeShareLink} />
    </>
});

export default withTranslation()(VistaPhotoAction);