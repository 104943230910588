import React from 'react';
import { Typography, FormControl, FormGroup, TextField, MenuItem, Select, InputLabel, Grid, Button, FormControlLabel } from "@material-ui/core";
import DialogModal from '../Common/DialogModel';
import utils from '../../utils';
import { useTranslation } from 'react-i18next';
const t = utils.t

const TrueCommandForm = React.memo(props => {


    const { onCommandChange, formValues, combos, fields } = props;
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };

    const getFormFields = columns => {
        const toReturn = [];
        for (const column of columns) {
            const { name, label } = column;
            toReturn.push(
                <Grid item xs={2}>
                    <div className="d-flex">
                        {t((label || name), tOpts)}
                    </div>
                </Grid>
            )
            toReturn.push(
                <Grid item xs={4}>
                    <TextField size="small" fullWidth variant={"outlined"} name={name} value={formValues[name] ?? ''} type={"number"} onChange={onCommandChange} />
                </Grid>
            )
        }
        return toReturn
    }

    return (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
            <Grid item xs={2}>
                <div className="d-flex">
                    {t("Command", tOpts)}<Typography id="text-bold" color='error' className='ml-1' >*</Typography>
                </div>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth size='small'>
                    <Select
                        labelId="command-name"
                        id="smart-device-command"
                        value={formValues["command"]}
                        name={"command"}
                        variant="outlined"
                        fullWidth={true}
                        onChange={onCommandChange}
                    >
                        {combos?.map(({ CustomStringValue, DisplayValue }, i) => {
                            return (
                                <MenuItem key={i} value={CustomStringValue}>{DisplayValue}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <div className="d-flex">
                    {t("Class", tOpts)}<Typography id="text-bold" color='error' className='ml-1' >*</Typography>
                </div>
            </Grid>
            <Grid item xs={4}>
                <TextField size="small" fullWidth variant={"outlined"} name={'class'} onChange={onCommandChange} value={formValues.class} />
            </Grid>
            {formValues["command"] && getFormFields(fields[formValues["command"]])}
        </Grid>
    )
});

export default TrueCommandForm;