import pluralize from 'pluralize';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import utils from '../utils';

dayjs.extend(utc);

class ModelConfig {
    combineConfig(modelConfig) {
        const { title, columns, defaultSort, readOnly } = modelConfig;
        let linkColumn;
        if (!readOnly) {
            ({ linkColumn } = modelConfig);
            if (linkColumn === undefined && defaultSort) {
                linkColumn = defaultSort.split(' ')[0];
            }
        }
        const gridColumns = [];
        for (const column of columns) {
            const headerName = column.headerName;
            const filter = column.filter;
            const filterOperators = utils.customDateOperators();
            if (headerName) {
                let valueGetter;
                if (column.type === 'dateTime') {
                    valueGetter = (params) => {
                        const originalValue = params.row[params.field];
                        if (originalValue) {
                            if (column.isUtc) {
                                return dayjs.utc(originalValue).format(utils.dateTimeFormat);
                            }
                        }
                        return originalValue;
                    }
                }
                if (column.type === 'time') {
                    valueGetter = (params) => {
                        const originalValue = params.row[params.field];
                        if (originalValue) {
                            return dayjs(originalValue).format(utils.timeFormat);
                        }
                        return originalValue;
                    }
                }
                gridColumns.push({
                    valueGetter,
                    headerName,
                    sortable: true,
                    filter: filter,
                    ...column
                });

                gridColumns.map((column, key) => {
                    if (column.type === "dateTime") {
                        gridColumns[key]["filterOperators"] = filterOperators;
                    }
                });

            }
        }
        return {
            linkColumn,
            listTitle: pluralize(title),
            gridColumns,
            ...modelConfig
        };
    }
}

export { ModelConfig };
export default new ModelConfig();