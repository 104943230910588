
import React, { useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, Switch, Grid } from '@material-ui/core';
import { Bar, Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import utils from "../../utils";
const colors = utils.chartColors;

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      offset: true,
      ticks: {
        beginAtZero: true,
        max: 100
      },
      gridLines: { display: false }
    }],
    xAxes: [{
      maxBarThickness: 100,
      gridLines: { display: false }
    }]
  },
  stacked: false,
  legend: {
    display: false
  },
  layout: {
    padding: {
      top: 20
    }
  }
}

const toggleSettings = {
  "onlyValuesBar": {
    showToolTips: false,
    tooltips: {
      enabled: false
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
        formatter: function (value, context) {
          return value ? Number(value).toFixed(1) : 'N/A'
        },
        borderRadius: 4,
        color: 'white',
        backgroundColor: function (context) {
          return context.dataset.borderColor;
        },
        padding: 3,
        offset: 2
      }
    }
  },
  "onlyValuesLine": {
    showToolTips: false,
    tooltips: {
      enabled: false
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'top',
        anchor: 'start',
        color: colors[1].br,
        padding: 3,
        offset: 10
      }
    },
  },
  "showAll": {
    tooltips: {
      enabled: true,
      caretPadding: 50
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  }
}

const monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

function PadLineChart({ data = [], defaultTooltip = true }) {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [toolTipChecked, setToolTipChecked] = React.useState(defaultTooltip);
  const [chartOptions, setChartOptions] = useState({ ...defaultOptions });

  useEffect(() => {
    const localChartData = {
      labels: [],
      data: []
    };

    for (const element of data) {
      localChartData.labels.push(dayjs(element.Date).format('D MMM'))
      localChartData.data.push(Number(element.Value).toFixed(1))
    }

    setChartData({
      labels: localChartData.labels, datasets: [{
        label: "Product Availability",
        data: localChartData.data,
        borderColor: colors[1].br,
        backgroundColor: colors[1].bg
      }]
    });
  }, [data])

  useEffect(() => {
    const optionsL = { ...defaultOptions, ...toggleSettings[toolTipChecked ? "onlyValuesLine" : "showAll"] };
    setChartOptions(optionsL);
  }, [toolTipChecked])

  return <>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormGroup>
          <FormControlLabel control={<Switch defaultChecked checked={toolTipChecked} onChange={() => setToolTipChecked(!toolTipChecked)} />} label="Tooltip Values" />
        </FormGroup>
      </Grid>
      <Grid item xs={6}> </Grid>
    </Grid>
    <Grid container spacing={2} className='kpi-chart-container'>
      <Line data={chartData} options={chartOptions} />
    </Grid>
  </>
}

function PadBarChart({ data = [] }) {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [toolTipChecked, setToolTipChecked] = React.useState(true);
  const [chartOptions, setChartOptions] = useState({ ...defaultOptions });

  useEffect(() => {
    const localChartData = {
      labels: [],
      data: []
    };

    for (const element of data) {
      localChartData.labels.push(monthsList[Number(element.Date) - 1]);
      localChartData.data.push(Number(element.Value).toFixed(1));
    }

    setChartData({
      labels: localChartData.labels, datasets: [{
        label: "Product Availability",
        data: localChartData.data,
        borderColor: colors[1].br,
        backgroundColor: colors[1].bg,
        borderWidth: 2,
        borderSkipped: false,
      }]
    });
  }, [data])

  useEffect(() => {
    const optionsL = { ...defaultOptions, ...toggleSettings[toolTipChecked ? "onlyValuesBar" : "showAll"] };
    setChartOptions(optionsL);
  }, [toolTipChecked])

  return <>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormGroup>
          <FormControlLabel control={<Switch defaultChecked checked={toolTipChecked} onChange={() => setToolTipChecked(!toolTipChecked)} />} label="Tooltip Values" />
        </FormGroup>
      </Grid>
      <Grid item xs={6}> </Grid>
    </Grid>
    <Grid container spacing={2} className='kpi-chart-container'>
      <Bar data={chartData} options={chartOptions} />
    </Grid>
  </>
}

export { PadLineChart, PadBarChart };