import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import { Button, Divider } from '@mui/material';
import { useState, useEffect } from 'react';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import utils from './../utils';
import useMobile from './../utils/useMobile';
import CustomFooter from './DataGrid/customFooter'
import HeaderFilterModelForm from './Common/HeaderFilterModelForm';
import actions from '../redux/actions';
import LocalizedDatePicker from "./LocalizedDatePicker";
const t = utils.t;

const capitalize = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

function CustomToolbar({ clearFilter, customToolbarOptions = [] }) {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <Button variant="text" onClick={clearFilter} className='grid-toolbar-button'><FilterListOffIcon className='grid-toolbar-icon' />{t('Clear Filter', tOpts)}</Button>
            <>{customToolbarOptions.map((item) => item)}</>
        </GridToolbarContainer>
    );
}


const GridBase = (props) => {
    const { columns,
        data,
        onCellClick,
        onCellDoubleClick,
        onRowDoubleClick,
        onRowClick,
        movePagination,
        defaultPageSize = 5,
        onSortModelChange,
        customClass = '',
        rowsPerPage = [5, 10, 20],
        autoHeight = true,
        onFilterModelChange,
        otherOptions = {},
        pagination = true,
        disableColumnMenu = false,
        disableColumnSelector = false,
        showFooter = false,
        showTitleColumn = false,
        filterMode = 'client',
        clearFilter,
        customToolbarOptions = [],
        hideFooter = false,
        gridRef = null,
        disableJTP = false,
        initialState,
        showHeaderFilter = false,
        setFiltersModel,
        parentFilters,
        setParentFilters,
        headerFilterValue
    } = props;

    const [hasColAndRows, setHasColAndRows] = useState(false);
    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [paginationClass, setPaginationClass] = useState("");
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    const gridRefLocal = useGridApiRef();
    const apiRef = gridRef ? gridRef : gridRefLocal;
    const isMobile = useMobile();
    const locale = useSelector(state => state.appReducer.locale);
    const dispatch = useDispatch();

    const [searchFilters, setSearchFilters] = useState({});
    const [typing, setTyping] = useState(true);

    useEffect(() => {
        if (!columns) {
            return;
        }
        if (columns.length > 0) {
            const getColumns = columns.map((column) => {
                const colObject = {
                    field: column.id,
                    headerName: t(capitalize(column.label), tOpts),
                    minWidth: column.minWidth,
                    width: column.width || null,
                    colSpan: column.colSpan || null,
                    filterable: column.filterable ?? true,
                    sortable: column.sortable ?? true,
                    flex: column.flex === undefined ? 1 : column.flex,
                    type: column.type,
                    headerAlign: column.headerAlign || null,
                    align: column.align || null,
                    valueOptions: column.valueOptions,
                    hide: column.hide,
                    headerFilter: column.headerFilter,
                }
                if (column.hide) {
                    colObject.hide = column.hide;
                }
                if (column.format) {
                    colObject.valueFormatter = column.format;
                }
                if (column.formatJSX) {
                    colObject.renderCell = column.formatJSX;
                }
                if (column.sortComparator) {
                    colObject.sortComparator = column.sortComparator;
                }
                if (column.valueGetter) {
                    colObject.valueGetter = column.valueGetter;
                }
                if (column.filterOperators) {
                    colObject.filterOperators = column.filterOperators
                }
                if (column.renderCell) {
                    colObject.renderCell = column.renderCell
                }
                if (column.columnMenu !== undefined) {
                    colObject.filterOperators = column.columnMenu
                }
                if (column.filter !== undefined) {
                    colObject.filterable = column.filter
                }
                if (column.sort !== undefined) {
                    colObject.sortable = column.sort
                }
                if (column.editable !== undefined) {
                    colObject.editable = column.editable
                }
                if (column.renderEditCell !== undefined) {
                    colObject.renderEditCell = column.renderEditCell
                }
                if (column.headerFilter) {
                    colObject.headerFilter = column.headerFilter;
                }
                if (column.type === "date" || column.type === "datetime") {
                    colObject.filterOperators = LocalizedDatePicker({ columnType: column.type });
                }
                return colObject;
            });
            setGridColumns(getColumns)
            setHasColAndRows(true);
        }
        if (movePagination) {
            setPaginationClass("move-pagination-right");
        }
    }, [columns, movePagination, locale])

    useEffect(() => {
        if (!data) {
            return;
        }
        if (data && Array.isArray(data) && data.length) {
            const getRows = data.map(row => {
                let rowId;
                const objKeys = Object.keys(row)
                objKeys.map((obj, index) => {
                    row[obj] = Object.values(row)[index]
                })
                if (row.id) {
                    return row;
                } else {
                    rowId = utils.generateUUID();
                    return { id: rowId, ...row }
                }
            })
            setGridRows(getRows);
        } else {
            setGridRows([]);
        }

    }, [data])


    const handleHeaderFilters = (e) => {
        const { value, name } = e.target;
        setTyping(true);
        const checkTyping = setTimeout(() => {
            setTyping(false);
            clearTimeout(checkTyping);
        }, 2000);

        let column = gridColumns.find(col => col.field === name);
        let columnType = column ? column.type : null;

        const setSearchFilterValue = (name, value) => {
            setSearchFilters((prevFilters) => {
                const newFilters = Object.assign({}, prevFilters);
                newFilters[name] = value;
                return newFilters;
            });
        };

        switch (name) {
            default:
                switch (columnType) {
                    case 'text':
                    case 'string':
                    case 'number':
                    case 'select':
                    case 'radio':
                        setSearchFilterValue(name, value);
                        break;
                    case 'date':
                        setSearchFilters((prevFilters) => {
                            const newFilters = Object.assign({}, prevFilters);

                            return newFilters;
                        });
                        break;
                    default:
                    // default action when the type is not recognized or not provided
                }
        }
    }


    useEffect(() => {
        if (!typing) {
            const items = [];
            for (const search in searchFilters) {
                const searchValue = searchFilters[search];
                let column = gridColumns.find(col => col.field === search);
                let columnType = column ? column.type : null;
                let operatorVal = "startsWith";
                if (columnType === "date") {
                    operatorVal = "is"
                }
                items.push({
                    "columnField": search,
                    "operatorValue": operatorVal,
                    "value": searchValue

                })
            }
            const headerFIlterObj = {
                ...otherOptions.filterModel.items,
                items
            }
            dispatch({ type: actions.GRID_HEADER_FILTERS, headerFilters: headerFIlterObj })
        }

    }, [typing])

    function customHeaderFilter() {
        return (
            <GridToolbarContainer>
                <HeaderFilterModelForm
                    modelConfig={gridColumns}
                    handleHeaderFilters={handleHeaderFilters}
                    searchFilters={searchFilters}
                />
            </GridToolbarContainer>
        )
    }
    function GridHeader() {
        if (showTitleColumn && showHeaderFilter && !disableColumnMenu) {
            return (
                <>
                    {CustomToolbar({ clearFilter, customToolbarOptions })}
                    {customHeaderFilter()}
                    <Divider sx={{ mt: 1, mb: 1 }} />
                </>
            );
        } else if (showTitleColumn && !disableColumnMenu) {
            return CustomToolbar({ clearFilter, customToolbarOptions });
        } else if (showHeaderFilter && !disableColumnMenu) {
            return customHeaderFilter();
        }
        return null;
    }
    return (
        <>
            {hasColAndRows ?
                <DataGridPro
                    sx={{
                        '@media print': {
                            '& .MuiDataGrid-window': {
                                height: '100%',
                                pageBreakAfter: 'avoid !important',
                            },
                            '& .MuiDataGrid-main': {
                                overflow: 'hidden !important',
                            },
                            '& .MuiDataGrid-window .MuiDataGrid-viewport': {
                                overflow: 'hidden !important',
                            },
                            '& .MuiDataGrid-panelFooter button:first-child': {
                                display: "none"
                            }
                        },
                    }}
                    localeText={{
                        toolbarColumns: t("Columns", tOpts),
                        noResultsOverlayLabel:t("No results found", tOpts),
                        columnsPanelTextFieldLabel: t('Find column', tOpts),
                        columnsPanelShowAllButton: t('Show all', tOpts),
                        columnsPanelHideAllButton: t('Hide all', tOpts),
                        columnsPanelTextFieldPlaceholder: t('Column title', tOpts),
                        toolbarFilters: t("Filters", tOpts),
                        columnHeaderSortIconLabel: t("Sort", tOpts),
                        filterPanelOperators: t('Operator', tOpts),
                        filterPanelColumns: t('Columns', tOpts),
                        filterPanelInputLabel: t('Value', tOpts),
                        filterOperatorContains: t('contains', tOpts),
                        filterOperatorEquals: t('equals', tOpts),
                        filterOperatorStartsWith: t('starts with', tOpts),
                        filterOperatorEndsWith: t('ends with', tOpts),
                        filterOperatorIsEmpty: t('is empty', tOpts),
                        filterOperatorIsNotEmpty: t('is not empty', tOpts),
                        filterOperatorIsAnyOf: t('is any of', tOpts),
                        filterPanelInputPlaceholder: t('Filter value', tOpts),
                        filterPanelAddFilter: t('Add filter', tOpts),
                        columnMenuLabel: t('Menu', tOpts),
                        columnMenuShowColumns: t('Show columns', tOpts),
                        columnMenuFilter: t('Filter', tOpts),
                        columnMenuHideColumn: t('Hide', tOpts),
                        columnMenuUnsort: t('Unsort', tOpts),
                        columnMenuSortAsc: t('Sort by ASC', tOpts),
                        columnMenuSortDesc: t('Sort by DESC', tOpts),
                        pinToLeft: t('Pin to left', tOpts),
                        pinToRight: t('Pin to right', tOpts),
                        unpin: t('Unpin', tOpts),
                        MuiTablePagination: { labelRowsPerPage: t("Rows per page", tOpts) },
                        noRowsLabel: t("No rows", tOpts),
                        checkboxSelectionHeaderName: t("Checkbox selection", tOpts),
                        detailPanelToggle: t("Detail panel toggle", tOpts),
                        toolbarColumnsLabel: t('Select columns', tOpts),
                        toolbarFiltersLabel: t('Show filters', tOpts),
                        toolbarFiltersTooltipHide: t('Hide filters', tOpts),
                        toolbarFiltersTooltipShow: t('Show filters', tOpts),
                        columnsPanelDragIconLabel: t('Reorder column', tOpts),
                        filterPanelOperatorAnd: t('And', tOpts),
                        filterPanelOperatorOr: t('Or', tOpts),
                        footerRowSelected: (count) => count === 1 ? `${count} ${t('row selected', tOpts)}` : `${count} ${t('rows selected', tOpts)}`,
                        columnHeaderFiltersTooltipActive: (count) => count === 1 ? `${count} ${t('active filter', tOpts)}` : `${count} ${t('active filters', tOpts)}`,
                        footerTotalRows: t("Total Rows", tOpts),
                        toolbarFiltersTooltipActive: (count) => count === 1 ? `${count} ${t('active filter', tOpts)}` : `${count} ${t('active filters', tOpts)}`,
                        filterPanelDeleteIconLabel: t('Delete', tOpts)
                    }}
                    className={paginationClass + `data-grid-base ${customClass}`}
                    onCellClick={onCellClick}
                    onCellDoubleClick={onCellDoubleClick}
                    onRowDoubleClick={onRowDoubleClick}
                    onRowClick={onRowClick}
                    columns={gridColumns}
                    onFilterModelChange={onFilterModelChange}
                    rows={gridRows}
                    autoHeight={autoHeight}
                    pagination={pagination}
                    disableColumnMenu={disableColumnMenu}
                    disableFooter={showFooter}
                    disableColumnSelector={disableColumnSelector}
                    labelRowsPerPage={t("Rows per page", tOpts)}
                    pageSize={pageSize}
                    filterMode={filterMode}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={rowsPerPage}
                    initialState={initialState}
                    components={{
                        Toolbar: GridHeader,
                        Footer: () => CustomFooter({ pagination, isMobile, tOpts, apiRef, page: otherOptions.page, disableJTP, data })
                    }}
                    hideFooter={hideFooter}
                    onSortModelChange={onSortModelChange ? onSortModelChange : (m) => { }}
                    {...otherOptions}
                    apiRef={apiRef}
                />
                : <h4 className='text-center pt-5'>{t('Loading...', tOpts)}</h4>}
        </>
    );
};

export default GridBase;