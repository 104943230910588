import React, { useEffect } from 'react';
import PageTitle from '../../components/PageTitle'
import Logs from '../../components/Logs';
import { useDispatch, useSelector } from 'react-redux';
import locationPin from '../../assets/images/vista-images/locationpin.png';
import ListRecordCount from '../../components/Common/ListRecordCount';
import actions from '../../redux/actions';
import { useTranslation } from 'react-i18next';
import utils from '../../utils';


const RightSide = (props) => {
    const scheduledLogsData = useSelector(state => state.appReducer.scheduledLogsData);
    let { records = [] } = scheduledLogsData || {}
    let recordListCount = records && records.length;
    return <><ListRecordCount icon={locationPin} count={recordListCount} displayText={"Log(s)"} /></>
}

const ScheduledOrderLogs = (props) => {
    const dispatch = useDispatch();
    const { t: T, i18n } = useTranslation();
    const tOpts = { t: T, i18n };
    const t = utils.t;

    useEffect(() => {
        dispatch({
            type: actions.SET_PAGE_TITLE_DETAILS,
            pageTitleDetails: <PageTitle
                titleHeading={t("Order Email Logs", tOpts)}
                titleDescription=""
                icon=""
                iconclass={'pageIconTitle'}
                RightComponent={() => <RightSide />}
                title={t("Scheduled Order Email Logs", tOpts)} />
        });

        return () => {
            dispatch({
                type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
            })
            utils.removeBackButton(dispatch);
        }
    }, [])

    return (<Logs />)
}

export default React.memo(ScheduledOrderLogs);