import DeviceTrackingSmartDeviceModel from './device-tracking-child';
import UiModel from './ui-model';

const deviceTrackingModel = new UiModel({
    title: "Device Tracking",
    defaultSort: 'ClientName ASC',
    titleDescription: 'Device Tracking Mechanism',
    idProperty: 'DeviceTrackingId',
    readOnly: true,
    addCreatedModifiedColumns: false,
    module: 'DeviceTracking',
    hideBackButton: true,
    preferenceId: 'playbookDeviceTracking',
    childTabs: [{ label: "Smart Device", config: DeviceTrackingSmartDeviceModel }],
    childTabLinkingKey: ['ClientId', 'SmartDeviceType', 'StatusId'],
    childParentKeyMapping: { ClientName: 'ClientId', DeviceType: 'SmartDeviceType', Status: 'StatusId' },
    columns: [
        { field: "ClientName", type: 'string', width: 200, label: "Client", onClickFilter: true },
        { field: "DeviceType", type: 'string', width: 250, label: "Device Type", onClickFilter: true },
        { field: "Status", type: "string", width: 150, label: "Status", onClickFilter: true },
        { field: "Count", type: 'number', width: 150, label: "Total", onClickFilter: true, link: true }
    ]
});

export default deviceTrackingModel;