import React, { useState, useEffect } from "react";
import utils from "../../utils";
import { useTranslation } from 'react-i18next'
import { Grid, TextField, FormGroup, FormControl, FormLabel } from "@material-ui/core";
const t = utils.t;
const defaultValue = { "gt": "", "lt": "" };

const MinMax = (props) => {

    const { onChange, value = defaultValue, type = "number", label } = props;
    const [valueL, setValueL] = useState(defaultValue);
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };

    useEffect(() => {
        setValueL(value)
    }, []);

    const handleDateChange = (e, action) => {
        const val = { ...valueL, ...{ [action]: e.target.value ? Number(e.target.value) : '' } };
        setValueL(val);
        if (val.gt && val.lt) {
            onChange && onChange(JSON.stringify(val));
        } else {
            onChange && onChange(null);
        }
    }

    return (
        <FormControl component="fieldset" variant="outlined">
            <FormLabel component="legend">{t(label, tOpts)}</FormLabel>
            <FormGroup>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} className="dis-grid pr-1">
                        <TextField
                            error={(!valueL.gt && valueL.lt) ? true : false}
                            size="small"
                            placeholder="Min"
                            variant="outlined"
                            type={type}
                            label={t("Min", tOpts)}
                            name="min"
                            value={valueL.gt}
                            onChange={(e) => handleDateChange(e, "gt")} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} className="dis-grid pl-1">
                        <TextField
                            error={(!valueL.lt && valueL.gt) ? true : false}
                            size="small"
                            placeholder={t("Max", tOpts)}
                            variant="outlined"
                            type={type}
                            label={t("Max", tOpts)}
                            name="max"
                            value={valueL.lt}
                            onChange={(e) => handleDateChange(e, "lt")} />
                    </Grid>
                </Grid>
            </FormGroup>
        </FormControl>
    )
}
export default MinMax;