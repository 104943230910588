import { React } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { KeyboardDatePicker } from "@material-ui/pickers";
import DropDownMenu from "../Common/DropDownMenu";
import { InfoRounded } from '@material-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import util from '../../utils';
import actions from "../../redux/actions";
import useMobile from '../../utils/useMobile';
import { Tooltip } from "@material-ui/core";
const isMobile = util.isMobile();

const kpiArray = [
  { name: "Assets covered (Total)", dataKey: "assetCoveredTotal", image: "outlet.png", definition: "Assets that sent at least one image from the period selected" },
  { name: "Devices covered (Total)", dataKey: "cameraCoveredTotal", image: "camera.png", definition: "Cameras that sent at least one image from the period selected" },
  { name: "Target portfolio size (Avg)", dataKey: "portfolioSize", image: "planogram-icon.png", definition: "Average quantity of products in the Planograms" }
]

const InfoBar = (props) => {

  const { infoBarData = {}, t, tOpts, filterConfig, dateFilterMaxDate } = props;
  const dispatch = useDispatch();
  const filterValues = useSelector((state) => state.appReducer.filterValues) || {};
  const userData = useSelector(state => state.appReducer.userData);
  const { fromDate, toDate, daysFilter } = filterValues;
  const mobile = useMobile();
  const dateFormat = userData?.tags?.DateFormat || "MM/dd/yyyy";

  const infoBarCard = document.getElementById("info-bar-card");
  let selectorHeight = null;
  if (infoBarCard) {
    selectorHeight = infoBarCard.offsetHeight;
    const selectorCard = document.getElementById("selector-card");
    if (selectorCard)
      selectorCard.style.height = selectorHeight + "px";
  }

  const handleChange = (event, selected, options, type) => {
    let { name, value } = event.target;
    if (Array.isArray(value)) {
      value = value.length ? value.filter((e) => e !== "-1") : ["-1"]
    }
    let filterObj = { ...filterValues };
    switch (name) {
      case "daysFilter":
        filterObj[name] = value;
        if (value !== '-1') {
          const obj = util.getDateFilter(value, dateFilterMaxDate);;
          filterObj['fromDate'] = obj.fromDate;
          filterObj['toDate'] = obj.toDate;
        }
        switch (value) {
          case '0:currentWeek':
            filterObj.activePeriods = ['WTD']
            break;
          case '0:currentMonth':
            filterObj.activePeriods = ['MTD']
            break;
          case '0:currentQuarter':
            filterObj.activePeriods = ['QTD']
            break;
          case '0:currentYear':
            filterObj.activePeriods = ['YTD']
            break;
          default:
            filterObj.activePeriods = ['Custom']
            break;
        }
        break;
      default:
        if (type === "checkUncheck" && options && event?.target?.value.includes('-1')) {
          if (filterObj[name] && filterObj[name].length === options.length) {
            filterObj[name] = []
          } else {
            filterObj[name] = options.map((item) => item.LookupId)
          }
        } else {
          filterObj[name] = value;
        }
        break;
    }
    filterObj["changedKey"] = name;
    dispatch({ type: actions.SET_FILTER_VALUES, filterValues: filterObj });
  };

  const handleDateChange = (date, field) => {
    let filterObj = { ...filterValues };
    filterObj[field] = date;
    dispatch({ type: actions.SET_FILTER_VALUES, filterValues: filterObj });
  };

  const filterGenerator = (filter, index) => {
    let filterComponent = <></>;
    switch (filter.type) {
      case 'select':
        filterComponent = <DropDownMenu
          classNameParent="w-100"
          options={filter.options}
          name={filter.name}
          label={t(filter.label, tOpts)}
          separateLabel={true}
          handleChange={(e) => handleChange(e, null, filter.options, filter.checkUncheck ? "checkUncheck" : "")}
          value={filterValues[filter.name] || (filter.multiSelect ? [] : "")}
          multiple={filter.multiSelect}
          size={filter.size}
          disabled={filter.disabled ? true : false}
          hideMenuLabel={filter.hideMenuLabel} />
        break;
      case "date":
        filterComponent = <>
          <Grid item xs={12} sm={12} md={12} lg={12} className=" mb-1">
            <DropDownMenu
              classNameParent="w-100"
              options={props.daysDropDown || util.daysDropDown}
              name="daysFilter"
              separateLabel={true}
              label={t("Date Range", tOpts)}
              handleChange={handleChange}
              value={daysFilter}
              size={filter.size}
              hideMenuLabel={filter.hideMenuLabel} />
          </Grid>
          
          <Grid item xs={5} sm={5} md={5} lg={5} className="">
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label={t("Start Date", tOpts)}
              name="fromDate"
              format={dateFormat}
              value={fromDate}
              invalidDateMessage={t("Invalid Date Format", tOpts)}
              minDateMessage={t('Date should not be before minimal date',tOpts)}
              maxDateMessage={t('Date should not be after maximal date',tOpts)}
              onChange={(e) => handleDateChange(e, "fromDate")}
              KeyboardButtonProps={{ "aria-label": "change date" }}
              disableFuture={true}
              disabled={props.customDate === "Disabled" ? true : daysFilter !== '-1'}
              maxDate={filter.maxDate ? filter.maxDate : new Date()} />
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} className="mt-4 pt-3 text-center">{t("To", tOpts)}</Grid>
          <Grid item xs={5} sm={5} md={5} lg={5} className="">
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              name="toDate"
              label={t("End Date", tOpts)}
              format={dateFormat}
              value={toDate}
              invalidDateMessage={t("Invalid Date Format", tOpts)}
              minDateMessage={t('Date should not be before minimal date',tOpts)}
              maxDateMessage={t('Date should not be after maximal date',tOpts)}
              onChange={(e) => handleDateChange(e, "toDate")}
              KeyboardButtonProps={{ "aria-label": "change date" }}
              disableFuture={true}
              disabled={props.customDate === "Disabled" ? true : daysFilter !== '-1'}
              maxDate={filter.maxDate ? filter.maxDate : new Date()}
              minDate={fromDate} />
          </Grid>
        </>
        break;
      default:
        filterComponent = <></>;
    }

    return (
      <Grid container key={index} item xs={12} sm={12} md={12} lg={12} className="dis-grid p-2">
        {filterComponent}
      </Grid>
    )
  }

  return (
    <>
      <Grid className="mt-2 " container spacing={mobile.mobile ? 0.5 : 1} >

        {filterConfig.map((categoryArray, catIndex) => {
          return (<>
            <Grid className="pt-1" item xs={12} sm={6} md={3} lg={3}>
              <Card className="mb-1 h-100" id='info-bar-card'>
                <CardContent className="pl-1 pr-1 pt-1 pb-0">
                  {categoryArray.length && categoryArray.map((filterObj, filterIndex) => {
                    return filterObj.hidden ? "" : filterGenerator(filterObj, catIndex + '-' + filterIndex);
                  })}
                </CardContent>
              </Card>
            </Grid>
          </>)
        })}

        {kpiArray.map((item) => {
          return (
            <Grid className="pt-1" item xs={4} sm={4} md={2} lg={2}>
              <Card className="mb-2 h-100" id='info-bar-card'>
                <CardContent className="pl-1 pr-1 pt-1 pb-0 text-center">
                  <div className={`${isMobile ? '' : 'all-center-div'}`}>
                    <div className="">
                      <img src={require(`../../assets/images/${item.image}`)} height="50" alt="" />
                    </div>
                    <div className="">
                      <Typography className='mb-0' variant="h6" gutterBottom component="div">{infoBarData[item.dataKey] ? Math.ceil(Number(infoBarData[item.dataKey])) : t("N/A", tOpts)}</Typography>
                    </div>
                    <div className="">
                      <Typography className='mb-0' variant="caption" display="block" gutterBottom> {t(item.name, tOpts)}</Typography>
                    </div>
                    <div className='tooltip-container'>
                      <Tooltip title={t(item.definition, tOpts)} placement="right">
                        <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                      </Tooltip>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </>
  );
};

export default InfoBar;