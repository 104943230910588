import React, { useEffect } from 'react';
import PageTitle from '../../components/PageTitle'
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions';
import Merchandising from '../../components/UnileverComponents/merchandising'
import ProductReport from '../UnileverReport/productReport';
import { useParams } from "react-router-dom";
import enums from '../../utils/constants'
import utils from "../../utils"
import { useTranslation } from 'react-i18next';
const { childRoutes, wrongAction } = enums;
const { t, isModuleExists } = utils;

export default function BusinessKPI() {
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const dispatch = useDispatch();
  const userData = useSelector(state => state.appReducer.userData) || {};
  const childPages = childRoutes.BusinessPerformance;
  const { page } = useParams();

  useEffect(() => {
    dispatch({
      type: actions.SET_PAGE_TITLE_DETAILS,
      pageTitleDetails: <PageTitle titleHeading={t(childPages[page]?.title, tOpts)} titleDescription={t(childPages[page]?.subtitle, tOpts)} icon='' title={t(childPages[page]?.title, tOpts)} />
    });

    return () => {
      dispatch({
        type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
      })
    }
  }, [])

  return (<>
    {(page === childPages.Merchandising.page && isModuleExists(userData, childPages.Merchandising.moduleName)) ? <Merchandising kpiCategory={page} /> :
      (page === childPages.SalesAndShopper.page && isModuleExists(userData, childPages.SalesAndShopper.moduleName)) ? <Merchandising kpiCategory={page} /> :
        (page === childPages.Portfolio.page && isModuleExists(userData, childPages.Portfolio.moduleName)) ? <ProductReport /> :
          <div>{t(wrongAction, tOpts)}</div>}
  </>)
}