
import React from 'react';
import { Grid, Card } from '@material-ui/core';
import utils from '../../utils';
import { withTranslation } from 'react-i18next';

const ValueDisplayCard = React.memo(function ValueDisplayCard({ t: translate, i18n }) {
    const tOpts = { t: translate, i18n };
    const t = utils.t;
    return (
        <>
            <Grid container spacing={1} direction="column" alignItems="stretch">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Card style={{ borderBottom: '4px solid #3e63d9' }} className="card-box">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="p-3">
                                    <h3 className="font-weight-bold display-4 mb-0 text-black">
                                        <span>86%</span>
                                    </h3>
                                </div>
                                <div className="pr-3">
                                    <small className="opacity-6 text-black-50">{t("PRESTOCK ACCURACY", tOpts)}</small>
                                </div>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Card style={{ borderBottom: '4px solid #3e63d9' }} className="card-box card-shadow-info">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="p-3">
                                    <h3 className="font-weight-bold display-4 mb-0 text-black">
                                        <span>93%</span>
                                    </h3>
                                </div>
                                <div className="pr-3">
                                    <small className="opacity-6 text-black-50">{t("STOCK ACCURACY", tOpts)}</small>
                                </div>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} lg={6}>
                        <Card style={{ borderBottom: '4px solid #00ad31' }} className="card-box mb-4">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="p-3">
                                    <h3 className="font-weight-bold display-4 mb-0 text-black">
                                        <span>5</span>
                                    </h3>
                                </div>
                                <div className="pr-3">
                                    <small className="opacity-6 text-black-50">{t("DAYS OUT OF STOCK", tOpts)}</small>
                                </div>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Card style={{ borderBottom: '4px solid #00ad31' }} className="card-box mb-4">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="p-3">
                                    <h3 className="font-weight-bold display-4 mb-0 text-black">
                                        <span>$250</span>
                                    </h3>
                                </div>
                                <div className="pr-3">
                                    <small className="opacity-6 text-black-50">{t("LOST REV", tOpts)}</small>
                                </div>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
})

export default  withTranslation()(ValueDisplayCard);