import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { apis } from "../httpUtil";
import actions from '../redux/actions'
import DialogModal from './Common/DialogModel';
import utils from '../utils';
import acostaReportListModel from '../pages/acosta-report-list';

let dispatch;

const AcostaReportList = () => {
    dispatch = useDispatch();
    const t = utils.t
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    const [isOpen, setIsOpen] = useState(false);
    const [images, setImages] = useState({ imageFileName: '', uniqueGuid: '' })
    let fromDate = useSelector(state => state.appReducer.fromDateFilterValue);
    let toDate = useSelector(state => state.appReducer.toDateFilterValue);

    if (!fromDate) {
        fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7)
    }
    if (!toDate) {
        toDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    }

    useEffect(() => {
        return () => {
            dispatch({ type: actions.SET_ACOSTA_REPORT_DATA, acostaReportData: { records: [], recordCount: 0 } });
            dispatch({ type: 'SET_LOCATION_SEARCH', locationSearch: "" });
        };
    }, [])

    const onRowClick = (rowData) => {
        let { ImageFileName, UniqueGuid } = rowData;
        setIsOpen(true);
        setImages({ imageFileName: ImageFileName, uniqueGuid: UniqueGuid })
    }

    return (
        <div>
            <acostaReportListModel.Grid onCellClick={onRowClick} />
            {isOpen && // For Prevent Rerendering
                <DialogModal open={isOpen} onClose={() => setIsOpen(false)} dialogTitle='Images' maxWidth="md" className="acosta-img-cont" >
                    <Grid container spacing={1}>
                        <Grid md={6} sm={6} lg={6} >
                            <p>{t("CoolR Images", tOpts)}</p>
                            <div className="text-align-center">
                                <img src={`${apis.imgBaseUrl}/Controllers/CoolerImagePreview.ashx?Guid=${images.uniqueGuid}&ImageType=Stitched&ImageName=''`} />
                            </div>
                        </Grid>
                        <Grid md={6} sm={6} lg={6} >
                            <p>{t("Acosta Images", tOpts)}</p>
                            <div className="text-align-center">
                                <img src={`${apis.imgBaseUrl}/Controllers/CoolerImagePreview.ashx?Guid=''&ImageType=AcostaImage&ImageName=${images.imageFileName}`} />
                            </div>
                        </Grid>
                    </Grid>
                </DialogModal>}
        </div>
    )
}

export default AcostaReportList