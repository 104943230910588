import { get, set, del } from 'idb-keyval';

export default {
	set: async (key, value) => {
		return await set(key, value);
	},

	get: async (key) => {
		let res = await get(key);
		return res;
	},

	del: (key) => {
		del(key);
	}
};