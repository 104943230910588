import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Card, Hidden } from '@material-ui/core';
import { List, AutoSizer, InfiniteLoader, CellMeasurerCache } from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once
import { request, apis } from "../../httpUtil";
import actions from '../../redux/actions';
import { useDispatch, connect } from 'react-redux';
import ScheduleTime from '../ScheduleTime/ScheduleTime'
import { useDebounce } from 'use-debounce';
import { useMedia } from 'the-platform';


import VistaDetailCard2 from './VistaDetailCard2';
import VistaSearch from '../VistaLocationCardList/VistaSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let history, dispatch, loadingDetail = false, initialLimit = { startIndex: 0, stopIndex: 10 };
const cache = new CellMeasurerCache({ defaultHeight: 550, fixedWidth: true, fixedHeight: false, minHeight: 400 });

const VistLocationListCard2 = React.memo(function VistLocationListCard2({ vistaSearch, vistaData, userData }) {
    const smallDevice = useMedia('(max-width: 400px)');
    const mediumDevice = useMedia({ minWidth: 401, maxWidth: 960 });

    const listRef = useRef(null);
    dispatch = useDispatch();
    history = useHistory();

    // State   
    const [fetchIndex, setFetchIndex] = useState(0);

    let { ClientId } = userData && userData.tags ? userData.tags : "";
    const [searchDebounce] = useDebounce(vistaSearch, 800)

    function isRowLoaded({ index }) {
        return !!vistaData.records[index];
    }

    const getFilter = useCallback(
        (params) => {
            let locationAssetFilter = {};
            if (vistaSearch) {
                locationAssetFilter = { assetOrLocationName: vistaSearch, isForRank: true };
                Object.assign(params, locationAssetFilter);
            }
            return params;
        },
        [vistaSearch],
    )

    const resetAll = useCallback(() => {
        cache.clearAll();
        listRef && listRef.current && listRef.current.recomputeRowHeights();
    }, [listRef])

    //fetch data
    const getData = useCallback(
        async ({ startIndex = 0, stopIndex }) => {
            if (!loadingDetail) {
                loadingDetail = true;

                if (startIndex !== 0 && fetchIndex === startIndex) { return }
                setFetchIndex(startIndex)
                let params = {
                    action: "list", asArray: 0, limit: 10, start: startIndex, showAllImages: false, isFetchCoolerData: true
                };
                params = getFilter(params);
                if (Number(ClientId) > 0) {
                    Object.assign(params, { clientId: Number(ClientId) });
                }
                const response = await request({ url: apis.assetLatestImages, params, history, dispatch });
                if (response && response.records) {
                    let isNeedsAttention = false;
                    let firstFilter = false;
                    if ((vistaSearch) && !startIndex) {
                        firstFilter = true;
                        dispatch({ type: actions.RESET_VISTA_DATA, vistaData: { records: [], recordCount: 0, TotalCount: 0, NeedAttentionCount: 0 } })
                    }
                    dispatch({ type: actions.SET_VISTA_DATA, vistaData: { records: response.records, recordCount: response.recordCount, TotalCount: response.TotalCount, NeedAttentionCount: response.NeedAttentionCount, startIndex, firstFilter, isNeedsAttention } })
                }
                resetAll()
                loadingDetail = false;
            }
        },
        [vistaSearch, ClientId, getFilter, fetchIndex, resetAll],
    )

    // resize virtual window
    const _onResize = props => cache.clearAll();

    useEffect(() => {
        if (searchDebounce || searchDebounce === '') {
            getData(initialLimit);
        }
    }, [searchDebounce]);

    let recordCount = vistaData && vistaData.recordCount ? vistaData.recordCount : 1
    let recordCountLst = vistaData && vistaData.records.length ? vistaData.records.length : 1

    return (<div className="virtual-list-wrapper">
        <ScheduleTime />
        <Hidden smDown>
            <div className="vista-search-wrapper justify-content-between">
                <VistaSearch />
                <Grid item xs={6} sm={2} lg={2}>
                    <Card className="pt-3 pr-3 pl-3 pb-2 current-rank" style={{ backgroundColor: "#4873FB" }}>
                        <div className="text-white current-rank-text">CURRENT RANK</div>
                        <div className="d-flex justify-content-between">
                            <div className="mb-0">
                                <span className="text-white current-rank-count">2</span>
                                <small className="text-white current-rank-count-outOf"> of 23</small>
                            </div>
                            <h3><FontAwesomeIcon icon={['fas', 'angle-right']} className="text-white" /></h3>
                        </div>
                    </Card>
                </Grid>
            </div>
        </Hidden>

        <InfiniteLoader
            loadMoreRows={getData}
            isRowLoaded={isRowLoaded}
            rowCount={recordCount}>
            {({ onRowsRendered, registerChild }) => {
                return <AutoSizer onResize={_onResize}>
                    {({ height, width }) => {
                        return (
                            <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', flexShrink: 1 }} ref={registerChild}>
                                <List
                                    ref={(listref) => {
                                        registerChild(listref);
                                        listRef.current = listref;
                                    }}
                                    style={{ outline: 'none', '&>div': { display: 'flex', justifyContent: 'space-around' } }}
                                    scrollToAlignment="start"
                                    onRowsRendered={onRowsRendered}
                                    deferredMeasurementCache={cache}
                                    height={height - (smallDevice ? 10 : mediumDevice ? 24 : 120)}
                                    rowCount={recordCountLst}
                                    rowHeight={cache.rowHeight}
                                    rowRenderer={({ key, index, style, parent }) => <VistaDetailCard2 key={index} keyCard={key} index={index} style={style} parent={parent} cache={cache} />}
                                    width={width}
                                /></div>
                        )
                    }}
                </AutoSizer>
            }}
        </InfiniteLoader>
    </div>
    );
});

const mapStateToProps = state => ({
    vistaSearch: state.appReducer.vistaSearch,
    vistaData: state.appReducer.vistaData,
    userData: state.appReducer.userData,
});

export default connect(mapStateToProps)(VistLocationListCard2);
