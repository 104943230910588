import React, { useEffect, useState } from 'react';
import PageTitle from '../../components/PageTitle'
import CardRouter from '../../components/GenericCard';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions';
import { Grid, Typography } from '@mui/material';
import utils from "../../utils"
import { useTranslation } from 'react-i18next';
import enums from '../../utils/constants'
import useMobile from '../../utils/useMobile';
const { childRoutes } = enums;
const { t, isModuleExists } = utils;

const Operations = () => {
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const dispatch = useDispatch();
  const childPages = childRoutes.Operations;
  const [childObj, setChildObj] = useState({ task: {}, page: {} });
  const isMobile = useMobile();
  const userData = useSelector(state => state.appReducer.userData) || {};

  useEffect(() => {
    dispatch({
      type: actions.SET_PAGE_TITLE_DETAILS,
      pageTitleDetails: <PageTitle titleHeading={t("Operations", tOpts)} titleDescription={t("Understand on-going tasks and equipment health", tOpts)} icon="" title={t("Operations", tOpts)} classTitle="home-header-container" />
    });
    dispatch({
      type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
        filterButton: "",
        filterButtonName: "",
        printButtonName: "",
        clear: null,
        apply: null,
        hidden: { search: true, operation: true, export: true, print: true, filter: true }
      }
    });


    const keys = Object.keys(childPages), childObjL = { task: {}, page: {} };
    for (const key of keys) {
      if (childPages[key].type === 'task') {
        childObjL.task[key] = childPages[key];
      } else {
        childObjL.page[key] = childPages[key];
      }
    }
    setChildObj(childObjL);

    return () => {
      dispatch({
        type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
      })
    }
  }, [])

  return (
    <Grid className={`mb-1 ${!isMobile.tablet ? (isMobile.portrait ? "mt-2" : "") : (isMobile.tablet && isMobile.portrait ? "mt-1" : "mt-n2")}`} container spacing={1}>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography className={(isMobile.mobile && !isMobile.tablet && isMobile.portrait) ? "ml-4" : ''} variant="h6">{t("Tasks", tOpts)}</Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CardRouter childObj={childObj.task} />
      </Grid>
    </Grid>
  )
}

export default Operations