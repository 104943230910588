import React, { useState, useEffect } from 'react';
import { Card, Grid } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { useGridApiRef, DataGridPremium, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExportContainer } from "@mui/x-data-grid-premium";
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import MenuItem from '@mui/material/MenuItem';
import { apis, request } from '../../httpUtil';
import { useHistory } from 'react-router-dom';
import { Info, ExpandLess } from '@material-ui/icons';
import addIcon from '../../assets/images/add.svg';
import utils from '../../utils';
import { useDispatch, useSelector } from "react-redux";
import useMobile from '../../utils/useMobile';
import { useTranslation } from 'react-i18next';
import constants from '../../utils/constants';
import GridPreference from '../GridPreference';
import dateUtils from '../../utils/date';
import Footer from '../grid/footer';

const t = utils.t;
const { dayjsFormatLocal } = utils;
const format = utils.systemDateTimeFormat(true);
const lang = 'en';
const permissions = constants.defaultPermissions;

function renderDate(value, tOpts) {
    const date = dateUtils.dateParse(value);
    return dayjsFormatLocal({ value: date, format: format || constants.dateFormat.date, tOpts, lang });
}
function Export() {

}
const ExportMenuItem = ({ handleExport, contentType, type, isPivotExport = false }) => {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const { onExportClick } = useSelector(state => state.appReducer.operationsCheckedData) || {};
    return (
        <MenuItem
            onClick={onExportClick}
            data-type={type}
            data-content-type={contentType}
            data-is-pivot-export={isPivotExport}
        >
            {t("Export", tOpts)} {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}
        </MenuItem>
    );
};



const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
        <ExportMenuItem {...props} type="excel" contentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
        {props.showPivotExportBtn && <ExportMenuItem {...props} type="excel With Pivot" contentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" isPivotExport={true} />}
    </GridToolbarExportContainer>
);
const GridBase = (props) => {
    const { columns,
        data,
        onCellClick,
        onCellDoubleClick,
        onRowDoubleClick,
        onRowClick,
        movePagination,
        onSortModelChange,
        customClass = '',
        autoHeight = true,
        otherOptions = {},
        pagination = true,
        disableColumnMenu = false,
        disableColumnSelector = false,
        showFooter = false,
        filterMode = 'client',
        hideFooter = false,
        gridRef = null,
        clearFilter,
        closeExpandedRows,
        row,
        onClickItem,
        columnVisibilityModel,
        preferenceName,
        setIsGridPreferenceFetched,
        applyPreferences = true,
        menuItems: menuItems
    } = props;

    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);
    const [paginationClass, setPaginationClass] = useState("");
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    const gridRefLocal = useGridApiRef();
    const apiRef = gridRef || gridRefLocal;
    const isMobile = useMobile();
    const locale = useSelector(state => state.appReducer.locale);
    const history = useHistory();
    const dispatch = useDispatch();
    const userData = useSelector(state => state.appReducer.userData);
    const { Username } = userData?.tags || {};
    let currentPreference = useSelector(state => state.appReducer.currentPreference);
    currentPreference = currentPreference?.[preferenceName];

    useEffect(() => {
        if (!columns) {
            return;
        }
        if (columns.length > 0) {
            const getColumns = columns.map((column) => {
                const { id, field, flex } = column;

                let colObject = column;

                colObject = { ...colObject, field: field || id, flex: flex === undefined ? 1 : flex, }
                return colObject;
            });
            setGridColumns(getColumns);
        }
        if (movePagination) {
            setPaginationClass("move-pagination-right");
        }
    }, [columns, movePagination, locale])

    useEffect(() => {
        if (!data) {
            return;
        }
        if (data && Array.isArray(data) && data.length) {
            const getRows = data.map(row => {
                let rowId;
                const objKeys = Object.keys(row)
                objKeys.map((obj, index) => {
                    row[obj] = Object.values(row)[index]
                })
                if (row.id) {
                    return row;
                } else {
                    rowId = utils.generateUUID();
                    return { id: rowId, ...row }
                }
            })
            setGridRows(getRows);
        } else {
            setGridRows([]);
        }

    }, [data]);

    useEffect(() => {
        if (applyPreferences && !isMobile.mobile && !isMobile.tablet) {
            utils.removeCurrentPreferenceName({ dispatch });
            utils.fetchPreferencesAndApplyDefault({ preferenceName, history, dispatch, Username, gridRef, setIsGridPreferenceFetched });
        } else if (setIsGridPreferenceFetched) {
            setIsGridPreferenceFetched(true);
        }
    }, [])

    const CustomToolbar = (props) => {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                {
                    currentPreference ? (
                        <Typography className="preference-name-text" variant="h6" component="h6" textAlign="center" sx={{ ml: 1 }}>
                            {t('Applied Preference', tOpts)} - {currentPreference}
                        </Typography>
                    ) : (
                        <Typography sx={{ visibility: 'hidden', flex: 1 }}>
                            Preference
                        </Typography>
                    )
                }
                <GridToolbarContainer {...props}>

                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    {permissions.export && (
                        <CustomExportButton Export={Export} />
                    )}
                    <Button startIcon={<FilterListOffIcon />} onClick={clearFilter} size="small">{t(constants.clearFilterText, tOpts)}</Button>
                    <GridPreference preferenceName={preferenceName} gridRef={gridRef} columns={columns} setIsGridPreferenceFetched={setIsGridPreferenceFetched} />
                </GridToolbarContainer>
            </div>
        );
    };

    let selectedRows = gridRows.filter(ele => otherOptions?.selectionModel?.includes(ele.LocationId))?.map(ele => { return ele.RowNumber });

    return (
        <>
            {isMobile.mobile && !isMobile.tablet && row &&
                <Grid item xs={12} sm={12} md={12} lg={12} id={`detail-panel-icons-div-${row?.LocationId}`}>
                    <div className='d-flex justify-space-between align-center w-100'>
                        <div onClick={() => onClickItem({ row, defaultTab: 0 })}><Info color="primary" /></div>
                        <div className='expandLessArrow' onClick={closeExpandedRows}><ExpandLess /></div>
                    </div>
                </Grid>
            }
            <DataGridPremium
                defaultGroupingExpansionDepth={-1}
                className={paginationClass + `data-grid-base grid-color ${customClass}`}
                onCellClick={onCellClick}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={otherOptions?.onColumnVisibilityModelChange}
                onCellDoubleClick={onCellDoubleClick}
                onRowDoubleClick={onRowDoubleClick}
                onRowClick={onRowClick}
                columns={gridColumns}
                onFilterModelChange={otherOptions?.onFilterModelChange}
                rows={gridRows}
                autoHeight={autoHeight}
                pagination={pagination}
                disableColumnMenu={disableColumnMenu}
                disableFooter={showFooter}
                disableColumnSelector={disableColumnSelector}
                labelRowsPerPage={t("Rows per page", tOpts)}
                filterMode={filterMode}
                sortModel={otherOptions?.sortModel}
                filterModel={otherOptions?.filterModel}
                paginationModel={otherOptions?.paginationModel}
                pageSizeOptions={[10, 20, 50]}
                onPaginationModelChange={otherOptions?.setPaginationModel}
                hideFooter={hideFooter}
                disableRowSelectionOnClick={true}
                rowSelectionModel={selectedRows}
                onRowSelectionModelChange={(e) => otherOptions?.onSelectionModelChange(e, apiRef)}
                onSortModelChange={onSortModelChange || ((m) => { })}
                disableRowGrouping={true}
                initialState={{
                    columns: {
                        columnVisibilityModel: columnVisibilityModel
                    },
                    pinnedColumns: { right: ['__detail_panel_toggle__'] }
                }}
                {...otherOptions}
                apiRef={apiRef}
                slots={{
                    toolbar: (otherOptions?.showCustomToolbar === false || isMobile.mobile) ? null : CustomToolbar,
                    footer: Footer
                }}
                slotProps={{
                    footer: {
                        pagination: true,
                        tOpts,
                        apiRef,
                        isMobileDevice: isMobile.mobile
                    },
                    panel: {
                        placement: "bottom-end"
                    },
                }}
            />
        </>
    );
};

function ExpandedRow(props) {
    const { data = [], onAddItem, t: translate, i18n, row, onClickItem, closeExpandedRows, filterValues = { uiClientIds: [] }, multiClientFilters } = props;
    const isMobile = useMobile();
    function formatCurrency(number, currency) {
        const formattedNumber = number.toFixed(2);
        return currency?.replace('{0}', formattedNumber);
    }

    const expandedColumns = [
        {
            id: 'ProductId', field: 'ProductId', headerName: 'Image', disableColumnMenu: true, pinnable: false, aggregable: false, groupable: false, filterable: false, sortable: false, flex: 0, align: 'center', width: 60, valueGetter: (value) => value.row, type: "string", renderCell: (params) => {
                return (
                    <div className="d-flex d-flex-justify-center">
                        {params.row.ProductId ? <img alt='...' src={apis.imgBaseUrl + "/controllers/thumbnail.ashx?imageType=Thumbnail&ImagePath=" + params.row.ProductId + ".png&maxWidth=30"} className="expanded-row-card-product py-2" /> : ''}
                    </div>
                )
            }
        },
        { id: 'UnitsNeeded', field: 'UnitsNeeded', headerName: 'Qty',pinnable: false, aggregable: true, flex: 0, align: 'center', width: isMobile.mobile ? 50 : 60, filterable: false, sortable: false, valueGetter: (value) => Number(value.row.UnitsNeeded || '0'), type: "number" },
        {
            id: 'Amount', field: 'Amount', headerName: 'Value', width: 70 ,pinnable: false, aggregable: true, flex: 0, align: 'center', filterable: false, sortable: false, justifyContent: 'flex-end', type: "number", valueGetter: (value) => Number(value.row.Amount || '0'), valueFormatter: (item) => {
                return formatCurrency(item.value, row.Currency);
            }
        },
    ];
    const history = useHistory();
    const dispatch = useDispatch();
    const [productId, setProductId] = useState(0);
    const [assetLocation, setAssetLocation] = useState([])
    const tOpts = { t: translate, i18n };
    const gridRef = useGridApiRef();

    const uniqueRows = [];

    data.forEach(row => {
        const { ProductName, CaseSize } = row;
        const existingRow = uniqueRows.find(r => r.ProductName === ProductName && r.CaseSize === CaseSize);

        if (existingRow) {
            existingRow.UnitPrice += row.UnitPrice;
            existingRow.UnitsNeeded += row.UnitsNeeded;
            existingRow.Amount += row.Amount;
        } else {
            uniqueRows.push({ ...row });
        }
    });

    const sortedData = uniqueRows.sort(sortData).map((data, index) => {
        return {
            ...data,
            hierarchy: [data.ProductCategoryName ? data.ProductCategoryName : 'No Category', `${data.ProductName} (${data.CaseSize} pack)`],
            rowIndex: index
        }
    });

    useEffect(() => {
        setAssetLocation([]);
        getLastImage();
    }, [row])

    useEffect(() => {
        if (productId) {
            document.getElementsByName("productQty")[0] && document.getElementsByName("productQty")[0].focus();
        }
    }, [productId])

    async function getLastImage() {

            let params = {
                action: "CustomList", asArray: 0, limit: 10, start: 0, showAllImages: false, isFetchCoolerData: true,
                sort: 'AvgStockPercentage', dir: 'ASC'
            };
            if (filterValues?.uiClientIds?.length > 0) {
                params.uiClientIds = filterValues.uiClientIds.toString();
                utils.applyMultiClientFilters(params, filterValues, multiClientFilters);
            }
            let assetPurityIdList = [];
            let { ProductInfo } = row;
            ProductInfo.forEach(({ AssetPurityId: assetPurityId, AssetPurityIds: assetPurityIds }) => {
                assetPurityIdList = assetPurityIdList.concat((assetPurityIds && assetPurityIds !== '' ? assetPurityIds.split(',') : [assetPurityId]).filter(imageId => !assetPurityIdList.includes(imageId)));
            });
            params = { ...params, assetOrLocationName: '', isForRank: true, LocationId: row.LocationId, assetPurityIds: assetPurityIdList.join() }
            const response = await request({ url: apis.assetLatestImagesNew, params, history, dispatch });
            setAssetLocation(response?.records || [])

    }

    function sortData(objF, ObjS) {
        const first = Number(objF.UnitsNeeded) * Number(objF.UnitPrice), second = Number(ObjS.UnitsNeeded) * Number(ObjS.UnitPrice);
        if (first < second) {
            return 1;
        }
        if (first > second) {
            return -1;
        }
        return 0;
    }

    const getRowClassName = (params) => {
        return `custom-row-style-${params.row.rowIndex}`;
    }

    const getTreeDataPath = (row) => row.hierarchy;
    const getRowId = (row) => row.hierarchy.join('/');

    const generateInlineStyles = () => {
        let styles = '';
        sortedData.map((value) => {
            styles += `
            .custom-row-style-${value.rowIndex} {
              background-color: ${value.bgColor ? value.bgColor : ''};
              padding: 5px 0px;
              min-Height: 60px !important
              white-space: normal !important;
              word-wrap: break-word !important;
            }
          `;
        });
        return styles;
    };

    const inlineStyles = generateInlineStyles();

    function getRowHeight(params) {
        return params.model.ProductName ? 'auto' : 50;
    }

    return (
        <div>
            <style>{inlineStyles}</style>
            <Grid className='' container spacing={1}>
                {isMobile.mobile && !isMobile.tablet &&
                    <Grid item xs={12} sm={12} md={12} lg={12} id={`detail-panel-icons-div-${row.LocationId}`}>
                        <div className='d-flex justify-space-between align-center w-100'>
                            <div onClick={() => onClickItem({ row, defaultTab: 0 })}><Info color="primary" /></div>
                            <div className='expandLessArrow' onClick={closeExpandedRows}><ExpandLess /></div>
                        </div>
                    </Grid>
                }
                <Grid container >
                    {isMobile.mobile && !isMobile.tablet &&
                        <Grid item xs={12} sm={12} md={12} lg={12} id={`detail-panel-icons-div-${row.LocationId}`}>
                            <Box component="section" sx={{ p: 2, border: '1px solid black' }}>
                                <div className='display-flex'>
                                    <Grid item xs={8} sm={8} md={8} lg={8}>{t('Last Order Date', tOpts)}:</Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={4} className='display-flex justify-flex-end fw-bold'>{renderDate(row.LastOrderDate, tOpts)}</Grid>
                                </div>
                                <div className='display-flex'>
                                    <Grid item xs={8} sm={8} md={8} lg={8}>{t('Last Delivery Date', tOpts)}:</Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={4} className='display-flex justify-flex-end fw-bold'>{renderDate(row.LastDeliveryDate, tOpts)}</Grid>
                                </div>
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        {assetLocation?.length > 0 && assetLocation.map((asset, index) => {
                            let child = null;
                            if (asset.IsParentAsset && asset.Children && asset.Children[0]) {
                                child = asset.Children[0];
                            }
                            return (
                                <div className='expand-asset-container' key={index}>
                                    <p className=" mb-1 expand-asset-title">{`${asset.AssetSerialNumber} - ${dateUtils.dateMatch(asset.PurityDateTime)} - ${asset.ReplenishModel}`}</p>
                                    <div className='location-expand-image-container' onClick={() => dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: `${asset.StitchedImageURL}` } })}>
                                        <img alt='...' src={`${asset.StitchedImageURL}&maxWidth=350`} className='location-expand-image' />
                                    </div>
                                    {child && <>
                                        <p className=" mb-1 expand-asset-title">{`${child.AssetSerialNumber} - ${dateUtils.dateMatch(child.PurityDateTime)} - ${asset.ReplenishModel}`}</p>
                                        <div className='location-expand-image-container' onClick={() => dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: `${child.StitchedImageURL}` } })}>
                                            <img alt='...' src={`${child.StitchedImageURL}&maxWidth=350`} className='location-expand-image' />
                                        </div>
                                    </>}
                                </div>
                            )
                        })}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <GridBase
                            applyPreferences={false}
                            customClass={'mt-2 py-2'}
                            columns={expandedColumns}
                            data={sortedData}
                            showTitleColumn={true}
                            gridRef={gridRef}
                            pagination={false}
                            hideFooter={true}
                            disableColumnSelector={true}
                            otherOptions={{
                                showCustomToolbar: false,
                                initialState: {
                                    aggregation: {
                                        model: {
                                            UnitsNeeded: "sum",
                                            Amount: "sum"
                                        }
                                    }
                                },
                                getRowClassName: getRowClassName,
                                getRowHeight: getRowHeight,
                                getTreeDataPath: getTreeDataPath,
                                getRowId: getRowId,
                                pinnedColumns: isMobile.mobile ? { right: ['ProductId', 'UnitsNeeded', 'Amount'] } : {},
                                treeData: true,
                                groupingColDef: { headerName: 'Product Name', width: isMobile.mobile ? 150 : 400, },
                            }}
                        />
                    </Grid>

                </Grid>
                {
                    onAddItem && <Grid item xs={2} sm={2} md={2} lg={2} >
                        <Card className="expand-item-center " style={{ minHeight: '60px' }}>
                            <img src={addIcon} alt='Add' height="18" />
                            <span className="add-item" onClick={onAddItem} >{t("ADD ITEM", tOpts)}</span>
                        </Card>
                    </Grid>
                }

            </Grid>
        </div >
    )
}

export { ExpandedRow, GridBase };