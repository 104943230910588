/*
  @questionJson : param of type object. Contains the jsonobject selected for the survey
  @repeatCount: param of type integer. Contains the number of time the recursive function is executed
  deeClone(): function used to copy an object erasing all reference
  getRepeatedQuestions(): function used to extract the information about the question (s) that need to be repeated
  createIndex(): function to generate the id of repeated question
  createNewDependency(): function to generate the dependency array for a repeated question
*/
import utils from "../../utils";
const separator = "~";
const deepClone = (objectpassed) => {
    if (objectpassed === null || typeof objectpassed !== 'object') {
        return objectpassed;
    }
    // give temporary-storage the original obj's constructor
    const temporaryStorage = objectpassed.constructor();
    for (const key in objectpassed) {
        temporaryStorage[key] = deepClone(objectpassed[key]);
    }
    return temporaryStorage;
}

//get details of all questions that trigger a group of question to repeat
const getRepeatedQuestions = (questionJson) => {
    let repeated = {};
    questionJson["sections"].forEach((section) => {

        section["questions"].forEach((question) => {
            if (question.repeat) {
                repeated[section.id] = {};
                repeated[section.id]["questions"] = question.repeat;
                repeated[section.id]["position"] = question.id;
                repeated[section.id]["answerkey"] = question.repeatkey;

            }
        })
    })
    return repeated;
}

const createIndex = ({ question, repeatCount, idQuestAfterWhichToInject, qIndex, includeProductInfo }) => {
    const oldId = question.id;
    if (includeProductInfo) {
        return { oldId, newId: `${oldId}${utils.USER_REPEAT_COUNT_SEPARATOR}${repeatCount}` };
    } else {
        let idRef = Number(idQuestAfterWhichToInject.split(separator)[1]);
        let splittedCurrId = question.id.split(separator);
        return { oldId, newId: `${splittedCurrId[0]}${separator}${idRef + qIndex + 1}${separator}${splittedCurrId[2]}` };
    }

}
const createNewDependency = (dependency, idDictionary) => {
    let newDependency = dependency.map((item) => {
        item.question = idDictionary[item.question];
        return item;
    })
    return newDependency;
}
  
export function addRepeatedQuestions(
    questionJson,
    repeatCount = 1,
    showHiddenQuestion = false
  ) {
    if (!Object.keys(questionJson).length) {
      return;
    }
    const paramToInject = getRepeatedQuestions(questionJson);
    if (Object.keys(paramToInject) && !Object.keys(paramToInject).length) {
      return questionJson;
    }
    const questionJsonCopy = deepClone(questionJson);
    const sectionsOfQuestionJson = questionJson["sections"];
    let newQuestions = {},
      secPos = {},
      questPos = {},
      includeProductInfo = false,
      noListFoundOnDynamicOption = {};
  
    for (const [index, section] of sectionsOfQuestionJson.entries()) {
      const sectionId = section["id"];
      const idQuestAfterWhichToInject = paramToInject[sectionId]["position"]; //question after which repeated questions will be added
      const answerkey = paramToInject[sectionId]["answerkey"];
  
      if (paramToInject[sectionId]) {
        secPos[sectionId] = index;
        const getQuestionToinject = paramToInject[sectionId]["questions"]; // will get questions of a given section
        const idDictionary = {};
        if (section?.includeProductInfo) {
          includeProductInfo = true;
        }
        for (const [qIndex, question] of section["questions"].entries()) {
          const questionCopy = deepClone(question);
          const { oldId, newId } = createIndex({
            question: questionCopy,
            repeatCount,
            qIndex,
            idQuestAfterWhichToInject,
            includeProductInfo,
          });
          
          idDictionary[oldId] = newId;
          
          if (questionCopy["id"] == idQuestAfterWhichToInject) {
            questPos[sectionId] = qIndex;
          }
  
          if (getQuestionToinject.includes(questionCopy["id"])) {
            questionCopy.id = newId;
            questionCopy.name = newId;
            if (questionCopy["dependentQuestion"]) {
              const newDependency = createNewDependency(
                questionCopy["dependentQuestion"],
                idDictionary
              );
              questionCopy["dependentQuestion"] = newDependency;
            } else {
              questionCopy["dependentQuestion"] = [];
            }
            if (questionCopy.defaultDisplay !== "hidden") {
              questionCopy.dependentQuestion = questionCopy.dependentQuestion || [];
              questionCopy.dependentQuestion.push({
                  question: idQuestAfterWhichToInject,
                  behaviour: "show",
                  answerkey: answerkey,
              });
          } else if (questionCopy["dependentQuestion"]) {
              questionCopy["dependentQuestion"].forEach((que) => {
                  if (!getQuestionToinject.includes(que.question.split(utils.USER_REPEAT_COUNT_SEPARATOR)[0])) {
                      questionCopy.dependentQuestion.push({
                          question: idQuestAfterWhichToInject,
                          behaviour: "show",
                          answerkey: answerkey,
                      });
                  }
              });
          }
  
            if (questionCopy.dynamicOptions) {
              if (!questionCopy?.options || questionCopy?.options.length === 0) {
                const optionLayout = JSON.stringify(
                  questionCopy.dynamicOptions.layout
                );
                const qOption = JSON.parse(optionLayout);
                const type = qOption.value.split("{")[1]?.split("}")[0];
                noListFoundOnDynamicOption = {
                  ...noListFoundOnDynamicOption,
                  [questionCopy.id]: { type: type, sectionId: sectionId },
                };
              }
            }
  
            if (!showHiddenQuestion) {
              questionCopy.defaultDisplay = "hidden";
            }
  
            questionCopy.repeated = true;
  
            if (newQuestions[sectionId]) {
              newQuestions[sectionId].push(questionCopy);
            } else {
              newQuestions[sectionId] = [];
              newQuestions[sectionId].push(questionCopy);
            }
            continue;
          }
        }
      }
    }
  
    Object.keys(paramToInject) &&
      Object.keys(paramToInject).length &&
      Object.keys(paramToInject).map((sectionId, key) => {
        const scPos = secPos[sectionId]; // sectionPosition, the index at which it can be found in the array of sections
        const qtPos = questPos[sectionId]; // questionPosition, the index at which it can be found in the array of questions
  
        if (questionJsonCopy["sections"][scPos]) {
          const oldQuestions = questionJsonCopy["sections"][scPos]["questions"];
          
          questionJsonCopy["sections"][scPos]["questions"].splice(
            qtPos + 1,
            oldQuestions.length
          );
  
          questionJsonCopy["sections"][scPos]["questions"] = [
            ...questionJsonCopy["sections"][scPos]["questions"],
            ...newQuestions[sectionId],
          ];
         
        }
      });
  
    return { noListFound: noListFoundOnDynamicOption, questionJsonCopy };
  }

