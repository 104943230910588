import React, { useState } from 'react';
import { Card, CardContent, Grid } from "@material-ui/core";
import outletInstallationsModel from '../../pages/outlet-installations';
import { apis } from '../../httpUtil';
import utils from '../../utils';
import { useTranslation } from 'react-i18next';

const OutletInstallationAlert = (props) => {
    const { locationId } = props;
    const [outletAttachmentId, setOutletAttachmentId] = useState(null);
    const t = utils.t
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    const onRowClick = (params) => {
        setOutletAttachmentId(params.id);
    }

    const onImageLoadError = (e) => {
        e.target.src = `${apis.imgBaseUrl}/Controllers/CoolerImagePreview.ashx?Guid=null&ImageType=Stitched&maxWidth=350`;
    }

    return (
        <div>
            <Card className='pt-0 m-0 pl-1 pr-1 pb-1'>
                <CardContent className='pt-0 m-0 pl-1 pr-1 pb-1'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={8} md={8} lg={8} className='outlet-installation-grid'>
                            <outletInstallationsModel.Grid baseFilters={[{ field: 'AssociationId', operator: '=', value: locationId }]} onRowClick={onRowClick} gridStyle={{ maxHeight: '485px' }} hideBackButton={true} />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} >
                            <div className='outlet-installation-image-div' >
                                {outletAttachmentId ?
                                    <img className='outlet-installation-image' alt="Outlet Installation Audit" src={`${apis.downloadAttachment}?Id=${outletAttachmentId}`} onError={(e) => onImageLoadError(e)} /> :
                                    <div className='outlet-installation-class'>{t('Please select an image on the table to visualize it',tOpts)}</div>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}

export default OutletInstallationAlert;