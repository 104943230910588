import React, { memo, useCallback, useMemo, useEffect } from 'react';
import { Select, InputAdornment, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import AccountBox from "@material-ui/icons/AccountBox";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { LOGIN_CONST } from '../../constants';
import axios from 'axios';
import swal from 'sweetalert';
import actions from '../../redux/actions';
import { withTranslation } from 'react-i18next';
import { apis, request } from '../../httpUtil';
import utils from '../../utils';

const t = utils.t;
const ClientSelect = memo(function ({ t: translate, i18n, history, fromAppBar }) {
    const tOpts = { t: translate, i18n }
    const dispatch = useDispatch();
    const selectClient = useSelector(state => state.appReducer.selectClient);
    const clientsList = useSelector(state => state.appReducer.clientsList) || [];
    let loginData = useSelector(state => state.appReducer.userData, shallowEqual) || {};
    const clientSelectIcon = useMemo(() => <InputAdornment position="start"> <AccountBox /> </InputAdornment>, []);

    useEffect(() => {
        if (fromAppBar) {
            const response = async () => {
                const response = await request({ url: apis.SwitchClient, params: { "OtherAction": "ClientList" }, history, dispatch });
                dispatch({ type: actions.SET_CLIENT_LIST_DATA, clientsList: response?.data });
            }
            dispatch({ type: actions.SET_CLIENT_SELECT, selectClient: Number(loginData?.tags?.ClientId) })
            response().catch()
        }
    }, [])

    useEffect(() => {
        if (loginData?.tags?.ClientId)
            dispatch({ type: actions.SET_CLIENT_SELECT, selectClient: Number(loginData?.tags?.ClientId) })
    }, [loginData])

    //Set user select client in redux
    const memoizedSetClientSelect = useCallback(async (e) => {
        if (fromAppBar) {
            const response = await request({ url: apis.SwitchClient, params: { ScopeId: e.target.value }, history, dispatch });
            if (response.success) {
                window.location.reload(false)
            } else {
                const transport = axios.create({ withCredentials: true })
                let formData = new FormData();
                formData.append("signout", true);
                let params = {
                    method: 'POST', url: apis.login, withCredentials: true, data: formData
                };
                transport(params).then((response) => {
                    if (response && response.status === 200) {
                        dispatch({ type: actions.RESET_STATE });
                        swal({
                            title: '',
                            text: "You're not authorized to access this page",
                            icon: "error",
                            dangerMode: true
                        });
                        history.push('/login');
                        // unsubscribing the subscription if it's subscribed and then re subscribing after login.
                        navigator.serviceWorker.ready.then((reg) => {
                            reg.pushManager.getSubscription().then((subscription) => {
                                subscription && subscription.unsubscribe();
                            });
                        });
                    }
                });
            }
        }
        dispatch({ type: actions.SET_CLIENT_SELECT, selectClient: Number(e.target.value) });
    }, [dispatch]);



    return clientsList.length > 0 ? <div className={`${fromAppBar ? "" : "mb-3"}`}>
        <FormControl className="w-100" key="user-client-control">
            <Select MenuProps={{
                style: { height: '300px', width: '100%' },
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                getContentAnchorEl: null
            }}
                className={`search-select ${fromAppBar ? "app-bar-select-menus" : ""}`} variant={fromAppBar ? "outlined" : "standard"} value={selectClient} onChange={memoizedSetClientSelect} name={LOGIN_CONST.CLIENT} >
                <MenuItem value={selectClient} disabled>
                    {t(LOGIN_CONST.CLIENT, tOpts) + ' *'}
                </MenuItem>
                {clientsList.map(d => <MenuItem key={d.Key || d.LookupId} value={d.Key || d.LookupId}> {(d.Value?.indexOf('(') !== -1 ? d.Value?.substring(0, d.Value?.indexOf('(')).trim() : d.Value) || (d.DisplayValue?.indexOf('(') !== -1 ? d.DisplayValue?.substring(0, d.DisplayValue?.indexOf('(')).trim() : d.DisplayValue)}</MenuItem>)}
            </Select>
        </FormControl>
    </div> : <div></div>
})

export default withTranslation()(ClientSelect);
