import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import { Hidden, IconButton, AppBar, Box, Tooltip } from '@material-ui/core';
import { HeaderLogo, HeaderUserBox } from '../../components';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { useSelector, useDispatch } from 'react-redux';
import utils from '../../utils'
import logo from '../../assets/images/logo.png';
import { useTranslation } from 'react-i18next';
import ClientLogo from '../Common/ClientLogo';

let dispatch = null
const Header = props => {
  dispatch = useDispatch();
  const { t: trans, i18n } = useTranslation();
  const tOpts = { t: trans, i18n };
  const t = utils.t;

  const sidebarToggle = useSelector(state => state.appReducer.sidebarToggle);
  const headerFixed = useSelector(state => state.appReducer.headerFixed);
  const headerShadow = useSelector(state => state.appReducer.headerShadow);
  const sidebarToggleMobile = useSelector(state => state.appReducer.sidebarToggleMobile);
  const userData = useSelector(state => state.appReducer.userData);
  const userRoutes = userData ? utils.getAssignedRoutes(userData) : []
  const defaultRoute = userRoutes && userRoutes[0] ? userRoutes[0] : '/';
  const { ScheduledOrderId = 0 } = userData && userData.tags ? userData.tags : 0;

  const toggleSidebar = () => {
    localStorage.setItem('CoolRD3-sidebar', !sidebarToggle);
    dispatch({ type: actions.SET_SIDEBAR_TOGGLE, sidebarToggle: !sidebarToggle });
  };
  const toggleSidebarMobile = () => dispatch({ type: actions.SET_SIDEBAR_TOGGLE_MOBILE, sidebarToggleMobile: !sidebarToggleMobile });

  return (
    <div className="menu-wraper">
      <div className="upper-menu">
        <AppBar  className='app-header top-bar-header' position={headerFixed ? 'fixed' : 'absolute'} elevation={headerShadow ? 11 : 3}>

          <Hidden mdDown>
            <Box className="d-flex align-items-center">
              <Box className={clsx('btn-toggle-collapse', { 'btn-toggle-collapse-closed': sidebarToggle })}>
                <Tooltip title={t("Toggle Menu", tOpts)} placement="bottom">
                  <IconButton color="inherit" onClick={toggleSidebar} size="medium" className="btn-inverse">
                    {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Hidden>

          <HeaderLogo userRoutes={defaultRoute} />

          {!Number(ScheduledOrderId) > 0 && <Box className="d-flex align-items-center">
            <Box className="toggle-sidebar-btn-mobile">
              <Tooltip title={t("Toggle Menu", tOpts)} >
                <IconButton
                  color="inherit"
                  onClick={toggleSidebarMobile}
                  size="medium">
                  {sidebarToggleMobile ? (
                    <MenuOpenRoundedIcon />
                  ) : (
                    <MenuRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>}
          <Box className="app-header-toolbar">
            <Hidden lgUp>
              <Box className="app-logo-wrapper" title={t("CoolR Theme", tOpts)}>
                <Link to={userRoutes && userRoutes[0]} className="app-logo-link">
                  <img src={logo} alt="logo" width={97} height={29} />
                  <ClientLogo />
                </Link>
                <Hidden smDown>
                  <Box className="app-logo-text"></Box>
                </Hidden>
              </Box>
            </Hidden>
            <Box className="d-flex align-items-center">
              <HeaderUserBox />
            </Box>
          </Box>
        </AppBar>
      </div>
      <div>
      </div>
    </div>
  );
};


export default Header;
