import React, { useEffect, useState, useRef } from 'react';
import { FormControlLabel, FormGroup, Switch, Grid } from '@material-ui/core';
import { Chart as ChartJs, Line, Bar, HorizontalBar } from 'react-chartjs-2';
import ChartLabels from "chartjs-plugin-datalabels";
import DropDownMenu from "../Common/DropDownMenu";
import { useTranslation } from 'react-i18next';
import utils from "../../utils";
const t = utils.t;
ChartJs.plugins.register(ChartLabels);
const { chartColors, chartColorsD, kpiUnits } = utils;

//----------------------------------------------
// Progress Chart

const defaultOptionsProgress = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  scales: {
    yAxes: [{
      id: 'leftAxis',
      position: 'left',
      offset: true,
      ticks: {
        beginAtZero: true
      },
      gridLines: { display: false },
    },
    {
      id: 'rightAxis',
      position: 'right',
      offset: true,
      gridLines: { display: false },
      ticks: {
        beginAtZero: true
      }
    }],
    xAxes: [{
      gridLines: { display: false }
    }]
  },
  legend: {
    display: false
  },
  layout: {
    padding: {
      top: 20
    }
  }
};

const toggleSettingsProgress = {
  "onlyValues": {
    showToolTips: false,
    tooltips: {
      enabled: false
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'top',
        anchor: 'start',
        borderRadius: 4,
        color: 'white',
        backgroundColor: function (context) {
          return context.dataset.borderColor;
        },
        padding: 3,
        offset: 10
      }
    },
  },
  "showAll": {
    tooltips: {
      enabled: true,
      caretPadding: 50
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  }
}

let labelArray = [], labelArrayMap = [];

function ProgressCharts({ data, options = {} }) {
  const [toolTipChecked, setToolTipChecked] = useState(true);
  const [selectedAxis, setSelectedAxis] = useState({ left: "", right: "" });
  const [chartOptions, setChartOptions] = useState({ ...defaultOptionsProgress, ...options });
  const [chartData, setChartData] = useState({ labels: data.labels, datasets: [] });
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };

  useEffect(() => {
    let tempLabelArrayMap = [];
    let i = 0;
    const localChartData = {
      labels: data.labels,
      datasets: []
    };
    labelArray = [];
    labelArrayMap = [];

    for (let key in data.data) {
      if (!tempLabelArrayMap.some(item => item.key === key)) {
        tempLabelArrayMap.push({ key: key, color: chartColorsD[i] });
        i = (i + 1) % chartColorsD.length;
      }
      if (!labelArray.includes(key)) {
        labelArray.push(key);
        labelArrayMap.push({ key: key, color: chartColorsD[i] });
      }
      if (i === (chartColorsD.length - 1))
        i = 0
      else
        i++
    }
    if (tempLabelArrayMap.length > 0) {
      setSelectedAxis(prevState => ({
        ...prevState,
        left: `${tempLabelArrayMap[0].key} - Left`,
        right: tempLabelArrayMap.length > 1 ? `${tempLabelArrayMap[1].key} - Right` : "",
        leftColor: tempLabelArrayMap[0].color,
        rightColor: tempLabelArrayMap.length > 1 ? tempLabelArrayMap[1].color : prevState.rightColor,
      }));
    }
    setChartData(localChartData);
  }, [data])

  useEffect(() => {
    const optionsL = { ...defaultOptionsProgress, ...options, ...toggleSettingsProgress[toolTipChecked ? "onlyValues" : "showAll"] };
    setChartOptions(optionsL);
  }, [toolTipChecked])

  const dropDownOptionsLeft = labelArrayMap.map((item) => { return selectedAxis.right.includes(item.key) ? "" : { LookupId: item.key + " - Left", DisplayValue: item.key, TextColor: item.color } });
  const dropDownOptionsRight = labelArrayMap.map((item) => { return selectedAxis.left.includes(item.key) ? "" : { LookupId: item.key + " - Right", DisplayValue: item.key, TextColor: item.color } });

  useEffect(() => {
    const localChartData = {
      labels: data.labels,
      datasets: []
    };

    for (let key in data.data) {
      if ((key + " - Left") === selectedAxis.left) {
        localChartData.datasets.push({
          label: t(selectedAxis.left, tOpts),
          data: [...data.data[key]],
          backgroundColor: 'rgba(255, 255, 255, 0)',
          borderColor: selectedAxis.leftColor,
          yAxisID: 'leftAxis'
        })
      }
      if ((key + " - Right") === selectedAxis.right) {
        localChartData.datasets.push({
          label: t(selectedAxis.right, tOpts),
          data: [...data.data[key]],
          backgroundColor: 'rgba(255, 255, 255, 0)',
          borderColor: selectedAxis.rightColor,
          yAxisID: 'rightAxis'
        })
      }
    }
    setChartData(localChartData);
  }, [selectedAxis, toolTipChecked])

  const handleChangeAxis = (e, name, options) => {
    const value = e.target.value, selectedValue = options.filter((item) => item.LookupId === value);
    setSelectedAxis({ ...selectedAxis, [name]: value, [name + "Color"]: (value === '-1') ? 'white' : selectedValue[0]?.TextColor });
  }

  return <>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3} md={3} lg={3}>
        <FormGroup>
          <FormControlLabel className="no-print" control={<Switch defaultChecked checked={toolTipChecked} onChange={() => setToolTipChecked(!toolTipChecked)} />} label={t("Tooltip Values", tOpts)} />
        </FormGroup>
        <div className='mt-2'>
          <DropDownMenu
            style={{ "border-bottom": `3px solid  ${selectedAxis.leftColor}` }}
            options={dropDownOptionsLeft}
            classNameParent="w-100"
            name="legendsLeft"
            label={t("Left Axis", tOpts)}
            separateLabel={true}
            handleChange={(e) => { handleChangeAxis(e, 'left', dropDownOptionsLeft) }}
            value={selectedAxis.left}
            multiple={false}
            size="Small" />
        </div>
        <div className='mt-2'>
          <DropDownMenu
            style={{ "border-bottom": `3px solid ${selectedAxis.rightColor}` }}
            options={dropDownOptionsRight}
            classNameParent="w-100"
            name="legendsLeft"
            label={t("Right Axis", tOpts)}
            separateLabel={true}
            handleChange={(e) => { handleChangeAxis(e, 'right', dropDownOptionsRight) }}
            value={selectedAxis.right}
            multiple={false}
            size="Small" />
        </div>
      </Grid>
      <Grid item xs={12} sm={9} md={9} lg={9}>
        <Grid container spacing={2} className='kpi-chart-container'>
          <Line data={chartData} options={chartOptions} />
        </Grid>
      </Grid>
    </Grid>
  </>
}

//----------------------------------------------
// Progress Chart SingleKPI


const defaultOptionsProgressSingle = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  scales: {
    yAxes: [{
      ticks: { beginAtZero: true },
      gridLines: { display: false },
      offset: true,
    }],
    xAxes: [{
      gridLines: { display: false }
    }]
  },
  htmlLegend: {
    containerID: 'legend-container',
  },
  legend: {
    display: false,
  },
  layout: {
    padding: {
      top: 20
    }
  }
};

function ProgressChartsSingleKPI({ data, options = {} }) {
  const [toolTipChecked, setToolTipChecked] = useState(true);
  const [chartOptions, setChartOptions] = useState({ ...defaultOptionsProgressSingle, ...options });
  const [chartData, setChartData] = useState({ labels: data.labels, datasets: [] });
  const { t: translate, i18n } = useTranslation()
  const legendRef = useRef(null);
  const tOpts = { t: translate, i18n };

  useEffect(() => {
    let i = 0;
    const localChartData = {
      labels: data.labels,
      datasets: []
    };

    for (let key in data.data) {
      localChartData.datasets.push({
        label: (key === 'null' || key === 'undefined') ? 'N/A' : t(key, tOpts),
        data: [...data.data[key]],
        borderColor: chartColorsD[i],
        backgroundColor: 'rgba(255, 255, 255, 0)',
      })

      i = (i === (chartColorsD.length - 1)) ? 0 : (i + 1);
    }

    setChartData(localChartData);
  }, [data])

  useEffect(() => {
    const optionsL = { ...defaultOptionsProgressSingle, ...options, ...toggleSettingsProgress[toolTipChecked ? "onlyValues" : "showAll"] };
    setChartOptions(optionsL);
  }, [toolTipChecked])

  return <>
    <Grid container spacing={3}>
      <Grid item xs={12} className='mt-0 mb-0 pt-0 pb-0'>
        <FormGroup>
          <FormControlLabel className="no-print" control={<Switch defaultChecked checked={toolTipChecked} onChange={() => setToolTipChecked(!toolTipChecked)} />} label={t("Tooltip Values", tOpts)} />
        </FormGroup>
      </Grid>
      <Grid item xs={2}>
        <div ref={legendRef} style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <ul className='kpi-chart-ul'>
            {chartData.datasets.map((dataset, index) => (
              <li className='kpi-chart-ul-li'>
                <span style={{ border: `3px solid ${dataset.borderColor}` }} className='kpi-chart-ul-li-span-box'></span>
                <span className='kpi-chart-ul-li-span'>{dataset.label}</span>
              </li>
            ))}
          </ul>
        </div>
      </Grid>
      <Grid item xs={10}>
        <Grid container spacing={2} className='kpi-chart-container'>
          <Line data={chartData} options={chartOptions} />
        </Grid>
      </Grid>
    </Grid>
  </>
}

//----------------------------------------------
// Global Chart

const defaultOptionsGlobal = {
  type: 'bar',
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      offset: true,
      ticks: {
        beginAtZero: true,
        max: 100
      },
      gridLines: { display: false }
    }],
    xAxes: [{
      maxBarThickness: 100,
      gridLines: { display: false }
    }]
  },
  plugins: {
    legend: {
      position: 'top',
    }
  },

  layout: {
    padding: {
      top: 20
    }
  }
};

const toggleSettingsGlobal = {
  "onlyValues": {
    showToolTips: false,
    tooltips: {
      enabled: false
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
        formatter: function (value, context) {
          return value === '' ? 'N/A' : Number(value).toFixed(1)
        },
        borderRadius: 4,
        color: 'white',
        backgroundColor: function (context) {
          return context.dataset.borderColor;
        },
        padding: 6,
        offset: 10
      }
    }
  },
  "showAll": {
    tooltips: {
      enabled: true,
      caretPadding: 50
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  }
}

function GlobalCharts({ data, helper, sortModal, options = {} }) {
  const [modData, setModData] = useState([]);
  const [chartOptions, setChartOptions] = useState({ ...defaultOptionsGlobal, ...options });
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [toolTipChecked, setToolTipChecked] = React.useState(true);
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };

  useEffect(() => {
    let i = 0;
    const localChartData = {
      labels: [],
      datasets: []
    }, dataMap = {};

    const periodsL = [...helper.periods], activePeriodL = [...helper.activePeriods];
    if (helper.customColumn) {
      const { fromDate, toDate } = helper.getCustomHeading();
      periodsL.push({ LookupId: "Custom", DisplayValue: `${fromDate} to ${toDate}` });
      activePeriodL.push('Custom');
    }

    for (const dataObj of modData) {
      let nameKey = Object.keys(dataObj);
      nameKey = nameKey.filter((item) => item.includes("Name"));
      localChartData.labels.push(dataObj[nameKey]);
      for (const period of periodsL) {
        if (activePeriodL.includes(period.LookupId)) {
          if (dataMap[period.DisplayValue]) {
            if (dataObj[period.LookupId + "_Images"] === 0) {
              dataMap[period.DisplayValue].push('');
            } else {
              dataMap[period.DisplayValue].push(dataObj[period.LookupId + "_" + helper.kpiName]);
            }
          } else {
            if (dataObj[period.LookupId + "_Images"] === 0) {
              dataMap[period.DisplayValue] = [''];
            } else {
              dataMap[period.DisplayValue] = [dataObj[period.LookupId + "_" + helper.kpiName]];
            }
          }
        }
      }
    }

    for (const key in dataMap) {
      localChartData.datasets.push({
        label: key,
        data: [...dataMap[key]],
        backgroundColor: chartColors[i].bg,
        borderColor: chartColors[i].br,
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
      })
      i++;
    }

    setChartData(localChartData);

  }, [modData])


  useEffect(() => {
    if (sortModal && sortModal.field) {
      const localArray = [...data]
      const sortFunc = {
        asc: function (a, b) { return (b[sortModal.field] != null) - (a[sortModal.field] != null) || a[sortModal.field] - b[sortModal.field] },
        desc: function (a, b) { return (b[sortModal.field] != null) - (a[sortModal.field] != null) || b[sortModal.field] - a[sortModal.field] }
      }
      localArray.sort(sortFunc[sortModal.sort])
      setModData(localArray);
    } else {
      setModData(data);
    }
  }, [data, sortModal])

  useEffect(() => {
    const optionsL = { ...defaultOptionsGlobal, ...options, ...toggleSettingsGlobal[toolTipChecked ? "onlyValues" : "showAll"] };
    setChartOptions(optionsL);
  }, [toolTipChecked])

  return <>
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <FormGroup>
          <FormControlLabel control={<Switch defaultChecked checked={toolTipChecked} onChange={() => setToolTipChecked(!toolTipChecked)} />} label={t("Tooltip Values", tOpts)} />
        </FormGroup>
      </Grid>
      <Grid item xs={10}> </Grid>
    </Grid>
    <Grid container spacing={2} className='kpi-chart-container'>
      <Bar data={chartData} options={chartOptions} />
    </Grid>
  </>;
}

//----------------------------------------------
// Summary Chart

const defaultOptionsSummary = () => {
  return {
    type: 'horizontalBar',
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        maxBarThickness: 30,
        minBarThickness: 30,
        gridLines: { display: false },
        ticks: { display: false },
        stacked: true,
      }],
      xAxes: [{
        gridLines: { display: false },
        ticks: {
          display: false,
          beginAtZero: true,
          max: 100
        },
        stacked: true,
      }]
    },
    showToolTips: false,
    tooltips: { enabled: false },
    plugins: {
      legend: {
        position: 'top',
      },
      datalabels: {
        display: true,
        align: 'center',
        anchor: 'center',
        color: 'white',
        formatter: function (value, context) {
          const { dataTotalRecord, singleKPI } = context.chart.options;
          if (singleKPI) {
            const dataArray = context.dataset.data;
            const index = dataArray.indexOf(value);
            return !value ? '' : Math.round((Number(value) / 100) * dataTotalRecord[index]);
          } else {
            return !value ? '' : Math.round((Number(value) / 100) * dataTotalRecord);
          }
        }
      }
    }
  }
};

function SummaryCharts({ data, metrics, isPlanogramCompliance, singleKPI }) {

  const [chartOptions, setChartOptions] = useState(defaultOptionsSummary());
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  useEffect(() => {

    const localChartData = {
      label: [],
      dataGreen: [],
      dataYellow: [],
      dataRed: []
    }
    let totalRecord = 100;

    if (singleKPI.status) {
      const kpiName = singleKPI.kpiName, unit = kpiUnits[kpiName], totalArray = [];
      for (const obj of data) {
        localChartData.label.push('');
        localChartData.dataGreen.push(formatValue({ kpi: kpiName, isPlanogramCompliance, unit, value: obj[`${singleKPI.kpiName}_Green`], total: obj.TotalRecord }));
        localChartData.dataYellow.push(formatValue({ kpi: kpiName, isPlanogramCompliance, unit, value: obj[`${singleKPI.kpiName}_Yellow`], total: obj.TotalRecord }));
        localChartData.dataRed.push(formatValue({ kpi: kpiName, isPlanogramCompliance, unit, value: obj[`${singleKPI.kpiName}_Red`], total: obj.TotalRecord }));
        totalArray.push(obj.TotalRecord);
      }
      totalRecord = totalArray;
    } else {
      const dataMap = {}, kpiNames = {}, totalRecordL = data?.TotalRecord;

      for (const element of metrics) {
        if (element.checked)
          kpiNames[element.dataKey] = element.label;
      }

      for (const key in data) {
        const temp = key.split("_");
        const kpi = temp[0], range = temp[1];
        if (!kpiNames[kpi])
          continue;
        if (!dataMap[kpiNames[kpi]]) {
          dataMap[kpiNames[kpi]] = {}
        }
        const percentage = (data[key] / totalRecordL) * 100;
        const unit = kpiUnits[kpi];
        dataMap[kpiNames[kpi]][range] = ((kpi === 'PlanogramCompliance' && !isPlanogramCompliance) || unit !== '%') ? NaN : percentage;
      }

      let label = Object.keys(dataMap).sort();
      for (const key of label) {
        localChartData.label.push('');
        localChartData.dataGreen.push(dataMap[key].Green);
        localChartData.dataYellow.push(dataMap[key].Yellow);
        localChartData.dataRed.push(dataMap[key].Red);
      }
      totalRecord = totalRecordL;
    }

    setChartOptions({ ...chartOptions, dataTotalRecord: totalRecord, singleKPI: singleKPI.status })
    setChartData({
      labels: localChartData.label,
      datasets: [{
        label: '< 50%',
        data: localChartData.dataRed,
        backgroundColor: chartColors[0].bg,
        borderColor: chartColors[0].br,
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
      }, {
        label: '50% - 75%',
        data: localChartData.dataYellow,
        backgroundColor: chartColors[3].bg,
        borderColor: chartColors[3].br,
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
      }, {
        label: '> 75%',
        data: localChartData.dataGreen,
        backgroundColor: chartColors[2].bg,
        borderColor: chartColors[2].br,
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
      }]
    });

  }, [data])

  function formatValue({ kpi, isPlanogramCompliance, unit, value, total }) {
    const percentage = (value / total) * 100;
    return ((kpi === 'PlanogramCompliance' && !isPlanogramCompliance) || unit !== '%') ? NaN : percentage;
  }

  return <HorizontalBar data={chartData} options={chartOptions} />;
}

export { ProgressCharts, GlobalCharts, SummaryCharts, ProgressChartsSingleKPI };