import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Box, Tooltip } from '@material-ui/core';
import utils from '../../utils'
import logo from '../../assets/images/logo.png';
import actions from '../../redux/actions';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { useSelector, useDispatch } from 'react-redux';
import ClientLogo from '../Common/ClientLogo';
import useMobile from '../../utils/useMobile';

const SidebarHeader = ({ userData }) => {
  const t = utils.t
  const { t: trans, i18n } = useTranslation();
  const tOpts = { t: trans, i18n };
  const dispatch = useDispatch();
  const isMobile = useMobile()
  const customClass = isMobile.tablet ? 'client-sidebar-text-customClass' : ""

  const sidebarToggle = useSelector(state => state.appReducer.sidebarToggle);
  const sidebarHover = useSelector(state => state.appReducer.sidebarHover);
  const sidebarToggleMobile = useSelector(state => state.appReducer.sidebarToggleMobile);
  const userRoutes = userData ? utils.getAssignedRoutes(userData) : []

  const toggleSidebar = () => {
    localStorage.setItem('CoolRD3-sidebar', !sidebarToggle);
    dispatch({ type: actions.SET_SIDEBAR_TOGGLE, sidebarToggle: !sidebarToggle });
  };
  const toggleSidebarMobile = () => dispatch({ type: actions.SET_SIDEBAR_TOGGLE_MOBILE, sidebarToggleMobile: !sidebarToggleMobile });



  return (
    <Fragment>
      <div className={clsx('app-sidebar-header', { 'app-sidebar-header-close': sidebarToggle && !sidebarHover })}>
        <Box className="header-logo-wrapper" title="CoolR Header">
          <Link to={userRoutes && userRoutes[0]} className="header-logo-wrapper-link">
            <img alt="CoolR Logo" src={logo} width={97} height={29} />
            <ClientLogo customClassName={customClass} />
          </Link>
        </Box>
        <Box className={clsx('app-sidebar-header-btn', { 'app-sidebar-header-btn-close': sidebarToggle && !sidebarHover })}>
          <Tooltip title={t("Toggle Sidebar", tOpts)} placement="right">
            <IconButton color="inherit" size="medium" onClick={toggleSidebar}>
              {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="app-sidebar-header-btn-mobile">
          <Tooltip title={t("Toggle Sidebar", tOpts)} placement="right">
            <IconButton color="inherit" onClick={toggleSidebarMobile} size="medium">
              {sidebarToggleMobile ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    </Fragment>
  );
};

export default SidebarHeader;
