import React, { useEffect, useState, useCallback } from 'react'
import {
    DataGridPro,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExportContainer,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import utils from '../../utils';
import useMobile from '../../utils/useMobile';
import { apis, request } from '../../httpUtil';
import actions from '../../redux/actions';
import MenuItem from '@mui/material/MenuItem';
import FilterPanel from '../../components/filterPanel';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import CustomFooter from './customFooter'
import { STRINGS_CONST, ORDER_STATUS } from '../../constants';
import LocalizedDatePicker from "../LocalizedDatePicker";
import constants from '../../utils/constants';

const t = utils.t
const defaultPageSize = 25;
const sortRegex = /(\w+)( ASC| DESC)?/gi;
const convertDefaultSort = (defaultSort) => {
    const orderBy = [];
    if (typeof defaultSort === 'string') {
        const sortFields = defaultSort.split(',');
        for (const sortField of sortFields) {
            sortRegex.lastIndex = 0;
            const sortInfo = sortRegex.exec(sortField);
            if (sortInfo) {
                const [, field, direction = "ASC"] = sortInfo;
                orderBy.push({ field: field.trim(), sort: direction.trim().toLowerCase() });
            }
        }
    }
    return orderBy;
};

const ExportMenuItem = ({ handleExport, type }) => {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };

    return (
        <MenuItem
            onClick={handleExport}
        >
            {t("Export", tOpts)} {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}
        </MenuItem>
    );
};

ExportMenuItem.propTypes = {
    hideMenu: PropTypes.func
};

const DataGridBase = ({
    gridConfig,
    onGridCellSelect,
    pageTitle,
    onGridCellDoubleClick,
    url,
    sortingMode,
    pagination = true,
    gridRef = null,
    disableJTP = false,
    pageType = ''
}) => {
    let { gridColumns } = gridConfig;
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const history = useHistory();
    const dispatch = useDispatch();
    const gridRefLocal = useGridApiRef();
    const apiRef = gridRef ? gridRef : gridRefLocal;
    const isMobile = useMobile();
    const filterValues = useSelector(state => state.appReducer.filterValues) || {};
    const userData = useSelector(state => state.appReducer.userData);
    const { ClientId } = userData && userData.tags ? userData.tags : 0;
    const { fromDate, toDate, daysFilter } = filterValues;
    const [data, setData] = useState({ rowCount: 0, rows: [] });
    const [sortModel, setSortModel] = useState(convertDefaultSort(gridConfig?.defaultSort));
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [firstLoad, setFirstLoad] = useState(true);
    const [page, setPage] = useState(0);
    const [prevPage, setPrevPage] = useState(0);
    const [prevGlobalFilter, setPrevGlobalFilter] = useState({});
    const [prevPageSize, setPrevPageSize] = useState(null);
    const [prevSortModel, setPrevSortModel] = useState(null);
    const [exportFilters, setExportFilters] = useState({});
    const [exportFormat] = useState("XLSX");
    // const [operationOptions, setOperationsOption] = useState([]);
    // const [columnOptions, setColumnOption] = useState([]);

    gridColumns = gridColumns.map((column) => {
        const newGridColumn = { ...column, headerName: t(column.headerName, tOpts) }
        if (column.type === "date" || column.type === "dateTime") {
            newGridColumn.filterOperators = LocalizedDatePicker({ columnType: column.type.toLowerCase() });
            newGridColumn.valueFormatter=(i)=>{
                return utils.formatDate(i.value, false,false)
            }
        }
        return newGridColumn;
    })
    const [filterModel, setFilterModel] = useState({
        "items": [],
        "linkOperator": "and",
        "quickFilterValues": [],
        "quickFilterLogicOperator": "and"
    })

    const handleExport = () => {
        let ExportCols = gridConfig?.columns, cols = [], filters = exportFilters || {}, title = pageTitle || "", fileName = pageTitle || "", format = exportFormat, additionalParams = "forExport=true";
        if (!url) {
            return false;
        }

        let params = {
            exportFormat: format, title: title, exportFileName: fileName
        };

        let filtersForExport = utils.createFilter(filterModel, true);
        if (Object.keys(filtersForExport).length > 0 && pageType !== constants.surveyInboxPageType) {
            filtersForExport.map((item) => {
                if (item?.operatorValue) {
                    if (item.isValueADate) {
                        let operatorId = utils.dateOperator[item?.operatorValue];
                        if (operatorId?.length > 0) {
                            params.OperatorId = operatorId;
                        } else {
                            params.startDate = exportFilters.startDate;
                            params.endDate = exportFilters.endDate;
                            params.OperatorId = exportFilters.OperatorId;

                        }
                    }
                }
                params = { ...params, ...item };
            })
        } else if (Object.keys(exportFilters)?.length > 0) {
            params.startDate = exportFilters.startDate;
            params.endDate = exportFilters.endDate;
            params.OperatorId = exportFilters.OperatorId;
        }
        const filter = { fieldName: 'EndDate', operatorId: "DATE_RANGE", convert: false, values: [exportFilters.startDate, exportFilters.endDate] }
        params["filter"] = JSON.stringify(Object.assign({}, filter));
        params.action = "export";

        if (!params?.startDate || !params?.endDate) {
            if (filterValues?.gridFilters?.right) {
                params.startDate = filterValues?.gridFilters?.right?.values[0];
                params.endDate = filterValues?.gridFilters?.right?.values[1];
                params.OperatorId = filterValues?.gridFilters?.right?.OperatorId;
            }
        }

        ExportCols.map(col => {
            const exportCol = { ColumnName: col.field, Header: col.headerName, Width: col.width || 200, Convert: false, Align: col.type == "int" ? "right" : "left" };
            if (col.export) {
                cols.push(exportCol);
            }
        });

        const customFilterValues = pageType === constants.surveyInboxPageType ? prevGlobalFilter : filterValues;

        if (customFilterValues?.gridFilters?.left) {
            const exportGridFilters = customFilterValues.gridFilters;
            params.filter = JSON.stringify(exportGridFilters);
        }

        if (sortModel && sortModel.length > 0 && sortModel[0].field && sortModel[0].sort) {
            params.sort = sortModel[0]?.field;
            params.dir = sortModel[0]?.sort;
        }

        let form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("target", "_blank")
        form.setAttribute("id", "exportForm")
        let currentTime = new Date();
        let exportUrl = url + "?v=" + currentTime;
        if (additionalParams) {
            exportUrl = exportUrl + '&' + additionalParams;
        }
        let d = new Date(),
            gmtHours = -d.getTimezoneOffset();
        params.TimeOffSet = gmtHours;

        for (var key in params) {
            let hiddenTag = document.createElement('input');
            hiddenTag.type = "hidden";
            hiddenTag.name = key;
            hiddenTag.value = params[key];
            form.append(hiddenTag);
        }
        form.setAttribute('action', exportUrl);
        document.body.appendChild(form);
        form.submit();
        setTimeout(() => {
            document.getElementById("exportForm").remove();
        }, 3000)
        if (pageType !== constants.surveyInboxPageType) {
            setExportFilters({});
        }
    }


    const CustomToolbar = function (props) {
        return (
            <GridToolbarContainer {...props}>
                <GridToolbarColumnsButton />
                <GridToolbarExportContainer {...props}>
                    <ExportMenuItem handleExport={handleExport} type={exportFormat} />
                </GridToolbarExportContainer>
            </GridToolbarContainer>
        );
    };

    const filtersConfig = [
        {
            name: "Filter By Date",
            visible: true,
            config: [
                {
                    name: "date",
                    type: "date",
                    size: 'small',
                    maxDate: '',
                    hidden: false,
                    hideMenuLabel: true
                }
            ],
            openDefault: true
        }
    ]

    const [pinnedColumns, setPinnedColumns] = useState(gridConfig?.pinnedColumns);

    const handlePinnedColumnsChange = useCallback((updatedPinnedColumns) => {
        setPinnedColumns(updatedPinnedColumns);
    }, []);

    const clear = () => {
        dispatch({
            type: actions.SET_FILTER_VALUES, filterValues: {

                daysFilter: "7:day",
                fromDate: dayjs().subtract(7, 'day').format(utils.dateFormat),
                toDate: dayjs().format(utils.dateFormat),
            }
        });
        setFilterModel({
            "items": [],
            "linkOperator": "and",
            "quickFilterValues": [],
            "quickFilterLogicOperator": "and"
        })
    }

    useEffect(() => {
        dispatch({
            type: actions.SET_FILTER_VALUES, filterValues: {
                daysFilter: "7:day",
                fromDate: dayjs().subtract(7, 'day').format(utils.dateFormat),
                toDate: dayjs().format(utils.dateFormat)
            }
        });
    }, [])

    useEffect(() => {
        if (pageType !== "SalesHistory" && pageType !== "OrderSuggestionHistory") {
            const filteredConfig = filtersConfig.filter((value) => value.visible);

            dispatch({
                type: actions.PASS_FILTERS_TOHEADER,
                filtersInHeader: {
                    filterButton: <FilterPanel filtersConfig={filteredConfig} getData={null} customDate={true} />,
                    filterButtonName: t("Options"),
                    clear: clear,
                    apply: getData,
                    hidden: { search: true, operation: true, export: true, print: true, filter: false }
                }
            });
        }
    }, [filterValues]);



    useEffect(() => {
        getData({})
    }, [pageSize, sortModel, page, filterModel, toDate, fromDate]);

    const onCellClick = ({ row: record }) => {
        const { id } = record;
        if (!id || !onGridCellSelect) {
            return;
        }
        onGridCellSelect(id, record);
    }
    const onCellDoubleClick = ({ row: record }) => {
        const { RowNumber } = record;
        if (!RowNumber || !onGridCellDoubleClick) {
            return;
        }
        onGridCellDoubleClick(RowNumber, record);
    }
    const hasFilterReallyChanged = (filterCount, page, sort, pageSize, globalFilter) => {
        const serializedGlobalFilters_prev = typeof (prevGlobalFilter) !== "string" ? JSON.stringify(prevGlobalFilter) : prevGlobalFilter;
        const serializedGlobalFilters_curr = typeof (globalFilter) !== "string" ? JSON.stringify(globalFilter) : globalFilter;
        const serializedSort_prev = typeof (prevSortModel) !== "string" ? JSON.stringify(prevSortModel) : prevSortModel;
        const serializedSort_curr = typeof (sort) !== "string" ? JSON.stringify(sort) : sort;

        /*
            The following condition will be true if
             1- There are filters and no previous filters
             2- There are filters but and the value is different from the previous value
             3- Pagination has changed
             4- Global filters have changed
        */
        const condition = (page !== prevPage) || (serializedSort_curr !== serializedSort_prev) || (pageSize !== prevPageSize) || (serializedGlobalFilters_prev !== serializedGlobalFilters_curr);
        if (condition) {
            return true;
        } else {
            return false;
        }

    }

    const getData = useCallback(async () => {
        //get value from filter
        const { fromDate, toDate } = filterValues || {};
        let params = { action: 'list', asArray: 0, start: page * pageSize, limit: pageSize };
        let where = {};
        let filters = {};
        if (sortModel.length) {
            sortModel.map(sort => {
                params["sort"] = sort.field;
                params["dir"] = sort.sort
            });
        }
        //date Filter
        if (filterModel?.items?.length) {
            filters = utils.createFilter(filterModel);
        }

        let filterCount = Object.keys(filters).length;

        let formatedFromDate = fromDate ? dayjs(fromDate).format(utils.dateFormat) : dayjs().subtract(7, 'day').format(utils.dateFormat);
        let formatedToDate = toDate ? dayjs(toDate).format(utils.dateFormat) : dayjs().format(utils.dateFormat);
        formatedFromDate = `${formatedFromDate} 00:00:00`;
        formatedToDate = `${formatedToDate} 00:00:00`;
        if (pageType === "SalesHistory") {
            where["left"] = { fieldName: gridConfig?.rangeFilter, operatorId: "DATE_RANGE", convert: false, values: [formatedFromDate, formatedToDate] };
            where["logicalOperator"] = "AND";
            where["right"] = { fieldName: "StatusId", operatorId: "STRING_CONTAINS", convert: false, values: [ORDER_STATUS.SCHEDULED] };
        } else {
            where["fieldName"] = gridConfig?.rangeFilter;
            where["operatorId"] = "DATE_RANGE";
            where["convert"] = false;
            where["values"] = [formatedFromDate, formatedToDate];
        }



        const filterHasEndate = filterModel.items.findIndex(item => item.columnField === "EndDate" && item?.value?.length > 0);
        // When there is not any date filter in that case we apply for empty
        if (filterHasEndate === -1) {
            filters = utils.addToFilter(filters, where, filterModel?.linkOperator.toUpperCase());
            if (pageType === "SalesHistory") {
                setExportFilters({ clientId: ClientId, startDate: where["left"].values[0], endDate: where["left"].values[1], OperatorId: "<=" })
            } else {
                setExportFilters({ clientId: ClientId, startDate: where["values"][0], endDate: where["values"][1], OperatorId: "<=" })
            }

        }

        params["filter"] = Object.assign({}, filters);

        const newFilters = { ...filterValues, gridFilters: params["filter"] };
        // dispatch({ type: actions.SET_FILTER_VALUES, filterValues: newFilters });

        if (!hasFilterReallyChanged(filterCount, page, sortModel, pageSize, newFilters)) {
            if (!firstLoad) {
                return;
            }

        } else {
            setPrevGlobalFilter(newFilters);
            setPrevPage(page);
            setPrevPageSize(pageSize);
            setPrevSortModel(sortModel);
        }

        const response = await request({ url: apis[gridConfig.controller], params, history, dispatch });
        if (response && response.records) {
            setData({ rows: response.records, rowCount: response.recordCount });
        }
        setFirstLoad(false)
    }, [filterValues, page, pageSize, sortModel, filterModel, gridConfig?.rangeFilter, gridConfig.controller, dispatch, hasFilterReallyChanged, history, ClientId, firstLoad, toDate, fromDate])

    return (
        <Box className="data-grid-base" sx={{ backgroundColor: 'background.paper' }}>
            {pageType === "SalesHistory" || pageType === "OrderSuggestionHistory" ? (
                <FilterPanel filtersConfig={filtersConfig} inGridFilter={true} customDate={true} />
            ) : null}
            <div className='data-grid-replainshment'>
                <DataGridPro
                    localeText={{
                        toolbarColumns: t("Columns", tOpts),
                        columnsPanelTextFieldLabel: t('Find column', tOpts),
                        columnsPanelShowAllButton: t('Show all', tOpts),
                        columnsPanelHideAllButton: t('Hide all', tOpts),
                        columnsPanelTextFieldPlaceholder: t('Column title', tOpts),

                        toolbarFilters: t("Filters", tOpts),
                        filterPanelOperator: t('Operator', tOpts),
                        filterPanelColumns: t('Columns', tOpts),
                        filterPanelInputLabel: t('Value', tOpts),
                        filterOperatorContains: t('contains', tOpts),
                        filterOperatorEquals: t('equals', tOpts),
                        filterOperatorStartsWith: t('starts with', tOpts),
                        filterOperatorEndsWith: t('ends with', tOpts),
                        filterOperatorIsEmpty: t('is empty', tOpts),
                        filterOperatorIsNotEmpty: t('is not empty', tOpts),
                        filterOperatorIsAnyOf: t('is any of', tOpts),
                        filterPanelInputPlaceholder: t('Filter value', tOpts),
                        filterPanelAddFilter: t('Add filter', tOpts),

                        toolbarExport: t('Export', tOpts),
                        toolbarExportLabel: 'Export',
                        toolbarExportCSV: 'Download as CSV',
                        toolbarExportPrint: 'Print',
                        toolbarExportExcel: 'Download as Excel',

                        columnMenuLabel: t('Menu', tOpts),
                        columnMenuShowColumns: t('Show columns', tOpts),
                        columnMenuFilter: t('Filter', tOpts),
                        columnMenuHideColumn: t('Hide', tOpts),
                        columnMenuUnsort: t('Unsort', tOpts),
                        columnMenuSortAsc: t('Sort by ASC', tOpts),
                        columnMenuSortDesc: t('Sort by DESC', tOpts),

                        pinToLeft: t('Pin to left', tOpts),
                        pinToRight: t('Pin to right', tOpts),
                        unpin: t('Unpin', tOpts),

                        MuiTablePagination: {
                            labelRowsPerPage: t("Rows per page", tOpts)
                        },
                        noRowsLabel: t("No rows", tOpts),
                    }}

                    rows={data.rows.map((row, index) => ({ ...row, id: index }))} // Assuming `index` is unique for each row
                    rowCount={data.rowCount}
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 25, 50, 75, 100]}
                    onPageSizeChange={setPageSize}
                    onPageChange={setPage}
                    columns={gridColumns}
                    /* Check if it is Survey Report then call onceldouble click else oncellclick */
                    onCellClick={pageTitle === STRINGS_CONST.SURVEY_TITLE ? onCellDoubleClick : onCellClick}
                    onCellDoubleClick={onCellDoubleClick}
                    paginationMode={true}
                    autoHeight
                    pagination
                    getRowId={(row) => row.id}
                    sortModel={sortModel}
                    sortingMode={sortingMode}   // as per mui-datagrid doc sortingMode accept two values either client or server
                    onSortModelChange={setSortModel}
                    filterMode={true}
                    filterModel={filterModel}
                    onFilterModelChange={setFilterModel}
                    apiRef={apiRef}
                    initialState={{
                        columns: {
                            columnVisibilityModel: gridConfig?.columnVisibilty
                        }
                    }}

                    pinnedColumns={pinnedColumns}
                    onPinnedColumnsChange={handlePinnedColumnsChange}
                    components={{
                        Toolbar: CustomToolbar,
                        Footer: () => CustomFooter({ pagination, isMobile, tOpts, apiRef, page: page, disableJTP, data: data.rows })
                    }}
                />
            </div>
        </Box>)
}

DataGridBase.propTypes = {}

export default (DataGridBase);