import React, { useEffect, useState } from 'react';
import PageTitle from '../../components/PageTitle'
import CardRouter from '../../components/GenericCard';
import { useDispatch } from 'react-redux';
import actions from '../../redux/actions';
import { useHistory } from 'react-router';
import { Grid, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogModal from '../../components/Common/DialogModel';
import GridBase from "../../components/GridBase";
import utils from "../../utils";
import enums from '../../utils/constants'
import useMobile from '../../utils/useMobile';
const { childRoutes, customOrder } = enums;
const { t } = utils;

const kpiDefinitionsTable = ({ t, tOpts, type }) => {

  const columns = [
    { id: "category", label: `${t("Category", tOpts)}`, flex: 0, minWidth: 150, valueGetter: (value) => value.row.category },
    { id: "subCategory", label: `${t("Sub-Category", tOpts)}`, flex: 0, minWidth: 150, valueGetter: (value) => value.row.subCategory },
    { id: "kpi", label: `${t("KPI", tOpts)}`, flex: 0, minWidth: 250, valueGetter: (value) => value.row.kpi },
    { id: "definition", label: `${t("Explanation", tOpts)}`, flex: 1, minWidth: 300, valueGetter: (value) => value.row.definition }
  ],
    data = [
      { "category": `${t("Merchandising", tOpts)}`, "subCategory": `${t("Share of Shelf", tOpts)}`, "kpi": `${t("Own Share of Shelf", tOpts)}`, "definition": `${t("What is the share of shelf my products take?", tOpts)}`, "qa": `${t("How much of the cooler has my product in it?", tOpts)}` },
      { "category": `${t("Merchandising", tOpts)}`, "subCategory": `${t("Share of Shelf", tOpts)}`, "kpi": `${t("Foreign Share of Shelf", tOpts)}`, "definition": `${t("What is the share of shelf that foreign products take?", tOpts)}`, "qa": `${t("How much of the cooler has a foreign product in it?", tOpts)}` },
      { "category": `${t("Merchandising", tOpts)}`, "subCategory": `${t("Share of Shelf", tOpts)}`, "kpi": `${t("Empty Share of Shelf", tOpts)}`, "definition": `${t("What is the share of shelf that remains unused?", tOpts)}`, "qa": `${t("How much of the cooler is empty?", tOpts)}` },
      { "category": `${t("Merchandising", tOpts)}`, "subCategory": `${t("Product Availability", tOpts)}`, "kpi": `${t("Occupancy", tOpts)}`, "definition": `${t("What is the overall availability of own products considering both shelf space and fill levels?", tOpts)}`, "qa": `${t("What is the overall fill % of the cooler?", tOpts)}` },
      { "category": `${t("Merchandising", tOpts)}`, "subCategory": `${t("Product Availability", tOpts)}`, "kpi": `${t("Assortment Depth Numeric", tOpts)}`, "definition": `${t("How many different products from our portfolio can shoppers find?", tOpts)}`, "qa": `${t("How many different products the customer can find in the cooler?", tOpts)}` },
      { "category": `${t("Merchandising", tOpts)}`, "subCategory": `${t("Compliance", tOpts)}`, "kpi": `${t("Assortment Depth Compliance", tOpts)}`, "definition": `${t("To which degree do shoppers find the number of own different products that we targeted to be there?", tOpts)}`, "qa": `${t("Is the customer finding the variety of different products as we aimed?", tOpts)}` },
      { "category": `${t("Merchandising", tOpts)}`, "subCategory": `${t("Compliance", tOpts)}`, "kpi": `${t("Target Portfolio Compliance", tOpts)}`, "definition": `${t("To which degree is the defined target portfolio available?", tOpts)}`, "qa": `${t("Are the products you aim to be there available?", tOpts)}` },
      { "category": `${t("Merchandising", tOpts)}`, "subCategory": `${t("Compliance", tOpts)}`, "kpi": `${t("Planogram Compliance", tOpts)}`, "definition": `${t("To which degree are the right products in the targeted, right location on the shelf?", tOpts)}`, "qa": `${t("Do we have the expected product in the correct placement?", tOpts)}` },
      { "category": `${t("Portfolio", tOpts)}`, "subCategory": `${t("Portfolio", tOpts)}`, "kpi": `${t("Product Distribution", tOpts)}`, "definition": `${t("What are the overall distribution levels of my own products?", tOpts)}`, "qa": `${t("Which products do we succeed to land most widely?", tOpts)}` },
      { "category": `${t("Portfolio", tOpts)}`, "subCategory": `${t("Portfolio", tOpts)}`, "kpi": `${t("Product Distribution Compliance", tOpts)}`, "definition": `${t("To which degree are individual products available where they were targeted to be available?", tOpts)}`, "qa": `${t("Which targeted products do we land most successfully?", tOpts)}` },
      { "category": `${t("Sales & Shoppers", tOpts)}`, "subCategory": `${t("Value", tOpts)}`, "kpi": `${t("Average Suggested Order Value", tOpts)}`, "definition": `${t("What is the average value of suggested orders?", tOpts)}`, "qa": `${t("What is the average value of suggested orders?", tOpts)}` },
      { "category": `${t("Sales & Shoppers", tOpts)}`, "subCategory": `${t("Cases", tOpts)}`, "kpi": `${t("Average Suggested order cases", tOpts)}`, "definition": `${t("What is the average number of cases of suggested orders?", tOpts)}`, "qa": `${t("What is the average number of cases of suggested orders?", tOpts)}` },
      { "category": `${t("Sales & Shoppers", tOpts)}`, "subCategory": `${t("Sales", tOpts)}`, "kpi": `${t("Average Door Openings", tOpts)}`, "definition": `${t("What is the level of average door openings per asset?", tOpts)}`, "qa": `${t("What is the level of average door openings per asset?", tOpts)}` }
    ];

  if (type === 'columns')
    return columns;
  else
    return data;
}

function BusinessPerformance() {

  const history = useHistory()

  const t = utils.t
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const dispatch = useDispatch();
  const childPages = childRoutes.BusinessPerformance;
  const [extraText, setExtraText] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useMobile();

  const customFooterRenderer = (params) => {
    const { api } = params;
    const totalRows = api.getDisplayedRowCount();
    const footerText = t("Total rows:", tOpts) + `${totalRows}`; // Modify the footer text as needed
    return <div>{footerText}</div>; // Render the modified footer text
  };

  const understandKPI = () => {
    return (<Button variant="outlined" size="small" className="background-theme-blue sub-header-button" onClick={() => setOpenModal(true)}>{t("Understand the KPIs", tOpts)}</Button>)
  }

  useEffect(() => {
    dispatch({
      type: actions.SET_PAGE_TITLE_DETAILS,
      pageTitleDetails: <PageTitle titleHeading={t("Business Performance", tOpts)} titleDescription={t("Select a KPI presets or view a personalized report", tOpts)} icon="" title={t("Business Performance", tOpts)} classTitle="home-header-container" />
    });

    dispatch({
      type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
        filterButton: null,
        customButtonsRight: understandKPI,
        hidden: { search: true, operation: true, export: true, print: true, filter: true }
      }
    });

    loadKPIList();

    return () => {
      dispatch({
        type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
      })

      dispatch({
        type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
          filterButton: null,
          customButtons: null,
          clear: null,
          apply: null,
          hidden: { search: true, operation: true, export: true, print: true, filter: true }
        }
      });
    }
  }, [])

  function loadKPIList() {
    utils.getMetricFilters({ history, dispatch })
      .then((response) => {
        if (response && response.length) {
          const list = [];
          for (const item of customOrder) {
            const element = response.find(v => v.label === item);
            if (element && element.dashboardView) {
              list.push(element.label)
            }
          }

          const keys = Object.keys(childPages), extraTextL = {};
          for (const key of keys) {
            const localizedKPIList = childPages[key].KPIList?.map(kpi => t(kpi, tOpts));
            const localizedCustomList = list.map(kpi => t(kpi, tOpts));
            extraTextL[key] = <i>KPIs: {childPages[key].KPIList === null ? localizedCustomList?.join(', ') : localizedKPIList?.join(', ')}</i>
          }
          setExtraText(extraTextL);
        }
      })
  }

  return <>
    <Grid className={`mb-1 ${!isMobile.tablet ? (isMobile.portrait ? "mt-2" : "") : (isMobile.tablet && isMobile.portrait ? "mt-1" : "mt-n2")}`} container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography className={(isMobile.mobile && !isMobile.tablet && isMobile.portrait) ? "ml-4" : ''} variant="h6">{t("KPI Sets", tOpts)}</Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CardRouter childObj={childPages} largeCard={true} extraText={extraText} />
      </Grid >

    </Grid>

    {openModal && // For Prevent Rerendering
      <DialogModal open={openModal} onClose={() => { setOpenModal(false) }} dialogTitle={t(`KPIs Explanations`, tOpts)} maxWidth="lg" dividers={true} hideFooter={true} >
        <GridBase
          customClass={'data-grid-font-12'}
          columns={kpiDefinitionsTable({ t, tOpts, type: "columns" })}
          data={kpiDefinitionsTable({ t, tOpts })}
          pagination={false}
          autoHeight={false}
          otherOptions={{
            disableSelectionOnClick: true, density: "compact", hideFooter: false, disableColumnMenu: true,
            renderFooter: customFooterRenderer
          }}
        />
      </DialogModal>}

  </>
}

export default BusinessPerformance;