import React, { useMemo } from 'react';
import { Grid, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ImageNotFound from '../../assets/images/vista-images/imagenotfound.jpg';
import toast from 'react-hot-toast';

// Load share Icon
import shareIcon from '../../assets/images/vista-images/share.svg';

const ShareInformation = React.memo(function ShareInformation({ data: { RowNumber = '', StitchedImageURL = ImageNotFound }, enqueueSnackbar }) {

    const dispatch = useDispatch();
    const memorizedShowAll = useMemo(() => <span className="green" style={{ cursor: 'pointer' }} onClick={() => onShare(StitchedImageURL)}>share this information</span>, [StitchedImageURL, dispatch]);

    const onShare = (StitchedImageURL) => {
        let textField = document.createElement('textarea')
        textField.innerText = StitchedImageURL;
        document.body.appendChild(textField)
        textField.select();
        if (document.execCommand("copy")) {
            toast.success("Link copied to clipboard.");
            textField.remove();
        }
    }
   
    return <Box className='vista-box mt-3 mb-2'><Grid container wrap="nowrap" spacing={1}>
        <Grid item>
            <div className="icon-size">
                <img src={shareIcon} alt={`shareIcon${RowNumber}`} />
            </div>
        </Grid>
        <Grid item xs>
            {memorizedShowAll}
        </Grid>
    </Grid></Box>
}, (prevProps, nextProps) => prevProps.data !== nextProps.data && prevProps.enqueueSnackbar !== nextProps.enqueueSnackbar);

export default ShareInformation;