import { makeStyles } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import { Avatar, Box, IconButton, LinearProgress, Typography, useTheme } from "@mui/material";
import constants from "../../utils/constants";

const useStyles = makeStyles({
    root: {
        display: 'flex'
    },
    progressContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    progressBar: {
        width: '100%',
        mr: 1
    }
})
export const brandBackgroundColor = '#182eb5';
export const brandColor = '#ffffff';
function RenderDayCell({ value }) {
    const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    const dayValues = value.split('');
    const classes = useStyles();
    const theme = useTheme();
    return (
        <div className={classes.root}>
            {dayValues.map((val, index) => (
                <Avatar
                    key={index}
                    sx={{
                        backgroundColor: val === '1' ? theme.palette.success.main : brandColor,
                        color: val === '1' ? 'white' : 'black',
                        marginRight: '5px',
                        width: '25px',
                        height: '25px',
                        fontSize: '0.65rem',
                        lineHeight: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: "1px solid grey"
                    }}
                >
                    {days[index]}
                </Avatar>
            ))}
        </div>
    );
}

function RenderColorDotCell({ value }) {
    const itemColor = '#'+value;
    const iconButtonStyle = {
        color: itemColor,
    };
    return (
        <IconButton style={iconButtonStyle}>
            <FiberManualRecord />    
        </IconButton>
    );
}

function RenderProgressBar({ progress }) {
    const { progressLowColor, progressMediumColor, progressHighColor, progressFullColor } = constants;
    const classes = useStyles();
    let color;
    if (progress <= 25) {
      color = progressLowColor;
    } else if (progress <= 50) {
      color = progressMediumColor;
    } else if (progress <= 75) {
      color = progressHighColor;
    } else {
      color = progressFullColor;
    }
  
    return (
        <Box className={classes.progressContainer}>
        <Box className={classes.progressBar}>
            <LinearProgress
            variant="determinate"
            value={progress}
            >
            <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ backgroundColor: color }}
            />
            </LinearProgress>
        </Box>
        <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2">{`${Math.round(
            progress, 
            )}%`}</Typography>
        </Box>
        </Box>
    );
  };

export {
    RenderDayCell,
    RenderColorDotCell,
    RenderProgressBar
}