import React, { forwardRef, useImperativeHandle } from 'react';

const ExportExcelFile = forwardRef((props, ref) => {
    let { url } = props;

    const onSubmitExport = (exportParams, additionalParams) => {
        let form = document.getElementById("exportForm");
        document.getElementById("exportForm").innerHTML = ""; // Clear previous hidden fields before adding new fields.
        let currentTime = new Date();
        let exportUrl = url + "?v=" + currentTime;
        if (additionalParams) {
            exportUrl = exportUrl + '&' + additionalParams;
        }
        let d = new Date(),
            gmtHours = -d.getTimezoneOffset();
        exportParams.TimeOffSet = gmtHours;


        for (var key in exportParams) {
            let hiddenTag = document.createElement('input');
            hiddenTag.type = "hidden";
            hiddenTag.name = key;
            hiddenTag.value = exportParams[key];
            form.append(hiddenTag);
        }
        form.setAttribute('action', exportUrl);
        form.submit();
    }
    useImperativeHandle(
        ref,
        () =>
        ({
            setExportParams({ ExportCols = [], filters = {}, title, fileName, format, additionalParams }) {
                let cols = [], exportCol;
                let params = {
                    exportFormat: format, title: title, exportFileName: fileName
                };
                if (Object.keys(filters).length > 0) {
                    params = { ...params, ...filters }
                }
                params.action = "export";

                ExportCols.forEach(col => {
                    const Renderer = col.type === 'date' ? 'Date' : undefined
                    exportCol = { ColumnName: col.dataIndex, Header: col.title, Width: 200, Renderer: Renderer ,Convert: false, Align: col.type == "int" ? "right" : "left" };
                    cols.push(exportCol);
                });
                exportCol = JSON.stringify(cols);
                params.cols = exportCol;
                if (params.filter) {
                    params.filter = JSON.stringify(params.filter)
                }
                onSubmitExport(params, additionalParams);
            }
        })
    )


    return (
        <form action='' target="_blank" id="exportForm" method="post">
        </form>
    );
});
export default ExportExcelFile;