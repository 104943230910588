import React, { useEffect } from 'react';
import PageTitle from '../../components/PageTitle'
import AcostaReportList from '../../components/AcostaReportList';
import { useDispatch } from 'react-redux';
import utils from "../../utils"
import { useTranslation } from 'react-i18next';
import actions from '../../redux/actions';

const t = utils.t;

const AcostaReport = () => {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle titleHeading={t("Acosta Report", tOpts)} titleDescription="" icon="" iconclass={'pageIconTitle'} title={t("Acosta Report", tOpts)} /> });

        return () => {
            dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null });
        }
    }, [])

    return (
        <>
            <AcostaReportList />
        </>
    )
}

export default AcostaReport
