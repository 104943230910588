import React from "react";
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles({
    customTooltip: {
        backgroundColor: '#ffffff',
        border: '1px solid #cccccc',
        borderRadius: '4px',
        padding: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    },
    customTooltipLabel: {
        fontWeight: 'bold',
        marginBottom: '5px',
    }
})



const CustomTooltip = ({ active, payload, label }) => {
    const classes = useStyles();
    if (active && payload && payload.length) {
        return (
            <div className={classes.customTooltip}>
                <p className={classes.customTooltipLabel}>{`${label}`}</p>
                {payload.map((entry, index) => (
                    <p key={`item-${index}`} style={{ color: entry.color }}>
                        {`${entry.name} : ${entry.value}%`}
                    </p>
                ))}
            </div>
        );
    }

    return null;
};
export default function CombinedBarLineChart({ data, barDataKey, lineDataKey, toggleState }) {
    const isTop10 = toggleState === 'top10';
    const isBottom10 = toggleState === 'bottom10';
    const isDefault = (isTop10 || isBottom10) ? 400 : 900;
    return (
        <ResponsiveContainer width="100%" height={isDefault}>
            <ComposedChart
                layout="vertical"
                width={500}
                height={isDefault}
                data={data}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
            >
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" scale="band" width={300} interval={0} />
                <Tooltip content={<CustomTooltip />} />
                <Legend layout="horizontal" verticalAlign="top" align="center" />
                <Bar dataKey={barDataKey} barSize={40} fill="#112388" />
                <Line dataKey={lineDataKey} stroke="#FF8A00" />
            </ComposedChart>
        </ResponsiveContainer>
    );
}
