import 'rapidoc';
import {apis} from '../httpUtil';
const Docs = () => {
    return <rapi-doc
      spec-url = {apis.docs}
      render-style = "read"
      route-prefix = "docs/"
      style = {{ height: "100vh", width: "100%" }}
    >
    </rapi-doc>
}

export default Docs;