import { React } from 'react';
import dayjs from 'dayjs';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import useMobile from '../../utils/useMobile';
import { Tooltip } from "@material-ui/core";
import { InfoRounded } from '@material-ui/icons';
import utils from '../../utils/index';


const dateFormat = { date: 'DD MMM YYYY', month: 'DD MMM', monthSingle: 'D MMM' };
const kpiArray = [
  { name: "Assets covered (Total)", dataKey: "assetCoveredTotal", image: "outlet.png", definition: "Assets that sent at least one image from the period selected" },
  { name: "Devices covered (Total)", dataKey: "cameraCoveredTotal", image: "camera.png", definition: "Cameras that sent at least one image from the period selected" },
  { name: "Target portfolio size (Avg)", dataKey: "portfolioSize", image: "planogram-icon.png", definition: "Average quantity of products in the Planograms" }
]

const InfoBar = (props) => {
  const { infoBarData = {}, t, tOpts, summaryTittle } = props;
  const mobile = useMobile()
  const infoBarCard = document.getElementById("info-bar-card");
  let selectorHeight = null;
  if (infoBarCard) {
    selectorHeight = infoBarCard.offsetHeight;
    const selectorCard = document.getElementById("selector-card");
    if (selectorCard)
      selectorCard.style.height = selectorHeight + "px";
  }

  return (
    <>
      <Grid className="mt-2 mb-2" container spacing={mobile.mobile ? 0.5 : 1}>
        <Grid className="pt-1" item xs={12} sm={6} md={6}>
          <Card className="mb-1 info-bar-print h-100" id='info-bar-card'>
            <div className="text-right">
              <p className="info-bar-header">
                <small>{`${t('Showing data for', tOpts)} ${dayjs(infoBarData.startDate).format(utils.systemDateTimeFormat(true))} ${t('to', tOpts)} ${dayjs(infoBarData.endDate).format(utils.systemDateTimeFormat(true))}`}</small>
              </p>
            </div>
            <CardContent className="pl-2 pr-2 pt-2 pb-0">
              {Object.keys(summaryTittle).map((key) => {
                return <div> <p className="m-0 p-0">{`${t(key, tOpts)}: `}<small>{`${summaryTittle[key].length ? t(summaryTittle[key].join(", "), tOpts) : t("All", tOpts)}`}</small></p></div>
              })}
            </CardContent>
          </Card>
        </Grid>

        {kpiArray.map((item) => {
          return (
            <Grid className="pt-1" item xs={4} sm={2} md={2}>
              <Card className="mb-2 info-bar-print h-100" id='info-bar-card'>
                <CardContent className="pl-2 pr-2 pt-2 pb-0">
                  <div className="text-center">
                    <img src={require(`../../assets/images/${item.image}`)} height="50" alt="" />
                  </div>
                  <div className="text-center">
                    <Typography className='mb-0' variant="h6" gutterBottom component="div">{infoBarData[item.dataKey] ? Math.ceil(Number(infoBarData[item.dataKey])) : t("N/A", tOpts)}</Typography>
                  </div>
                  <div className="text-center">
                    <Typography className='mb-0' variant="caption" display="block" gutterBottom> {t(item.name, tOpts)}</Typography>
                  </div>
                  <div className='tooltip-container'>
                    <Tooltip title={t(item.definition, tOpts)} placement="right">
                      <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                    </Tooltip>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </>
  );
};

export default InfoBar;
