import React from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as JsError from './JsError';
import { SnackbarProvider } from '@durlabh/dframework-ui';
import { StateProvider } from '@durlabh/dframework-ui';
import ThemeAdder from './components/mui/locale/themeAdder';
import { apis } from './httpUtil';
JsError.initialize(apis.SendErrorEmail, 'Playbook');

ReactDOM.render(
    <React.StrictMode>
        <SnackbarProvider SnackbarProps={{ autoHideDuration: 4000 }}>
            <StateProvider>
                <ThemeAdder >
                    <App />
                </ThemeAdder>
            </StateProvider>
        </SnackbarProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

const configuration = {
    onUpdate: (registration) => {
        if (registration && registration.waiting) {
            if (window.confirm('New Version Available! refresh to update your app?')) {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                window.location.reload(true);
            }
        }
    }
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(configuration);
