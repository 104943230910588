import { getGridDateOperators } from '@mui/x-data-grid-premium';
import UiModel from './ui-model';

const userLoginModel = new UiModel({
    title: "User Login",
    defaultSort: 'LoginTime DESC',
    titleDescription: 'Understand and identify the users accessing your platform',
    idProperty: 'RecordId',
    readOnly: true,
    addCreatedModifiedColumns: false,
    module: 'UserLogin',
    hideBackButton: true,
    preferenceId: 'playbookUserLogin',
    columns: [
        { field: "Email", type: 'string', width: 200, label: "Email" },
        { field: "FirstName", type: 'string', width: 150, label: "First Name" },
        { field: "LastName", type: "string", width: 150, label: "Last Name" },
        { field: "Role", type: 'string', width: 150, label: "Role" },
        { field: "LoginTime", type: 'dateTimeLocal', width: 250, label: "Login Time", filterOperators: getGridDateOperators() }
    ]
});

export default userLoginModel;