import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import {
  Card, CardContent, Grid, Typography, Tabs, Tab, Chip
} from "@material-ui/core";
import { request, apis } from "../../httpUtil";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import utils from '../../utils';
import enums from '../../utils/constants';
import DialogModal from '../Common/DialogModel';
import { useTranslation, withTranslation } from 'react-i18next';
import actions from '../../redux/actions';
import AlertOverview from '../Alerts/AlertOverview'
import OutletPerformance from './outletPerformance'
import useMobile from '../../utils/useMobile';
import OutletMap from './outletCardMap';
import OutletInstallationAlert from './outletInstallaitonAlert';
import { useSelector } from 'react-redux';
import OutletPlanogram from './outletPlanogram';
import { ORDER_STATUS } from '../../constants';
import { useSnackbar } from '@durlabh/dframework-ui';
import VistaTab from './vistaTab';
import OrderingTab from './orderingTab';
import InformationTab from './informationTab';
import AlertsTab from './alertsTab';
const { t, dayjsFormatLocal } = utils;
const { isModuleExists } = utils;
const { turkeyOrderValidateModule } = enums;

const orderQuantityEnum = {
  Increase: 'increase',
  Decrease: 'decrease'
};

const tabList = [
  { label: "Information" },
  { label: "Planogram" },
  { label: "Installation Audit" },
  { label: "Location" },
  { label: "Alerts" },
  { label: "Image Recognition" },
  { label: "Performance" }
];


const componentsMap = {
  '0': InformationTab,
  '1': OutletPlanogram,
  '2': OutletInstallationAlert,
  '3': OutletMap,
  '4': AlertsTab,
  '5': VistaTab,
  '6': OutletPerformance,
  '7': OrderingTab
}

function OutletCard(props) {
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMobile();
  const { outletCard = {}, setOutletCard, defaultTab = 0, customClass = '' } = props;
  const { locationName, code, locationId, clientId: outletClientId } = outletCard.data;
  const dataTypes = ['info'];
  const [outletData, setOutletData] = useState({});
  const [assetData, setAssetData] = useState([]);
  const [pricingData, setPricingData] = useState([]);
  const [userSymbol, setUserSymbol] = useState(null);
  const [activeTab, setActiveTab] = useState(defaultTab);
  const mapData = useRef({});
  const onClose = () => { setOutletCard({ modal: false, data: {}, vistaData: {} }) };
  const [assetsList, setAssetsList] = useState([])
  const [assetImages, setAssetImages] = useState([])
  const [selectedAssetId, setSelectedAssetId] = useState(null)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [replenishData, setReplenishData] = useState([])
  const [orderingTabSelection, setOrderingTabSelection] = useState('image');
  const [showTabs, setShowTabs] = useState(true);
  const [showOrderingTabs, setShowOrderingTabs] = useState(true);
  const [firstAsset, SetFirstAsset] = useState(null);
  const userData = useSelector(state => state.appReducer.userData);
  const [outletDataForOrderingTab, setOutletDataForOrderingTab] = React.useState({});
  const [orderStatusId, setOrderStatusId] = useState(null);
  const { ClientId } = userData && userData.tags ? userData.tags : 0;
  const filterValues = useSelector(state => state.appReducer.filterValues);
  const multiClientFilters = useSelector(state => state.appReducer.filters);
  const gridData = useSelector(state => state.appReducer.replenishmentGridOrderingData);
  const [manualRefresh, setManualRefresh] = useState(0);
  const data = gridData?.data;
  const [dataLoaded, setDataLoaded] = useState(false);
  const { PlaybookHideInTransitButton, PlaybookOrderingTab = {} } = userData?.modules ? userData.modules : {};
  const hideInTransitButton = !Number(userData?.tags?.IsSuperAdmin) > 0 && PlaybookHideInTransitButton.Module;
  const snackbar = useSnackbar();
  useEffect(() => {
    loadOutletData()
  }, [manualRefresh])

  useEffect(() => {
    const orderingTabExists = tabList.some(tab => tab.label === "Ordering");
    if (!orderingTabExists && isModuleExists(userData, PlaybookOrderingTab.Name)) {
      tabList.push({ label: "Ordering" });
    }

  }, [])
  useEffect(() => {
    if (Object.keys(outletData).length > 0) {
      setDataLoaded(true);
    }
  }, [outletData]);

  const TabEnum = {
    INFORMATION: 0,
    PLANOGRAM: 1,
    INSTALLATION_AUDIT: 2,
    LOCATION: 3,
    ALERTS: 4,
    VISTA: 5,
    PERFORMANCE: 6,
    ORDERING: 7
  };

  useEffect(() => {
    if (activeTab === TabEnum.ORDERING) {
      getData(orderStatusId);
    }
  }, [activeTab, orderStatusId]);
  async function loadOutletData() {

      const response = await request({ url: apis.OutletCardView, params: { locationName, code, dataTypes, locationId }, history, dispatch, jsonPayload: true });
      if (response) {
        if (response.OutletInfo) {
          setAssetData(response.OutletInfo.assets);
          setPricingData(response.OutletInfo.pricing);
          setUserSymbol(response.OutletInfo.userCurrencySymbol);

          getData(response.OutletInfo.OrderStatusId);
          setOrderStatusId(response.OutletInfo.OrderStatusId);
          const allAssets = response.OutletInfo.assets, assets = [], cameras = [];
          setAssetsList(allAssets);
          if (allAssets.length !== 0) {
            if (activeTab === TabEnum.ORDERING) {
              SetFirstAsset(allAssets[0].AssetId);
            } else {
              getAssetImages({}, allAssets[0].AssetId);
              SetFirstAsset(allAssets[0].AssetId);
              setSelectedTabIndex(allAssets[0].AssetId);
            }
          } else {
            setShowTabs(false);
            setShowOrderingTabs(false);
          }
          for (const asset of allAssets) {
            if (asset.IsParentAsset && asset.AssetSerialNumber) {
              assets.push((asset.AssetSerialNumber));
            }
            if (asset.SmartDeviceSerialNumber)
              cameras.push(asset.SmartDeviceSerialNumber);
          }
          delete response.OutletInfo.assets;
          response.OutletInfo.assets = assets;
          response.OutletInfo.cameras = cameras;
          setOutletData(response.OutletInfo);
        };
        if (response.MapInfo || (response.Latitude && response.Longitude)) {
          mapData.current = (response.Latitude && response.Longitude) ? response : response.MapInfo;
        }
      }

  }

  const handleChangeTab = (event, newValue) => {
    if (activeTab !== newValue) {
      if (firstAsset) {
        setSelectedAssetId(firstAsset);
        setSelectedTabIndex(firstAsset);
        getAssetImages({}, firstAsset);
      };
      setActiveTab(newValue);
    }
  };

  async function getData(orderStatusId) {
    if (orderStatusId >= ORDER_STATUS.INBOX) {
      let payload = { ...utils.listDefaultParams, IncludeKPI: true }

      payload.assetOrLocationName = code
      let URL = ''
      if (orderStatusId === ORDER_STATUS.INBOX) {
        URL = apis.ReorderDetail
        payload.locationStatusId = orderStatusId
      } else {
        URL = apis.ScheduledOrder
        payload.orderStatusId = orderStatusId
      }
      if (filterValues.uiClientIds?.length) {
        payload.uiClientIds = filterValues.uiClientIds?.toString();
        utils.applyMultiClientFilters(payload, filterValues, multiClientFilters);
      }
      const data = await request({ url: URL, params: payload, history, dispatch });
      if (data) {
        if (data?.records?.length) {
          const { ProductInfo } = data.records[0];
          data.records[0].ProductInfo = utils.combineProducts(ProductInfo);
          setOutletDataForOrderingTab(data.records[0])
        }
        dispatch({ type: actions.REPLENISHMENT_GRID_ORDERING_DATA, replenishmentGridOrderingData: { data: data } });
      }
    }

  }
  const getAssetImages = async () => {
    const params = { action: "GetPrevNextImage", asArray: 0, showAllImages: false, isFetchCoolerData: true, AssetId: selectedTabIndex };
    if (Number(ClientId) > 0) {
      params.clientId = Number(ClientId);
    }
    if (outletClientId > 0) {
      params.clientId = outletClientId;
    }
    if (filterValues.uiClientIds?.length) {
      params.uiClientIds = filterValues.uiClientIds?.toString();
      utils.applyMultiClientFilters(params, filterValues, multiClientFilters);
    }
    const response = await request({ url: apis.assetLatestImages2, params, history, dispatch });
    const data = response?.records?.length === 0 ? {} : response?.records[0];
    const isPlanogramAvailable = data && data.PlanogramId ? true : false;
    const isPOSImage = data && data.SceneTypeId === 5;
    if (isPlanogramAvailable || isPOSImage) {
      setShowTabs(true);
    } else {
      setShowTabs(false);
    }
    setAssetImages(response.records);
    getReplenishTableData(response.records[0]?.AssetPurityId, response.records);
    setSelectedAssetId(selectedTabIndex);
  };

  const getReplenishTableData = async (AssetPurityId) => {
    if (!AssetPurityId) {
      return;
    }
    const params = { action: 'list', otherAction: 'ProductDetail', AssetPurityId, asArray: 1, isReplenish: true };
    if (filterValues.uiClientIds?.length) {
      params.uiClientIds = filterValues.uiClientIds.toString();
      utils.applyMultiClientFilters(params, filterValues, multiClientFilters);
    }
    const response = await request({ url: apis.ProductOutOfStock, params, history, dispatch });
    if (response && response.records) {
      setReplenishData(response.records);
    }
  }


  useEffect(() => {
    getAssetImages();
  }, [selectedTabIndex])

  const onScheduledIgnoreClick = (e, orderMovingId, row) => {
    const record = row ? [row.LocationId] : ''
    const successCBK = () => setManualRefresh((val) => (val + 1));
    let updateData = (orderStatusId === ORDER_STATUS.IGNORED) || (orderStatusId === ORDER_STATUS.SCHEDULED) ? true : false
    let reorder = orderStatusId === ORDER_STATUS.INBOX ? true : false
    let params = { e, isMulti: true, data, isReorder: reorder, statusId: orderMovingId, dispatch, history, updateData: updateData, checkedData: record, t, successCBK, addNotes: isModuleExists(userData, turkeyOrderValidateModule), tOpts }
    utils.onScheduledIgnoreClick(params);
  }

  const noneEditOrderSwal = () => {
    snackbar.showMessage(t("The order can not be modified as the order is already in In Transit.", tOpts));
  };
  const editOrder = () => {
    if (orderStatusId === ORDER_STATUS.INTRANSIT) {
      noneEditOrderSwal();
    } else {
      dispatch({ type: actions.EDIT_REPLENISHMENT_ORDER, replenishmentOrder: { open: true, data: gridData.data.records[0], isOrderingTab: true } })
    }
  }
  const orderingTableScrollRef = useRef();
  const orderingTableScrollTopRef = useRef(0);
  const onConfirm = (e, index, action) => {
    if (orderingTableScrollRef.current) {
      orderingTableScrollTopRef.current = orderingTableScrollRef.current.scrollTop
    }
    if (orderStatusId === ORDER_STATUS.INTRANSIT) {
      noneEditOrderSwal();
      return
    }
    let gridDataClone = utils.deepCloneObject(gridData);
    let units = gridDataClone.data.records[0].ProductInfo[index].UnitsNeeded;
    if ((units === 1 && action === orderQuantityEnum.Decrease) || (units === enums.maximumQuantityToOrder && action === orderQuantityEnum.Increase)) {
      return
    }
    let products = gridDataClone.data.records[0].ProductInfo
    let product = gridDataClone.data.records[0].ProductInfo[index]
    if (action === orderQuantityEnum.Increase) {
      units = units + 1
    } else {
      units = units - 1
    }
    product.UnitsNeeded = units
    let newCases = 0, newValue = 0;
    for (const product of products) {
      newCases += Number(product.UnitsNeeded);
      newValue += Number((product.UnitPrice * product.UnitsNeeded));
      product.Amount = Number(product.UnitPrice) * Number(product.UnitsNeeded);
      product.isNew = false;
    }
    gridDataClone.data.records[0].FinalTotalCase = newCases;
    gridDataClone.data.records[0].FinalTotalOrder = Math.round(newValue);
    gridDataClone.data.records[0].TotalCase = newCases;
    gridDataClone.data.records[0].TotalOrder = Math.round(newValue);

    dispatch({ type: actions.REPLENISHMENT_GRID_ORDERING_DATA, replenishmentGridOrderingData: { data: gridDataClone.data, editProductRowId: gridDataClone.data.records[0].RowNumber } });
  }
  useEffect(() => {
    if (orderingTableScrollRef.current) {
      orderingTableScrollRef.current.scrollTop = orderingTableScrollTopRef.current
    }

  }, [gridData])

  const TabComponent = useMemo(() => componentsMap[activeTab], [activeTab])

  return (
    <DialogModal
      dialogTitle={`${code || outletData.Code || '-'} - ${t(locationName || outletData.Name || '-', tOpts)}`}
      maxWidth="lg"
      className={`outletCard-modal ${customClass}`}
      open={outletCard.modal}
      onClose={onClose}
      dividers={true}
      hideFooter={true}>
      <div className='w-100 flex-d-c'>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          centered={(isMobile.mobile) ? false : true}
          variant={(isMobile.mobile) ? "scrollable" : "fullWidth"}
          scrollButtons={(isMobile.mobile) ? 'on' : 'off'} >
          {tabList.map(tab => {
            return <Tab className="pt-0 outlet-Screen-tab-container" label={t(tab.label, tOpts)} disabled={!dataLoaded} />
          })}
        </Tabs>
        <div style={{ height: (isMobile.mobile && !isMobile.tablet) ? "700px" : "500px" }}>
          {TabComponent && <TabComponent selectedTabIndex={selectedTabIndex} setSelectedTabIndex={setSelectedTabIndex} assetsList={assetsList} assetImages={assetImages} selectedAssetId={selectedAssetId} showOrderingTabs={showOrderingTabs} outletDataForOrderingTab={outletDataForOrderingTab} replenishData={replenishData} code={code} gridData={gridData} onClose={onClose} onConfirm={onConfirm} orderStatusId={orderStatusId} editOrder={editOrder} outletData={outletData} orderingTableScrollRef={orderingTableScrollRef} onScheduledIgnoreClick={onScheduledIgnoreClick} outletCard={outletCard} showTabs={showTabs} orderingTabSelection={orderingTabSelection} outletClientId={outletClientId} locationId={locationId} assets={assetData} pricing={pricingData} userSymbolCurreny={userSymbol} />}
        </div>
      </div>
    </DialogModal>
  )
}

export default withTranslation()(OutletCard);