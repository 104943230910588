import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import useMobile from '../../utils/useMobile';
import SalesHistory from '../sales-history';
import InboxOrderAnalysis from '../../components/Replenishment/InboxOrderAnalysis';
import utils from '../../utils';
import enums from '../../utils/constants';

const { isModuleExists } = utils;
const { inboxAndSalesChartsModule } = enums;

const SalesHistoryPage = () => {
    const isMobile = useMobile();
    const [fetchData, setFetchData] = useState(false);
    const userData = useSelector(state => state.appReducer.userData);
    const isSales = true;
    return (
        <div>
            {(!isMobile.mobile && isModuleExists(userData, inboxAndSalesChartsModule)) &&
                <InboxOrderAnalysis isSales={isSales} valueField='TotalOrder' caseField='TotalCase' source='Sales' keyColumn='SalesHistoryId' fetchData={fetchData} tabsData={utils.salesHistoryChartsTabsEnum} />
            }
            <Grid item className='width-100 mt1'>
                <SalesHistory.Grid setFetchData={setFetchData} />
            </Grid>
        </div >
    )
}

export default SalesHistoryPage;