import { getGridDateOperators } from '@mui/x-data-grid-premium';
import UiModel from './ui-model';

const ImportLogModel = new UiModel({
    title: "Import Logs",
    defaultSort: 'MasterDataImportLogId DESC',
    titleDescription: 'Import Logs',
    idProperty: 'MasterDataImportLogId',
    readOnly: true,
    addCreatedModifiedColumns: false,
    module: 'ImportLogs',
    hideBackButton: true,
    api: "MasterDataImportLog",
    preferenceId: 'playbookImportLog',
    columns: [
        { field: "ClientName", width: 200, label: "Client", hide: true },
        { field: "Grid", width: 200, label: "Grid" },
        { field: "Errors", width: 500, label: "Errors" },
        { field: "ImportedRecords", type: "number", width: 250, label: "Imported Records Count" },
        { field: "PrimaryEmail", width: 200, label: "Imported By" },
        { field: "CreatedOn", type: 'dateTimeLocal', width: 250, label: "Imported On", filterOperators: getGridDateOperators() }
    ]
});

export default ImportLogModel;