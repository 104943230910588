import UiModel from './ui-model';

const productListModel = new UiModel({
    title: "Master Data",
    defaultSort: 'Product DESC',
    titleDescription: "Check how your master data is set",
    idProperty: 'ProductId',
    readOnly: true,
    api: 'Product',
    addCreatedModifiedColumns: false,
    preferenceId: 'playbookMasterProduct',
    columns: [
        { field: "Product", label: "Product", type: 'string', width: 200 },
        { field: "SKU", label: "SKU", type: 'string', width: 200 },
        { field: "ProductImageCount", label: "# of Images", type: 'number', width: 120 },
        { field: "ProductCategory", label: "Product Category", type: 'string', width: 120 },
        { field: "BrandName", label: "Brand", type: 'string', width: 100 },
        { field: "UPC", label: "UPC", type: 'string', width: 200 },
        { field: "ShortEANCode", label: "Short EAN Code", type: 'string', width: 200 },
        { field: "IsForeign", label: "Is Foreign Product?", type: 'boolean', width: 102 },
        { field: "Country", label: "Country", type: 'string', width: 200, hide: true },
        { field: "State", label: "State", type: 'string', width: 200, hide: true },
        { field: "ProductSecondCategory", label: "Product Category 2", type: 'string', width: 200, hide: true },
        { field: "DistributorName", label: "Distributor", type: 'string', width: 200 },
        { field: "DistributorGTIN", label: "Distributor GTIN", type: 'string', width: 200 },
        { field: "PackagingType", label: "Packaging Type", type: 'string', width: 200 },
        { field: "BeverageType", label: "Product Type", type: 'string', width: 200 },
        { field: "MeasurementUnit", label: "Measurement Unit", type: 'string', width: 200, hide: true },
        { field: "ShortName", label: "Short Name", type: 'string', width: 200, hide: true },
        { field: "IsKeyProduct", label: "Is KeyProduct?", type: 'boolean', width: 200, hide: true },
        { field: "Manufacturer", label: "Manufacturer Name", type: 'string', width: 200, hide: true },
        { field: "ProductGTIN", label: "Product GTIN", type: 'string', width: 200, hide: true }
    ]
});

export default productListModel;