import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const BarChartComponent = ({ ...props }) => {
    const { series, subTitle, categories, title, orderBy = 'descending' } = props;
    const options = {
        chart: {
            type: 'bar',
            height: '640vh'
        },
        title: {
            text: subTitle
        },
        xAxis: {
            categories: categories
        },
        yAxis: {
            title: {
                text: title
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                    inside: true
                }
            },
            bar: {
                pointPadding: 0.8,
                groupPadding: 0.8,
                pointWidth: 20,
                borderWidth: 0,
                colorByPoint: true
            }
        },
        series: series
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default BarChartComponent;