import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'the-platform';
import { useTranslation } from "react-i18next";
import utils from '../../utils';
const t = utils.t;

const CurrentRank = ()=> {
    const dispatch = useDispatch();
    const smallDevice = useMedia('(max-width: 959px)');
    let currentRank = useSelector(state => state.appReducer.currentRank);
    const { t: translate, i18n } = useTranslation();
  const tOpts = { t: translate, i18n };

    const setCurrentRankCheckFun = () => {
        dispatch({ type: 'SET_CURRENT_RANK', currentRank: !currentRank })
        if (!currentRank) {
            dispatch({ type: 'SET_CURRENT_POG', currentPog: false })
        }
    }

    const memorizedCurrentRank = useMemo(() =>
    
        <>
            <input type="checkbox" id="todo" name="todo" checked={currentRank} onChange={setCurrentRankCheckFun} />
            <label className="chk-label" htmlFor="todo" data-content={`${smallDevice ? t('Rank',tOpts) : t('Rank by Stock',tOpts)}`}>{`${smallDevice ? t('Rank',tOpts) : t('Rank by Stock',tOpts)}`}</label>
        </>, [currentRank, setCurrentRankCheckFun,t]);

    return <div className="vista-search-current-rank mr-3 w-100 background-theme-blue">
        {memorizedCurrentRank}
    </div>
}

export default  withTranslation()(CurrentRank);