import React from 'react';
import { withTranslation } from 'react-i18next';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import utils from '../../utils';


const OOSProductList = React.memo(function OOSProductList({ data: { ProductInfo = [], Children = null } , t: translate, i18n}) {
    let records = [], ChildProductInfo;
    const tOpts = { t: translate, i18n };
    const t = utils.t;
    const hasChild = Children && Children.length ? true : false;
    if (hasChild) {
        ChildProductInfo = (Children && Children[0].ProductInfo);
        if (ChildProductInfo.length) {
            var uniqueArray = [...ProductInfo.map((e) => e.ProductId), ...ChildProductInfo.map((e) => e.ProductId)].filter(function (item, i, ar) { return ar.indexOf(item) === i; });
            uniqueArray.map((i) => {
                let parentProduct = ProductInfo.filter(e => e.ProductId === i);
                let childProduct = ChildProductInfo.filter(e => e.ProductId === i);
                let record = { FacingInCooler: 0, FacingInPlanogram: 0, PercentageOfProduct: 0 };
                if (parentProduct.length > 0) {
                    record = parentProduct[0];
                } else {
                    record = childProduct[0];
                }
                if (parentProduct.length > 0 && childProduct.length > 0) {
                    record.FacingInCooler += childProduct[0].FacingInCooler;
                    record.FacingInPlanogram += childProduct[0].FacingInPlanogram;
                    record.PercentageOfProduct += childProduct[0].PercentageOfProduct;
                }
                records.push(record);
            })
        }
    } else {
        records = ProductInfo;
    }

    const productNameRenderer = ({ value, rowData }) => {
        let isEmpty = rowData.ProductName.toLowerCase().indexOf("empty") > -1;
        let isTotal = rowData.ProductName.toLowerCase().indexOf("total") > -1;
        if (rowData.IsForeign) {
            rowData.ProductName = 'Unknown'
        }
        return <div className={isEmpty || isTotal ? "font_weight_600" : ""} title={rowData.ProductName}>{rowData.ProductName}</div>
    }

    return (
        <div className="product-grid" id="product-grid">
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        width={width}
                        height={height - 2}
                        headerHeight={38}
                        rowHeight={26}
                        rowCount={records.length}
                        rowGetter={({ index }) => records[index]}>
                        <Column
                            className="vista-location-product-table-data pl-2"
                            label={t('Product Name', tOpts)}
                            headerStyle={{ textAlign: 'left', color: '#3B3E66' }}
                            dataKey='ProductName'
                            headerClassName="column-header-style"
                            cellRenderer={productNameRenderer}
                            width={width * 0.5} />
                        <Column
                            className="vista-location-product-table-data text-align-center"
                            label={t('Days OOS', tOpts)}
                            headerStyle={{ textAlign: 'center', color: '#3B3E66' }}
                            dataKey='FacingInCooler'
                            headerClassName="column-header-style"
                            width={width * 0.25}
                        />
                        <Column
                            className="vista-location-product-table-data text-align-center"
                            label={t('Lost Rev', tOpts)}
                            dataKey='FacingInPlanogram'
                            headerClassName="column-header-style"
                            width={width * 0.25}
                            headerStyle={{ textAlign: 'center', color: '#3B3E66' }}
                            justifyContent={'center'}
                        />
                    </Table>
                )}
            </AutoSizer>
        </div>)
})

export default  withTranslation()(OOSProductList);