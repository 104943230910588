
import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, MarkerClusterer, useLoadScript } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";
import utils from '../../utils'
const clusterMarker = require('../../assets/images/map/cluster-marker.png');
const redPin = require('../../assets/images/map/pin-red.png');
const greenPin = require('../../assets/images/map/pin-green.png');
const mapStyle = { height: "100%", width: "100%", "borderRadius": "4px" },
    clusterStyle = [{ url: clusterMarker, width: 50, height: 50, textSize: 14, textColor: "white", className: 'map-cluster-text' }];

const MapComponent = (props) => {
    const { mapData = [], selectedMarker, setSelectedMarker, tableList, setFilterModel, setFilterReady, mapKey, onLoadDone, setOnLoadDone } = props;
    const [mapInstance, setMapInstance] = useState(null);
    const [mapMarkers, setMapMarkers] = useState([]);
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const { isLoaded, } = useLoadScript({
        googleMapsApiKey: utils.Google_ApiKey,
        language: i18n.language
    })

    useEffect(() => {
        mapOnload();
    }, [mapData])

    useEffect(() => {
        if (mapInstance && (selectedMarker.Latitude || selectedMarker.lat)) {
            const bounds = new window.google.maps.LatLngBounds();
            const point = new window.google.maps.LatLng(selectedMarker.Latitude || selectedMarker.lat, selectedMarker.Longitude || selectedMarker.lng);
            bounds.extend(point);
            const mapCenter = bounds.getCenter();
            mapInstance.setCenter(mapCenter);
            mapInstance.panToBounds(bounds);
            mapInstance.fitBounds(bounds);
        }

        if (setFilterModel && selectedMarker.fromMap) {
            setFilterReady(true)
            setFilterModel({
                linkOperator: "AND",
                items: [{ columnField: 'Location.Code', value: selectedMarker.outletCode, operatorValue: "equals" }]
            })
        }

    }, [selectedMarker])

    useEffect(() => {
        if (onLoadDone && mapInstance && tableList && tableList.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            let extend = false;
            for (const item of tableList) {
                if (item.Latitude && item.Longitude) {
                    const point = new window.google.maps.LatLng(item.Latitude, item.Longitude);
                    bounds.extend(point);
                    extend = true;
                }
            }
            if (extend) {
                const mapCenter = bounds.getCenter();
                mapInstance.setCenter(mapCenter);
                mapInstance.panToBounds(bounds);
                mapInstance.fitBounds(bounds);
            }
        }
    }, [tableList])

    const mapOnload = (map) => {
        if (map)
            setMapInstance(map);
        updateMap({ map });
        setOnLoadDone(true);
    }

    function updateMap({ map = null }) {
        const localMarkerArray = [], localMarkerMap = [];
        for (let i = 0; i < mapData.length; i++) {
            let latitude = mapData[i].Latitude, longitude = mapData[i].Longitude, key = latitude + ':' + longitude;
            // if a marker has same lat/lng with some other marker then offset it a little to make the cluster expandable
            if (localMarkerMap.includes(key)) {
                const angle = 360.0 / mapData.length, offset = -.00005;
                latitude = Number(latitude) + offset * Math.cos((+angle * i) / 180 * Math.PI);  //x
                longitude = Number(longitude) + offset * Math.sin((+angle * i) / 180 * Math.PI);  //Y
                key = latitude + ':' + longitude;
            }

            let markerData = { lat: latitude, lng: longitude, id: key, outletCode: mapData[i].OutletCode, mapId: mapData[i].AlertId };

            localMarkerArray.push(markerData);
            localMarkerMap.push(key);
        }
        setMapMarkers(localMarkerArray);

        const currentMapInstance = mapInstance ? mapInstance : map;

        if (currentMapInstance) {
            const bounds = new window.google.maps.LatLngBounds();
            localMarkerArray.forEach(marker => {
                const point = new window.google.maps.LatLng(marker.lat, marker.lng);
                bounds.extend(point);
            })
            const mapCenter = bounds.getCenter();
            currentMapInstance.setCenter(mapCenter);
            currentMapInstance.panToBounds(bounds);
            currentMapInstance.fitBounds(bounds);
        }
    }

    const mapUnload = (map) => {
        setMapInstance(null);
        setMapMarkers([]);
    }

    const clusterPriority = (markers) => {
        return { text: markers.length, index: 1 };
    }

    return <>
        {isLoaded ?
            <GoogleMap
                key={'_GoogleMap'}
                onClick={() => setSelectedMarker({})}
                zoom={13}
                mapContainerStyle={mapStyle}
                onLoad={mapOnload}
                options={{ gestureHandling: 'greedy', language: i18n.language }}
                onUnmount={mapUnload}>
                {mapMarkers.length > 0 &&
                    <MarkerClusterer
                        key={"MarkerClusterer-" + mapKey}
                        maxZoom={21}
                        averageCenter={true}
                        calculator={clusterPriority}
                        styles={clusterStyle}>
                        {(clusterer) =>
                            mapMarkers.map((location, index) => (
                                <Marker
                                    key={location.name + ':' + location.lat + ':' + location.lng + index}
                                    position={new window.google.maps.LatLng(location.lat, location.lng)}
                                    clusterer={clusterer}
                                    onClick={() => { setSelectedMarker(location.mapId === (selectedMarker?.AlertId || selectedMarker?.mapId) ? { fromMap: true } : { ...location, fromMap: true }) }}
                                    animation={"BOUNCE"}
                                    icon={{ url: location.mapId === (selectedMarker?.AlertId || selectedMarker?.mapId) ? greenPin : redPin, scaledSize: new window.google.maps.Size(40, 40) }}
                                />
                            ))
                        }
                    </MarkerClusterer>
                }
            </GoogleMap> : <div className="text-center pt-5">{t('Loading Map data ...',tOpts)}</div>
        }
    </>
}

export default MapComponent;