import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@material-ui/core';
import { SidebarHeader, SidebarMenu } from '../';
import actions from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import utils from '../../utils';

let dispatch = null
const Sidebar = props => {
  const { sidebarShadow } = props;
  const [dashboardMenu, SetDashboardMenu] = useState({});
  dispatch = useDispatch();

  const sidebarToggle = useSelector(state => state.appReducer.sidebarToggle);
  const sidebarToggleMobile = useSelector(state => state.appReducer.sidebarToggleMobile);
  const sidebarHover = useSelector(state => state.appReducer.sidebarHover);
  const sidebarFixed = useSelector(state => state.appReducer.sidebarFixed);
  const loginData = useSelector(state => state.appReducer.userData) || {};
  const closeDrawer = () => dispatch({ type: actions.SET_SIDEBAR_TOGGLE_MOBILE, sidebarToggleMobile: !sidebarToggleMobile });

  useEffect(() => {
    if (loginData.menu) {
      let tempArray = (loginData && loginData.menu && loginData.menu.children) ? loginData.menu.children : [];
      if (tempArray.length) {
        const result = tempArray.filter(obj => obj.id === utils.dashboardMenuId);
        SetDashboardMenu(result[0]);
      }
    }
  }, [loginData])


  // TODO
  // const toggleHoverOn = () => dispatch({ type: actions.SET_SIDEBAR_HOVER, sidebarHover: true });
  // const toggleHoverOff = () => dispatch({ type: actions.SET_SIDEBAR_HOVER, sidebarHover: false });

  function sidebarMenuContent(menu) {
    return (<>
      {(menu.children && menu.children.length) ?
        <div className={clsx({ 'app-sidebar-nav-close': sidebarToggle && !sidebarHover })}>
          <SidebarMenu component="div" key={menu.id} pages={menu.children} title={""} modules={loginData.modules} className="nav-wrapper" />
        </div> :
        <div className={clsx({ 'app-sidebar-nav-close': sidebarToggle && !sidebarHover })}>
        </div>
      }</>
    )
  }

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" open={sidebarToggleMobile} onClose={closeDrawer} variant="temporary" elevation={4} className="app-sidebar-wrapper-lg">
          <SidebarHeader userData={loginData} />
          <PerfectScrollbar component="div">
            {sidebarMenuContent(dashboardMenu)}
          </PerfectScrollbar>
        </Drawer>
      </Hidden>
      <Hidden mdDown >
        <Paper className={clsx('app-sidebar-wrapper', {
          'app-sidebar-wrapper-close': sidebarToggle,
          'app-sidebar-wrapper-open': sidebarHover,
          'app-sidebar-wrapper-fixed': sidebarFixed
        })}
          square open={sidebarToggle} elevation={sidebarShadow ? 11 : 3}>
          <SidebarHeader userData={loginData} />
          <div className={clsx({ 'app-sidebar-collapsed': sidebarToggle && !sidebarHover })} >
            <PerfectScrollbar component="div" options={{ wheelPropagation: false }} style={{ 'minHeight': '90vh', 'maxHeight': '90vh' }} >
              {sidebarMenuContent(dashboardMenu)}
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </>
  );
};



export default Sidebar;
