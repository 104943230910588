import React from 'react';
import { useDispatch } from 'react-redux'
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/PageTitle';
import CardRouter from '../../components/GenericCard';
import actions from '../../redux/actions';
import enums from '../../utils/constants';
import utils from '../../utils'
import useMobile from '../../utils/useMobile';
const t = utils.t;
const { childRoutes } = enums;

const TrueGateway = React.memo(props => {
    const { t: trans, i18n } = useTranslation();
    const tOpts = { t: trans, i18n };
    const dispatch = useDispatch();
    const childPages = childRoutes.TrueGateway;
    const isMobile = useMobile();

    React.useEffect(() => {
        dispatch({
            type: actions.SET_PAGE_TITLE_DETAILS,
            pageTitleDetails: <PageTitle titleHeading="True Gateway" titleDescription="" icon="" title="True Gateway" classTitle="home-header-container" />
        });
        dispatch({
            type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                filterButton: "",
                filterButtonName: "",
                printButtonName: "",
                clear: null,
                apply: null,
                hidden: { search: true, operation: true, export: true, print: true, filter: true }
            }
        });

        return () => {
            dispatch({
                type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
            })
        }
    });


    return (
        <Grid className={`mb-1 ${!isMobile.tablet ? (isMobile.portrait ? "mt-2" : "") : (isMobile.tablet && isMobile.portrait ? "mt-1" : "mt-n2")}`} container spacing={1}>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={(isMobile.mobile && !isMobile.tablet && isMobile.portrait) ? "ml-4" : ''} variant="h6">{t('Pages', tOpts)}</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <CardRouter childObj={childPages} />
            </Grid>

        </Grid>
    )
});

export default TrueGateway;