import React from 'react';
import { useTranslation } from 'react-i18next';
import utils from "../../utils"

const ListRecordCount = React.memo(function ListRecordCount({ icon, count, displayText }) {
    const { t: T, i18n } = useTranslation();
    const tOpts = { t: T, i18n };
    const t = utils.t;

    return (
        <span className="text-theme-blue"><img src={icon} alt={t(displayText, tOpts)} /> &nbsp; {t("Showing", tOpts)} <span className="list-record-count-label"> {count} </span> {t(displayText, tOpts)}</span>
    )
})

export default ListRecordCount;