
import React, { useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, Switch, Grid } from '@material-ui/core';
import { Chart as ChartJs, Bar, HorizontalBar, Pie } from 'react-chartjs-2';
import ChartLabels from "chartjs-plugin-datalabels";
import { useTranslation } from "react-i18next";
import utils from "../../utils";
const t = utils.t;
ChartJs.plugins.register(ChartLabels);
const colors = utils.chartColors;

const defaultOptions = {
  bar: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        offset: true,
        ticks: {
          beginAtZero: true
        },
        scaleLabel: {
          display: false,
          labelString: ''
        }
      }],
      xAxes: [{
        maxBarThickness: 100,
        scaleLabel: {
          display: false,
          labelString: ''
        }
      }]
    },
    stacked: false,
    legend: {
      display: true,
      position: "top"
    },
    layout: {
      padding: {
        top: 20
      }
    }
  },
  pie: {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom"
    },
    layout: {
      padding: {
        top: 10
      }
    }
  }
}

const toggleSettings = ({ unit = "", fontSize = 12 }) => {
  return ({
    "onlyValuesBar": {
      showToolTips: false,
      tooltips: {
        enabled: false
      },
      plugins: {
        datalabels: {
          display: true,
          align: 'end',
          anchor: 'end',
          formatter: function (value, context) {
            value = (value === '') ? 'N/A' : Number(value);
            value = ((value % 1 === 0) ? value : value.toFixed(1));
            value = `${value}${unit}`;
            return value;
          },
          borderRadius: 4,
          color: 'white',
          backgroundColor: function (context) {
            return context.dataset.borderColor;
          },
          padding: 3,
          offset: 2
        }
      }
    },
    "onlyValuesPie": {
      showToolTips: false,
      tooltips: {
        enabled: false
      },
      plugins: {
        datalabels: {
          display: true,
          anchor: 'center',
          color: 'white',
          font: {
            size: fontSize,
            weight: 'bold'
          }
        }
      },
    },
    "showAllBar": {
      tooltips: {
        enabled: true,
        caretPadding: 50,
        callbacks: {
          label: function (tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label + ": ";
            let value = Number(tooltipItem.value);
            value = ((value % 1 === 0) ? value : value.toFixed(1));
            value = `${label}${value}${unit}`;
            return value;
          }
        }
      },
      plugins: {
        datalabels: {
          display: false
        }
      }
    },
    "showAllPie": {
      tooltips: {
        enabled: true,
        caretPadding: 50
      },
      plugins: {
        datalabels: {
          display: false
        }
      }
    },
    "toolTipAndValuesPie": {
      showToolTips: true,
      tooltips: {
        enabled: true
      },
      plugins: {
        datalabels: {
          display: true,
          anchor: 'center',
          color: 'white',
          font: {
            size: fontSize,
            weight: 'bold'
          }
        }
      },
    },
  })
}

function PieChart({ data = [], config = {} }) {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [toolTipChecked, setToolTipChecked] = React.useState(true);
  const [chartOptions, setChartOptions] = useState({ ...defaultOptions.pie });
  const { t: translate, i18n } = useTranslation();
  const tOpts = { t: translate, i18n }; 

  useEffect(() => {
    const localChartData = {
      labels: [],
      data: [],
      backgroundColor: [],
      borderColor: []
    };
    let i = 1;
    for (const element of data) {
      localChartData.labels.push(t(element[config.labelName] || ('Blank'), tOpts));
      localChartData.data.push(element[config.dataName]);
      if (!element[config.labelName]) {
        localChartData.backgroundColor.push(colors[0].bg);//Red
        localChartData.borderColor.push(colors[0].br);//Red
      } else {
        localChartData.backgroundColor.push(colors[i].bg);
        localChartData.borderColor.push(colors[i].br);
        if (i === (colors.length - 1))
          i = 1
        else
          i++
      }
    }

    setChartData({
      labels: localChartData.labels, datasets: [{
        label: config.chartName,
        data: localChartData.data,
        backgroundColor: localChartData.backgroundColor,
        borderColor: localChartData.borderColor,
        borderWidth: 1
      }]
    });
  }, [data])

  useEffect(() => {
    const toggleSettingsL = toggleSettings({ unit: config.unit, fontSize: config.fontSize });
    let optionsL = {}
    if (config.toolTipToggle) {
      optionsL = { ...defaultOptions.pie, ...toggleSettingsL[toolTipChecked ? "onlyValuesPie" : "showAllPie"] };
    } else {
      optionsL = { ...defaultOptions.pie, ...toggleSettingsL[config.defaultToggle] };
    }

    if (config.legendPosition) {
      optionsL.legend.position = config.legendPosition;
    }

    setChartOptions(optionsL);
  }, [toolTipChecked])

  return <>
    {config.toolTipToggle && <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormGroup>
          <FormControlLabel control={<Switch defaultChecked checked={toolTipChecked} onChange={() => setToolTipChecked(!toolTipChecked)} />} label={t("Tooltip Values", tOpts)} />
        </FormGroup>
      </Grid>
      <Grid item xs={6}> </Grid>
    </Grid>}
    <Grid container spacing={2} className={config.chartContainerClass}>
      <Pie data={chartData} options={{ ...chartOptions, onClick: (event, elements) => { onClick({ event, elements, config, data }) } }} />
    </Grid>
  </>
}

function BarChart({ data = [], config = {} }) {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [toolTipChecked, setToolTipChecked] = React.useState(true);
  const [chartOptions, setChartOptions] = useState({ ...defaultOptions.bar });
  const { t: translate, i18n } = useTranslation();
  const tOpts = { t: translate, i18n }; 

  useEffect(() => {
    const localChartOptions = { ...chartOptions };
    if (config.xAxisName) {
      localChartOptions.scales.xAxes[0].scaleLabel.display = true;
      localChartOptions.scales.xAxes[0].scaleLabel.labelString = config.xAxisName;
    } else {
      localChartOptions.scales.xAxes[0].scaleLabel.display = false;
    }
    if (config.yAxisName) {
      localChartOptions.scales.yAxes[0].scaleLabel.display = true;
      localChartOptions.scales.yAxes[0].scaleLabel.labelString = config.yAxisName;
    } else {
      localChartOptions.scales.yAxes[0].scaleLabel.display = false;
    }

    localChartOptions.scales.xAxes[0].gridLines = { display: Boolean(config.gridLines.x) };
    localChartOptions.scales.yAxes[0].gridLines = { display: Boolean(config.gridLines.y) };

    if (config.tab === 'outlet') {
      localChartOptions.scales.yAxes[0].stacked = true;
      localChartOptions.scales.xAxes[0].stacked = true;
      setToolTipChecked(false)
    } else {
      localChartOptions.scales.xAxes[0].stacked = false;
      localChartOptions.stacked = false
    }

    if (config.legend === 'hidden') {
      localChartOptions.legend.display = false;
    } else {
      localChartOptions.legend.display = true;
    }

    setChartOptions(localChartOptions);
  }, [])

  useEffect(() => {
    if (config.tab === 'outlet') {
      const localChartData = {
        labels: [],
        dataFull: [],
        dataEmpty: [],
        keyData:[]
      };
      for (const element of data) {
        let labelName = element[config.labelName].split('_')[0];
        let keyName= element[config.labelName].split('_')[0];
        if (config.labelMap && config.labelMap.length) {
          labelName = config.labelMap.filter((item) => item.id === labelName);
          labelName = labelName[0];
          keyName = labelName.id
          labelName = labelName.label
        }
        localChartData.keyData.push(keyName);
        localChartData.labels.push(t(labelName, tOpts));
        const roundUpVal = Math.ceil(element[config.dataName]);
        localChartData.dataEmpty.push(roundUpVal);
        localChartData.dataFull.push(100 - roundUpVal);
      }

      setChartData({
        labels: localChartData.labels,
        keyData: localChartData.keyData,
        datasets: [{
          label: t('% of data filled', tOpts),
          labelWithoutTranlsation: '% of data filled',
          data: localChartData.dataFull,
          borderColor: colors[2].br,//green
          backgroundColor: colors[2].bg,
          borderWidth: 2,
          borderRadius: 5,
          borderSkipped: false,
        },
        {
          label: t('% of data empty', tOpts),
          labelWithoutTranlsation: '% of data empty',
          data: localChartData.dataEmpty,
          borderColor: colors[0].br, //Red
          backgroundColor: colors[0].bg,
          borderWidth: 2,
          borderRadius: 5,
          borderSkipped: false,
        }]
      });
    } else if (config.tab === 'product') {
      const localChartData = {
        labels: [],
        data: [],
        bgColor: [],
        brColor: []
      };
      for (const element of data) {
        localChartData.labels.push(element[config.labelName] + '');
        localChartData.data.push(element[config.dataName]);
        if (element[config.labelName] === 0) {
          localChartData.bgColor.push(colors[0].bg);//Red
          localChartData.brColor.push(colors[0].br);
        } else if (element[config.labelName] === 1) {
          localChartData.bgColor.push(colors[4].bg);
          localChartData.brColor.push(colors[4].br);
        } else {
          localChartData.bgColor.push(colors[2].bg);
          localChartData.brColor.push(colors[2].br);
        }
      }

      setChartData({
        labels: localChartData.labels, datasets: [{
          label: config.chartName,
          data: localChartData.data,
          product: localChartData.product,
          borderColor: localChartData.brColor,
          backgroundColor: localChartData.bgColor,
          borderWidth: 2,
          borderSkipped: false,
        }]
      });
    } else {
      const localChartData = {
        labels: [],
        data: []
      };
      for (const element of data) {
        localChartData.labels.push(element[config.labelName]);
        const value = element[config.dataName];
        localChartData.data.push((value % 1 === 0) ? value : value.toFixed(1));
      }

      const colorIndex = config.colorIndex || 1;
      setChartData({
        labels: localChartData.labels, datasets: [{
          label: config.chartName,
          data: localChartData.data,
          borderColor: colors[colorIndex].br,
          backgroundColor: colors[colorIndex].bg,
          borderWidth: 2,
          borderSkipped: false
        }]
      });
    }
  }, [data])

  useEffect(() => {
    const unitL = (config.unit || ''), toggleSettingsL = toggleSettings({ unit: unitL, fontSize: config.fontSize });
    const optionsL = { ...defaultOptions.bar, ...toggleSettingsL[toolTipChecked ? "onlyValuesBar" : "showAllBar"] };
    if (config.tab === 'outlet') {
      optionsL.plugins.datalabels.formatter = function (value, context) {
        value = ((value === '') ? 'N/A' : Number(value).toFixed(1))
        return value;
      }
    } else {
      optionsL.plugins.datalabels.formatter = function (value, context) {
        value = (value === '') ? 'N/A' : Number(value);
        value = ((value % 1 === 0) ? value : value.toFixed(1));
        value = `${value}${unitL}`;
        return value;
      }
    }

    setChartOptions(optionsL);
  }, [toolTipChecked])

  return <>
    {config.toolTipToggle && <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormGroup>
          <FormControlLabel control={<Switch defaultChecked checked={toolTipChecked} onChange={() => setToolTipChecked(!toolTipChecked)} />} label={t("Tooltip Values", tOpts)} />
        </FormGroup>
      </Grid>
      <Grid item xs={6}> </Grid>
    </Grid>}
    <Grid container spacing={2} className={config.chartContainerClass}>
      {config.horizontal ?
        <HorizontalBar data={chartData} options={{ ...chartOptions, onClick: (event, elements) => { onClick({ event, elements, config, data }) } }} /> :
        <Bar data={chartData} options={{ ...chartOptions, onClick: (event, elements) => { onClick({ event, elements, config, data }) } }} />}
    </Grid>
  </>
}

function onClick({ event, elements, data, config }) {
  const { onElementClick, gridFilterKey, customGetValue, useDataSet = false } = config;
  const element = elements[0];
  if (!(typeof onElementClick === "function" && element)) {
    return;
  }
  let dataSet = { key: '', value: '', operator: '', product:'' };
  if (useDataSet) {
    const chart = element._chart;
    const elementAtEvent = chart.getElementAtEvent(event)[0];
    if (typeof customGetValue === 'function') {
      dataSet = Object.assign(dataSet, customGetValue({ chart, element: elementAtEvent }) || {});
    } else {
      const dataset = chart?.data?.datasets[element._datasetIndex];
      dataSet.key = chart?.data?.labels[element._index];
      dataSet.value = dataset?.data[element._index];
    }
  } else {
    const chart = element._chart;
    dataSet.key = gridFilterKey || '';
    const result = data[element._index];
    dataSet.value = result[gridFilterKey];  
    dataSet.product = elements[0]._index+1;
  }
  onElementClick(dataSet)
}
export { PieChart, BarChart };