import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import utils from "../../utils"


export default function MuiTypography({ variant = "h2", component = "h2", text = '', name = null, ...rest }) {
    const t = utils.t;
    const trans = useTranslation();
    const tOpts = { t: trans.t, i18n: trans.i18n }
    return (<Typography variant={variant} component={component} {...rest}>
        {text && t(text, tOpts) || ''} {name && name}
    </Typography>)
}