import ReactHighCharts from "../../components/ReactHighCharts"
import { useSelector } from 'react-redux';
import utils from "../../utils"
import inFieldStatusModel from '../in-field-status';
import { useState } from "react";
const { isModuleExists } = utils;


const defaultCharts = [
    {
        "title": "Top 3 In Field Status Responsible",
        "chartType": "pie",
        "dataKey": "chart1702364008596",
        "selectBy": {
            "label": "Top 3",
            "value": "3,DESC",
            "ScopeId": 0
        },
        "sourceType": "InFieldStatus",
        "params": {
            "xAxis": [
                {
                    "title": "Responsible",
                    "id": "Responsible"
                }
            ],
            "yAxis1": [
                {
                    "title": "In Field Status",
                    "id": "InFieldStatus"
                }
            ],
            "groupBy": ""
        }
    },
    {
        "title": "Top 15 In Field Status Hardware Status",
        "chartType": "column",
        "dataKey": "chart1702380078083",
        "selectBy": {
            "label": "Top 15",
            "value": "15,DESC",
            "ScopeId": 0
        },
        "sourceType": "InFieldStatus",
        "params": {
            "xAxis": [
                {
                    "title": "Hardware Status",
                    "id": "HardwareStatus"
                }
            ],
            "yAxis1": [
                {
                    "title": "In Field Status",
                    "id": "InFieldStatus"
                }
            ],
            "groupBy": ""
        }
    },
    {
        "title": "Top 10 In Field Status Primary Sales Rep",
        "chartType": "bar",
        "dataKey": "chart1702380104076",
        "selectBy": {
            "label": "Top 10",
            "value": "10,DESC",
            "ScopeId": 0
        },
        "sourceType": "InFieldStatus",
        "params": {
            "xAxis": [
                {
                    "title": "Primary Sales Rep",
                    "id": "PrimaryRepName"
                }
            ],
            "yAxis1": [
                {
                    "title": "In Field Status",
                    "id": "InFieldStatus"
                }
            ],
            "groupBy": ""
        }
    }
]


const InFieldStatusWithCharts = () => {
    const userData = useSelector(state => state.appReducer.userData) || {};
    const [customFilters, setCustomFilters] = useState({});
    let crudPermissions = false;
    let preferenceName = 'InFieldStatusCharts'
    
    let filterOptions = [
        { fieldName: 'PrimaryRepName', placeHolderValue: 'Primary Rep Name', type: 'dropdown', isVisible: true },
        { fieldName: 'HardwareStatus', placeHolderValue: 'Hardware Status', type: 'dropdown', isVisible: true, isLookupFilter: true },
        { fieldName: 'Responsible', placeHolderValue: 'Responsible', type: 'dropdown', isVisible: true, isLookupFilter: true },
        { fieldName: 'InFieldStatusChartsSortingFilter', isVisible: false, isLookupFilter: true, type: 'sorting' }
    ]
    if (isModuleExists(userData, userData?.modules?.InfieldStatusCharts?.Name)) {
        crudPermissions = true;
    }

    return (
        <div>
            <ReactHighCharts showFilters={true}
                crudPermission={crudPermissions}
                preference={preferenceName}
                specificSourceType={'InFieldStatus'}
                setCustomFilters={setCustomFilters}
                defaultCharts={defaultCharts}
                filterOptions={filterOptions}
            />
            <inFieldStatusModel.Grid customFilters={customFilters} />
        </div>

    )
}

export default InFieldStatusWithCharts;