import React from 'react';
import { Select, FormControl, MenuItem } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import utils from "../../utils";

const t = utils.t

const SelectMonth = React.memo(function SelectMonth({ options, selected, handelSelectMonth }) {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };

    return (
        <FormControl variant="outlined" fullWidth={true}>
            <Select name="month" MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                getContentAnchorEl: null
            }} className="filter-select months" value={selected || (options.length > 0 && options[0]["monthNum"])} onChange={handelSelectMonth}>
                {options.map(d => <MenuItem key={d.month} value={d.monthNum}>{t(d.month, tOpts)}</MenuItem>)}
            </Select>
        </FormControl>
    )
})

export default SelectMonth;