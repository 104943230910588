import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { useDispatch } from 'react-redux';
import { Box, MenuItem, FormControl, Select, Button, Container, Grid, InputLabel, Input } from '@mui/material';
import ChartPreviewModel from './chartPreviewModel';
import { request, apis } from "../../httpUtil";
import { useHistory } from 'react-router-dom';
import common from './common';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewListIcon from '@mui/icons-material/ViewList';
import swal from 'sweetalert';
import {
    DataGridPremium,
    GridColumnMenu
} from '@mui/x-data-grid-premium';
import utils from '../../utils'
import { useTranslation } from 'react-i18next';
import HCColorAxis from 'highcharts/modules/coloraxis';

const defaultPageSize = 5;

HCColorAxis(Highcharts); // Initialize the coloraxis module
const ReactHighCharts = ({
    showFilters = true,
    crudPermission = false,
    preference = '',
    specificSourceType = '',
    defaultCharts = {},
    setCustomFilters = {},
    filterOptions = [],
}) => {

    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [charts, setCharts] = useState([]);
    const [seletcedChartIndex, setSelectedChartIndex] = useState(null);
    const [chartsData, setChartsData] = useState([]);
    const [sourceTypes, setSourceTypes] = useState([]);
    const [chartCreactionDropDownFilters, setChartCreactionDropDownFilters] = useState({});
    const [selectedSourceType, setSelectedSourceType] = useState('');
    const [valueColumns, setValueColumns] = useState([]);
    const [groupColumns, setGroupColumns] = useState([]);
    const [filterDropdownOptions, setFilterDropdownOptions] = useState([]);
    const [selectedChartFilters, setSelectedChartFilters] = useState([]);
    const [selectedCustomFilter, setSelectedCustomFilter] = useState({});
    const [selectedDropDownFilterValues, setSelectedDropDownFilterValues] = useState({});
    const [reflowKey, setReflowKey] = useState(0);
    const [chartsReFlowKey, setChartsReFlowKey] = useState(0);
    const [isSingleSource, setIsSingleSource] = useState(false);
    const [paginationModel, setPaginationModel] = useState({ pageSize: defaultPageSize, page: 0 });
    const [isFilterBtnsDisabled, setIsFilterBtnsDisabled] = useState(true);

    const dispatch = useDispatch();
    const history = useHistory();
    const chartJsonKeys = ['line', 'pie', 'column', 'bar'];
    let dragOverIndex;
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };

    function CustomColumnMenu(props) {
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    //   Hide `columnMenuColumnsItem`
                    columnMenuColumnsItem: null,
                    columnMenuGroupingItem: null,
                    columnMenuFilterItem: null
                }}
            />
        );
    }


    useEffect(() => {
        if (valueColumns.length !== 0 && groupColumns.length !== 0) {
            return
        };
        getDropDownValues();
    }, [])

    useEffect(() => {
        if (!selectedSourceType) {
            return
        };
        sourceTypes.forEach((el) => {
            if (el.id === selectedSourceType) {
                setValueColumns(el.valueColumns);
                setGroupColumns(el.groupColumns);
            }
        })
    }, [selectedSourceType])

    const getChartData = async () => {
        if (!sourceTypes) {
            return
        }
        const response = await request({ url: apis.getChartsData, params: { chartParams: charts }, history, dispatch, jsonPayload: true });
        let chartsData = common.getChartsObjectFromResponse(charts, response.data, false);
        setChartsData(chartsData);
    }

    useEffect(() => {
        getChartData()
    }, [charts])

    const getDropDownValues = async () => {
        const response = await request({ url: apis.getChartsDropdown, params: { filterOptions }, history, dispatch, jsonPayload: true });
        if (response.success) {
            if (specificSourceType) {
                let source = response.sourceTypes.filter(source => source.id === specificSourceType);
                if (source.length == 1) {
                    setSelectedSourceType(source[0].id)
                    setValueColumns(source[0].valueColumns);
                    setGroupColumns(source[0].groupColumns);
                    setIsSingleSource(true)
                }
                setSourceTypes(source);
            } else {
                setSourceTypes(response.sourceTypes);
            }
            let sorting = filterOptions.filter(el => el.type == 'sorting')
            setChartCreactionDropDownFilters(response.combos[sorting[0].fieldName])
            setFilterDropdownOptions([response?.combos])
        };
    }

    const getFilteredCharts = async () => {
        let selectedCustomFilterClone = JSON.parse(JSON.stringify(selectedCustomFilter))
        setCustomFilters(selectedCustomFilterClone)
        const response = await request({ url: apis.getChartsData, params: { chartParams: charts, filters: selectedChartFilters }, history, dispatch, jsonPayload: true });
        let chartData = common.getChartsObjectFromResponse(charts, response.data, false)
        setChartsData(chartData)
    };

    const revertDefaultCharts = () => {
        swal({
            title: `${t('Are you sure?', tOpts)}`,
            text: `${t('This action will load default charts', tOpts)}`,
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                if (Object.keys(defaultCharts).length != 0) {
                    setCharts(defaultCharts)
                }
            } else {
                return
            }
        });

    };



    useEffect(() => {
        if (Object.keys(selectedDropDownFilterValues).length === 0) {
            setIsFilterBtnsDisabled(true)
        } else {
            setIsFilterBtnsDisabled(false)
        }
    }, [selectedDropDownFilterValues]);

    const handleClearFilters = () => {
        getChartData();
        setCustomFilters({ clear: true })
        setSelectedChartFilters([])
        setSelectedCustomFilter({})
        setSelectedDropDownFilterValues({})
    };


    const handleOpenPopup = (e, index) => {
        setSelectedChartIndex(index);
        setIsDropDownOpen(true);
    };

    const handleDeleteChart = (e, index) => {
        swal({
            title: `${t('Are you sure?', tOpts)}`,
            text: `${t('This action cannot be undone', tOpts)}`,
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                const updatedCharts = [...charts];
                const updatedChartsData = [...chartsData];
                if (index > -1) {
                    // only splice array when item is found
                    updatedCharts.splice(index, 1); // 2nd parameter means remove one item only
                    updatedChartsData.splice(index, 1);
                }
                setCharts(updatedCharts);
                setChartsData(updatedChartsData);
            } else {
                return
            }
        });

    }

    const getChartsFromPreference = async () => {
        let params = {
            action: 'list',
            id: preference
        }
        const response = await request({ url: apis.ChartPreference, params, history, dispatch });
        if (response && response.totalCount > 0) {
            let userPreferenceCharts = JSON.parse(response?.preferences[response.preferences.length - 1]?.prefValue);
            setCharts(userPreferenceCharts.charts);
        } else {
            if (Object.keys(defaultCharts).length != 0) {
                setCharts(defaultCharts)
            }
        }

    }

    useEffect(() => {
        getChartsFromPreference();
    }, [])

    const handleSavePreference = () => {
        const savePreference = async () => {
            let params = {
                action: 'save',
                id: preference,
                prefName: `${preference} Charts`,
                prefDesc: `${preference} Charts`,
                prefValue: { charts },
                isDefault: true,
            }
            const response = await request({ url: apis.ChartPreference, params, history, dispatch });
            if (response) {
                swal(`${t("Preference Saved", tOpts)}`, {
                    icon: "success",
                });
            }
        }
        savePreference();

    }

    const handleClosePopup = () => {
        setIsDropDownOpen(false);
        setSelectedChartIndex(null)
        if (!isSingleSource) {
            setGroupColumns([])
            setValueColumns([])
        }
    };
    const handleSwitchBetweenChartAndGrid = (e, index, dataLength) => {
        if (!dataLength) {
            return
        }
        const updatedChartsData = [...chartsData];
        updatedChartsData[index].isGrid = !updatedChartsData[index].isGrid;
        setChartsData(updatedChartsData);
    };
    const dragEnd = (e, index) => {
        if (index == dragOverIndex) {
            return
        }
        spliceCharts(index)
    }

    const spliceCharts = (index) => {
        //currenlty the following code is commented
        //it enables the user to save the order of charts too but requires load every time user drags and drops to change the order of charts
        // const updatedCharts = [...charts];
        const updatedChartsData = [...chartsData];
        // const [draggedItemCharts] = updatedCharts.splice(index, 1);
        const [draggedItemChartsData] = updatedChartsData.splice(index, 1);
        // updatedCharts.splice(dragOverIndex, 0, draggedItemCharts);
        updatedChartsData.splice(dragOverIndex, 0, draggedItemChartsData);
        // setCharts(updatedCharts);
        setChartsData(updatedChartsData);
        setChartsReFlowKey(chartsReFlowKey + 1)
    }

    const dragOver = (e, index) => {
        e.preventDefault();
        dragOverIndex = index
    }

    const exportToCSV = (data) => {
        if (!data) {
            return
        }
        const headers = Object.keys(data[0]);
        const filteredColumns = headers.filter(column => column !== 'id');
        const formattedColumns = filteredColumns.map(column =>
            column.replace(/([a-z])([A-Z])/g, '$1 $2')
        );
        const csvContent = "data:text/csv;charset=utf-8," +
            formattedColumns.join(",") + "\n" + data.map(obj => filteredColumns.map(key => obj[key]).join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
    };

    const handleFilterChange = (value, property) => {
        // Create a copy of filt to maintain immutability
        const updatedFilt = { ...selectedDropDownFilterValues };
        updatedFilt[property] = value;
        setSelectedDropDownFilterValues(updatedFilt);
        let filterVal = value.Name ? value.Name : value.label
        const chartFilterObject = {
            field: property,
            value: typeof value === 'object' ? (value.Id ? value.Id : filterVal) : value,
        };

        let modifiedChartsFilters = selectedChartFilters.filter(el => el.field !== property)
        const updatedChartFilters = [...modifiedChartsFilters, chartFilterObject];
        setSelectedChartFilters(updatedChartFilters);

        const updatedCustomFilter = { ...selectedCustomFilter };
        updatedCustomFilter[property] = typeof value === 'object' ? filterVal : value;
        setSelectedCustomFilter(updatedCustomFilter);
        setReflowKey(reflowKey + 1)
    };

    return (

        <div className='container'>
            <Grid className={`mb-5`} justifyContent="space-between" alignItems='center' container spacing={1}>
                {
                    showFilters &&
                    <Grid className='charts-filters-container' key={reflowKey}>
                        {
                            filterDropdownOptions && filterDropdownOptions.length !== 0 &&
                            filterOptions.map((el, index) => (
                                el && el.isVisible &&
                                <FormControl className='w-25 p0' >
                                    <InputLabel id={`select-small-label-${index}`}>{`${t(el.placeHolderValue, tOpts)}`}</InputLabel>
                                    <Select
                                        variant="outlined"
                                        labelId={`select-small-label-${index}`}
                                        displayEmpty
                                        key={selectedDropDownFilterValues[el.fieldName]}
                                        value={selectedDropDownFilterValues[el.fieldName]}
                                        onChange={(e) => handleFilterChange(e.target.value, el.fieldName)}
                                        input={<Input />}
                                    >
                                        {filterDropdownOptions[0][el.fieldName]?.map((value, innerIndex) => (
                                            <MenuItem value={value} key={innerIndex}>
                                                {value.Name || value.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ))
                        }
                        <Button disabled={isFilterBtnsDisabled} variant="contained" color="primary" className="background-theme-blue" onClick={getFilteredCharts} >{t("Search", tOpts)}</Button>
                        <Button disabled={isFilterBtnsDisabled} variant="contained" color="primary" className="mr-2 background-theme-blue" onClick={handleClearFilters} >{t("Clear", tOpts)}</Button>
                    </Grid>
                }
                {
                    crudPermission &&
                    <Grid alignItems='flex-end'>
                        <Button variant="contained" color="primary" className="mr-2 background-theme-blue" onClick={revertDefaultCharts} >{t("Revert", tOpts)}</Button>
                        <Button variant="contained" color="primary" className="mr-2 background-theme-blue" onClick={handleSavePreference} >{t("Save Preference", tOpts)}</Button>
                        <Button variant="contained" color="primary" className="background-theme-blue" onClick={handleOpenPopup} >{t("Add Chart", tOpts)}</Button>
                    </Grid>
                }

            </Grid>
            <Container >
                {chartsData && chartsData.map((item, index) => {
                    if (!item || Object.keys(item).length === 0) {
                        return null;
                    }
                    const isGrid = item.isGrid;
                    return (
                        <Box key={index} sx={{ m: 2 }} onDragEnd={(e) => { dragEnd(e, index) }} draggable>
                            <Grid className='d-flex justify-content-end bg-white p-2' sx={{ border: '0.2px solid #d6d6d6', }} spacing={1}>
                                <div style={{ cursor: 'pointer' }}>
                                    <img onClick={(e) => { exportToCSV(item?.gridData[0]?.data) }} src={require(`../../assets/images/excel.png`)} height='25' alt={"Export"} />
                                </div>
                                {!isGrid && crudPermission &&
                                    <EditIcon onClick={(e) => { handleOpenPopup(e, item.chartDataKey) }} className='charts-action-btns' />
                                }
                                <ViewListIcon onClick={(e) => { handleSwitchBetweenChartAndGrid(e, index, item?.gridData[0]?.data?.length) }} className='charts-action-btns' />
                                {crudPermission &&
                                    <DeleteIcon onClick={(e) => { handleDeleteChart(e, index) }} className='charts-action-btns' />
                                }
                            </Grid>
                            <Grid sx={{ border: '0.2px solid #d6d6d6' }} onDragOver={(e) => { dragOver(e, index) }}>
                                {isGrid ?
                                    <DataGridPremium
                                        sx={{
                                            backgroundColor: 'white',
                                            "& .MuiTablePagination-selectLabel": {
                                                marginTop: 2
                                            },
                                            "& .MuiTablePagination-displayedRows": {
                                                marginTop: 2
                                            }
                                        }}
                                        density="compact"
                                        pageSizeOptions={[5, 10, 20, 50, 100]}
                                        pagination
                                        disableRowSelectionOnClick={true}
                                        paginationModel={paginationModel}
                                        onPaginationModelChange={setPaginationModel}
                                        rows={item.gridData[0].data}
                                        columns={item.gridData[0].column}
                                        sortingMode='client'
                                        paginationMode='client'
                                        columnVisibilityModel={{
                                            id: false
                                        }}
                                        autoHeight
                                        disableAggregation
                                        slots={{ columnMenu: CustomColumnMenu }}
                                        slotProps={{
                                            footer: {
                                                pagination: true,

                                            }
                                        }}
                                    /> :
                                    <HighchartsReact highcharts={Highcharts} options={item} key={chartsReFlowKey} />
                                }

                            </Grid>
                        </Box>
                    )
                })
                }
            </Container >
            <Modal open={isDropDownOpen}>
                <ChartPreviewModel
                    chartTypesArray={chartJsonKeys}
                    handleClosePopup={handleClosePopup}
                    setCharts={setCharts}
                    isSingleSource={isSingleSource}
                    chartIndexValue={seletcedChartIndex}
                    setChartsData={setChartsData}
                    chartsData={chartsData}
                    valueColumns={valueColumns}
                    groupColumns={groupColumns}
                    sourceTypes={sourceTypes}
                    setSelectedSourceType={setSelectedSourceType}
                    selectedSourceType={selectedSourceType}
                    dropDownFilters={chartCreactionDropDownFilters}
                    chartItems={charts}>
                </ChartPreviewModel>
            </Modal>
        </div >
    )
}

export default ReactHighCharts