import React, { useEffect } from 'react';
import PageTitle from '../../components/PageTitle'
import Outlets from '../../components/Outlets'
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import actions from '../../redux/actions';
import utils from "../../utils"

const t = utils.t

export default function VistaLocationDetails() {
  const dispatch = useDispatch();
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  useEffect(() => {
    dispatch({
      type: actions.SET_PAGE_TITLE_DETAILS,
      pageTitleDetails: <PageTitle titleHeading={t('Outlets', tOpts)} titleDescription={t('Insights into your outlets, their performance and more', tOpts)} title={t('Outlets', tOpts)} />
    });

    return () => {
      dispatch({
        type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
      })
    }
  }, [])

  return (<Outlets />)
}