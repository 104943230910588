import { ToggleButtonGroup, ToggleButton } from '@mui/material';


const ChartToggleButtonGroup = ({ activeTab, handleTabChange, tabs , t, tOpts}) => (
  <ToggleButtonGroup
    value={activeTab}
    exclusive
    onChange={handleTabChange}
    aria-label="text alignment"
    className='toggle-button-group'
    size='medium'
  >
    {tabs.map((tab, index) => {
      return (
        <ToggleButton
          key={index}
          value={tab.value}
          aria-label={`${tab.value} aligned`}
          className='toggle-button'
          style={{
            color: activeTab === tab.value ? '#5383ff' : 'black',
          }}
        >
          {t(tab.text,tOpts)}
        </ToggleButton>
      )
    })}
  </ToggleButtonGroup>
);

export default ChartToggleButtonGroup;