import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import { getGridNumericOperators } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, CardContent, Typography, Grid, Tabs, Tab } from "@material-ui/core";
import GridBase from "../GridBase";
import utils from '../../utils'
import dateUtil from '../../utils/date';
import { useTranslation } from "react-i18next";
import { apis, request } from "../../httpUtil";
import Commands from './commands';
import SensorReadings from './sensorReadings';
import useMobile from '../../utils/useMobile';
dayjs.extend(utc);
dayjs.extend(timezone);

const t = utils.t;

let nodaTimeZone = '';
const dateTimeFormat = dateUtil.formats.dateTimeIn;
const renderer = ({ value, row = {} }) => {
    const { GenericAbbreviation } = row;
    return dayjs.tz(value, nodaTimeZone).format(`${utils.systemDateTimeFormat()} ${GenericAbbreviation || ''}`);
}
const valueRenderer = ({ value }) => {
    //Number(value).toFixed(2) was rounding to the nearest whole number after the decimal point, that's why we use below instead
    return value ? (Math.floor(value * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2) : '';
}
const sensorTypeMappings = {
    'tempSensor1': 'Cabinet Temperature',
    'tempSensor2': 'Liquid Line Temperature',
    'tempSensor3': 'Ambient Temperature',
    'motion': 'Motion Sensor',
    'humidity': 'Humidity',
    'doorSensor': 'Door Sensor',
    'compSensor': 'Compressor Sensor',
    'doorOpenAlarm': 'Door Open Alarm'
}
const eventTypeMappings = {
    'tempSensor1_FaultOpen': 'Analog tempSensor1 Fault Open',
    'tempSensor2_FaultOpen': 'Analog tempSensor2 Fault Open',
    'tempSensor3_FaultOpen': 'Analog tempSensor3 Fault Open',
    'tempSensor1_FaultShorted': 'Analog tempSensor1 Fault Shorted',
    'tempSensor2_FaultShorted': 'Analog tempSensor2 Fault Shorted',
    'tempSensor3_FaultShorted': 'Analog tempSensor3 Fault Shorted',
    'tempSensor1_High': 'Analog tempSensor1 High Alert',
    'tempSensor2_High': 'Analog tempSensor2 High Alert',
    'tempSensor3_High': 'Analog tempSensor3 High Alert',
    'tempSensor1_Low': 'Analog tempSensor1 Low Alert',
    'tempSensor2_Low': 'Analog tempSensor2 Low Alert',
    'tempSensor3_Low': 'Analog tempSensor3 Low Alert',
    'compHighRunTime': 'Compressor High Run Time Alert',
    'doorOpenAlarm': 'Door Open Alarm'
}
const sensorTypeRenderer = ({ value, field }, mappings = sensorTypeMappings) => {
    if (field === 'EventType') {
        mappings = eventTypeMappings;
    }
    return mappings[value]
};
const formatter = {
    valueRenderer,
    renderer,
    sensorTypeRenderer
}
const valueOptions = ({ field }, mappings = sensorTypeMappings) => {
    if (field === 'EventType') {
        mappings = eventTypeMappings;
    }
    const toReturn = [];
    const options = Object.entries(mappings);
    for (const [value, label] of options) {
        toReturn.push({ label, value });
    }
    return toReturn;
}
// Removed '=' & '!=' & 'is any of' operators from the filter combo, discussed with the team as we are showing the float value by doing formatting and showing only two value after decimal (exe- 65.42), and it is saved in DB like (65.42599487304688)
const filterOperators = getGridNumericOperators().filter((operator) => !['=', '!=', 'isAnyOf'].includes(operator.value));
const defaultFilter = utils.defaultFilter;
const gridConfig = {
    columns: {
        sensorReadings: [
            { id: 'Id', label: 'Id', minWidth: 200 },
            { id: 'GatewayMac', label: 'Gateway Mac', minWidth: 170, isVisible: 'isParent' },
            { id: 'SmartDeviceId', label: 'Smart Device', minWidth: 100, isVisible: 'isParent', align: 'right' },
            { id: 'SerialNumber', label: 'Serial Number', minWidth: 160, isVisible: 'isParent', align: 'right', sortable: false, filterable: false },
            { id: 'EventId', label: 'Event Id', minWidth: 90, type: 'number', align: 'right' },
            { id: 'SensorType', label: 'Sensor Type', minWidth: 200, sortable: false, valueGetter: sensorTypeRenderer, type: 'singleSelect', valueOptions: valueOptions },
            { id: 'EventTime', label: 'Event Time', minWidth: 200, valueGetter: renderer, type: 'date' },
            { id: 'Value', label: 'Value', minWidth: 90, valueGetter: valueRenderer, type: 'number', align: 'right', filterOperators },
            { id: 'Duration', label: 'Duration', minWidth: 100, type: 'number', align: 'right', valueGetter: ({ value }) => value ? value : '' },
            { id: 'UndetectCount', label: 'Undetected Count', minWidth: 90, flex: 1, type: 'number', align: 'right', valueGetter: ({ value }) => value ? value : '' },
            { id: 'AssetSerialNumber', label: 'Asset Serial #', minWidth: 150, sortable: false, filterable: false },
            { id: 'ClientName', label: 'Client Name', minWidth: 100, sortable: false, filterable: false },
            { id: 'LocationName', label: 'Outlet', minWidth: 150, sortable: false, filterable: false },
            { id: 'LocationCode', label: 'Outlet Code', minWidth: 120, sortable: false, filterable: false }
        ],
        sensorReadingsLatest: [
            { id: 'SmartDeviceId', label: 'Smart Device', minWidth: 100, align: 'right' },
            { id: 'SerialNumber', label: 'Serial Number', minWidth: 160, align: 'right' },
            { id: 'Reading1', label: sensorTypeMappings['tempSensor1'], minWidth: 150, valueGetter: valueRenderer, type: 'number', align: 'right', filterOperators },
            { id: 'Reading2', label: sensorTypeMappings['tempSensor2'], minWidth: 150, valueGetter: valueRenderer, type: 'number', align: 'right', filterOperators },
            { id: 'Reading3', label: sensorTypeMappings['tempSensor3'], minWidth: 130, valueGetter: valueRenderer, type: 'number', align: 'right', filterOperators },
            { id: 'Reading4', label: sensorTypeMappings['motion'], minWidth: 100, valueGetter: valueRenderer, type: 'number', align: 'right', filterOperators },
            { id: 'Reading5', label: sensorTypeMappings['humidity'], minWidth: 70, valueGetter: valueRenderer, type: 'number', align: 'right', filterOperators },
            { id: 'Reading6', label: sensorTypeMappings['doorSensor'], minWidth: 80, valueGetter: valueRenderer, type: 'number', align: 'right', filterOperators },
            { id: 'Reading7', label: sensorTypeMappings['compSensor'], minWidth: 150, valueGetter: valueRenderer, type: 'number', align: 'right', filterOperators },
            { id: 'EventTime', label: 'Event Time', minWidth: 180, valueGetter: renderer, type: 'date' },
            { id: 'AssetSerialNumber', label: 'Asset Serial #', minWidth: 150 },
            { id: 'MacAddress', label: 'Smart Device Mac', minWidth: 130 },
            { id: 'ClientName', label: 'Client Name', minWidth: 150 },
            { id: 'LocationName', label: 'Outlet', minWidth: 150 },
            { id: 'LocationCode', label: 'Outlet Code', minWidth: 120 }
        ],
        sensorAlerts: [
            { id: 'Id', label: 'Id', minWidth: 200 },
            { id: 'GatewayMac', label: 'Gateway Mac', minWidth: 170, isVisible: 'isParent' },
            { id: 'SmartDeviceId', label: 'Smart Device', minWidth: 100, isVisible: 'isParent', align: 'right' },
            { id: 'SerialNumber', label: 'Serial Number', minWidth: 160, isVisible: 'isParent', align: 'right' },
            { id: 'EventId', label: 'Event Id', minWidth: 90, align: 'right' },
            { id: 'SensorType', label: 'Sensor Type', minWidth: 200, sortable: false, valueGetter: sensorTypeRenderer, type: 'singleSelect', valueOptions: valueOptions },
            { id: 'EventType', label: 'Event Type', minWidth: 200, sortable: false, valueGetter: sensorTypeRenderer, type: 'singleSelect', valueOptions: valueOptions },
            { id: 'Value', label: 'Value', minWidth: 90, valueGetter: valueRenderer, align: 'right', filterOperators },
            { id: 'EventTime', label: 'Event Time', minWidth: 200, valueGetter: renderer, type: 'date' },
            { id: 'CreatedOn', label: 'Created On', minWidth: 200, valueGetter: renderer, flex: 1 }
        ]
    },
    tabList: [
        {
            icon: "dashboard.png", iconSize: '30', iconPosition: "start", label: "Latest Sensor Readings",
            children: [{ icon: "commands.png", iconSize: '30', iconPosition: "start", label: "Commands" },
            { icon: "sensor.png", iconSize: '30', iconPosition: "start", label: "Sensor Readings" },
            { icon: "alert.png", iconSize: '30', iconPosition: "start", label: "Sensor Alerts" }]
        },
        { icon: "sensor.png", iconSize: '30', iconPosition: "start", label: "Sensor Readings" },
        { icon: "alert.png", iconSize: '30', iconPosition: "start", label: "Sensor Alerts" },
        { icon: "commands.png", iconSize: '30', iconPosition: "start", label: "Commands" }
    ]
};

const TrueDashboard = React.memo(props => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = React.useState(0);
    const [sensorReadingData, setSensorReadingData] = React.useState({});
    const [sensorReadingLatestData, setSensorReadingLatestData] = React.useState({});
    const [sensorAlertsData, setSensorAlertsData] = React.useState({});
    const [activeChildTab, setActiveChildTab] = React.useState(0);
    const [filterModel, setFilterModel] = React.useState({ items: [] });
    const [commandFilterModel, setCommandFilterModel] = React.useState({ items: [] });
    const [filter, setFilters] = React.useState(defaultFilter);
    const [oldFilter, setOldFilters] = React.useState({ ...defaultFilter, linkOperator: "AND" });
    const [filterReady, setFilterReady] = React.useState(false);
    const [columns, setColumns] = React.useState(gridConfig.columns);
    const [childFilters, setChildFilters] = React.useState(null);
    const onFilterModelChange = utils.onFilterModelChange({ setFilters });
    const page = filter?.pagination?.page || 0;
    const pageSize = filter?.pagination.pageSize;
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const isMobile = useMobile();
    const loginData = useSelector(state => state.appReducer.userData) || {};
    const { NodaTimeZone } = loginData && loginData.tags ? loginData.tags : {};
    const setPage = page => {
        setFilters(prev => ({ ...prev, isGridFilter: true, pagination: { ...prev.pagination, page } }));
    }
    const setPageSize = pageSize => {
        setFilters(prev => ({ ...prev, isGridFilter: true, pagination: { ...prev.pagination, pageSize, page: 0 } }));
    }

    const commonGridOptions = {
        filterMode: 'server',
        sortingMode: 'server',
        filterModel: filterModel,
        page: page,
        paginationMode: 'server',
        onPageChange: setPage,
        pageSize: pageSize,
        onPageSizeChange: setPageSize,
        onFilterModelChange: setFilterModel,
        disableSelectionOnClick: false,
        disableMultipleSelection: true,
        density: "compact",
        hideFooter: false,
    }

    const handleChangeTab = (event, newValue) => {
        setFilterModel({ items: [] });
        setFilters(defaultFilter);
        setOldFilters({ ...defaultFilter, linkOperator: "AND" })
        setFilterReady(false);
        setActiveTab(newValue);
        setChildFilters(null);
        setActiveChildTab(0)
    };

    React.useEffect(() => {
        if (filterReady) {
            onFilterModelChange({ ...filterModel, pagination: { ...(filter?.pagination || {}), page: 0 } })
        }
    }, [filterModel])

    React.useEffect(() => {
        const pagination = JSON.stringify(filter.pagination) === JSON.stringify(oldFilter.pagination),
            sort = JSON.stringify(filter.sort) === JSON.stringify(oldFilter.sort),
            where = JSON.stringify(filter.where) === JSON.stringify(oldFilter.where),
            operator = JSON.stringify(filter.linkOperator) === JSON.stringify(oldFilter.linkOperator);

        if (filter.isGridFilter && (!pagination || !sort || !where || !operator)) {
            getDashboardDetail({ extra: filter, filter: true });
            setOldFilters(utils.deepCloneObject(filter));
        }
    }, [filter]);

    const isChild = () => {
        return childFilters ? true : false;
    }

    const getDashboardDetail = async (params = {}) => {
        // eslint-disable-next-line no-unused-vars
        const { extra = {}, isSensorReading = false, isSensorAlerts = false } = params;
        const { page, pageSize } = filter?.pagination || {};
        const payload = {
            ...extra,
            action: 'latestSensorReadings',
            pagination: {
                page: (extra.pagination?.page ?? page) + 1,
                pageSize: extra.pagination?.pageSize ?? pageSize
            }
        };
        if (activeTab === 1 || isSensorReading) {
            payload.action = 'sensorReadings';
            payload.grid = 'readings';
        }
        if (activeTab === 2 || isSensorAlerts) {
            payload.action = 'sensorAlerts';
            payload.grid = 'alerts';
        }
        if (childFilters) {
            payload.smartDeviceId = childFilters.SmartDeviceId;
        }
        const data = await request({ url: apis.TrueDashboard, params: payload, history, dispatch, jsonPayload: true });
        if (data) {
            nodaTimeZone = NodaTimeZone;
            if (data.mappings) {
                const { columns, sensorTypeMappings } = JSON.parse(data.mappings);
                for (const key in columns) {
                    for (const column of columns[key]) {
                        if (Object.prototype.hasOwnProperty.call(column, 'valueGetter')) {
                            const fnName = column.valueGetter;
                            column.valueGetter = ({ value, row, field }) => { return formatter[fnName]({ value, row, field }, sensorTypeMappings) };
                        }
                        if (Object.prototype.hasOwnProperty.call(column, 'valueOptions')) {
                            column.valueOptions = ({ field }) => { return valueOptions({ field }, sensorTypeMappings) };
                        }
                    }
                }
                gridConfig.columns = { ...gridConfig.columns, ...columns };
                setColumns(gridConfig.columns);
            }
            if (isSensorReading) {
                setSensorReadingData(data);
                return;
            }
            if (isSensorAlerts) {
                setSensorAlertsData(data);
                return;
            }
            switch (activeTab) {
                case 0: {
                    setSensorReadingLatestData(data);
                    break;
                }
                case 1: {
                    setSensorReadingData(data);
                    break;
                }
                case 2: {
                    setSensorAlertsData(data);
                }
                default: { }
            }
        }
        !filterReady && setFilterReady(true)

    }

    React.useEffect(() => {
        if (activeTab === 3) {
            return;
        }
        getDashboardDetail()
    }, [activeTab]);

    const clearFilter = () => {
        setFilterModel({
            pagination: { ...filter?.pagination, page: 0 },
            linkOperator: "or",
            items: []
        })
    };

    const onCellClick = ({ id, row }, rec) => {
        const { SmartDeviceId, SerialNumber } = row;
        if (id && rec) {
            setChildFilters({ SmartDeviceId, SerialNumber });
            setCommandFilterModel({ items: [] });
        }
    }

    const changeChildTab = (e, newValue) => setActiveChildTab(newValue);

    const latestSensorReadings = React.useCallback(({ children }) => {
        return <>
            {<Grid container>
                <Grid item md={12} sm={12} lg={12} className="mt-2 gridbase-wraper">
                    <Card>
                        <CardContent>
                            <Grid container >
                                <GridBase
                                    clearFilter={clearFilter}
                                    customClass={'mt-2 data-grid-font-12'}
                                    columns={columns.sensorReadingsLatest}
                                    data={sensorReadingLatestData?.latestSensorReading}
                                    rowsPerPage={[10, 20, 50, 100]}
                                    movePagination={false}
                                    onSortModelChange={onFilterModelChange}
                                    onFilterModelChange={setFilterModel}
                                    showTitleColumn={true}
                                    otherOptions={{
                                        disableMultipleColumnsFiltering: true,
                                        onCellClick,
                                        ...commonGridOptions,
                                        rowCount: sensorReadingLatestData?.totalCount
                                    }} />
                            </Grid>
                            {childFilters && <>
                                <Tabs value={activeChildTab} onChange={changeChildTab} variant="standard">
                                    {children.map(tab => {
                                        return <Tab icon={<img src={require(`../../assets/images/${tab.icon}`)} height={tab.iconSize} alt="" />} iconPosition={tab.iconPosition} label={t(tab.label, tOpts)} />
                                    })}
                                </Tabs>
                                {activeChildTab === 0 ? <Commands childFilters={childFilters} disableAdd={false} setCommandFilterModel={setCommandFilterModel} commandFilterModel={commandFilterModel} /> :
                                    activeChildTab === 1 ? <SensorReadings childFilters={childFilters} getDashboardDetail={getDashboardDetail} columns={columns.sensorReadings}
                                        data={sensorReadingData?.data} totalCount={sensorReadingData?.totalCount} filterReady={filterReady} isSensorReading={true} /> : activeChildTab === 2 ?
                                        <SensorReadings childFilters={childFilters} getDashboardDetail={getDashboardDetail} columns={columns.sensorAlerts}
                                            data={sensorAlertsData?.data} totalCount={sensorAlertsData?.totalCount} filterReady={filterReady} isSensorAlerts={true} /> : ""}
                            </>}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            }
        </>
    });

    const tabs = {
        '0': latestSensorReadings,
        '1': () => <SensorReadings getDashboardDetail={getDashboardDetail} columns={columns.sensorReadings} data={sensorReadingData?.data}
            totalCount={sensorReadingData?.totalCount} filterReady={filterReady} />,
        '2': () => <SensorReadings getDashboardDetail={getDashboardDetail} columns={columns.sensorAlerts} data={sensorAlertsData?.data}
            totalCount={sensorAlertsData?.totalCount} filterReady={filterReady} />,
        '3': () => <Commands childFilters={null} setCommandFilterModel={setCommandFilterModel} commandFilterModel={commandFilterModel} />
    }

    return <>
        <Card className={`mb-1 p-0  ${isMobile.portrait ? "mt-4" : "mt-2"}`} >
            <CardContent className="m-0 p-0">
                <Tabs value={activeTab} onChange={handleChangeTab} centered variant="fullWidth">
                    {gridConfig.tabList.map(tab => {
                        return <Tab icon={<img src={require(`../../assets/images/${tab.icon}`)} height={tab.iconSize} alt="" />} iconPosition={tab.iconPosition} label={t(tab.label, tOpts)} />
                    })}
                </Tabs>
            </CardContent>
        </Card>

        <Card className="mt-2 section-to-print">
            <CardContent>
                {tabs[activeTab](gridConfig.tabList[activeTab])}
            </CardContent>
        </Card>
    </>

});

export default TrueDashboard;