import React from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import dayjs from 'dayjs';
import { Card, CardContent, Grid, Button } from "@material-ui/core";
import swal from "sweetalert";
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import GridBase from "../GridBase";
import DialogModal from '../Common/DialogModel';
import CommandForm from './commandForm';
import utils from '../../utils';
import dateUtil from '../../utils/date';
import { useTranslation } from 'react-i18next';
import { apis, request } from "../../httpUtil";
const t = utils.t

const defaultFilter = utils.defaultFilter;
const commandKey = 'command';
const defaultCommandClass = 'AMOCTrue';
const backdropClick = 'backdropClick';
const commands = {
    "set_AlertThresh": "Set Device Alert Threshold Parameters",
    "set_CabTempRecordFreq": "Set Cabinet Temperature Recording Frequency",
    "set_AmbTempRecordFreq": "Set Ambient Temperature Recording Frequency",
    "set_CompTempRecordFreq": "Set Compressor Temperature Recording Frequency",
    "set_ReportingFreq": "Set Reporting Frequency"
};
const fields = {
    "set_AlertThresh": [
        { name: 'highCabTempThresh', label: "High cabinet temperature threshold" },
        { name: 'highCabTempDelay', label: "High cabinet temperature delay" },
        { name: 'lowCabTempThresh', label: "Low cabinet temperature threshold" },
        { name: 'lowCabTempDelay', label: "Low cabinet temperature delay" },
        { name: 'highAmbTempThresh', label: "High ambient temperature threshold" },
        { name: 'highAmbTempDelay', label: "High ambient temperature delay" },
        { name: 'lowAmbTempThresh', label: "Low ambient temperature threshold" },
        { name: 'lowAmbTempDelay', label: "Low ambient temperature delay" },
        { name: 'highCompTempThresh', label: "High compressor temperature threshold" },
        { name: 'highCompTempDelay', label: "High compressor temperature delay" },
        { name: 'doorOpenDelay', label: "Door open delay" },
        { name: 'cabProbeTempFaultOpen', label: "Cabinet probe temperature fault open" },
        { name: 'cabProbeTempFaultShorted', label: "Cabinet probe temperature fault shorted" }
    ],
    "set_CabTempRecordFreq": [
        { name: "cabTempRecordTime", label: "Cabinet temperature record time" },
        { name: "cabTempACReadingTime", label: "Cabinet temperature AC reading time" },
        { name: "cabTempACReadingCount", label: "Cabinet temperature AC reading count" },
        { name: "cabTempBatteryReadingTime", label: "Cabinet temperature battery reading time" },
        { name: "cabTempBatteryReadingCount", label: "Cabinet temperature battery reading count" }
    ],
    "set_AmbTempRecordFreq": [
        { name: "ambTempRecordTime", label: "Ambient temperature record time" },
        { name: "ambTempACReadingTime", label: "Ambient temperature AC reading time" },
        { name: "ambTempACReadingCount", label: "Ambient temperature AC reading count" },
        { name: "ambTempBatteryReadingTime", label: "Ambient temperature battery reading time" },
        { name: "ambTempBatteryReadingCount", label: "Ambient temperature battery reading count" }
    ],
    "set_CompTempRecordFreq": [
        { name: "compTempRecordTime", label: "Compressor temperature record time" },
        { name: "compTempACReadingTime", label: "Compressor temperature AC reading time" },
        { name: "compTempACReadingCount", label: "Compressor temperature AC reading count" },
        { name: "compTempBatteryReadingTime", label: "Compressor temperature battery reading time" },
        { name: "compTempBatteryReadingCount", label: "Compressor temperature battery reading count" }
    ],
    "set_ReportingFreq": [
        { name: "opReportTime", label: "Report Time" },
        { name: "opBatteryReportTime", label: "Battery Report Time" },
        { name: "opWarehouseReportTime", label: "Warehouse report time" }
    ]
};
const renderer = ({ value }) => {
    const { command: { request_data, type } } = JSON.parse(value);
    const mappings = fields[type];
    const toReturn = {};
    for (const { label, name } of mappings) {
        if (request_data[name]) {
            toReturn[label] = request_data[name];
        }
    }
    return JSON.stringify(toReturn);

}
const valueOptions = ({ field }, mappings = commands) => {
    const toReturn = [];
    const options = Object.entries(mappings);
    for (const [value, label] of options) {
        toReturn.push({ label, value });
    }
    return toReturn;
}
const gridConfig = {
    commands: [
        { id: 'SmartDeviceRemoteManagementId', label: 'Command Id', align: 'right', minWidth: 80 },
        { id: 'RemoteManagementCommandType', label: 'Command Name', minWidth: 280, valueGetter: ({ value }) => commands[value], type: 'singleSelect', valueOptions: valueOptions },
        { id: 'Value', label: 'Value', minWidth: 450, sortable: false, valueGetter: renderer, filterable: false },
        { id: 'Result', label: 'Result', minWidth: 150 },
        { id: 'CreatedOn', label: 'Command Sent On', minWidth: 100, type: 'date', valueGetter: ({ value }) => value && dayjs(value).format(utils.systemDateTimeFormat()) },
        { id: 'ExecutedOn', label: 'Executed On', minWidth: 100, type: 'date', valueGetter: ({ value }) => value && dayjs(value).format(utils.systemDateTimeFormat()) }
    ],
    tabList: [
        { icon: "commands.png", iconSize: '30', iconPosition: "start", label: "True Gateway Commands" }
    ]
};
const TrueCommands = React.memo(props => {

    const { childFilters, disableAdd = true, setCommandFilterModel: setFilterModel, commandFilterModel: filterModel } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const [result, setResult] = React.useState({ data: [] });
    const [filter, setFilters] = React.useState(defaultFilter);
    const [oldFilter, setOldFilters] = React.useState({ ...defaultFilter, linkOperator: "AND" });
    const [filterReady, setFilterReady] = React.useState(false);
    const [openForm, setOpenForm] = React.useState(false);
    const [formValues, setFormValues] = React.useState({ class: defaultCommandClass });
    const onFilterModelChange = utils.onFilterModelChange({ setFilters });
    const page = filter?.pagination?.page || 0;
    const pageSize = filter?.pagination.pageSize;

    const setPage = page => {
        setFilters(prev => ({ ...prev, isGridFilter: true, pagination: { ...prev.pagination, page } }));
    }
    const setPageSize = pageSize => {
        setFilters(prev => ({ ...prev, isGridFilter: true, pagination: { ...prev.pagination, pageSize, page: 0 } }));
    }

    const commonGridOptions = {
        filterMode: 'server',
        sortingMode: 'server',
        filterModel: filterModel,
        page: page,
        paginationMode: 'server',
        onPageChange: setPage,
        pageSize: pageSize,
        onPageSizeChange: setPageSize,
        onFilterModelChange: setFilterModel,
        disableSelectionOnClick: true,
        disableMultipleSelection: true,
        density: "compact",
        hideFooter: false,
    }

    React.useEffect(() => {
        if (filterReady) {
            onFilterModelChange({ ...filterModel });
        }
    }, [filterModel])

    React.useEffect(() => {
        const pagination = JSON.stringify(filter.pagination) === JSON.stringify(oldFilter.pagination),
            sort = JSON.stringify(filter.sort) === JSON.stringify(oldFilter.sort),
            where = JSON.stringify(filter.where) === JSON.stringify(oldFilter.where),
            operator = JSON.stringify(filter.linkOperator) === JSON.stringify(oldFilter.linkOperator);
        if (filter.isGridFilter && (!pagination || !sort || !where || !operator)) {
            getCommandDetails({ extra: filter, filter: true, childFilters });
            setOldFilters(utils.deepCloneObject(filter));
        }
    }, [filter])

    const getCommandDetails = async params => {
        const { extra = {}, childFilters, latestCommand } = params || {};
        const action = latestCommand ? 'loadCommand' : 'commands';
        let payload = {
            ...extra,
            action: action,
            pagination: {
                page: page + 1,
                pageSize
            },
            linkOperator: 'AND'
        };
        if (latestCommand) {
            payload = {
                action
            }
        }
        if (childFilters) {
            payload.smartDeviceId = childFilters.SmartDeviceId;
            payload.commandId = childFilters.commandId;
        }
        const result = await request({ url: apis.TrueDashboard, params: payload, history, dispatch, jsonPayload: true });
        if (result) {
            const updatedCommand = Object.assign({ command: latestCommand }, result.lastSentCommand);
            if (!latestCommand) {
                setResult(result);
            }
            setFormValues({ ...formValues, ...updatedCommand });
        }
        !filterReady && setFilterReady(true);
    };

    React.useEffect(() => {
        getCommandDetails({ childFilters });
    }, [childFilters]);

    const clearFilter = () => {
        setFilterModel({
            pagination: { ...filter?.pagination, page: 0 },
            linkOperator: "or",
            items: []
        })
    };

    const onCommandChange = React.useCallback(({ target = {} }) => {
        const { name, value } = target;
        if (name === commandKey) {
            formValues.class = defaultCommandClass;
            const commandType = result?.commandsCombo?.filter(e => e.CustomStringValue === value);
            childFilters.commandId = commandType[0].LookupId;
            getCommandDetails({ extra: filter, filter: true, childFilters, latestCommand: value });
        }
        setFormValues({ ...formValues, [name]: value });
    });

    const onSubmitCommand = async () => {
        formValues.object = childFilters.SerialNumber;
        formValues.smartDeviceId = childFilters.SmartDeviceId;
        const commandType = result?.commandsCombo?.filter(e => e.CustomStringValue === formValues.command);
        formValues.commandId = commandType[0].LookupId;
        const payload = { ...formValues, action: 'sendCommand' };
        const { success, msg } = await request({ url: apis.TrueDashboard, params: payload, history, dispatch, jsonPayload: true });
        swal({
            text: t(msg, tOpts),
            title: success ? "Success" : "Error",
            icon: success ? "success" : "error",
            dangerMode: !success
        });
        setOpenForm(false);
    }

    const onCancel = (e, reason) => {
        // Prevent closing the modal if the user clicks outside of it. backdropClick prop is deprecated to the component so used reason in the cancel function as per the DOC
        if (reason === backdropClick) {
            return;
        }
        setOpenForm(false);
        setFormValues({ ...formValues, ...result.lastSentCommand });
    }

    const handleRefresh = () => {
        getCommandDetails({ extra: filter, filter: true, childFilters });
    }

    const onAddClick = () => {
        setFormValues({});
        setOpenForm(!openForm);
    }

    return (
        <>
            <Grid container>
                <Grid item md={12} sm={12} lg={12} className="mt-2 gridbase-wraper">
                    <Card>
                        <CardContent>
                            <Grid container >
                                {!disableAdd && <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Button color="primary" variant="contained" onClick={onAddClick} size="small" > <AddIcon /> {t('Add', tOpts)} </Button>
                                    {' '}
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        onClick={() => handleRefresh(true)}
                                    >
                                        <RefreshIcon />{t('Refresh Commands', tOpts)}
                                    </Button>
                                </Grid>}
                                <GridBase
                                    clearFilter={clearFilter}
                                    customClass={'mt-2 data-grid-font-12'}
                                    columns={gridConfig.commands}
                                    data={result?.commands}
                                    rowsPerPage={[10, 20, 50, 100]}
                                    onSortModelChange={onFilterModelChange}
                                    onFilterModelChange={setFilterModel}
                                    showTitleColumn={true}
                                    otherOptions={{
                                        ...commonGridOptions,
                                        rowCount: result?.totalCount
                                    }} />
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <DialogModal dividers={true} open={openForm} onClose={onCancel} onSubmit={onSubmitCommand} submitBtnDisabled={(!formValues.class || !formValues.command)} headerClassName="true-command-header"
                disableStyle={true} dialogTitle={`${t('Command', tOpts)} - ${childFilters?.SmartDeviceId}`} maxWidth="lg" closeBtnTitle={t('Cancel', tOpts)} closeBtnColor="error" closeBtnCls="error" submitBtnTitle={t('Save', tOpts)} >
                <CommandForm onCommandChange={onCommandChange} formValues={formValues} combos={result?.commandsCombo || []} fields={fields} />
            </DialogModal>
        </>)
});

export default TrueCommands;