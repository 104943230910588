import React from 'react';
import { Select, FormControl, MenuItem } from '@material-ui/core';

const SelectYear = React.memo(function SelectYear({ options, selected, handelSelectYear }) {
    return (
        <FormControl variant="outlined" fullWidth={true}>
            <Select MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                getContentAnchorEl: null
            }} name="year" className="filter-select years" value={selected || (options.length > 0 && options[0])} onChange={handelSelectYear}>
                {options.map(d => <MenuItem key={d} value={d}>{d}</MenuItem>)}
            </Select>
        </FormControl>
    )
})

export default SelectYear;