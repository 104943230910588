import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { apis, request } from "../../httpUtil";
import {
    Card, CardContent, Grid
} from "@material-ui/core";
import GridBase from "../GridBase";
import actions from "../../redux/actions";
import utils from '../../utils'
import { useTranslation } from 'react-i18next';
const { defaultFilter, t, dayjsFormatLocal } = utils;

const RowColumns = (tOpts) => [
    { id: "AlertId", label: "ID", flex: 0, minWidth: 60, valueGetter: (value) => value.row.AlertId },
    { id: "AlertType", label: "Alert Type", flex: 0, minWidth: 100, valueGetter: (value) => value.row.AlertType },
    { id: "AlertText", label: "Alert Text", flex: 0, minWidth: 100, valueGetter: (value) => value.row.AlertText },
    {
        id: "AlertAt", type: "date", label: "Alert At", flex: 0, minWidth: 100, valueGetter: (value) => value.row.AlertAt, format: (item) => {
            return dayjsFormatLocal({ value: item.value, format: utils.systemDateTimeFormat(), tOpts: tOpts, lang: null });
        }
    },
    {
        id: "ClosedOn", label: "Closed On", type: "date", flex: 0, minWidth: 100, valueGetter: (value) => value.row.ClosedOn, format: (item) => {
            return dayjsFormatLocal({ value: item.value, format: utils.systemDateTimeFormat(), tOpts: tOpts, lang: null });
        }
    },
    { id: "AlertAge", label: "Age", flex: 0, minWidth: 60, valueGetter: (value) => value.row.AlertAge },
    {
        id: "ScheduleDate", label: "Schedule Date", type: "date", flex: 0, minWidth: 100, valueGetter: (value) => value.row.ScheduleDate, format: (item) => {
            return dayjsFormatLocal({ value: item.value, format: utils.systemDateTimeFormat(), tOpts: tOpts, lang: null });
        }
    },
    { id: "SLAHours", label: "SLA Hours", type: "number", flex: 0, minWidth: 100, valueGetter: (value) => value.row.SLAHours },
    { id: "Owner.Username", label: "Owner", flex: 0, minWidth: 200, valueGetter: (value) => value.row.OwnerName },
    { id: "Manager.Username", label: "Manager", flex: 0, minWidth: 200, valueGetter: (value) => value.row.ManagerName },
    { id: "AssetSerialNumber", label: "Asset Serial #", flex: 0, minWidth: 100, valueGetter: (value) => value.row.AssetSerialNumber },
    { id: "OutletCode", label: "Outlet Code", flex: 0, minWidth: 70, valueGetter: (value) => value.row.OutletCode },
    { id: "Location", label: "Outlet Name", flex: 0, minWidth: 70, valueGetter: (value) => value.row.Location },
    { id: "Street", label: "Street", flex: 0, minWidth: 70, valueGetter: (value) => value.row.Street },
    { id: "City", label: "City", flex: 0, minWidth: 70, valueGetter: (value) => value.row.City },
    { id: "Country", label: "Country", flex: 0, minWidth: 70, valueGetter: (value) => value.row.Country },
]


function AlertHistory(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const { isOutletCard = false, gridColumn = RowColumns(tOpts), setAlertCount = null, location = null } = props;
    const [data, setData] = useState({})
    const [filterModel, setFilterModel] = useState({ items: [] })
    const [filter, setFilters] = useState(defaultFilter);
    const [oldFilter, setOldFilters] = useState({ ...defaultFilter, linkOperator: "AND" });
    const [filterReady, setFilterReady] = useState(false);
    const onFilterModelChange = utils.onFilterModelChange({ setFilters });
    const page = filter?.pagination?.page || 0;
    const pageSize = filter?.pagination.pageSize;
    const commonGridOptions = {
        filterMode: 'server',
        sortingMode: 'server',
        filterModel: filterModel,
        page: page,
        paginationMode: 'server',
        onPageChange: setPage,
        pageSize: pageSize,
        onPageSizeChange: setPageSize,
        onFilterModelChange: setFilterModel,
        disableSelectionOnClick: true,
        density: "compact",
        hideFooter: false,
    }

    function setPage(page) {
        setFilters(prev => ({ ...prev, isGridFilter: true, pagination: { ...prev.pagination, page } }));
    }

    function setPageSize(pageSize) {
        setFilters(prev => ({ ...prev, isGridFilter: true, pagination: { ...prev.pagination, pageSize, page: 0 } }));
    }

    useEffect(() => {
        getHistoryData({})
    }, [])

    useEffect(() => {
        if (!isOutletCard)
            dispatch({
                type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                    filterButton: null,
                    hidden: { search: true, operation: true, export: true, print: true, filter: true }
                }
            });

        return () => {
            if (!isOutletCard)
                dispatch({
                    type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                        filterButton: null,
                        clear: null,
                        apply: null,
                        hidden: { search: false, operation: false, export: false, print: false, filter: false }
                    }
                });
        }
    }, []);


    useEffect(() => {
        const pagination = JSON.stringify(filter.pagination) === JSON.stringify(oldFilter.pagination),
            sort = JSON.stringify(filter.sort) === JSON.stringify(oldFilter.sort),
            where = JSON.stringify(filter.where) === JSON.stringify(oldFilter.where),
            operator = JSON.stringify(filter.linkOperator) === JSON.stringify(oldFilter.linkOperator);

        if (filter.isGridFilter && (!pagination || !sort || !where || !operator)) {
            getHistoryData({ extra: filter, filter: true });
            setOldFilters(utils.deepCloneObject(filter));
        }
    }, [filter])

    useEffect(() => {
        if (filterReady) {
            onFilterModelChange({ ...filterModel, pagination: { ...(filter?.pagination || {}), page: 0 } })
        }
    }, [filterModel])

    async function getHistoryData({ action = "", extra = {}, filter: isGridFilter = false }) {
        const { page, pageSize } = filter?.pagination || {};
        let payload = {
            ...extra,
            chartCount: 8,
            action: "AlertHistory",
            status: "Closed",
            pagination: {
                page: page + 1,
                pageSize
            },
        };
        const additionalParams = {}
        if (action === 'export') {
            payload["exportData"] = true;
        }
        if (isOutletCard) {
            payload.where = { ...payload.where, 'Alert.LocationId': [{ value: location, operator: "=" }] }
        }

        const data = await request({ url: apis.AlertHistory, params: payload, history, dispatch, jsonPayload: true, additionalParams });

        if (data) {
            const chartData = [];
            for (const key in data.alertNullPer) {
                chartData.push({ label: data.alertNullPer[key].OutletCode, value: data.alertNullPer[key].OutletName })
            }
            if (isGridFilter) {
                setData(prev => ({ ...prev, ...data }))
                return;
            }
            if (setAlertCount) {
                setAlertCount(data.totalCount || 0)
            }
            setData({ ...data, chartData: chartData })
        }
        !filterReady && setFilterReady(true)
    }

    function exportExcel(action) {
        getHistoryData({
            action: "export",
            extra: {
                "responseType": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "fileName": action.replace(/ /g, ""),
                ...filter
            }
        })
    }

    function clearFilter() {
        setFilterModel({
            pagination: { ...filter?.pagination, page: 0 },
            linkOperator: "or",
            items: []
        })
        dispatch({
            type: actions.SET_FILTER_VALUES, filterValues: {}
        });
    }

    if (isOutletCard) {
        return <>
            <GridBase
                clearFilter={clearFilter}
                customClass={'mt-2 data-grid-font-12'}
                columns={gridColumn}
                data={data?.alertList}
                rowsPerPage={[10, 20, 50, 100]}
                movePagination={false}
                autoHeight={false}
                onSortModelChange={onFilterModelChange}
                showTitleColumn={true}
                otherOptions={{
                    ...commonGridOptions,
                    rowCount: data?.totalCount,
                }} />
        </>
    }

    return <>
        <Card className="mt-2 section-to-print">
            <CardContent>
                {Object.keys(data).length > 0 ?
                    <Grid container >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className="d-flex d-flex-end d-flex-align-center">
                                <div>
                                    <img className="ifh-export-icon ml-2" onClick={() => exportExcel("Outlet List")} src={require(`../../assets/images/excel.png`)} height='35' alt={"Export"} />
                                </div>
                            </div>
                        </Grid>
                        <GridBase
                            clearFilter={clearFilter}
                            customClass={'mt-2 data-grid-font-12'}
                            columns={gridColumn}
                            data={data?.alertList}
                            rowsPerPage={[10, 20, 50, 100]}
                            movePagination={false}
                            onSortModelChange={onFilterModelChange}
                            showTitleColumn={true}
                            otherOptions={{
                                ...commonGridOptions,
                                rowCount: data?.totalCount,
                            }} />
                    </Grid> :
                    <p>{t('No Data', tOpts)}</p>}
            </CardContent>
        </Card>
    </>
}

export default AlertHistory;