import React, { useRef, useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { apis, request } from "../../httpUtil";
import {
    Card, CardContent, Typography,
    Grid, Tabs, Tab, Tooltip, Box, FormControl, InputLabel, Select, MenuItem
} from "@material-ui/core";
import { List, ListItem, ListItemButton, ListItemText, DialogTitle, Dialog } from '@mui/material';
import dayjs from 'dayjs';
import GridBase from "../../components/GridBase";
import CloseIcon from '@material-ui/icons/Close';
import InfoRounded from '@material-ui/icons/InfoRounded';
import actions from "../../redux/actions";
import utils from '../../utils';
import kpiDescription from '../../utils/kpiDescription.json'
import { getGridStringOperators } from "@mui/x-data-grid-pro";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import TextForObjects from "../../utils/TextForObjects";
import Button from '@mui/material/Button';
import swal from 'sweetalert';
import { useTranslation } from "react-i18next";
import useMobile from '../../utils/useMobile';
import enums from "../../utils";
const { exportAction } = enums;
const { dayjsFormatLocal, t, defaultFilter, IS_ACTIVE, BOOLEAN_FIELDS } = utils;
const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['equals'].includes(value),
);
const IsChestOperators = getGridStringOperators().filter(({ value }) =>
    ['equals', "isEmpty", "isNotEmpty"].includes(value),
);


const CustomFilterComponent = React.memo((props) => {

    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const { item, applyValue, name, list = [] } = props;
    const [open, setOpen] = React.useState(false);
    const ref = useRef(null);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const handleFilterChange = (event) => {
        const { value = [] } = event?.target || {};
        applyValue({ ...item, value: value.length > 0 ? value : null })
    }

    return (
        <Box>
            <FormControl className="select-form-control select-form-product">
                <InputLabel className="h4 product-filter-input" >{t(name, tOpts)}</InputLabel>
                <Select
                    multiple={item.columnField !== "IsSmart"}
                    className="w-100 product-filter-select"
                    ref={ref}
                    value={item?.value || []}
                    onChange={handleFilterChange} >
                    {list.map((v, i) => (<MenuItem spacing={3} key={i} value={v}>{t(v, tOpts)}</MenuItem>))}
                </Select>
                {item?.value?.length > 0 && item.columnField !== "IsSmart" && <Button color='primary' onClick={handleClickOpen} className='select-multiple-button'>...</Button>}
            </FormControl>

            {open && <Dialog onClose={handleClose} open={open}>
                <DialogTitle>{t("Selected Products", tOpts)}</DialogTitle>
                <List sx={{ pt: 0 }} className='pl-2'>
                    {item?.value?.length > 0 && item?.value?.map((product, index) => (
                        <ListItem disableGutters className="p-0">
                            <ListItemButton key={index} className="py-1">
                                <ListItemText primary={product} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Dialog>}
        </Box>
    )
});

const customFilterConfig = {
    "IsSmart": (data) => [{
        label: <TextForObjects text="Select" />,
        value: "=",
        InputComponent: (props) => {
            return <CustomFilterComponent list={["Yes", "No"]} name={"Is Smart"} {...props} />
        }
    }]
}

let columns = ''

const gridConfig = ({ tOpts, key }) => {
    const columnsData = columns ? columns : {
        assetList: [
            {
                field: 'SerialNumber', id: "SerialNumber", label: "Asset Serial #", flex: 0, minWidth: 150, valueGetter: (value) => value.row.SerialNumber, headerAlign: 'left',
                fixed: true
            },
            {
                field: 'AssetType', id: "AssetType", label: "Asset Type", flex: 0, minWidth: 150, valueGetter: (value) => value.row.AssetType, headerAlign: 'left',
                fixed: true
            },
            {
                field: 'IsSmart', id: "IsSmart", label: "Is Smart", filterOperators, flex: 0, minWidth: 100, valueGetter: (value) => value.row.IsSmart, renderCell: (params) => {
                    const isSmart = params.value;
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="outlined"
                                color={isSmart ? "success" : "error"}
                                style={{
                                    borderRadius: '12px', padding: '1px 4px', minWidth: '60px', fontSize: '10px', pointerEvents: 'none',
                                    cursor: 'default',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            >
                                {isSmart ? t('Yes', tOpts) : t('No', tOpts)}
                            </Button>
                        </div>
                    );
                },
                headerAlign: 'left',
                fixed: true
            },
            { id: "SmartDeviceSerialNumber", label: "Camera Serial #", flex: 0, minWidth: 100, valueGetter: (value) => value.row.SmartDeviceSerialNumber },
            { id: "MDMSerialNumber", label: "Hub Serial #", flex: 0, minWidth: 150, valueGetter: (value) => value.row.MDMSerialNumber },
            {
                id: 'Installation', label: 'Installed At', type: "date", flex: 0, minWidth: 100, valueGetter: ({ row }) => row.Installation, format: (item) => {
                    return utils.formatDate(item.value, true);
                }, sort: 'desc'
            },
            { id: 'InstallerUser', label: 'Installed By', flex: 0, minWidth: 150, valueGetter: ({ row }) => row.InstallerUser },
            { id: 'Location', label: 'Outlet Name', flex: 0, minWidth: 150, valueGetter: ({ row }) => row.Location },
            { id: "LocationCode", label: "Outlet Code", flex: 0, minWidth: 150, valueGetter: ({ row }) => row.LocationCode },
            { id: 'Street', label: 'Street', flex: 0, minWidth: 150, valueGetter: ({ row }) => row.Street },
            { id: 'City', label: 'City', flex: 0, minWidth: 100, valueGetter: ({ row }) => row.City },
            { id: 'State', label: 'State', flex: 0, minWidth: 100, valueGetter: ({ row }) => row.State },
            { id: 'PostalCode', label: 'Postal', flex: 0, minWidth: 100, valueGetter: ({ row }) => row.PostalCode },
            { id: 'PrimaryRepName', label: 'Primary Sales Rep', flex: 0, minWidth: 150, valueGetter: ({ row }) => row.PrimaryRepName },
            { id: 'PrimarySalesRep', label: 'Primary Sales Rep E-mail', flex: 0, minWidth: 150, valueGetter: ({ row }) => row.PrimarySalesRep },
            { id: 'SecondaryRepName', label: 'Secondary Sales Rep', flex: 0, minWidth: 150, valueGetter: ({ row }) => row.SecondaryRepName },
            { id: 'LocationRoute', label: 'Delivery Route', flex: 0, minWidth: 90, valueGetter: ({ row }) => row.LocationRoute },
            { id: 'PlanogramName', label: 'Planogram', flex: 0, minWidth: 150, valueGetter: ({ row }) => row.PlanogramName }
        ]
    }
    if (key) {
        return columnsData[key]
    } else {
        return columnsData
    }
}

const operatorMappings = {
    "IsSmart": "equals"
}

const ReportsList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [activeTab, setActiveTab] = React.useState(0);
    const [assetData, setAssetData] = useState({})
    const [sectionToPrint, setSectionToPrint] = useState('section-to-print-1')
    const [customFilterData, setCustomFilterData] = useState({});
    const [filterModel, setFilterModel] = useState({ items: [] })
    const [exportColumns, setExportColumns] = useState([]);
    const [filter, setFilters] = useState(defaultFilter);
    const [hideAllColumns, setHideAllColumns] = useState(false)
    const [oldFilter, setOldFilters] = useState({ ...defaultFilter, linkOperator: "AND" });
    const [filterReady, setFilterReady] = useState(false);
    const [pinnedColumns, setPinnedColumns] = useState({
        left: ['SerialNumber', 'AssetType', 'IsSmart'],
    });

    const handlePinnedColumnsChange = useCallback((updatedPinnedColumns) => {
        setPinnedColumns(updatedPinnedColumns);
    }, []);

    const [columnVisibility, setColumnVisibility] = useState({
        SerialNumber: true,
        AssetType: true,
        IsSmart: true,
        SmartDeviceSerialNumber: true,
        MDMSerialNumber: true,
        Installation: true,
        InstallerUser: true,
        Location: true,
        LocationCode: true,
        Street: false,
        City: false,
        State: false,
        PostalCode: false,
        PrimaryRepName: false,
        PrimarySalesRep: false,
        SecondaryRepName: false,
        LocationRoute: false,
        PlanogramName: false,


    })
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };

    const onFilterModelChange = utils.onFilterModelChange({ setFilters });
    const page = filter?.pagination?.page || 0;
    const pageSize = filter?.pagination.pageSize;
    const commonGridOptions = {
        filterMode: 'server',
        sortingMode: 'server',
        filterModel: filterModel,
        page: page,
        paginationMode: 'server',
        onPageChange: setPage,
        pageSize: pageSize,
        onPageSizeChange: setPageSize,
        onFilterModelChange: setFilterModel,
        disableSelectionOnClick: true,
        density: "compact",
        hideFooter: false
    }


    useEffect(() => {
        getMasterData({})
    }, [activeTab])


    useEffect(() => {
        const pagination = JSON.stringify(filter.pagination) === JSON.stringify(oldFilter.pagination),
            sort = JSON.stringify(filter.sort) === JSON.stringify(oldFilter.sort),
            where = JSON.stringify(filter.where) === JSON.stringify(oldFilter.where),
            operator = JSON.stringify(filter.linkOperator) === JSON.stringify(oldFilter.linkOperator);

        if (filter.isGridFilter && (!pagination || !sort || !where || !operator)) {
            getMasterData({ extra: filter, filter: true });
            setOldFilters(utils.deepCloneObject(filter));
        }
    }, [filter])

    useEffect(() => {
        if (filterReady) {
            onFilterModelChange({ ...filterModel, pagination: { ...(filter?.pagination || {}), page: 0 } })
        }
    }, [filterModel])


    useEffect(() => {
        switch (pageSize) {
            case 10: setSectionToPrint('section-to-print-1'); break;
            case 20: setSectionToPrint('section-to-print-1'); break;
            case 50: setSectionToPrint('section-to-print-2'); break;
            case 100: setSectionToPrint('section-to-print-3'); break;
            default: setSectionToPrint('section-to-print-1')
        }
    }, [pageSize])

    useEffect((v) => {
        columns = gridConfig({ tOpts })
        for (let key in columns) {
            if (key !== "planogramList") {
                for (let [index, column] of columns[key].entries()) {
                    if (!customFilterConfig[column.id]) {
                        continue;
                    }
                    const data = customFilterData[column.id];
                    columns[key][index].filterOperators = customFilterConfig[column.id](data);
                }
            }

        }
    }, [customFilterData])

    function setPage(page) {
        setFilters(prev => ({ ...prev, isGridFilter: true, pagination: { ...prev.pagination, page } }));
    }

    function setPageSize(pageSize) {
        setFilters(prev => ({ ...prev, isGridFilter: true, pagination: { ...prev.pagination, pageSize, page: 0 } }));
    }

    const visibleColumns = Object.keys(columnVisibility).filter((key) => columnVisibility[key]);

    useEffect(() => {
        setExportColumns(visibleColumns);
    }, []);

    async function getMasterData({ action = "", extra = {}, filter: isGridFilter = false }) {
        const { page, pageSize } = filter?.pagination || {};
        const payload = {
            ...extra,
            action: "Installations",
            pagination: {
                page: page + 1,
                pageSize
            }
        };
        const additionalParams = {}
        if (action === exportAction) {
            payload["exportData"] = true;
            payload["exportColumns"] = exportColumns
        }
        const data = await request({ url: apis.MasterDataDash, params: payload, history, dispatch, jsonPayload: true, additionalParams });
        const newData = isGridFilter ? data.data : data
        if (newData) {
            setAssetData(prev => ({ ...prev, ...newData }))
            return;

        }
    }
    !filterReady && setFilterReady(true)

    function exportExcel(action) {
        if (exportColumns.length === 0 && hideAllColumns) {
            swal({
                title: '',
                text: t("You cannot export while all columns are hidden... please show at least 1 column before exporting", tOpts),
                icon: "warning",
                dangerMode: false
            });
        } else {
            getMasterData({
                action: exportAction,
                extra: {
                    "responseType": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "fileName": action.replace(/ /g, ""),
                    ...filter
                }
            })
        }

    }

    const handleExportColumnsChnage = (columns) => {
        const columnsCloned = columns;
        const newColumns = [];
        Object.keys(columnsCloned).forEach(key => {
            if (columnsCloned[key] !== false) {
                newColumns.push(key);
            }
        });
        setExportColumns(newColumns);
        setHideAllColumns(true);
    }

    function filterByEmpty(page) {
        const operatorValue = "isEmpty"
        const data = columns[page] || [];
        const items = []
        for (let item of data) {
            if (BOOLEAN_FIELDS.includes(item.id)) {
                items.push({
                    columnField: item.id,
                    value: "no",
                    operatorValue: operatorMappings[item.id]

                })
                continue;
            }
            items.push({
                columnField: item.id,
                value: null,
                operatorValue: operatorValue

            })
        }
        setFilterModel({
            pagination: {
                ...filter?.pagination,
                page: 0
            },
            linkOperator: "or",
            items
        })
    }


    function clearFilter() {
        setFilterModel({
            pagination: { ...filter?.pagination, page: 0 },
            linkOperator: "or",
            items: []
        })
    }

    return (
        <div>
            <Grid item md={12} sm={12} lg={12} className="mt-2 gridbase-wraper">
                <Card>
                    <CardContent>
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div className="installation-page">
                                    <div>
                                        <img className="ifh-export-icon ml-2" onClick={() => exportExcel("Installations")} src={require(`../../assets/images/excel.png`)} height='35' alt={"Export"} />
                                    </div>
                                </div>
                            </Grid>

                            <GridBase
                                clearFilter={clearFilter}
                                customClass={'mt-2 data-grid-font-12'}
                                columns={gridConfig({ tOpts, key: "assetList" })}
                                data={assetData?.assetList}
                                rowsPerPage={[10, 20, 50, 100]}
                                movePagination={false}
                                onSortModelChange={onFilterModelChange}
                                onFilterModelChange={setFilterModel}
                                showTitleColumn={true}
                                otherOptions={{
                                    ...commonGridOptions,
                                    initialState: {
                                        columns: {
                                            columnVisibilityModel: columnVisibility

                                        }
                                    },
                                    rowCount: assetData?.totalCount,
                                    onColumnVisibilityModelChange: (newModel) => handleExportColumnsChnage(newModel),
                                    pinnedColumns: pinnedColumns,
                                    onPinnedColumnsChange: handlePinnedColumnsChange,

                                }} />
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </div>
    );
};

export default ReportsList;
