import actions from '../actions';
import util from '../../utils'

const initialState = {
    appTitleName: 'CoolR',
    sidebarToggle: localStorage.getItem('CoolRD3-sidebar') === 'true' ? true : false,
    headerFixed: true,
    headerShadow: true,
    sidebarToggleMobile: false,
    sidebarHover: false,
    sidebarFixed: true,
    timeSlotDialogState: false,
    vistaData: { records: [], recordCount: 0, TotalCount: 0, NeedAttentionCount: 0 },
    loaderOpen: false,
    ScheduleTimePopup: false,
    openMagnifier: false,
    vistaSearch: '',
    scheduleData: {},
    scheduleDataIndex: null,
    vistaPopupData: { open: false, url: '' },
    vistaNeedsAttention: false,
    locationsData: { records: [], recordCount: 0, TotalCount: 0, NeedAttentionCount: 0 },
    skuOutOfStockData: { skuRecords: [], skuRecordCount: 0 },
    skuDetailList: { records: [], recordCount: 0 },
    userEmail: '',
    userPassword: '',
    clientsList: [],
    selectClient: 0,
    isPlanogram: [],
    isInStore: [],
    isOptional: [],
    comboData: {},
    activateLocationReorder: {},
    productSearch: { skuSearchValue: "", inventorySearchValue: "" },
    telemetricData: {},
    locationsReorderData: { records: [], recordCount: 0, TotalCount: 0 },
    scheduledReorderData: { records: [], recordCount: 0, TotalCount: 0 },
    ignoredReorderData: { records: [], recordCount: 0, TotalCount: 0 },
    inTransitData: { records: [], recordCount: 0, TotalCount: 0 },
    scheduledLogsData: { records: [], recordCount: 0, TotalCount: 0 },
    acostaReportData: { records: [], recordCount: 0, TotalCount: 0 },
    replenishData: [],
    reorderLogsData: { records: [], recordCount: 0, TotalCount: 0 },
    kpiFilters: {
        marketIds: ["-1"],
        classificationIds: ["-1"],
        channelIds: ["-1"],
        daysFilter: '30:day',
        fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 30),
        toDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        managerSelectedValue: [],
        pagination: { page: 0, rowsPerPage: 10, orderBy: '' }
    },
    kpiData: { records: [], recordCount: 0 },
    kpiGridsData: [],
    kpiImageData: [],
    showKPICharts: true,
    filterPanelToggle: false,
    pageTitleDetails: null,
    editMode: false,
    filtersInHeader: { hidden: { search: true, operation: true, export: true, print: true, filter: true } },
    operationsCheckedData: { checkedData: [], isReorder: false, isIgnore: false, isSchedule: false, isTransit: false, data: [], getData: null, enableActivationAction: false, headerChecked: false },
    pageTitle: { title: '', desc: "" },
    answerData: null,
    expanded: null,
    appInfo: null,
    surveyType: null,
    typeQuestion: null,
    allSurveyData: null,
    getAllOrderHistoryAppliedFilters: null,
    replenishmentOrder: { open: false, data: {} },
    replenishmentGridData: { data: [], editProductRowId: 0 },
    pageBackButton: { status: false, backRoute: "" },
    overviewRecordSelect: { code: null },
    productCaseTypeDdl: { caseTypeDdlData: {} },
    groupDetails: {},
    surveyDetails: {},
    modal: { modal: { status: false, data: {} } },
    hideDateFilter: false,
    stateCombo: [],
    frequencyCycleData: {},
    headerFilters: {},
    captureMatrix: {},
    captureMatrixClone: {},
    scenePicturesTaken: {},
    scenePicturesTakenClone: {},
    captureSceneSerialNumbers: {},
    captureSceneSerialNumbersClone: {},
    captureSceneQuestionIds: [],
    captureSceneQuestionIdsClone: [],
    imageSequence: [],
    questionnaireDetails: { title: '', description: '', isActive: true },
    questionnaireLocationDetails: { assets: {}, products: {}, productsContextSensitive: {}, productsInStorePlanogram: {} },
    questionnaireLocationId: null,
    questionnaireAssetFilter: null,
    questionnairePreviewRepeatOption: util.questionnaireAssetText,
    questionnairePreviewAssetFilter: null,
    preferences: {},
    currentPreference: {},
    resultAnalysisComboData: {},
    pageLeftFilters: { ClientId: [], MarketId: [], LocationTypeId: [], ClassificationId: [], DistributorId: [], SalesRepId: [], RouteId: [], PreSellerRouteId: [], IsSmart: -1, Location: '', LocationCode: '' },
    dataGroupBy: 'MarketId'
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.LOGIN:
            return { ...state, ...{} };
        case actions.SET_USER_DATA:
            let userDataObj = { ...action.userData }
            if (action.clientId && userDataObj && userDataObj.tags) {
                userDataObj.tags.ClientId = action.clientId
            }
            window.userData = userDataObj
            return { ...state, ...{ userData: userDataObj } };
        case actions.CAMERA_IMAGE_LIST:
            return { ...state, ...{} };
        case actions.ADD_REMOTE_COMMAND:
            return { ...state, ...{} };
        case actions.SET_SIDEBAR_TOGGLE:
            return {
                ...state,
                sidebarToggle: action.sidebarToggle
            };
        case actions.SET_HEADER_FIXED:
            return {
                ...state,
                headerFixed: action.headerFixed
            };
        case actions.SET_HEADER_SHADOW:
            return {
                ...state,
                headerShadow: action.headerShadow
            };
        case actions.SET_SIDEBAR_TOGGLE_MOBILE:
            return {
                ...state,
                sidebarToggleMobile: action.sidebarToggleMobile
            };
        case actions.SET_SIDEBAR_HOVER:
            return {
                ...state,
                sidebarHover: action.sidebarHover
            };
        case actions.SET_SIDEBAR_FIXED:
            return {
                ...state,
                sidebarFixed: action.sidebarFixed
            };
        case actions.SET_TIMESLOT_DIALOG_STATE:
            return {
                ...state,
                timeSlotDialogState: action.timeSlotDialogState,
                scheduleDataIndex: action.scheduleDataIndex,
                scheduleData: action.scheduleData
            };
        case actions.SET_VISTA_DATA:
            let VistaDataClone = { ...state.vistaData }

            if (!action.vistaData.firstFilter && action.vistaData && action.vistaData.records && action.vistaData.records.length) {
                for (let d of action.vistaData.records) {
                    if (d) {
                        let startIndex = action.vistaData.startIndex;
                        let findIndexObj = VistaDataClone.records.findIndex(vista => vista.RowNumber === d.RowNumber);
                        if (findIndexObj > -1) {
                            VistaDataClone.records[findIndexObj] = d;
                        } else {
                            d.startIndex = startIndex;
                            VistaDataClone.records.push(d)
                        }
                    }
                }
            }
            if (!action.vistaData.firstFilter) {
                VistaDataClone.recordCount = action.vistaData.recordCount;
                VistaDataClone.TotalCount = action.vistaData.TotalCount
                VistaDataClone.NeedAttentionCount = action.vistaData.NeedAttentionCount;
            }

            return {
                ...state,
                vistaData: action.vistaData.firstFilter ? action.vistaData : VistaDataClone
            };

        case actions.RESET_VISTA_DATA:
            return {
                ...state,
                vistaData: action.vistaData
            };
        case actions.SET_LOCATIONS_DATA:
            let LocationsDataClone = { ...state.locationsData }

            if (!action.locationsData.firstFilter && action.locationsData && action.locationsData.records && action.locationsData.records.length) {
                for (let d of action.locationsData.records) {
                    let startIndex = action.locationsData.startIndex;
                    let findIndexObj = LocationsDataClone.records.findIndex(vista => vista.RowNumber === d.RowNumber);
                    if (findIndexObj > -1) {
                        LocationsDataClone.records[findIndexObj] = d;
                    } else {
                        d.startIndex = startIndex;
                        LocationsDataClone.records.push(d)
                    }
                }
            }

            if (!action.locationsData.firstFilter) {
                LocationsDataClone.recordCount = action.locationsData.recordCount;
                LocationsDataClone.TotalCount = action.locationsData.TotalCount
                LocationsDataClone.NeedAttentionCount = action.locationsData.NeedAttentionCount;
                LocationsDataClone.PlanogramComplianceAvg = action.locationsData.PlanogramComplianceAvg;
                LocationsDataClone.OutOfStockAvg = action.locationsData.OutOfStockAvg;
            }
            return {
                ...state,
                locationsData: action.locationsData.firstFilter ? action.locationsData : LocationsDataClone
            };

        case actions.RESET_LOCATIONS_DATA:
            return {
                ...state,
                locationsData: action.locationsData
            };
        case actions.RESET_SKU_OOS_LIST_DATA:
            return {
                ...state,
                skuOutOfStockData: action.skuOutOfStockData
            };
        case actions.RESET_OOS_SKU_DETAIL_LIST:
            return {
                ...state,
                skuDetailList: action.skuDetailList
            }

        case actions.SET_VISTA_DATA_RECORD:
            let vistaDataObj = { ...state.vistaData };
            let { key, RowNumber, value, extra } = action.vistaData;
            if (key && RowNumber > -1 && value) {
                if (vistaDataObj.records && vistaDataObj.records.length > 0) {
                    let findIndex = vistaDataObj.records.findIndex(d => d.RowNumber === RowNumber);
                    if (findIndex > -1) {
                        if (key === 'ImageSeen') {
                            if (vistaDataObj.records[findIndex][key] && value === 1) {
                                vistaDataObj.NeedAttentionCount--;
                            } else if (value === 2) {
                                vistaDataObj.NeedAttentionCount++;
                            }
                        }
                    }
                    vistaDataObj.records[findIndex][key] = value;
                    if (extra && Object.keys(extra).length) {
                        for (let d in Object.keys(extra)) {
                            vistaDataObj.records[findIndex][d] = extra[d];
                        }
                    }

                    if (key === 'ImageSeen' && value === 1 && vistaDataObj.isNeedsAttention) {
                        vistaDataObj.records.splice(findIndex, 1)
                    }
                }
            }
            return {
                ...state, vistaData: vistaDataObj
            };
        case actions.SET_PREV_NEXT_VISTA_DATA_RECORD:
            let vistaDataObject = { ...state.vistaData };
            let { UpdatedRecord, rowId } = action.vistaData;
            let updateRecordCopy = { ...UpdatedRecord }
            if (updateRecordCopy && rowId) {
                if (vistaDataObject.records && vistaDataObject.records.length > 0) {
                    delete updateRecordCopy.RowNumber;
                    let findIndex = vistaDataObject.records.findIndex(d => d.RowNumber === rowId);
                    if (findIndex > -1) {
                        let id = vistaDataObject.records[findIndex].RowNumber;
                        vistaDataObject.records[findIndex] = { ...updateRecordCopy, RowNumber: id };
                    }
                }
            }
            return {
                ...state, vistaData: vistaDataObject
            };
        case actions.SET_SEARCH_FILTER_VALUE:
            return { ...state, searchValue: action.searchValue };
        case actions.SET_OOS_COLLAPSED_LIST:
            return { ...state, skuCollapsedList: action.skuCollapsedList };
        case actions.SET_SORTING_FILTER_VALUE:
            return { ...state, sortingValue: action.sortingValue };
        case actions.SET_NEEDS_ATTENTION_FILTER_VALUE:
            return { ...state, needsAttentionValue: action.needsAttentionValue };
        case actions.SET_VISTA_LIST_COUNT_DATA:
            return { ...state, ...{ listCountData: action.listCountData } };
        case actions.UPDATE_LOADER_STATE:
            return { ...state, loaderOpen: action.loaderOpen };
        case actions.UPDATE_SCHEDULE_TIME_POPUP:
            return { ...state, ScheduleTimePopup: action.ScheduleTimePopup };
        case actions.SET_STATE_MAGNIFIER:
            return { ...state, openMagnifier: action.openMagnifier };
        case actions.SET_VISTA_SEARCH:
            return { ...state, vistaSearch: action.vistaSearch };
        case actions.SET_VISTA_IMAGE_POPUP:
            return { ...state, vistaPopupData: action.vistaPopupData };
        case actions.SET_NEED_ATTENTION:
            return { ...state, vistaNeedsAttention: action.vistaNeedsAttention };
        case actions.SET_LOCATION_SEARCH:
            return { ...state, locationSearch: action.locationSearch };
        case actions.RESET_STATE:
            return initialState;
        case actions.SET_USER_EMAIL:
            return { ...state, userEmail: action.userEmail, clientsList: action.clientsList };
        case actions.SET_CLIENT_LIST_DATA:
            return { ...state, clientsList: action.clientsList };
        case actions.SET_CLIENT_SELECT:
            return { ...state, selectClient: action.selectClient };
        case actions.SET_USER_PASSWORD:
            return { ...state, userPassword: action.userPassword };
        case actions.SET_PLANOGRAM_CHECKBOX:
            let isPlanogramArr = [...state.isPlanogram];
            let { key: planoKey, value: planoValue } = action.isPlanogram;
            isPlanogramArr[planoKey] = planoValue;
            return { ...state, isPlanogram: isPlanogramArr };
        case actions.SET_INSTORE_CHECKBOX:
            let isStoreArr = [...state.isInStore];
            let { key: storeKey, value: storeValue } = action.isInStore;
            isStoreArr[storeKey] = storeValue;
            return { ...state, isInStore: isStoreArr };
        case actions.SET_OPTIONAL_CHECKBOX:
            let isOptionalArr = [...state.isOptional];
            let { key: optionalKey, value: optionalValue } = action.isOptional;
            isOptionalArr[optionalKey] = optionalValue;
            return { ...state, isOptional: isOptionalArr };
        case actions.SET_OVERVIEW_CHART_DATA:
            return { ...state, overviewChartData: action.overviewChartData };
        case actions.SET_OOS_REPORT_CHART_DATA:
            return { ...state, oosChartData: action.oosChartData };
        case actions.SET_COMBO_DATA:
            return { ...state, comboData: action.comboData };
        case actions.SET_PRE_SALES_DATA:
            return { ...state, preSalesData: action.preSalesData };
        case actions.SET_INVENTORY_DATA:
            return { ...state, inventoryData: action.inventoryData }
        case actions.SET_PRE_SALES_DETAIL_DATA:
            return { ...state, preSalesDetailData: action.preSalesDetailData };
        case actions.SET_FROM_DATE_FILTER:
            return { ...state, fromDateFilterValue: action.fromDateFilterValue };
        case actions.SET_RESET_ORDER_HISTORY_CLEAR:
            return { ...state, clearReset: action.clearReset };
        case actions.SET_TO_DATE_FILTER:
            return { ...state, toDateFilterValue: action.toDateFilterValue };
        case actions.SET_ACTIVATE_LOCATION_REORDER:
            return { ...state, activateLocationReorder: action.activateLocationReorder };
        case actions.SET_SKU_OOS_LIST_DATA:
            let skuOutOfStockDataClone = { ...state.skuOutOfStockData }
            if (!action.skuOutOfStockData.firstFilter && action.skuOutOfStockData && action.skuOutOfStockData.skuRecords && action.skuOutOfStockData.skuRecords.length) {
                for (let d of action.skuOutOfStockData.skuRecords) {
                    let startIndex = action.skuOutOfStockData.startIndex;
                    let findIndexObj = skuOutOfStockDataClone.skuRecords.findIndex(vista => vista.RowNumber === d.RowNumber);
                    if (findIndexObj > -1) {
                        skuOutOfStockDataClone.skuRecords[findIndexObj] = d;
                    } else {
                        d.startIndex = startIndex;
                        skuOutOfStockDataClone.skuRecords.push(d)
                    }
                }
            }

            if (!action.skuOutOfStockData.firstFilter) {
                skuOutOfStockDataClone.skuRecordCount = action.skuOutOfStockData.skuRecordCount;
            }
            return {
                ...state,
                skuOutOfStockData: action.skuOutOfStockData.firstFilter ? action.skuOutOfStockData : skuOutOfStockDataClone
            };
        case actions.SET_OOS_SKU_DETAIL_LIST:
            let skuDetailListDataClone = { ...state.skuDetailList }
            if (!action.skuDetailList.firstFilter && action.skuDetailList && action.skuDetailList.records && action.skuDetailList.records.length) {
                for (let d of action.skuDetailList.records) {
                    let startIndex = action.skuDetailList.startIndex;
                    let findIndexObj = skuDetailListDataClone.records.findIndex(vista => vista.RowNumber === d.RowNumber);
                    if (findIndexObj > -1) {
                        skuDetailListDataClone.records[findIndexObj] = d;
                    } else {
                        d.startIndex = startIndex;
                        skuDetailListDataClone.records.push(d)
                    }
                }
            }

            if (!action.skuDetailList.firstFilter) {
                skuDetailListDataClone.recordCount = action.skuDetailList.recordCount;
            }
            return {
                ...state,
                skuDetailList: skuDetailListDataClone
            };
        case actions.SET_FILTER_VALUES:
            return { ...state, filterValues: action.filterValues };
        case actions.SET_HEADER_FILTERS:
            return { ...state, headerFilters: action.headerFilters };
        case actions.SET_HEADER_TITLE:
            return { ...state, headerTitle: action.headerTitle };
        case actions.SET_CURRENT_RANK:
            return { ...state, currentRank: action.currentRank };
        case actions.SET_CURRENT_POG:
            return { ...state, currentPog: action.currentPog };
        case actions.SET_IMAGE_PREVIEW:
            return { ...state, imagePreview: action.imagePreview };
        case actions.SHOW_VISTA_DETAIL_POP_UP:
            return { ...state, openVistaDetail: action.openVistaDetail }
        case actions.SET_DATE_FILTERS:
            return { ...state, dateFiltersProp: action.dateFiltersProp }
        case actions.SET_PRODUCT_SEARCH:
            let productSearchClone = { ...state.productSearch };
            let { skuSearchValue, inventorySearchValue } = action.productSearch || {};
            productSearchClone["skuSearchValue"] = skuSearchValue;
            productSearchClone["inventorySearchValue"] = inventorySearchValue;
            return { ...state, productSearch: productSearchClone };
        case actions.SET_TELEMETRIC_SEARCH:
            return { ...state, telemetricData: action.telemetricData };
        case actions.SET_LOCATIONS_REORDER_DATA:
            let locationsReorderDataClone = { ...state.locationsReorderData }
            const cloneData = util.getFilterData(action.locationsReorderData, locationsReorderDataClone);
            return { ...state, locationsReorderData: action.locationsReorderData.firstFilter ? action.locationsReorderData : cloneData }
        case actions.SET_SCHEDULED_REORDER_DATA:
            let scheduledReorderDataClone = { ...state.scheduledReorderData }
            const scheduledCloneData = util.getFilterData(action.scheduledReorderData, scheduledReorderDataClone);
            return { ...state, scheduledReorderData: action.scheduledReorderData.firstFilter ? action.scheduledReorderData : scheduledCloneData }
        case actions.SET_IGNORED_REORDER_DATA:
            let ignoredReorderCloneData = { ...state.ignoredReorderData }
            const irnoredCloneData = util.getFilterData(action.ignoredReorderData, ignoredReorderCloneData);
            return { ...state, ignoredReorderData: action.ignoredReorderData.firstFilter ? action.ignoredReorderData : irnoredCloneData }
        case actions.SET_IN_TRANSIT_DATA:
            let inTransitCloneData = { ...state.inTransitData }
            const transitCloneData = util.getFilterData(action.inTransitData, inTransitCloneData);
            return { ...state, inTransitData: action.inTransitData.firstFilter ? action.inTransitData : transitCloneData }
        case actions.SET_SCHEDULED_LOG:
            let scheduledLogsDataClone = { ...state.scheduledLogsData }
            const scheduledLogsData = util.getFilterData(action.scheduledLogsData, scheduledLogsDataClone, true);
            return { ...state, scheduledLogsData: action.scheduledLogsData.firstFilter ? action.scheduledLogsData : scheduledLogsData }
        case actions.SET_ACOSTA_REPORT_DATA:
            let acostaReportDataClone = { ...state.acostaReportData }
            const acostaReportData = util.getFilterData(action.acostaReportData, acostaReportDataClone, true);
            return { ...state, acostaReportData: action.acostaReportData.firstFilter ? action.acostaReportData : acostaReportData }
        case actions.SET_REPLENISH_ORDER:
            return { ...state, replenishData: action.replenishData }
        case actions.SET_REORDER_LOG:
            let reorderLogsDataClone = { ...state.reorderLogsData }
            const reorderLogsData = util.getFilterData(action.reorderLogsData, reorderLogsDataClone, true);
            return { ...state, reorderLogsData: action.reorderLogsData.firstFilter ? action.reorderLogsData : reorderLogsData }
        case actions.SET_KPI_FILTERS:
            return { ...state, kpiFilters: action.kpiFilters }
        case actions.SET_KPI_DATA:
            return { ...state, kpiData: action.kpiData, showKPICharts: true }
        case actions.SET_KPI_GRID_DATA:
            return { ...state, kpiGridsData: action.kpiGridsData, showKPICharts: false }
        case actions.SET_KPI_IMAGE_DATA:
            return { ...state, kpiImageData: action.kpiImageData }
        case actions.SET_CURRENT_LOCALE:
            return { ...state, locale: action.locale }
        case actions.SET_FILTER_PANEL_TOGGLE:
            return { ...state, filterPanelToggle: action.filterPanelToggle }
        case actions.SET_PAGE_TITLE_DETAILS:
            return { ...state, pageTitleDetails: action.pageTitleDetails }
        case actions.PASS_FILTERS_TOHEADER:
            return { ...state, filtersInHeader: action.filtersInHeader }
        case actions.SET_EDIT_MODE:
            return { ...state, editMode: action.editMode }
        case actions.SET_OPERATIONS_INFORMATION:
            return { ...state, operationsCheckedData: { ...state?.operationsCheckedData, ...action.operationsCheckedData } }
        case actions.SET_KPI_PAGE_TITLE:
            return { ...state, pageTitle: action.pageTitle }
        case actions.SET_ANSWER_DATA:
            return { ...state, answerData: action.answerData }
        case actions.SET_IS_EXPANDED:
            return { ...state, expanded: action.expanded }
        case actions.SET_APP_INFO:
            return { ...state, appInfo: action.appInfo }
        case actions.SET_SURVEY_TYPE:
            return { ...state, surveyType: action.surveyType }
        case actions.SET_TYPE_QUESTION:
            return { ...state, typeQuestion: action.typeQuestion }
        case actions.SET_ALL_SURVEY_DATA:
            return { ...state, allSurveyData: action.allSurveyData }
        case actions.SET_ALL_ORDER_HISTORY_APPLIED_FILTERS:
            return { ...state, getAllOrderHistoryAppliedFilters: action.getAllOrderHistoryAppliedFilters }
        case actions.EDIT_REPLENISHMENT_ORDER:
            return { ...state, replenishmentOrder: action.replenishmentOrder }
        case actions.REPLENISHMENT_GRID_DATA:
            return { ...state, replenishmentGridData: action.replenishmentGridData }
        case actions.REPLENISHMENT_GRID_ORDERING_DATA:
            return { ...state, replenishmentGridOrderingData: action.replenishmentGridOrderingData }
        case actions.SET_PAGE_BACK_BUTTON:
            return { ...state, pageBackButton: action.pageBackButton }
        case actions.SET_HIDE_FOOTER:
            return { ...state, hideFooter: action.hideFooter }
        case actions.OVERVIEW_RECORD_SELECT:
            return { ...state, overviewRecordSelect: action.overviewRecordSelect }
        case actions.PRODUCT_CASE_TYPE_DDL:
            return { ...state, productCaseTypeDdl: action.productCaseTypeDdl }
        case actions.SAVE_GROUP_DETAILS:
            return { ...state, groupDetails: action.groupDetails }
        case actions.SAVE_SURVEY_DETAILS:
            return { ...state, surveyDetails: action.surveyDetails }
        case actions.OPEN_MODAL:
            return { ...state, modal: action.modal }
        case actions.HIDE_DATE_FILTER:
            return { ...state, hideDateFilter: action.hideDateFilter };
        case actions.SET_OUTLET_FILTER_STATE_COMBO:
            return { ...state, stateCombo: action.stateCombo }
        case actions.SET_FREQUENCY_CYCLE_DATA:
            return { ...state, frequencyCycleData: action.frequencyCycleData }
        case actions.GRID_HEADER_FILTERS:
            return { ...state, headerFilters: action.headerFilters }
        case actions.SET_CAPTURE_MATRIX:
            return { ...state, captureMatrix: action.captureMatrix };
        case actions.SET_CAPTURE_MATRIX_CLONE:
            return { ...state, captureMatrixClone: action.captureMatrixClone };
        case actions.SET_SCENE_PICTURES:
            return { ...state, scenePicturesTaken: action.scenePicturesTaken };
        case actions.SET_SCENE_PICTURES_CLONE:
            return { ...state, scenePicturesTakenClone: action.scenePicturesTakenClone };
        case actions.SET_CAPTURE_SCENE_QUESTION_IDS:
            let newQuestionIds;
            if (Array.isArray(action.captureSceneQuestionIds)) {
                newQuestionIds = action.captureSceneQuestionIds
            } else {
                if (state.captureSceneQuestionIds.findIndex(id => id === action.captureSceneQuestionIds) > -1) {
                    return state;
                }
                newQuestionIds = [...state.captureSceneQuestionIds, action.captureSceneQuestionIds]
            }

            return { ...state, captureSceneQuestionIds: newQuestionIds };
        case actions.SET_CAPTURE_SCENE_QUESTION_IDS_CLONE:
            let newQuestionIdsClone;
            if (Array.isArray(action.captureSceneQuestionIdsClone)) {
                newQuestionIdsClone = action.captureSceneQuestionIdsClone
            } else {
                if (state.captureSceneQuestionIdsClone.findIndex(id => id === action.captureSceneQuestionIdsClone) > -1) {
                    return state;
                }
                newQuestionIdsClone = [...state.captureSceneQuestionIdsClone, action.captureSceneQuestionIdsClone]
            }

            return { ...state, captureSceneQuestionIdsClone: newQuestionIdsClone };
        case actions.SET_CAPTURE_SCENE_SERIAL_NUMBERS:
            return { ...state, captureSceneSerialNumbers: action.captureSceneSerialNumbers };
        case actions.SET_CAPTURE_SCENE_SERIAL_NUMBERS_CLONE:
            return { ...state, captureSceneSerialNumbersClone: action.captureSceneSerialNumbersClone };
        case actions.SET_IMAGE_SEQUENCE:
            return { ...state, imageSequence: action.imageSequence }
        case actions.SAVE_QUESTIONNAIRE_DETAILS:
            return { ...state, questionnaireDetails: action.questionnaireDetails }
        case actions.SAVE_QUESTIONNARE_LOCATION_DETAILS:
            return { ...state, questionnaireLocationDetails: action.questionnaireLocationDetails }
        case actions.SAVE_QUESTIONNARE_LOCATIONID:
            return { ...state, questionnaireLocationId: action.questionnaireLocationId }
        case actions.SAVE_QUESTIONNAIRE_ASSET_FILTER:
            return { ...state, questionnaireAssetFilter: action.questionnaireAssetFilter }
        case actions.SAVE_QUESTIONNAIRE_PREVIEW_REPEAT:
            return { ...state, questionnairePreviewRepeatOption: action.questionnairePreviewRepeatOption }
        case actions.SAVE_QUESTIONNAIRE_PREVIEW_ASSET_FILTER:
            return { ...state, questionnairePreviewAssetFilter: action.questionnairePreviewAssetFilter }
        case actions.RESET_QUESTIONNAIRE_DETAILS:
            return { ...state, questionnaireDetails: { title: '', description: '', isActive: true } }
        case actions.UDPATE_PREFERENCES:
            return { ...state, preferences: {...state.preferences, [action.model]: { preferences: action.preferences, totalPreferences: action.totalPreferences }} }
        case actions.SET_CURRENT_PREFERENCE_NAME:
            return {...state, currentPreference: {...state.currentPreference, [action.model]: action.currentPreference }};
        case actions.SET_COMBO_DATA_RESULT_ANALYSIS:
            return { ...state, resultAnalysisComboData: action.resultAnalysisComboData };
        case actions.SET_MULTICLIENT_FILTERS: {
            return { ...state, filters: action.filters };
        }
        case actions.SET_MULTICLIENT_COMBO_DATA: {
            return { ...state, multiClientComboData: action.comboData };
        }
        case actions.SET_PAGE_LEFT_FILTERS: {
            return { ...state, pageLeftFilters: action.filters };
        }
        case actions.SET_DATA_GROUP_BY: {
            return { ...state, dataGroupBy: action.groupBy };
        }
        default:
            return state;
    }
}

export default appReducer;
