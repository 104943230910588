import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utils from '../../../utils';

const field = ({ column, field, fieldLabel, formik, otherProps, classes, fieldConfigs, model, mode }) => {
    let isDisabled;
    if (mode !== 'copy') {
        isDisabled = fieldConfigs?.disabled;
    }
    const shouldDisableDate = column.shouldDisableDate ? column.shouldDisableDate : null;
    let helperText;
    if (isDisabled && column.showErrorText) {
        helperText = model?.helperText;
    } else if (formik.touched[field] && formik.errors[field]) {
        helperText = formik.errors[field];
    }
    const showError = !!helperText;
    const props = { variant: "standard", error: showError };
    return <DatePicker
        {...otherProps}
        variant="standard"
        readOnly={column?.readOnly === true}
        key={field}
        fullWidth
        format={utils.systemDateTimeFormat(true)}
        name={field}
        value={dayjs(formik.values[field])}
        onChange={(value) => {
            if (dayjs(value).isValid()) {
                const adjustedDate = dayjs(value).hour(12);
                const isoString = adjustedDate.toISOString();
                formik.setFieldValue(field, isoString);
            } else {
                formik.setFieldValue(field, null);
            }
        }}
        onBlur={formik.handleBlur}
        helperText={formik.touched[field] && formik.errors[field]}
        disablePast={column?.disablePast}
        disabled={isDisabled}
        shouldDisableDate={date => shouldDisableDate ? shouldDisableDate(date, formik) : false}
        slotProps={{ textField: { fullWidth: true, helperText, ...props } }}
    />

}

export default field;