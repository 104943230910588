import React from "react";
import ButtonWithIcon from "./menuComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import actions from "../../redux/actions";

function Print({ t: trans, i18n, history, label }) {
    const dispatch = useDispatch();
    let { onPrint } = useSelector(state => state.appReducer.filtersInHeader) || {};
    const hideFooter = useSelector(state => state.appReducer.hideFooter)

    if (!onPrint) {
        onPrint = async () => {
            await dispatch({ type: actions.SET_HIDE_FOOTER, hideFooter: true });
            await window.print();
        }
    }

    let resetFooter = setInterval(()=>{
        dispatch({ type: actions.SET_HIDE_FOOTER, hideFooter: false });
    },1000)

    if(!hideFooter){
        clearInterval(resetFooter)
    }

    return <>
        <ButtonWithIcon size="small" handleClick={onPrint} disabled={!onPrint} btnClass={"background-theme-blue sub-header-button"} showIconOnMobile={true} showLabelOnWeb={true} icon={<FontAwesomeIcon icon={faPrint} />} showOnMobile={true} label={label} menuItems={[]} iconButtonClass="iconButtonClass" />
    </>
}

export default withTranslation()(withRouter(Print));