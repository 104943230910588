import React from 'react';
import { Card, CardContent, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import utils from "../../utils"
import useMobile from '../../utils/useMobile';
const { t, isModuleExists } = utils;

const CardRouter = ({ childObj = {}, isArray = false, largeCard = false, noRibbon = false, extraText = null, metaData }) => {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const history = useHistory();
    const isMobile = useMobile();
    const userData = useSelector(state => state.appReducer.userData) || {};
    const cardStyle = largeCard ?
        { height: "300px", width: "320px" } :
        (isMobile.mobile && !isMobile.tablet && isMobile.portrait) ?
            { height: "250px", width: "300px" } :
            { height: "250px", width: "250px" };

    const handleRoute = (route) => {
        if (route)
            history.push(route)
    }

    const routes = isArray ? childObj : Object.keys(childObj);

    return (
        <>
            <Stack
                direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                justifyContent={{ xs: 'flex-start', sm: 'center', md: 'flex-start', lg: 'flex-start' }}
                spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                alignItems="center"
                sx={{ flexWrap: 'wrap', gap: 1 }}>
                {routes.map((item) => {
                    const child = isArray ? item : childObj[item];
                    const AppId = isArray ? item.metaData : childObj[item].metaData
                    const showMenu = ((isMobile.mobile && !isMobile.tablet) && AppId.AppId.includes('2')) ? true : (isMobile.tablet && AppId.AppId.includes('3')) ? true : ((!isMobile.mobile && !isMobile.tablet) && AppId.AppId.includes('1')) ? true : false
                    return (<>
                        {(isModuleExists(userData, child.moduleName) && showMenu) ?
                            <Card className={`${child.route ? 'cursor_pointer' : ''} ifh-status-card`} onClick={() => handleRoute(child.route)}>
                                <CardContent className='text-center' style={cardStyle}>
                                    {(!child.noRibbon && !noRibbon) && <div className="ribbon ribbon-top-right">
                                        <span className={child.route ? 'ribbon-green' : 'ribbon-yellow'}>{child.route ? t('New', tOpts) : t('Coming soon', tOpts)}</span>
                                    </div>}
                                    <div>
                                        <img src={require(`../../../src/assets/images/${child.icon}`)} height="70" alt="png Icon" />
                                    </div>
                                    <div>
                                        <Typography className='mb-0' variant="h6" gutterBottom component="div">{t(child.title, tOpts)}</Typography>
                                    </div>
                                    <div style={{ minHeight: '50px' }} >
                                        <Typography className='mb-0' variant="h7" gutterBottom component="div"><i>{t(child.description, tOpts)}</i></Typography>
                                    </div>
                                    {extraText &&
                                        <div className="mb-2">
                                            <Typography className="data-grid-font-12">{extraText[item]?.props.children.map(child => <i>{t(child, tOpts)}</i>)}</Typography>
                                        </div>}
                                </CardContent>
                            </Card> : ""}
                    </>)
                })}
            </Stack>
        </>
    )
}

export default CardRouter;