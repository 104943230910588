import React, { useCallback, useState, useEffect } from 'react';
import { InputBase } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import utils from "../utils"

const HeaderSearch = React.memo(function HeaderSearch({ vistaSearch, dispatch }) {
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };  
    const setSearchValueFun = useCallback(
        (event) => {
            event.preventDefault();
            const { value = '' } = event.target;
            dispatch({ type: 'SET_VISTA_SEARCH', vistaSearch: value });
        }, [dispatch])

    const [openInput, setOpenInput] = useState(false);

    const searchAction = () => setOpenInput(!openInput);

    useEffect(() => {
        if (vistaSearch && !openInput) {
            setOpenInput(!openInput)
        }
    }, [vistaSearch])

    return <div>
        <div id="wrap">
            <div style={{ position: 'relative' }} className="header-search-wrapper">
                <InputBase
                    placeholder={t("Filter by Location…", tOpts)}
                    value={vistaSearch} onChange={setSearchValueFun} 
                    classes={{
                      
                        input: clsx('default-input', { 'input-onClick': openInput })
                      }}
                    inputProps={{ 'aria-label': 'search' }}
                />
              <div className="search_submit" onClick={() => searchAction()}></div>
            </div>
        </div>
    </div>
});
const mapStateToProps = state => ({
    vistaSearch: state.appReducer.vistaSearch,
});

export default connect(mapStateToProps)(HeaderSearch);