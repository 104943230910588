import React from "react";
import { request, apis } from "../../httpUtil/";
import { Grid, Container, Card, CardContent, Button } from "@material-ui/core";
import { useDispatch } from 'react-redux';
import utils from "../../utils";
import ChangeLanguage from "../../components/ChangeLanguage";
import MailIcon from '@material-ui/icons/Mail';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import clsx from "clsx";
const isMobile = utils.isMobile()?.length;
const subDomain = utils.getSubdomain();
const isExistImage = () => {
  try {
    return require(`../../assets/images/login-images/${subDomain}.png`);
  } catch (err) {
    return require("../../assets/images/login-images/logo.png");
  }
}
const Logo = isExistImage();

const ChooseSSO = () => {

  const dispatch = useDispatch();

  const processLogin = async (policyName) => {
    let params = { policyName: policyName };
    const response = await request({ url: apis.login, params, dispatch });
    if (response) {
      window.location.href = response.RedirectUrl;
    }
  };

  return (
    <div className="app-wrapper min-vh-100">
      <div className="app-main flex-column">
        <div className={clsx("app-content  p-0 min-vh-100", { 'w-100': isMobile })}>
          <div className="app-content--inner d-flex align-items-center">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content py-5">
                <Container maxWidth="lg" className="login-container">
                  <Grid container className="login-container w-100">
                    <Grid item xs={12} sm={12} lg={5} className="d-flex align-items-center flex-column" >
                      <img alt="..." className="w-60 mx-auto d-block img-fluid logo-img" src={Logo} />
                    </Grid>
                    <Grid item xs={12} lg={12} className={`d-flex flex-column align-items-center ${isMobile ? 'w-100' : "width-60"}`}>
                      <div className=" d-flex flex-row justify-content-end  w-100">
                        <ChangeLanguage />
                      </div>
                      <Card className="m-0 w-100 p-0 border-0">
                        <CardContent className="p-5">
                          <Grid container spacing={3}>
                            <Grid item xs={4} md={4} sm={4} lg={4}  >
                              <Button
                                className="background-theme-blue sub-header-button"
                                fullWidth={true}
                                startIcon={<MailIcon />}
                                onClick={(e) => { processLogin("B2C_1A_SIGNUP_SIGNIN_MICROSOFT") }}>
                                Personal
                              </Button>
                            </Grid>
                            <Grid item xs={4} md={4} sm={4} lg={4}  >
                              <Button
                                className="background-theme-blue sub-header-button"
                                fullWidth={true} startIcon={<ContactMailIcon />}
                                onClick={(e) => { processLogin("B2C_1A_SIGNUP_SIGNIN") }} >
                                Corporate
                              </Button>
                            </Grid>
                            <Grid item xs={4} md={4} sm={4} lg={4}  >
                              <Button
                                className="background-theme-blue sub-header-button"
                                fullWidth={true}
                                startIcon={<MailOutlineIcon />}
                                onClick={(e) => { processLogin("B2C_1A_SIGNUP_SIGNIN_GMAIL") }}>
                                Gmail
                              </Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChooseSSO;
