import React, { useState, useEffect } from 'react';
import utils from '../../utils';
import {
  Card, CardContent, Typography, CardHeader, CardActions
} from "@material-ui/core";
import dayjs from "dayjs";
import { GoogleMap, Marker, MarkerClusterer, InfoWindow, useLoadScript } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import DialogModal from '../Common/DialogModel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import * as JsError from '../../JsError';
const t = utils.t;
const clusterMarker = require('../../assets/images/map/cluster-marker.png');
const outletMarker = require('../../assets/images/map/outlet-marker.png');
const mapStyles = { height: "100vh", width: "100%" };
const clusterStyle = [{ url: clusterMarker, width: 50, height: 50, textSize: 14, textColor: "white", className: 'map-cluster-text' }]

const PopoverList = ({ items, isOpen, onClose, onItemClick, t, tOpts }) => {

  const tableCellStyle = { padding: '8px 4px', height: '10px' }
  const tableRowStyle = { cursor: 'pointer', height: '10px' }
  return (
    <DialogModal
      dialogTitle={`${t("Outlets", tOpts)}`}
      maxWidth="sm"
      dividers={true}
      onClose={onClose}
      className={'vista-model-bx-sm'}
      open={isOpen}
      hideFooter={true}
      contentStyle={{ display: 'block', padding: '10px' }}
    >
      <Table>
        <TableBody>
          {items.map((item, index) => (
            <TableRow
              key={index}
              hover
              onClick={() => {
                onItemClick(item);
              }}
              style={tableRowStyle}
            >
              <TableCell style={tableCellStyle}>{item.outletName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DialogModal>
  );
};


const OutletMap = ({ openOutletCard, outletData = [], outletLoading }) => {
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const [mapMarkers, setMapMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState({});
  const [clusteredOutletsList, setClusteredOutletsList] = useState([]);
  const [isClusteredBoxOpen, setIsClusteredBoxOpen] = useState(false);
  const vistaSearch = useSelector(state => state.appReducer.vistaSearch);
  const [searchDebounce] = useDebounce(vistaSearch, 1000);
  const onCloseClusteredBox = () => { setIsClusteredBoxOpen(false) };
  const dispatch = useDispatch();
  const { isLoaded, } = useLoadScript({
    googleMapsApiKey: utils.Google_ApiKey,
    language: i18n.language
  });

  useEffect(() => {
    const mapMarkersGenerator = async () => {
      try {
        if (outletData.length > 0) {
          const localMarkerArray = [], localMarkerMap = [];
          for (let i = 0; i < outletData.length; i++) {
            let latitude = outletData[i].Latitude,
              longitude = outletData[i].Longitude,
              key = latitude + ":" + longitude;

            //if latitude and longitude is 0 do not render markers on map  
            if (latitude != 0 || longitude != 0) {
              localMarkerArray.push({
                lat: latitude,
                lng: longitude,
                id: outletData[i].LocationId,
                outletData: outletData[i]
              });
              localMarkerMap.push(key);
            }
            setMapMarkers(localMarkerArray);
          }
        }
      } catch (error) {
        JsError.handleGlobalError("Error on Map component", '', 0, 0, error);
      }
    }
    mapMarkersGenerator();
  }, [outletData, vistaSearch])

  const onLoad = React.useCallback(function callback(map) {
    map.setCenter({ lat: 0, lng: 0 });
    map.setZoom(3);
    setTimeout(() => {
      dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false })
    }, 5000)
  }, []);

  function clusterPriority(markers, icons) {
    let highestPriorityColor = 1;
    return {
      text: markers.length,
      index: highestPriorityColor
    };
  }
  const handleClusterClick = (cluster) => {
    // Get all markers associated with the cluster
    const zoomLevel = cluster.getMap().getZoom();
    if (zoomLevel > 21) {
      const outlets = cluster.markers.map(el => {
        let outlet = {}
        const parts = el.getTitle().split(',');
        outlet.LocationId = parts[0];
        outlet.outletName = parts[1];
        return outlet
      })
      setClusteredOutletsList(outlets)
      setIsClusteredBoxOpen(true)
    }
  };
  return (
    <Card className='m-0 p-0'>
      {isClusteredBoxOpen &&
        <PopoverList items={clusteredOutletsList} isOpen={isClusteredBoxOpen} onClose={onCloseClusteredBox} t={t} tOpts={tOpts} onItemClick={openOutletCard} />
      }

      <CardContent className='m-0 p-0' style={{ height: '75.7vh' }}>
        {isLoaded && !outletLoading ?
          outletData.length > 0 ?
            <GoogleMap
              onClick={() => setSelectedMarker({})}
              mapContainerStyle={mapStyles}
              onLoad={onLoad}
              zoom={3}
              options={{ gestureHandling: 'greedy', minZoom: 0.2, language: i18n.language }}>
              <MarkerClusterer
                averageCenter={true}
                calculator={clusterPriority}
                onClick={handleClusterClick}
                styles={clusterStyle}
                zoomOnClick={!isClusteredBoxOpen}>
                {(clusterer) =>
                  mapMarkers.map((location) => (
                    <Marker
                      key={location.id}
                      position={new window.google.maps.LatLng(location.lat, location.lng)}
                      title={`${location.id},${location.outletData.Name}`}
                      clusterer={clusterer}
                      onClick={() => { openOutletCard(location.outletData) }}
                      animation={"BOUNCE"}
                      icon={{ url: outletMarker, scaledSize: new window.google.maps.Size(50, 50) }}
                    />
                  ))
                }
              </MarkerClusterer>
              {(selectedMarker.lat && selectedMarker.lng) &&
                <InfoWindow
                  options={{ pixelOffset: new window.google.maps.Size(0, -55) }}
                  position={new window.google.maps.LatLng(selectedMarker.lat, selectedMarker.lng)}
                  clickable={true}
                  onCloseClick={() => setSelectedMarker({})}
                  className="info-window">
                  <Card className='m-0 p-0 remove-shadow cursor_pointer' onClick={() => { openOutletCard(selectedMarker.outletData) }}>
                    <CardHeader className='m-0 p-0' title={selectedMarker.outletData?.LocationName} subheader={selectedMarker.outletData?.Code} />
                    <CardContent className='p-2 text-center' style={{ height: "200px" }}>
                      <img
                        className='cursor_pointer m-0 p-0'
                        src={`${selectedMarker.outletData?.StitchedImageURL}&maxWidth=300`}
                        alt={selectedMarker.outletData?.LocationName}
                        style={{ height: '100%', maxWidth: '100%' }} />
                    </CardContent>
                    <CardActions className='m-0 p-0'>
                      <Typography variant="caption">{t("Last Image Date:", tOpts)} {dayjs(selectedMarker.StitchedImageURL?.PurityDateTime).format('DD MMM YYYY')}</Typography>
                    </CardActions>
                  </Card>
                </InfoWindow>
              }
            </GoogleMap> : <div className='no-map-location'>{t("No map location found", tOpts)}</div>
          : <div className='no-map-location'></div>
        }
      </CardContent>
    </Card>
  );
}

export default OutletMap;