import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { CellMeasurer } from 'react-virtualized';
import CoolerView2 from './CoolerView2';
import ImageNotFound from '../../assets/images/vista-images/imagenotfound.jpg';
import shareIcon from '../../assets/images/vista-images/share.svg';
import copyIcon from '../../assets/images/vista-images/copy.png'
import ShowPlanogramInStore from '../Common/ShowPlanogramInStore';
import VistaCardMobile from '../VistaLocationCardList/VistaCardMobile';
import VistaImages from './VistaImages';
import { Tooltip, Box, Card, Divider, Grid, Typography } from '@material-ui/core';
import SocialShare from "../SocialShare";
import utils from '../../utils';
import dateUtil from '../../utils/date';
import { automationModel } from '../../constants'
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import useMobile from '../../utils/useMobile';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import actions from '../../redux/actions';
import { apis, request } from '../../httpUtil';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { ExpandedRowOrderingTab } from './orderingGrid';
import CopyModal from './CopyModal';
const t = utils.t;

const { COMPASS } = automationModel;

const VistaDetailCard = React.memo(function VistaDetailCard({
    keyCard, index, style, parent, cache, hideChildInfo, manualData = null, isCardContentOnly = false, isOutletCard = false,
    hideHeaderFilter, hidePrevNext, showImages = true, t: translate, i18n, onClose, showImageOnly = false, showTable = false, replenishmentData = [], showShoppingCart = false, orderSummaryTab = false, outletDataForOrderingTab = {} }) {
    const isMobile = useMobile();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    const [automationValues, setAutomationValues] = React.useState({ totalScoreAvg: 0, checked: false, fullCount: 0, lowCount: 0, emptyCount: 0, automationData: {} });
    const dispatch = useDispatch();
    const history = useHistory();
    const inStoreData = manualData?.TagStatus ? JSON.parse(manualData.TagStatus)?.tags || [] : [];
    //Vista Data From redux 
    const vistaData = useSelector(state => state.appReducer.vistaData, shallowEqual);
    const data = manualData ? manualData : (vistaData.records && vistaData.records[index]) || {}
    const isPOSImage = data && data.SceneTypeId == 5;
    const tOpts = { t: translate, i18n };
    const [imageData, setImageData] = React.useState(data);
    const [replenishmentDataTable, setReplenishmentDataTable] = React.useState(replenishmentData);
    const loginData = useSelector(state => state.appReducer.userData);
    const replenishData = useSelector(state => state.appReducer.replenishData);
    const DateFormat = loginData?.tags?.CustomDateTimeFormat;
    const onShare = useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, [])

    useEffect(() => {
        if (automationValues.checked) {
            setAutomationValues({ totalScoreAvg: 0, checked: false, fullCount: 0, lowCount: 0, emptyCount: 0, automationData: {} });
        }
    }, [data]);


    useEffect(() => {
        setReplenishmentDataTable(replenishmentData);
    }, [replenishmentData]);

    const memorizedShare = useMemo(() => <span className="green" style={{ cursor: 'pointer', flex: '.09' }} onClick={onShare}> <img src={shareIcon} alt={""} /></span>, [onShare])

    const closeShareLink = () => setAnchorEl(null);
    let mobile = isMobile.mobile
    //Manage child AssetData, if available 
    const hasChild = data && data.Children && data.Children.length && !hideChildInfo ? true : false;
    let child_Data, child_imageURL = ImageNotFound, child_AssetSerialNumber, child_AssetId;
    let child_purityDateTime = "";
    let { AvgPlanogramCompliance, AvgStockPercentage, PurityDateTime, ProductInfo } = isOutletCard ? imageData : data;
    if (hasChild) {
        child_Data = data && data.Children && data.Children[0];
        if (child_Data) {
            child_imageURL = (child_Data.StitchedImageURL) || ImageNotFound;
            child_AssetSerialNumber = child_Data.AssetSerialNumber;
            child_purityDateTime = child_Data.PurityDateTime;
            child_AssetId = child_Data.AssetId
        }
    }
    let purityDateTimeLabel = PurityDateTime ? dateUtil.dateMatch(PurityDateTime) : "";
    let childPurityDateTimeLabel = child_purityDateTime ? dateUtil.dateMatch(child_purityDateTime) : "";

    if (Object.keys(data).length <= 0) {
        return hideHeaderFilter ?
            <div></div> : t("No Data", tOpts)
    }

    const LatestDateTimeLabel = ({ dateTime, extraLabel = '' }) => {
        return (<span className={`ellipsis-text card-footer-flex-asset ${mobile ? 'ml-1' : ''}`}>{extraLabel ? t(extraLabel, tOpts) + ' - ' : ''}{mobile && !isMobile.tablet ? <small>{dateTime}</small> : dateTime}</span>);
    }

    const handleOpen = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    let totalFacings = ProductInfo && ProductInfo.reduce(function (sum, rec) {
        return sum + rec.FacingInPlanogram;
    }, 0);

    let coolerProduct = ProductInfo && ProductInfo.filter(e => !(e.IsEmpty || e.IsForeign));
    let totalCoolerProduct = coolerProduct && coolerProduct.reduce(function (sum, rec) {
        return sum + rec.FacingInCooler;
    }, 0);
    const { RowNumber, LocationName = '', AssetSerialNumber = '', Street = '', Street2 = '', Street3 = '', City = '', State = '', PostalCode = '', AssetId, Code = '' } = data;
    let assetAddress = (Street ? Street : '') +
        (Street2 ? ', ' + Street2 : '') +
        (Street3 ? ', ' + Street3 : '') +
        (City ? ', ' + City : '') +
        (State ? ', ' + State : '') +
        (PostalCode ? ', ' + PostalCode : '');
    let coolerProductPerc = totalFacings === 0 ? totalFacings : (totalCoolerProduct / totalFacings) * 100;
    let toolTipTitle = `${LocationName}, ${assetAddress}`;
    const columns = [
        { field: 'productName', headerName: t('Product Name', tOpts), width: mobile ? 100 : 230 },
        { field: 'sku', headerName: t('SKU', tOpts), width: 120 },
        { field: 'planogramFacings', headerName: t('Planogram Facings', tOpts), width: mobile ? 20 : 40, type: 'number' },
        { field: 'recognizedFacings', headerName: t('Recognized Facings', tOpts), width: mobile ? 50 : 60, type: 'number' },
        { field: 'stockLevel', headerName: t('Stock Level (In %)', tOpts), width: mobile ? 50 : 70, type: 'number' },
    ];

    const rows = replenishmentDataTable?.map((e, index) => ({
        id: index,
        productName: e[3],
        sku: e[1],
        planogramFacings: e[5],
        recognizedFacings: e[4],
        stockLevel: e[9] === 0 ? e[9] : e[9].toFixed(2),
    }));

    let createdOn = replenishData.length !== 0 ? replenishData[0]?.CreatedOn : '';
    if (createdOn) {
        createdOn = dayjs(Number(createdOn.replace(/[/Date()/]/g, ""))).format(DateFormat || dateUtil.formats.dateTimeIn);
    }

    const cardContentOnly = () => {
        return <>
            <div style={{ ...style }} key={keyCard}
                className={`card-wrapper  ${!hideHeaderFilter ? "vista-container-custom" : ''} ${mobile ? "over-flow-auto" : ""} ${isCardContentOnly ? "mb-0" : ""}`}>
                {(!hideHeaderFilter && mobile) && <div className="card-row-mobile-wrapper">
                    <VistaCardMobile data={{ ...data }} />
                </div>}
                <div className={isMobile.landscape && isMobile.tablet || !mobile ? "card-row-wrapper" : ""} id="outlet-ordering-detail-left-container" >
                    {isOutletCard &&

                        <Grid container spacing={1} className={isMobile.portrait || !isMobile.tablet && mobile && isMobile.landscape ? "card-row-wrapper" : ""} >
                            <>
                                {showImageOnly &&
                                    <Grid item xs={12} sm={12} md={12} style={{ border: '1px solid black', borderRadius: '5px', height: '457px' }} className="d-flex">
                                        <Card className="Vista-Detail-left-card-container">
                                            <VistaImages data={isOutletCard ? imageData : data} isOutletCard={isOutletCard}
                                                setImageData={setImageData} index={index} hidePrevNext={hidePrevNext}
                                                leftImageDateTime={PurityDateTime} rightImageDateTime={child_purityDateTime} idx={index} isSingleImage={true} setReplenishmentDataTable={setReplenishmentDataTable} />
                                            <div >
                                                <div className={clsx("d-flex")} style={{ padding: '.5rem 1rem' }}>
                                                    <LatestDateTimeLabel dateTime={purityDateTimeLabel} />
                                                    <Tooltip title={t("share this information", tOpts)} placement="right">{memorizedShare}</Tooltip>
                                                    <SocialShare data={data} anchorEl={anchorEl} closeShareLink={closeShareLink} />
                                                    {!hideChildInfo && <Tooltip title={t("Open Card for Copy", tOpts)} placement="right" >
                                                        <span style={{ cursor: 'pointer', flex: '.09' }} onClick={() => handleOpen()}>
                                                            <img src={copyIcon} height="20" alt={"Copy"} />
                                                        </span>
                                                    </Tooltip>
                                                    }
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>}
                                {showModal && <CopyModal
                                    idx={keyCard}
                                    data={{
                                        toolTipTitle: toolTipTitle,
                                        LocationName: LocationName,
                                        hasChild: hasChild,
                                        imageURL: imageData.StitchedImageURL,
                                        child_imageURL: child_imageURL,
                                        AssetSerialNumber: AssetSerialNumber,
                                        child_AssetSerialNumber: child_AssetSerialNumber,
                                        purityDateTimeLabel: purityDateTimeLabel,
                                        childPurityDateTimeLabel: childPurityDateTimeLabel
                                    }}
                                    showModal={showModal}
                                    handleClose={handleClose} />}
                                {(!showImageOnly && !showTable && !showShoppingCart) ?
                                    (mobile && !orderSummaryTab) ? "" : <Grid item xs={12} sm={12} md={12} className='d-flex' style={{ border: '1px solid black', borderRadius: '5px', height: '457px' }}>
                                        <Card className={clsx(" w-100", { "hide-component": (mobile && !orderSummaryTab) })} >
                                            {!isPOSImage && <ShowPlanogramInStore index={index} label={AvgPlanogramCompliance}
                                                coolerProductPerc={coolerProductPerc} isOutletCard={isOutletCard} />}
                                            <div className="divider" />
                                            <div className="d-flex customScrollbar" style={{ overflow: 'scroll' }} key={Math.random()}>
                                                <CoolerView2 index={index} data={isOutletCard ? imageData : data} showSeparator={true} inStoreData={inStoreData} />
                                                {!isPOSImage && hasChild && <>
                                                    <Divider orientation="vertical" flexItem />
                                                    <CoolerView2 index={index} data={child_Data} showSeparator={true} inStoreData={inStoreData} />
                                                </>}
                                            </div>
                                        </Card>
                                    </Grid>
                                    :
                                    null}

                                {showTable &&
                                    <Grid md={12} sm={12} lg={12} style={mobile ? {} : { border: '1px solid black', borderRadius: '5px', height: '457px', padding: '5px' }}>
                                        <span className={`ellipsis-text card-footer-flex-asset ${mobile ? 'ml-1' : ''}`}>{t('Recognition Table', tOpts)}</span>
                                        <DataGridPremium
                                            rows={rows}
                                            columns={columns}
                                            pagination={false}
                                            hideFooter={true}
                                            density='compact'
                                            sx={{ height: '414px' }}
                                            disableAggregation={true}
                                            disableRowGrouping={true}
                                            disableColumnFilter={true}
                                            disableColumnMenu={true}
                                            rowSelection={false}
                                            disableSelectionOnClick
                                        />
                                    </Grid>
                                }

                                {showShoppingCart &&
                                    <Grid md={12} sm={12} lg={12} style={mobile ? {} : { border: '1px solid black', borderRadius: '5px', height: '457px', padding: '5px' }}>
                                        <span className={`ellipsis-text card-footer-flex-asset ${mobile ? 'ml-1' : ''}`}>{t('Suggested Order Table', tOpts)}</span>
                                        <ExpandedRowOrderingTab data={outletDataForOrderingTab.ProductInfo} isReorder={false} locationId={outletDataForOrderingTab.LocationId} row={outletDataForOrderingTab} t={t} tOpts={tOpts} />
                                    </Grid>
                                }
                            </>
                        </Grid>

                    }
                </div>
            </div>
        </>
    }


    return (<>
        {isCardContentOnly ?
            <>{cardContentOnly()}</> :
            <CellMeasurer key={keyCard} cache={cache} parent={parent} columnIndex={0} rowIndex={index} >
                {cardContentOnly()}
            </CellMeasurer>}
    </>
    );
})

export default withTranslation()(VistaDetailCard);