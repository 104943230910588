import React, { useState } from "react";
import { useSelector } from 'react-redux';
import deviceTrackingModel from '../device-tracking';
import DeviceTrackingChildModel from "../device-tracking-child";
import { Grid, Paper } from '@mui/material';
import MultiSelectDropdown from "../../components/mutliSelectDropdown";

const config = {
    label: "Clients",
    titleMessage: 'Select & Unselect All',
    multiSelectLabel: 'Multiple Clients',
}

const DeviceTracking = () => {
    const [selectedClients, setSelectedClients] = useState([]);
    const clientsList = useSelector(state => state.appReducer.clientsList) || {};

    const handleClientSelectChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === -1) {
            setSelectedClients(selectedClients.length === clientsList.length ? [] : clientsList.map(client => client.Key));
            return;
        }
        setSelectedClients(value);
    };

    return (
        <div>
            {(clientsList?.length > 1) ?
                <Paper elevation={3} style={{ backgroundColor: 'white', padding: '20px', width: '350px' }}>
                    <MultiSelectDropdown config={config} options={clientsList} selectedOptions={selectedClients} handleChange={handleClientSelectChange} />
                </Paper>
                : null
            }
            <Grid item className='width-100 mt1'>
                <deviceTrackingModel.Grid selectedClients={selectedClients} />
            </Grid>
        </div >
    )
}

export default DeviceTracking;