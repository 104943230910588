import React, { memo, useCallback, useMemo, useState } from 'react';
import { InputAdornment, FormControl, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux';
import { LOGIN_CONST } from '../../constants';
import actions from '../../redux/actions';
import { withTranslation } from 'react-i18next';
import { VisibilityOff, Visibility } from '@material-ui/icons';

import utils from '../../utils';
const t = utils.t;
const UserPassword = memo(function ({ t: translate, i18n }) {
    const tOpts = { t: translate, i18n }
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const memoizedUserPassIcon = useMemo(() => <InputAdornment className='ml-2' style={{ cursor: 'pointer' }} onClick={handleClickShowPassword} position="end">{showPassword ? <Visibility /> : <VisibilityOff />}</InputAdornment>, [showPassword]);

    //Set user select client in redux
    const memoizedSetUserPassword = useCallback((e) => dispatch({ type: actions.SET_USER_PASSWORD, userPassword: e.target.value, }), [dispatch]);
    const userPassword = useSelector(state => state.appReducer.userPassword);

    return <FormControl className="w-100  my-2 mt-4" key="user-password-control">
        <TextField
            label={t(LOGIN_CONST.PASSWORD, tOpts) + ' *'}
            id="standard-basic"
            variant="standard"  type={`${showPassword ? 'input' : 'password'}`} InputProps={{endAdornment: memoizedUserPassIcon}} onChange={memoizedSetUserPassword} value={userPassword} />
    </FormControl>
})

export default withTranslation()(UserPassword);
