import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Paper,
  Typography,
  CardContent,
  Button,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dbUtils from "../../../pages/RenderSurveyAnswer/dbUtil";
import { makeStyles } from "@material-ui/core";
import utils from "../../../utils";
import { useTranslation } from "react-i18next";
import constants from "../../../utils/constants";
import { useHistory } from "react-router";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMobile from "../../../utils/useMobile";
import { renderQuestionDetails } from "./RenderQuestionDetails";
import { ListDynamicOptions, QuestionInformation } from "./QuestionList";
import { Info } from "@material-ui/icons";
import { addRepeatedQuestions } from "./AddRepeatedQuestions";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%"
  }
}));

const RepeatItemContainer = ({ name, classes, children }) => {
  return (
    <>
      <Accordion className={classes.fullWidth}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${name}-content`}
          id={`${name}-id`}
        >
          <Typography>{name}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  );
};

const QuestionPreview = ({ droppedItems, showView, editMode, selectedPreviewTab, formatQuestionTypeText }) => {
  const t = utils.t;
  const { t: translate, i18n } = useTranslation();
  const tOpts = { t: translate, i18n };
  const {
    questionnairePreviewRepeatOption,
    questionnaireLocationId,
    questionnairePreviewAssetFilter,
    questionnaireLocationDetails,
  } = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  const [questionnaireInit, setQuestionnaireInit] = useState(droppedItems);
  const [showInfoFor, setShowInfoFor] = useState(null);
  const history = useHistory();
  const isMobile = useMobile();
  const classes = useStyles();
  let { assets, products, productsContextSensitive, productsInStorePlanogram } =
    questionnaireLocationDetails;
  if (questionnaireLocationId) {
    assets = { [questionnaireLocationId]: assets[questionnaireLocationId] };
    productsInStorePlanogram = {
      [questionnaireLocationId]:
        productsInStorePlanogram[questionnaireLocationId],
    };
  }
  if (questionnairePreviewAssetFilter) {
    productsContextSensitive = {
      [questionnairePreviewAssetFilter]:
        productsContextSensitive[questionnairePreviewAssetFilter],
    };
  }
  const [answerFields, setAnswerFields] = useState([]);
  const [repeatCountArray, setRepeatCountArray] = useState([]);

  React.useEffect(() => {
    const setQuestionnaire = async () => {
      const questionnaireInit = await dbUtils.get("questionnaire");
      setQuestionnaireInit(questionnaireInit);
    };
    setQuestionnaire();
  }, []);

  function checkRepeatQuestion(questionnaire, sectionId, questionId) {
    let isRepeatQuestion, answerKey;
    const section = questionnaire?.find((section) => section.id === sectionId);
    const pivotQuestion = section?.questions.find((question) => question.id === questionId);
    isRepeatQuestion = typeof section?.items === 'string' && pivotQuestion.repeat && pivotQuestion.repeatkey;
    if (isRepeatQuestion) {
      answerKey = pivotQuestion?.repeatkey;
    }
    return {isRepeatQuestion: isRepeatQuestion, repeatkey: answerKey};
  }
  const updateRepeatCount = ( questionnaire, sectionId, questionId, value, section, existingIndex ) => {
    if (!questionnaire || !sectionId || !questionId) return;
    let isRepeatQuestion, answerKey, iscorrectOption;
    const pivotQuestion = section?.questions.find((question) => question.id === questionId);
    isRepeatQuestion = !!(typeof section?.items === 'string' && pivotQuestion.repeat && pivotQuestion.repeatkey);
    if (isRepeatQuestion) {
      answerKey = pivotQuestion?.repeatkey;
    }
    iscorrectOption = answerKey?.includes(Number(value)) || false;
    return {isForRepeatCount: !!(isRepeatQuestion && iscorrectOption), isRepeatQuestion: isRepeatQuestion};
  }


  const handleChange = (event, sectionId, assetSerialNumber, product, qUid, repeatItem, section) => {
    event.preventDefault();
    let { type, value } = event.target
    if (type === 'number') {
      if (value.includes('e')) {
        value = value.replace(/e/g, "");
      }
    }
    let isForRepeatCount, isRepeatQuestion;
    let newAnswerObject = {
      assetSerialNumber: assetSerialNumber,
      product: product,
      sectionId: sectionId,
      questionId: qUid,
      answer: value,
    };
    let newRepeatObject = {
      repeatItem: repeatItem,
      sectionId: sectionId,
      questionId: qUid,
      repeatCount: 1
    }
    if (repeatItem) {
      newAnswerObject.repeatItem = repeatItem;
    }
    let existingIndex = null, existingRepeatIndex = null;
    if (repeatItem) {
      existingIndex = answerFields.findIndex(
        (item) =>
          item.sectionId === sectionId &&
          item.questionId === qUid &&
          item.repeatItem === repeatItem
      );
      existingRepeatIndex = repeatCountArray.findIndex(
        (item) =>
          item.sectionId === sectionId &&
          item.questionId === qUid &&
          item.repeatItem === repeatItem
      );
    } else {
      existingIndex = answerFields.findIndex(
        (item) =>
          item.sectionId === sectionId &&
          item.questionId === qUid
      );
      existingRepeatIndex = answerFields.findIndex(
        (item) =>
          item.sectionId === sectionId &&
          item.questionId === qUid
      );
    }
    if (type !== 'number') {
      ({ isForRepeatCount, isRepeatQuestion } = updateRepeatCount(questionnaireInit, sectionId, qUid, value, section, existingIndex));
    }
    if (existingIndex !== -1) {
      setAnswerFields((prevArray) => {
        const newArray = [...prevArray];
        newArray[existingIndex].answer = value;
        return newArray;
      });
    } else {
      setAnswerFields((prevArray) => [...prevArray, newAnswerObject]);
    }
    if (existingRepeatIndex !== -1) {
      if (repeatItem && isRepeatQuestion) {
        setRepeatCountArray((prevArray) => {
          const newArray = [...prevArray];
          if (isForRepeatCount) {
            newArray[existingRepeatIndex].repeatCount = 1
          } else {
            if (existingRepeatIndex > -1) {
                newArray.splice(existingRepeatIndex, 1);
            }
          }
          return newArray;
        });
      } 
    } else {
      if (repeatItem && isRepeatQuestion && isForRepeatCount) {
        setRepeatCountArray((prevArray) => [...prevArray, newRepeatObject]);
      }
    }
  };

  const handleDateChange = (value, sectionId, assetSerialNumber, product, qUid, repeatItem) => {
    let newAnswerObject = {
      assetSerialNumber: assetSerialNumber,
      product: product,
      sectionId: sectionId,
      questionId: qUid,
      answer: value,
    };
    if (repeatItem) {
      newAnswerObject.repeatItem = repeatItem;
    }
    let existingIndex = null;
    if (repeatItem) {
      existingIndex = answerFields.findIndex(
        (item) =>
          item.sectionId === sectionId &&
          item.questionId === qUid &&
          item.repeatItem === repeatItem
      );
    } else {
      existingIndex = answerFields.findIndex(
        (item) =>
          item.sectionId === sectionId &&
          item.questionId === qUid
      );
    }
    if (existingIndex !== -1) {
      setAnswerFields((prevArray) => {
        const newArray = [...prevArray];
        newArray[existingIndex].answer = value;
        return newArray;
      });
    } else {
      setAnswerFields((prevArray) => [...prevArray, newAnswerObject]);
    }
  };

  const handleMultipleSelectChange = (
    selectedValues,
    sectionId,
    assetSerialNumber,
    product,
    qUid,
    repeatItem,
    section
  ) => {
    let newAnswerObject = {
      assetSerialNumber: assetSerialNumber,
      product: product,
      sectionId: sectionId,
      questionId: qUid,
      answer: selectedValues,
    };

    let newRepeatObject = {
      repeatItem: repeatItem,
      sectionId: sectionId,
      questionId: qUid,
      repeatCount: 1,
    };

    if (repeatItem) {
      newAnswerObject.repeatItem = repeatItem;
    }

    let existingIndex = null, existingRepeatIndex = null;
    let isForRepeatCount, isRepeatQuestion;
    if (repeatItem) {
      existingIndex = answerFields.findIndex(
        (item) =>
          item.sectionId === sectionId &&
          item.questionId === qUid &&
          item.repeatItem === repeatItem
      );
      existingRepeatIndex = repeatCountArray.findIndex(
        (item) =>
          item.sectionId === sectionId &&
          item.questionId === qUid &&
          item.repeatItem === repeatItem
      );
    } else {
      existingIndex = answerFields.findIndex(
        (item) =>
          item.assetSerialNumber === assetSerialNumber &&
          item.product === product &&
          item.sectionId === sectionId &&
          item.questionId === qUid
      );
      existingRepeatIndex = answerFields.findIndex(
        (item) =>
          item.sectionId === sectionId &&
          item.questionId === qUid
      );
    }
    ({ isForRepeatCount, isRepeatQuestion } = updateRepeatCount(questionnaireInit, sectionId, qUid, selectedValues, section, existingIndex));
    if (existingIndex !== -1) {
      setAnswerFields((prevArray) => {
        const newArray = [...prevArray];
        newArray[existingIndex].answer = selectedValues;
        return newArray;
      });
    } else {
      setAnswerFields((prevArray) => [...prevArray, newAnswerObject]);
    }
    if (existingRepeatIndex !== -1) {
      if (repeatItem && isRepeatQuestion) {
        setRepeatCountArray((prevArray) => {
          const newArray = [...prevArray];
          if (isForRepeatCount) {
            newArray[existingRepeatIndex].repeatCount = 1
          } else {
            if (existingRepeatIndex > -1) {
                newArray.splice(existingRepeatIndex, 1);
            }
          }
          return newArray;
        });
      } 
    } else {
      if (repeatItem && isRepeatQuestion && isForRepeatCount) {
        setRepeatCountArray((prevArray) => [...prevArray, newRepeatObject]);
      }
    }
  };

  const handleCheckboxOptionChange = (
    event,
    sectionId,
    assetSerialNumber,
    product,
    qUid,
    repeatItem,
    section
  ) => {
    event.preventDefault();
    const selectedValue = event.target.value;

    let newAnswerObject = {
      assetSerialNumber: assetSerialNumber,
      product: product,
      sectionId: sectionId,
      questionId: qUid,
      answer: [selectedValue],
    };

    let newRepeatObject = {
      repeatItem: repeatItem,
      sectionId: sectionId,
      questionId: qUid,
      repeatCount: 1,
    };

    if (repeatItem) {
      newAnswerObject.repeatItem = repeatItem;
    }

    let existingIndex = null, existingRepeatIndex = null;
    let isForRepeatCount, isRepeatQuestion;
    if (repeatItem) {
      existingIndex = answerFields.findIndex(
        (item) =>
          item.sectionId === sectionId &&
          item.questionId === qUid &&
          item.repeatItem === repeatItem
      );
      existingRepeatIndex = repeatCountArray.findIndex(
        (item) =>
          item.sectionId === sectionId &&
          item.questionId === qUid &&
          item.repeatItem === repeatItem
      );
    } else {
      existingIndex = answerFields.findIndex(
        (item) =>
          item.sectionId === sectionId &&
          item.questionId === qUid
      );
      existingRepeatIndex = answerFields.findIndex(
        (item) =>
          item.sectionId === sectionId &&
          item.questionId === qUid
      );
    }
    ({ isForRepeatCount, isRepeatQuestion } = updateRepeatCount(questionnaireInit, sectionId, qUid, selectedValue, section, existingIndex));
    if (existingIndex !== -1) {
      setAnswerFields((prevArray) => {
        const newArray = [...prevArray];
        const answerArray = newArray[existingIndex].answer;

        if (answerArray.includes(selectedValue)) {
          newArray[existingIndex].answer = answerArray.filter((value) => value !== selectedValue);
        } else {
          answerArray.push(selectedValue);
        }
        return newArray;
      });
    } else {
      setAnswerFields((prevArray) => [...prevArray, newAnswerObject]);
    }
    if (existingRepeatIndex !== -1) {
      if (repeatItem && isRepeatQuestion) {
        setRepeatCountArray((prevArray) => {
          const newArray = [...prevArray];
          if (isForRepeatCount) {
            newArray[existingRepeatIndex].repeatCount = 1
          } else {
            if (existingRepeatIndex > -1) {
                newArray.splice(existingRepeatIndex, 1);
            }
          }
          return newArray;
        });
      } 
    } else {
      if (repeatItem && isRepeatQuestion && isForRepeatCount) {
        setRepeatCountArray((prevArray) => [...prevArray, newRepeatObject]);
      }
    }
  }; 

  const getAnswerValue = (
    sectionId,
    assetSerialNumber,
    product,
    questionId
  ) => {
    const answerObject = answerFields.find(
      (item) =>
        item.sectionId === sectionId &&
        item.assetSerialNumber === assetSerialNumber &&
        item.product === product &&
        item.questionId === questionId
    );
    return answerObject ? answerObject.answer : "";
  };

  const toggleQuestionInformation = (qUid) => {
    setShowInfoFor(showInfoFor === qUid ? null : qUid);
  }

  const renderSectionContent = (section, index, classes, renderQuestionDetails, handleChange, getAnswerValue, handleCheckboxOptionChange, handleMultipleSelectChange, handleDateChange, editMode, t, tOpts, showView, answerFields, setAnswerFields, item) => {
    return (
    <CardContent key={index} className={classes.fullWidth}>
      <Paper>
        {section.questions && section.questions.length > 0 &&
          section.questions.map((question, j) => (
            ((question.visibility !== 0 && question.text.length) ||
              (question.visibility === 0 &&
                question.text.length &&
                question.dependentQuestion &&
                answerFields.some((answer) =>
                  question.dependentQuestion.some((dependentQuestion) => {
                    const dependentAnswerKeys = dependentQuestion.answerkey.map(key => key.toString());
                    return (
                      dependentQuestion.behaviour === "show" &&
                      (
                        (Array.isArray(answer.answer) ?
                          answer.answer.some(val => dependentAnswerKeys.includes(val.toString())) :
                          dependentAnswerKeys.includes(answer.answer.toString())
                        ) &&
                        dependentQuestion.question === answer.questionId &&
                        (answer.repeatItem ? answer.repeatItem === item : !answer.repeatItem && !item)
                      )
                    )
                  })
                ))) && (
              <CardContent key={j}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "space-between",
                    padding: 0,
                    fontSize: 16,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                  >
                    {j + 1}. {question.text.length ? question.text : constants.defaultQuestionText}
                  </Typography>
                  <Button
                    variant="text"
                    className="float-right"
                    sx={{
                      marginTop: '-5px !important'
                    }}
                    startIcon={
                      <Tooltip
                        title={<QuestionInformation question={question} formatQuestionTypeText={formatQuestionTypeText} />}
                        placement="right"
                      >
                        <Info />
                      </Tooltip>
                    }
                    onClick={() => toggleQuestionInformation(question.questionUniqueId)}
                  ></Button>
                </Grid>
                {constants.questionTypesWithDynamicOptions.includes(question.type) ? (
                  <ListDynamicOptions
                    question={question}
                    showView={showView}
                    editMode={editMode}
                    handleChange={handleChange}
                    handleCheckboxOptionChange={handleCheckboxOptionChange}
                    section={section.id}
                    getAnswerValue={getAnswerValue}
                    answerFields={answerFields}
                    setAnswerFields={setAnswerFields}
                    item={item}
                    handleMultipleSelectChange={handleMultipleSelectChange}
                  />
                ) : null}
                {!question.dynamicOptionLookup || question.dynamicOptionLookup === 0
                  ? renderQuestionDetails(
                    question,
                    j,
                    handleChange,
                    section.id,
                    item,
                    getAnswerValue,
                    handleCheckboxOptionChange,
                    editMode,
                    handleMultipleSelectChange,
                    handleDateChange,
                    t,
                    tOpts,
                    section
                  )
                  : null}
              </CardContent>
            )
          ))}
      </Paper>
    </CardContent>
  )
  };

  const renderItems = (
    items,
    questionnaireInit,
    answerFields,
    renderQuestionDetails,
    questionnairePreviewRepeatOption,
    t,
    tOpts
  ) => {
    const repeatSectionQuestionnaires = questionnaireInit.filter(section => typeof (section.items) === 'string');
    const questionnaireSection = questionnaireInit[selectedPreviewTab];

    return (
      <>
        {typeof (questionnaireSection.items) === 'object' && (
          <Paper variant="outlined" sx={{ margin: 1 }}>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <CardContent>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 0,
                      fontSize: 16,
                    }}
                  >
                    {questionnaireInit[selectedPreviewTab] ? (
                      <>
                        {renderSectionContent(questionnaireInit[selectedPreviewTab], selectedPreviewTab, classes, renderQuestionDetails, handleChange, getAnswerValue, handleCheckboxOptionChange, handleMultipleSelectChange, handleDateChange, editMode, t, tOpts, showView, answerFields, setAnswerFields, null)}
                      </>
                    ) : (
                      <Typography
                        variant="subtitle1"
                        className={classes.fullWidth}
                        gutterBottom
                      >
                        {t("No questionnaire sections added", tOpts)}
                      </Typography>
                    )}
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </Paper>
        )}
        {typeof (questionnaireSection.items) === 'string' &&
          (Object.keys(items).map((key) => {
            const itemArray = items[key];
            return itemArray?.map((item, i) => {
              let currentSection = questionnaireSection;
              const repeatCountObjects = repeatCountArray.filter((object) => object.sectionId === currentSection?.id &&
                object.repeatItem.SerialNumber === item.SerialNumber && object.repeatItem.Product === item.Product);
              const repeatCount = repeatCountObjects.reduce((sum, item) => sum + (item.repeatCount || 0), 0)
              for (let i = 1; i <= repeatCount; i++) {
                const { noListFound = {}, questionJsonCopy = {} } = addRepeatedQuestions(currentSection, i) || {};
                currentSection = questionJsonCopy || currentSection;
              };
              return (
                <RepeatItemContainer
                  key={i}
                  name={
                    questionnairePreviewRepeatOption === utils.questionnaireAssetText
                      ? item.SerialNumber
                      : item.Product
                  }
                  classes={classes}
                >
                  <Paper variant="outlined" sx={{ margin: 1 }}>
                    <Grid container xs={12}>
                      <Grid item xs={12}>
                        <CardContent>
                          <Grid
                            container
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: 0,
                              fontSize: 16,
                            }}
                          >
                            {currentSection && currentSection?.questions.length > 0 ? (
                              <>
                                {renderSectionContent(currentSection, 0, classes, renderQuestionDetails, handleChange, getAnswerValue, handleCheckboxOptionChange, handleMultipleSelectChange, handleDateChange, editMode, t, tOpts, showView, answerFields, setAnswerFields, item)}
                              </>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={classes.fullWidth}
                                gutterBottom
                              >
                                {t("No questionnaire sections added", tOpts)}
                              </Typography>
                            )}
                          </Grid>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Paper>
                </RepeatItemContainer>
              )
            });
          }))
        }
      </>
    );
  };

  return (
    <Paper sx={{ padding: 2 }} elevation={0}>
      <Grid container alignItems="center" spacing={2}>
        <div className={classes.fullWidth}>
          <RenderRepeatPanels
            renderItems={renderItems}
            assets={assets}
            productsInStorePlanogram={productsInStorePlanogram}
            products={products}
            productsContextSensitive={productsContextSensitive}
            questionnaireInit={questionnaireInit}
            answerFields={answerFields}
            handleChange={handleChange}
            renderQuestionDetails={renderQuestionDetails}
            getAnswerValue={getAnswerValue}
            handleCheckboxOptionChange={handleCheckboxOptionChange}
            handleMultipleSelectChange={handleMultipleSelectChange}
            questionnairePreviewRepeatOption={questionnairePreviewRepeatOption}
            t={t}
            tOpts={tOpts}
          />
        </div>
      </Grid>
    </Paper>
  );
};

const RenderRepeatPanels = ({
  renderItems,
  assets,
  productsInStorePlanogram,
  products,
  productsContextSensitive,
  questionnaireInit,
  answerFields,
  handleChange,
  renderQuestionDetails,
  getAnswerValue,
  handleCheckboxOptionChange,
  questionnairePreviewRepeatOption,
  handleMultipleSelectChange,
  t,
  tOpts,
}) => {
  if (!questionnairePreviewRepeatOption) {
    return <></>;
  }
  const repeatValue = constants.dynamicOptionType[questionnairePreviewRepeatOption];
  const selectedItem = constants.previewRepeatType[repeatValue];
  const items = {
    0: [],
    1: assets,
    2: products,
    3: productsContextSensitive,
    4: productsInStorePlanogram
  };

  if (items[selectedItem]) {
    return renderItems(
      items[selectedItem],
      questionnaireInit,
      answerFields,
      renderQuestionDetails,
      questionnairePreviewRepeatOption,
      t,
      tOpts
    );
  }
  return <></>;
};

export default QuestionPreview;
