import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, ListItem, ListItemIcon, ListItemText, Modal, Paper, Typography } from '@mui/material';
import { renderIcon } from './CustomInputs';
import utils from '../../../utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(5),
        width: "70%",
    },
    listContainer: {
        marginLeft: '20px',
        marginRight: '20px'
    },
    container: {
        position: 'relative',
        padding: theme.spacing(2),
        marginTop: "30px"
    }
}));

const QuestionTypeContainer = ({ name, children, t, tOpts }) => {
    return (
        <>
            <Typography>{t(name,tOpts)}</Typography>
            {children}
        </>
    );
};

const QuestionsModal = ({ open, handleClose, list, addQuestion, prevQuestionIndex }) => {

    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };

    const classes = useStyles();

    function formatQuestionTypeText(str) {
        return str
            .toLowerCase()
            .replace(/(^|\s)\S/g, (match) => match.toUpperCase());
    }

    const listA = list.slice(0, list.length / 2);
    const listB = list.slice( list.length / 2);

    return (
        <Modal open={open} onClose={handleClose} className={classes.modal}>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6} >
                        {listA?.map((key, i) => {
                            const propertyName = Object.keys(key)[0];
                            const itemsInArray = key[propertyName] || [];
                            return (
                                <QuestionTypeContainer name={propertyName} key={i} t={t} tOpts={tOpts}>
                                    {itemsInArray?.map((item, index) => {
                                        return (
                                            <Paper
                                                elevation={0}
                                                onClick={() => addQuestion(item, prevQuestionIndex + 1, handleClose)}
                                                key={index}
                                            >
                                                <ListItem className='cursor-pointer'>
                                                    <ListItemIcon>
                                                        {item.displayName ? renderIcon(item) : null}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={t(formatQuestionTypeText(item.displayName), tOpts)}
                                                    />
                                                </ListItem>
                                            </Paper>
                                        )

                                    })}
                                </QuestionTypeContainer>
                            )
                        })}
                    </Grid>
                    <Grid item xs={6} md={6} >
                        {listB?.map((key, i) => {

                            const propertyName = Object.keys(key)[0];
                            const itemsInArray = key[propertyName] || [];
                            return (
                                <QuestionTypeContainer name={propertyName} key={i} t={t} tOpts={tOpts}>
                                    {itemsInArray?.map((item, index) => {
                                        return (
                                            <Paper
                                                elevation={0}
                                                key={index}
                                                onClick={() => addQuestion(item, prevQuestionIndex + 1, handleClose)}
                                            >
                                                <ListItem className='cursor-pointer'>
                                                    <ListItemIcon>
                                                        {item.displayName ? renderIcon(item) : null}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={t(formatQuestionTypeText(item.displayName), tOpts)}
                                                    />
                                                </ListItem>
                                            </Paper>
                                        )

                                    })}
                                </QuestionTypeContainer>
                            )
                        })}
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
};

export default QuestionsModal;
