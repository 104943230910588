import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { request, apis } from "../httpUtil";
import actions from '../redux/actions'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import localization from '../components/Common/Localization';
import { store } from '../redux/store'
import swal from 'sweetalert';
import { MOVEMENT_STATUS, ORDER_STATUS, MODULE_TO_REMOVE } from '../constants';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Button from "@material-ui/core/Button";
import toast from 'react-hot-toast';
import { getGridDateOperators } from '@mui/x-data-grid-pro';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import tablePreferenceEnums from './tablePreferenceEnums';
import constants from './constants';
import 'dayjs/locale/tr';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import { nanoid } from 'nanoid';
import * as JsError from '../JsError';
import { transport } from '../httpUtil/httpRequest';

const toastConfig = {
	position: 'bottom-right',
	style: {
		background: '#000',
		color: '#fff'
	}
};

const emptyOperatorFilters = ["isEmpty", "isNotEmpty"];
const { QUESTION_TYPES, CREATOR_QUESTION_DISPLAYNAME, maximumQuantityToOrder } = constants;
dayjs.extend(localizedFormat);
dayjs.extend(utc)
dayjs.extend(timezone)

const comboStores = ["SalesPerson", "LocationClassification", "Market", "LocationType", "State", "Country", "RestockDays", "OrderRange", "CaseRange", "FilterType", "AlertStatus", "ReorderLocationStatus", "Client", "AlertType", "RouteType"];
const analysisCombos = ["City", "Users", "Market", "Distributor"];
let loading = false;
const utils = {
	admin: 'Admin',
	superAdmin: 'Super Admin',
	operator: 'Operator',
	vistaGetDataFun: null,
	dashboardMenuId: 7184,
	batteryPermissionClientIds: [134],
	unileverUKClient: [291],
	SEARCH_DEBOUNCE: 800,
	dateFormat: "YYYY-MM-DD",
	dateWithTime: 'dd-MM-yyyy hh:mm:ss A',
	dateWithoutTime: 'dd-MM-yyyy',
	dateWithTimeFormat: 'DD-MM-YYYY hh:mm:ss A',
	dateWithoutTimeFormat: 'DD-MM-YYYY',
	dateTimeFormat: 'YYYY-MM-DD hh:mm:ss A',
	timeFormat: 'hh:mm A',
	forgotPasswordTimeInSeconds: 60,
	correctEmailResponseEnum: "5",
	Google_ApiKey: 'AIzaSyDsD1XV1hcrT05Rs896wUQ7wC7Dm50Pc3A',
	MUI_License: '7035b8c523111ed55150653713c75fbcTz02NzY3MixFPTE3MTcxODg1NjQ4NjUsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
	push_notification_pubKey: 'BFRPyzT4kOXz1Ckf5vRF4UyH7M_GBjRojxZki1BpGV5Ey7Qm0bv93vgbmksR5w2Dopb1Hgg3u0E1ZfbqTgeM-ks',
	autoSizerEnum: {
		heightOffset: {
			smallDevice: 10,
			mediumDevice: 24,
			default: 0
		},
		rowHeight: {
			smallDevice: 538,
			mediumDevice: 100,
			default: 0
		}
	},
	gridFilterOperatorMappings: {
		"contains": {
			operator: "like",
			value: "%@value%"
		},
		"startsWith": {
			operator: "like",
			value: "@value%"
		},
		"endsWith": {
			operator: "like",
			value: "%@value"
		},
		"equals": {
			operator: "="
		},
		"isAnyOf": {
			operator: "IN",
		},
		"isNotEmpty": {
			operator: "IS NOT",
		},
		"isEmpty": {
			operator: "IS",
		},
		is: {
			operator: "="
		},
		not: {
			operator: "!="
		},
		after: {
			operator: ">"
		},
		before: {
			operator: "<"
		},
		"onOrBefore": {
			operator: "<="
		},
		"onOrAfter": {
			operator: ">="
		},

	},
	filterType: {
		"contains": "STRING_CONTAINS",
		"startsWith": "STRING_STARTS_WITH",
		"endsWith": "STRING_ENDS_WITH",
		"equals": "STRING_EQUAL",
		"isAnyOf": "STRING_LIST",
		"isNotEmpty": "STRING_DIFFERENT",
		"isEmpty": "STRING_EQUAL",
		"is": "DATE_EQUAL",
		"onOrBefore": "DATE_LESS_OR_EQUAL",
		"onOrAfter": "DATE_GREATER_OR_EQUAL",
		"not": "DATE_NOT_EQUAL",
		"before": "DATE_LESS",
		"after": "DATE_GREATER",
		// "isEmpty": "DATE_ISNULL",
		// "isNotEmpty": "DATE_IS_NOT_NULL",
		"date_range": "DATE_RANGE",
		"=": "NUMBER_EQUAL",
		"!=": "NUMBER_NOT_EQUAL",
		">": "NUMBER_GREATER",
		">=": "NUMBER_GREATER_OR_EQUAL",
		"<": "NUMBER_LESS",
		"<=": "NUMBER_LESS_OR_EQUAL"
	},
	kpiTables: {
		'assetPurity': ['KPI6', 'KPI7', 'KPI8', 'KPI9', 'KPI10', 'PurityPercentage', "CaseSuggested", "ValueSuggested"],
		'assetDayKPI': ['SalesOpportunity', 'TargetPortfolioCompliance', 'TargetPortfolioOutOfStock', 'AssortmentDepth', 'FacingCompliance', 'DoorOpenDailyCount']
	},
	kpiUnits: {
		'AssetsCovered': '',
		'AssortmentDepth': '',
		'DoorOpenDailyCount': '',
		'KPI6': "%",
		'TargetPortfolioCompliance': "%",
		'TargetPortfolioOutOfStock': "%",
		'KPI9': "",
		'KPI10': "%",
		"PurityPercentage": "%",
		'SalesOpportunity': "",
		"FacingCompliance": "%",
		"PlanogramCompliance": "%",
		"CaseSuggested": "",
		"ValueSuggested": "",
		"KPI1": "",
		"KPI2": "",
		"KPI3": "",
		"KPI4": "",
		"KPI5": "",
		"TotalWeight": '',
		'ForeignProductPercentage': "%",
		'EmptyProductPercentage': "%"
	},
	kpiNames: {
		salesOpportunity: "SalesOpportunity",
		valueSuggested: "ValueSuggested",
		planogramCompliance: "PlanogramCompliance",
		assetsCovered: "AssetsCovered",
		doorOpenCount: "DoorOpenDailyCount"
	},
	customDateOperators() {
		let newOperators = getGridDateOperators(true).filter(operator => operator.value !== 'not');
		const operatorsToRemove = ["not", "isEmpty", "isNotEmpty", "is"];
		for (const op of operatorsToRemove) {
			newOperators = newOperators.filter(operator => operator.value !== op)
		}
		return newOperators;
	},
	DEFAULT_KPI_GROUP_BY: [
		{ label: "Market", dataKey: "MarketId", value: true },
		{ label: "Classification", dataKey: "ClassificationId", value: false, },
		{ label: "Channel", dataKey: "LocationTypeId", value: false, },
		{ label: "Asset Type", dataKey: "Asset.AssetTypeId", value: false, },
	],
	dateOperator: {
		"onOrBefore": "<=",
		"onOrAfter": ">=",
		"not": "!=",
		"before": "<",
		"after": ">",
		"is": "="
	},
	operatorSign: {
		"DATE_EQUAL": "=",
		"DATE_LESS_OR_EQUAL": "<=",
		"DATE_GREATER_OR_EQUAL": ">=",
		"DATE_NOT_EQUAL": "!=",
		"DATE_LESS": "<",
		"DATE_GREATER": ">"
	},
	chartColors: [
		{ bg: '#E74231', br: '#E74231' },		// red
		{ bg: '#172eb5', br: '#172eb5' },		// blue
		{ bg: '#57C980', br: '#57C980' },		// green
		{ bg: 'rgba(255, 206, 86, 0.7)', br: 'rgba(255, 206, 86)' },		// yellow
		{ bg: '#FF8A00', br: '#FF8A00' },		// orange
		{ bg: '#9C3BC3', br: '#9C3BC3' },	// purple
		{ bg: 'rgba(201, 203, 207, 0.7)', br: 'rgba(201, 203, 207)' },	// grey
		{ bg: 'rgba(255, 55, 132, 0.7)', br: 'rgba(255, 55, 132)' },
		{ bg: 'rgba(247, 120, 37, 0.7)', br: 'rgba(247, 120, 37)' },
		{ bg: 'rgba(0, 168, 19, 0.7)', br: 'rgba(0, 168, 19)' },
		{ bg: 'rgba(55, 159, 122, 0.7)', br: 'rgba(55, 159, 122)' },
		{ bg: 'rgba(204, 39, 56, 0.7)', br: 'rgba(204, 39, 56)' },
		{ bg: 'rgba(139, 98, 138, 0.7)', br: 'rgba(139, 98, 138)' },
		{ bg: 'rgba(143, 190, 0, 0.7)', br: 'rgba(2143, 190, 0)' },
		{ bg: 'rgba(96, 96, 96, 0.7)', br: 'rgba(96, 96, 96)' },
	],
	chartColorsD: [
		'#E74231',		// red
		'#172eb5',		// blue
		'#57C980',		// green
		'rgba(255, 206, 86)',		// yellow
		'#FF8A00',		// orange
		'#9C3BC3',	// purple
		'rgba(201, 203, 207)',	// grey
		'rgba(255, 55, 132)',
		'rgba(247, 120, 37)',
		'rgba(0, 168, 19)',
		'rgba(55, 159, 122)',
		'rgba(204, 39, 56)',
		'rgba(139, 98, 138)',
		'rgba(2143, 190, 0)',
		'rgba(96, 96, 96)'
	],
	operatorId: {
		0: "=",
		1: "!=",
		2: "<",
		3: ">",
		4: ">=",
		5: "<="
	},
	DEFAULT_KPI_COUNT_BY: "Location.LocationId",
	daysDropDown: [
		{ LookupId: "-1", DisplayValue: "Custom" },
		{ LookupId: "7:day", DisplayValue: "Last 7 Days" },
		{ LookupId: "14:day", DisplayValue: "Last 14 Days" },
		{ LookupId: "30:day", DisplayValue: "Last 30 Days" },
		{ LookupId: "90:day", DisplayValue: "Last 90 Days" },
		{ LookupId: "1:year", DisplayValue: "Last Year" },
		{ LookupId: "0:currentWeek", DisplayValue: "Week to Date" },
		{ LookupId: "0:currentMonth", DisplayValue: "Month to Date" },
		{ LookupId: "0:currentQuarter", DisplayValue: "Quarter to Date" },
		{ LookupId: "0:currentYear", DisplayValue: "Year to Date" },
	],
	KPI: [
		{ LookupId: "7:day", DisplayValue: "OSA" },
		{ LookupId: "14:day", DisplayValue: "OOSTotal" },
		{ LookupId: "30:day", DisplayValue: "Facings" },
		{ LookupId: "90:day", DisplayValue: "Facing-days" },
	],
	activeLocationStorage: "activeLocation",
	allLocationStorage: "allLocations",
	getCountData: async (response, history) => {
		let { ClientId } = response ? response.tags : "";
		let params = { action: 'otherAction', otherAction: 'GetDataCount' };
		if (Number(ClientId) > 0) {
			params = { ...params, ...{ clientId: Number(ClientId) } }
		}
		const listResponse = await request({ url: apis.assetLatestImages, params, history, dispatch: store.dispatch });
		if (listResponse && listResponse.success) {
			store.dispatch({ type: actions.SET_VISTA_LIST_COUNT_DATA, listCountData: listResponse.data });
		}
	},

	getComboData: async (history, userData) => {
		let params = {
			action: "load", isForAssignedSalesPerson: true
		};
		let comboTypes = [], filteredMarket = [], filteredClassification = [], filteredDistributor = [], filteredPreSellerRoutes = [];
		const { ClientId: tagsClientId = '' } = userData?.tags || {};
		for (var i = 0, len = comboStores.length; i < len; i++) {
			let comboObject = { type: comboStores[i], loaded: true }
			comboTypes.push(comboObject);
		}
		if (Array.isArray(comboTypes)) {
			params.comboTypes = JSON.stringify(comboTypes);
		}

		const [portalCombo, KPIFilter] = await Promise.all([
			request({ url: apis.Asset, params, history, dispatch: store.dispatch }),
			request({ url: apis.KPIFilter, params: {}, history, dispatch: store.dispatch, jsonPayload: true })
		]);
		if (userData) {
			const { combos } = await request({ url: apis.FilteredLookups, params: { clientIds: tagsClientId.toString() }, disableLoader: false, jsonPayload: true, history, dispatch: store.dispatch });
			filteredMarket = combos?.Market?.filter(utils.filteredLookup);
			filteredClassification = combos?.LocationClassification?.filter(utils.filteredLookup);
			filteredDistributor = combos?.Distributor?.filter(utils.filteredLookup);
			filteredPreSellerRoutes = combos?.PreSellerRoute?.filter(utils.filteredLookup);
		}

		if (portalCombo && KPIFilter) {
			portalCombo.combos = portalCombo.combos || {};
			const filteredUserMarkets = portalCombo?.combos?.Market?.filter((market) => Object.keys(KPIFilter?.market || {}).includes(market.DisplayValue))
			portalCombo.combos.Market = filteredUserMarkets || []
		}
		if (portalCombo && userData) {
			portalCombo.combos.Market = filteredMarket;
			portalCombo.combos.LocationClassification = filteredClassification;
			portalCombo.combos.Distributor = filteredDistributor;
			portalCombo.combos.PreSellerRoute = filteredPreSellerRoutes;
			store.dispatch({ type: actions.SET_COMBO_DATA, comboData: { ...portalCombo.combos } });
		}
	},
	afterLogin(userRoutes, history, userData) {
		const searchParams = history.location ? history.location.search : "";
		let pathname = history.location.pathname;
		pathname = pathname.split("/")[1]; // extracting the base path in case of param passed in route
		pathname = '/' + pathname;
		if (!!pathname && pathname !== '/' && pathname !== '/login' && userRoutes.includes(pathname)) {
			let queryString = searchParams.replace('?redirectUrl=', "/").split('?');
			history.replace({ pathname: queryString[0], search: queryString.length > 1 ? queryString[1] : "" });
		}
		else {
			window.location.href = window.location.origin + "/#" + userRoutes[0];
		}
		if (userData) {
			this.getComboData(history, userData);
		}
	},

	reLogin: function (history) {
		let params = { method: 'GET', credentials: 'include', url: apis.login, };
		const transport = axios.create({ withCredentials: true });

		transport(params).then((response) => {
			let sendToLogin = true;
			if (response && response.status === 200) {
				if (response.data && response.data.tags) {
					const { ScheduledOrderId: scheduledOrderId = 0, SalesLevelId: salesLevelId = 0 } = response.data.tags;
					if (scheduledOrderId > 0) {
						history.replace({ pathname: "/Replenishment/LocationReorder" });
					}
					if (MODULE_TO_REMOVE[salesLevelId]) {
						for (const module of MODULE_TO_REMOVE[salesLevelId]) {
							delete response.data.modules[module];
						}
					}
				}

				if (response.data.clientList) {
					store.dispatch({ type: actions.SET_CLIENT_LIST_DATA, clientsList: response.data.clientList });
					store.dispatch({ type: actions.SET_USER_EMAIL, userEmail: response.data.tags.Username, clientsList: response.data.clientList })
					history.replace({ pathname: "/login" });
				}
				else {
					const userRoutes = this.getAssignedRoutes(response.data)
					if (response.data.message === "Logged in" && userRoutes?.length) {
						sendToLogin = false;
						store.dispatch({ type: actions.SET_USER_DATA, userData: response.data });
						this.afterLogin(userRoutes, history, response.data);
						if (response.data && response.data.tags && response.data.tags.Language) {
							localization.setLanguage(response.data.tags.Language);
						}
					}
					else {
						if (response.data.RedirectUrl) {
							history.replace({ pathname: "/choosesso" });
						}
						else {
							let state = {};
							if (response && response.data && response.data?.tags?.ScheduledOrderId === -1) {
								state = { ScheduledOrderId: response.data.tags.ScheduledOrderId, message: response.data.message, ClientId: response.data.tags.ClientId }
							}
							history.replace({ pathname: "/login", state });
						}
					}
				}
			}
		});
	},

	sleep(milliseconds) {
		const date = Date.now();
		let currentDate = null;
		do {
			currentDate = Date.now();
		} while (currentDate - date < milliseconds);
	},

	hexCodeToRGBA(h) {
		h = h.replace('#', '');
		let r = 0, g = 0, b = 0;
		if (h.length === 6) {
			r = "0x" + h[1] + h[1];
			g = "0x" + h[2] + h[2];
			b = "0x" + h[3] + h[3];
		}

		return "rgba(" + +r + "," + +g + "," + +b + "," + .2 + ")";
	},
	string_ToRGB(str = '') {
		if (!str) {
			return;
		}
		let hash = 0;
		let length = str.length
		for (let i = 0; i < length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
		let colour = '#';
		for (let i = 0; i < 3; i++) {
			let value = (hash >> (i * 8)) & 0xFF;
			colour += ('00' + value.toString(16)).substr(-2);
		}
		return colour;
	},

	mapChildren(list) {
		let map = {}, node, roots = [], i, len = list.length;
		for (i = 0; i < len; i += 1) {
			map[list[i].AssetId] = i; // initialize the map
			list[i].children = []; // initialize the children
		}
		for (i = 0; i < len; i += 1) {
			node = list[i];
			if (node.ParentAssetId !== 0) {
				// if you have dangling branches check that map[node.parentId] exists
				if (list[map[node.ParentAssetId]]) {
					list[map[node.ParentAssetId]].children.push(node);
				}
			} else {
				roots.push(node);
			}
		}
		return roots;
	},
	round(num, precision, method) {

		// Convert string numbers to a float
		num = parseFloat(num);

		// If there's no rounding precision, return the number
		if (!precision) return num.toLocaleString();

		// Possible methods and their values
		var methods = {
			auto: 'round',
			up: 'ceil',
			down: 'floor'
		};

		// Get the method function
		var fn = methods[method];
		if (!fn) {
			fn = 'round';
		}

		// Do math!
		return (Math[fn](num / precision) * precision).toString();
	},
	getPrecisionValue(maxValue) {
		return maxValue <= 10 ? 5 : maxValue > 10 && maxValue <= 100 ? 10 : maxValue > 100 && maxValue <= 150 ? 20 : maxValue > 100 && maxValue <= 500 ? 50 : maxValue > 500 && maxValue <= 1000 ? 100 : 500;
	},
	// Number formatting eg -> 2324 -> 2,324
	formatNumValue(value) {
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	},
	stringFormatter(cellData) {
		return cellData.length > 23 ? cellData.toString().substr(0, 23) + '...' : cellData;
	},
	extractMenu(menuArray, finalMenuArray) {
		for (let i = 0, arrayLength = menuArray && menuArray.length; i < arrayLength; i++) {
			if (menuArray[i].children && menuArray[i].children.length) {
				this.extractMenu(menuArray[i].children, finalMenuArray)
			} else {
				finalMenuArray.push(menuArray[i].url)
			}
		}
	},
	getAssignedRoutes(userData) {
		let tempArray = (userData && userData.menu && userData.menu.children) ? userData.menu.children : [];
		if (tempArray.length) {
			const result = tempArray.filter(obj => obj.id === this.dashboardMenuId);
			let menuArray = result[0] && result[0].children ? result[0].children : [], finalMenuArray = []
			const homeIndex = menuArray.findIndex((v) => v.text === "Home");
			if (homeIndex > 0) {
				const temp = menuArray[0];
				menuArray[0] = menuArray[homeIndex];
				menuArray[homeIndex] = temp;
			}
			this.extractMenu(menuArray, finalMenuArray)
			return finalMenuArray
		}
	},
	getFilterData(newData, OldData, isFromLog = false) {
		if (!newData.firstFilter && newData && newData.records && newData.records.length) {
			for (let d of newData.records) {
				let startIndex = newData.startIndex;
				let findIndexObj = OldData.records.findIndex(el => isFromLog ? el.Id == d.Id : el.LocationId == d.LocationId);
				if (findIndexObj > -1) {
					OldData.records[findIndexObj] = d;
				} else {
					d.startIndex = startIndex;
					OldData.records.push(d)
				}
			}
		}
		if (!newData.firstFilter) {
			OldData.recordCount = newData.recordCount;
			OldData.TotalCount = newData.TotalCount
			OldData.PlanogramComplianceAvg = newData.PlanogramComplianceAvg;
			OldData.OutOfStockAvg = newData.OutOfStockAvg;
		}
		return OldData;
	},
	getSubdomain() {
		let host = window.location.host;
		let subdomain = host.split('.')[0];
		return subdomain
	},
	getExportColumn(currencySymbol, key, colList) {
		let columns = colList;
		if (colList && colList.length > 0) {
			let dataIndex = colList.findIndex(e => e.dataIndex == key);
			if (dataIndex > -1) {
				columns[dataIndex].title = `${colList[dataIndex].title} (${currencySymbol || '*'})`;
			}
		}
		return columns;
	},
	isAdminORSuperAdmin(value) {
		return Number(value) == 1;
	},
	getReorderFilters(params, filterObj) {
		let newParams = Object.assign({}, params);
		if (filterObj.locationSearch) {
			newParams = { ...newParams, ...{ assetOrLocationName: filterObj.locationSearch } };
		}
		if (filterObj.ClientId && Number(filterObj.ClientId) > 0) {
			newParams = { ...newParams, ...{ clientId: Number(filterObj.ClientId) } }
		}
		if (filterObj.channel) {
			newParams = { ...newParams, ...{ locationTypeId: filterObj.channel } };
		}
		if (filterObj.level) {
			newParams = { ...newParams, ...{ classificationId: filterObj.level } };
		}
		if (filterObj.restockIn) {
			newParams = { ...newParams, ...{ restockScheduleDays: filterObj.restockIn } };
		}
		if (filterObj.filterType >= 0) {
			newParams = { ...newParams, ...{ restockFilterType: filterObj.filterType } };
		}
		if (filterObj.order) {
			newParams = { ...newParams, ...{ orderRange: filterObj.order } };
		}
		if (filterObj.cases) {
			newParams = { ...newParams, ...{ casesRange: filterObj.cases } };
		}
		if (filterObj.reorderLocationStatus) {
			newParams = { ...newParams, ...{ locationStatusId: filterObj.reorderLocationStatus } };
		}

		return newParams
	},
	isMobile() {
		return navigator.userAgent?.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
	},
	isEdge() {
		const isIE = /*@cc_on!@*/false || !!document.documentMode;
		return !isIE && !!window.StyleMedia;
	},
	handleFilterChange: (state, action) => {
		if (!action.target) {
			return { ...state, ...action };
		}
		let { name, value } = action && action.target;
		let newState = {};
		//Add Default filter type of equal if Value is exist in restock day filter
		if ((name === 'restockIn' && value) && !state.filterType) {
			Object.assign(newState, { filterType: '0' });
		} else if (name === 'restockIn' && Number(value) < 0) {
			newState.filterType = '';
		}
		//Assign default equal filter for Restock In dropdown
		if (Number(state.restockIn) > 0 && (name === 'filterType' && (value === "-1" || value === 0))) {
			value = '0';
		}
		return { ...state, ...newState, [name]: value };
	},
	convertMultipleLocale(itemList = [], t, tOpts) {
		const list = [];
		for (let items of itemList) {
			const item = { ...items };
			list.push({ ...item, DisplayValue: t(item.DisplayValue, tOpts) })
		}
		return list;
	},
	resetCheckedLocation(checkedData, dispatch) {
		if (checkedData && checkedData.length > 0) {
			dispatch({ type: actions.SET_OPERATIONS_INFORMATION, operationsCheckedData: { checkedData: [] } });
		}
	},
	onScheduledIgnoreClick({ e, isMulti, data, statusId, getData, isReorder, dispatch, history, checkedData, t, tOpts, successCBK, addNotes = false, updateData = false, title = undefined, clearSelectionModel = undefined, clearExpandedRow = undefined, approveStatusId = 0, restOrderApproveStatus = true, ScheduledOrderId = 0, onRevertChanges }) {
		e.preventDefault();

		function location_has_latest_asset_data(rowData) {
			return ((rowData.TotalAssetCount - rowData.AssetCountWithOldImage) > 0);
		}

		const onChangeStatus = async (params, isReorder = false) => {
			const { lineItems, ScheduledOrderItem } = params;
			if (isReorder && ((!lineItems || (lineItems == "[]")) && !ScheduledOrderItem)) {
				toast.error(t("No active location to move.", tOpts), toastConfig);
				return;
			}
			if (updateData) {
				params.updateData = true;
			}
			const response = await request({ url: apis.ScheduledOrder, params, history, dispatch });
			if (response) {
				this.resetCheckedLocation(checkedData, dispatch);
				if (clearSelectionModel) {
					clearSelectionModel();
				}
				if (clearExpandedRow) {
					clearExpandedRow();
				}
				if (getData)
					getData({ startIndex: 0, isFirstFilter: true });
				if (successCBK)
					successCBK(response)
				//TODO
				// if (typeof props.onStatusChange == 'function') {
				//     props.onStatusChange(new Date())
				// }
			}
		}

		const getMultiSelectParams = (isReorderGrid, statusId, multiParams) => {
			let params = { ...multiParams };
			let lineItem = [];
			const locationsWithCaseZero = [];
			let filtered = data.records.filter(el => { return checkedData.indexOf(el.LocationId) > -1 && el.IsActive; });
			if (isReorderGrid) {
				Object.assign(params, { action: 'otherAction', otherAction: "SaveReorderMultipleLocation" });
				for (const e of filtered) {
					// remove product in case of unit needed is zero
					if (e.ProductInfo.length > 0) {
						var productDetails = [];
						e.ProductInfo.forEach((value) => {
							if (value.UnitsNeeded != "0") {
								productDetails.push(value);
							}
						})
						e.ProductInfo = productDetails;
					}
					lineItem.push({
						ScheduledOrderId: e.Id || 0,
						StatusId: statusId,
						LocationId: e.LocationId,
						OutOfStock: e.OutOfStock,
						ScheduledOrderItem: e.ProductInfo,
						AssetCountWithOldImage: e.AssetCountWithOldImage,
						TotalAssetCount: e.TotalAssetCount,
						OrderMovementTypeId: MOVEMENT_STATUS.MANUAL,
						OrderSize: e.FinalTotalOrder,
						TotalCaseSize: e.FinalTotalCase,
						RestockIn: e.RestockIn || 0,
						Notes: addNotes ? e.Notes : null,
						PurityDateTime: dayjs(e.OrderSuggestion).format(utils.dateTimeFormat),
						AssetPurityIds: e.AssetPurityIds,
						UniqueGuid: e.UniqueGuid,
						FinalMoQ: e.FinalMoQ,
						FinalMoV: e.FinalMoV,
						LastMOVAndMOQDateTime: e.LastMOVAndMOQDateTime ? dayjs(e.LastMOVAndMOQDateTime).format(utils.dateTimeFormat) : null
					})
				}
			} else if (!isReorderGrid) {
				Object.assign(params, { action: 'otherAction', otherAction: "MarkMultipleScheduleOrIgnore" });
				for (const e of filtered) {
					if (!e.ProductInfo.length && e.StatusId === ORDER_STATUS.IGNORED) {
						locationsWithCaseZero.push({ LocationId: e.LocationId, LocationName: e.LocationName, Code: e.Code });
						break;
					}
					const obj = {
						Id: e.Id || 0,
						ScheduledOrderId: e.Id || 0,
						StatusId: statusId,
						OutOfStock: e.OutOfStock,
						LocationId: e.LocationId,
						AssetCountWithOldImage: e.AssetCountWithOldImage,
						TotalAssetCount: e.TotalAssetCount,
						OrderMovementTypeId: MOVEMENT_STATUS.MANUAL,
						AssetPurityIds: e.AssetPurityIds,
						UniqueGuid: e.UniqueGuid,
						FinalMoQ: e.FinalMoQ,
						FinalMoV: e.FinalMoV,
						LastMOVAndMOQDateTime: e.LastMOVAndMOQDateTime ? dayjs(e.LastMOVAndMOQDateTime).format(utils.dateTimeFormat) : null
					}
					if (!restOrderApproveStatus) {
						obj.OrderApproveStatusId = e.OrderApproveStatusId;
					}

					if (updateData) {
						obj.ScheduledOrderItem = e.ProductInfo;
						obj.OrderSize = e.TotalOrder;
						obj.TotalCaseSize = e.TotalCase;
						obj.RestockIn = e.RestockIn || 0;
						obj.ReorderDate = dayjs(e.ReorderDate).format(utils.dateTimeFormat)
					}
					obj.PurityDateTime = dayjs(e.OrderSuggestion).format(utils.dateTimeFormat)
					lineItem.push(obj);
				}
				if (locationsWithCaseZero.length > 0) {
					swal({ title: t("Please ensure at least one product is added before proceeding to move the selected location.", tOpts), icon: "info", button: t("Ok", tOpts) });
					return false;
				}
			}
			params.lineItems = JSON.stringify(lineItem);
			return params;
		}
		let titleMessage;
		switch (statusId) {
			case ORDER_STATUS.SCHEDULED:
				titleMessage = "Do you want to schedule order?";
				break;
			case ORDER_STATUS.INTRANSIT:
				titleMessage = "Do you want to move the order to the In-Transit stage?";
				break;
			case ORDER_STATUS.INBOX:
				titleMessage = "Do you want to go to Order Inbox?";
				break;
			default:
				titleMessage = "Do you want to ignore order?";
				break;
		}
		const rowData = data[0] !== undefined ? data[0] : data.records[0];
		const orderItem = rowData ? JSON.stringify(rowData.ProductInfo) : '[]'
		title = (orderItem === '[]' && approveStatusId > 0) ? 'You are about to eliminate the last case and your order will be cancelled. Do you still want to proceed?' : title ?? titleMessage;
		swal({
			title: t(title, tOpts), icon: "info", buttons: { cancel: t("Cancel", tOpts), confirm: t("OK", tOpts), }, dangerMode: true,
		}).then(async (isConfirm) => {
			if (isConfirm) {
				let params = { approveStatusId };
				if (!isMulti) {
					Object.assign(params, {
						StatusId: statusId, ScheduledOrderId: rowData.Id, LocationId: rowData.LocationId, OutOfStock: rowData.OutOfStock, AssetCountWithOldImage: rowData.AssetCountWithOldImage,
						TotalAssetCount: rowData.TotalAssetCount, OrderMovementTypeId: MOVEMENT_STATUS.MANUAL
					});
					if (isReorder) {
						const { ProductInfo } = rowData;
						Object.assign(params, {
							OrderSize: rowData.FinalTotalOrder,
							TotalCaseSize: rowData.FinalTotalCase,
							RestockIn: rowData.RestockIn || 0,
							AssetPurityIds: (ProductInfo[0] && ProductInfo[0].AssetPurityIds) || '',
							FinalMoQ: rowData.FinalMoQ,
							FinalMoV: rowData.FinalMoV,
							LastMOVAndMOQDateTime: rowData.LastMOVAndMOQDateTime ? dayjs(rowData.LastMOVAndMOQDateTime).format(utils.dateTimeFormat) : null
						});
					}
				}
				let multiSelectParams = { approveStatusId };
				if (isReorder && isMulti) {
					multiSelectParams = getMultiSelectParams(isReorder, statusId, multiSelectParams);
				} else if (!isReorder && isMulti) {
					multiSelectParams = getMultiSelectParams(isReorder, statusId, multiSelectParams);
					if (multiSelectParams === false) {
						return;
					}
				} else if (isReorder) {
					Object.assign(params, { action: 'save', ScheduledOrderItem: orderItem });
				} else {
					Object.assign(params, { action: "otherAction", otherAction: "MarkScheduleOrIgnore" });
				}
				if (ScheduledOrderId) {
					Object.assign(params, { ScheduledOrderItem: orderItem });
				}
				onChangeStatus(isMulti ? multiSelectParams : params, isReorder);
			} else {
				if (orderItem === '[]' && approveStatusId > 0) {
					onRevertChanges();
				}
			}
		});
	},

	onActivateDeactivateLocation({ event, locationIds = [], statusId = 0, history, dispatch, getData, t, tOpts }) {
		event.preventDefault();
		if (!locationIds || locationIds.length == 0) {
			toast.error(t("No location selected.", tOpts), toastConfig);
			return;
		}
		let alertTitle = `Do you want to ${(!statusId || statusId == 2) ? 'de-activate' : 'activate'} the location ?`;

		swal({
			title: t(alertTitle, tOpts),
			icon: "info",
			buttons: true,
			dangerMode: true
		}).then(async (isConfirm) => {
			if (isConfirm) {
				let params = {};
				Object.assign(params, {
					LocationIds: locationIds,
					StatusId: statusId,
					action: 'otherAction',
					otherAction: 'ActivateDeactivateLocation'
				});
				const res = await request({ url: apis.ReorderDetail, params, history, dispatch });
				const { info, data, success } = res;
				if (success) {
					// reset location selection
					this.resetCheckedLocation(locationIds, dispatch);
					if (data) {
						toast.success(info, toastConfig);
						getData({ startIndex: 0, isFirstFilter: true });
					} else {
						toast.error(info, toastConfig);
					}

				} else {
					toast.error(t("Something went wrong, please try again.", tOpts), toastConfig);
				}
			}
		});
	},
	async getMetricFilters({ history, dispatch, oldComponent = false, clientId = 0 }) {
		const params = {};
		if (Number(clientId) > 0) {
			params.ClientId = Number(clientId);
		}
		let response = await request({ url: apis.KPIConfig, history, dispatch, params });
		response = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
		response = response.filter((item) => {
			if (oldComponent) {
				return item.dashboardView === true && !(this.kpiTables.assetDayKPI.includes(item.dataKey)); // this check is added for old component so that error does not comes for admin accounts if they open ECCBC dashboard with unilever client
			} else {
				return item.dashboardView === true
			}
		})
		return response;
	},
	getFilters({ metrics, groupBy = this.DEFAULT_KPI_GROUP_BY, kpiFilters, countBy = this.DEFAULT_KPI_COUNT_BY, sort, sortKey, dateFormat = "", timeZone = "" }) {
		try {
			let filterParams = {
				where: {},
				measures: {},
				groupBy: []
			};
			const {
				marketIds = [],
				classificationIds = [],
				channelIds = [],
				distributorIds = [],
				fromDate,
				toDate,
				managerSelectedValue = [],
				daysFilter,
				customFilter = {}
			} = kpiFilters;

			let filterGroupBy = [];
			groupBy.filter((k) => {
				if (k.value === true) {
					filterGroupBy.push(k.dataKey);
				}
			});

			let filterMetrics = metrics.filter((m) => m.checked === true) || [];
			if (fromDate) {
				if (timeZone) {
					filterParams.where.PurityDateTimeFrom = { value: dayjs.tz(fromDate, timeZone).format(utils.dateFormat), fieldName: "PurityDateTime", operator: ">=" };
				} else {
					filterParams.where.PurityDateTimeFrom = { value: dateFormat ? dayjs(fromDate).format(utils.dateFormat) : new Date(new Date(fromDate).setHours(0, 0, 0, 0)), fieldName: "PurityDateTime", operator: ">=" };
				}
			}
			if (toDate) {
				if (timeZone) {
					filterParams.where.PurityDateTimeTo = { value: dayjs.tz(toDate, timeZone).format(utils.dateFormat), fieldName: "PurityDateTime", operator: "<=" };
				} else {
					filterParams.where.PurityDateTimeTo = { value: dateFormat ? dayjs(toDate).format(utils.dateFormat) : new Date(new Date(toDate).setHours(23, 59, 59, 999)), fieldName: "PurityDateTime", operator: "<=" };
				}
			}
			if (classificationIds.length && classificationIds.join(",") !== "-1") {
				// adding classification filter
				Object.assign(filterParams.where, {
					ClassificationId: {
						value: classificationIds
					}
				});
			}
			if (marketIds.length && marketIds.join(",") !== "-1") {
				// adding markets filter
				Object.assign(filterParams.where, {
					MarketId: {
						value: marketIds
					}
				});
			}
			if (channelIds.length && channelIds.join(',') !== '-1') {
				// adding channel filter
				Object.assign(filterParams.where, {
					LocationTypeId: {
						value: channelIds
					}
				});
			}
			if (distributorIds.length && distributorIds.join(',') !== '-1') {
				// adding distributor filter
				Object.assign(filterParams.where, {
					DistributorId: {
						value: distributorIds
					}
				});
			}
			if (managerSelectedValue && managerSelectedValue.length) {
				let salesManagerIds = managerSelectedValue.map(m => m.LookupId || m);
				// adding salesRep filter
				if (salesManagerIds.join(',') !== '-1') {
					Object.assign(filterParams.where, {
						FirstRepId: {
							value: salesManagerIds
						}
					});
				}
			}
			if (Object.keys(customFilter).length) {
				Object.assign(filterParams.where, {
					...customFilter
				});
			}

			// adding groupBy params
			if (countBy) {
				filterGroupBy.push(countBy)
			}
			Object.assign(filterParams, { groupBy: filterGroupBy });

			if (filterMetrics.length) {
				// adding measures filter
				Object.assign(filterParams.measures, this.metricsMeasures(filterMetrics));
			}
			//add sorting
			if (sort) {
				Object.assign(filterParams, { sort })
			}
			//add sort key
			if (sortKey) {
				Object.assign(filterParams, { sortKey })
			}
			return filterParams;
		} catch (e) {
			JsError.handleGlobalError('Error getting KPI filters', '', 946, 0, e)
		}
	},
	metricsMeasures(matrics) {
		let filterMatrics = matrics.filter((m) => m.checked === true);
		var measure = {};
		filterMatrics.map((data) => {
			let key = data.dataKey;
			let val = {
				calc: data.metricsValue,
				ranges: data.ranges,
			};
			measure = {
				...measure,
				[key]: val,
			};
		});
		return measure;
	},
	async getData({ metricsFilter, kpiFilters, countBy = this.DEFAULT_KPI_COUNT_BY, groupBy = this.DEFAULT_KPI_GROUP_BY, preDefineFilters, dispatch, history, t, tOpts }) {
		const params = preDefineFilters ? preDefineFilters : this.getFilters({ metrics: metricsFilter, kpiFilters, countBy, groupBy });
		const validation = { status: false, title: "" };

		if (!(metricsFilter && metricsFilter.length)) {
			validation.status = true;
			validation.title = 'Unable to fetch client metrics, Please retry';
		}
		if (!(groupBy && groupBy.length)) {
			validation.status = true;
			validation.title = 'No values could be found to execute the group by function. Please retry.';
		}
		if (!(params && Object.keys(params).length)) {
			validation.status = true;
			validation.title = 'Unable to generate filters, Please retry';
		}

		groupBy = groupBy.filter((group) => group.value === true);
		metricsFilter = metricsFilter.filter((m) => m.checked === true);

		if (!groupBy.length) {
			validation.status = true;
			validation.title = 'Select at least one method of group by, Please retry';
		}

		if (!metricsFilter.length) {
			validation.status = true;
			validation.title = 'Select at least one metric, Please retry';
		}


		if (validation.status) {
			swal({ title: t(validation.title, tOpts), text: "", icon: "error", dangerMode: false });
			return;
		}

		const pageTittleLocal = this.genPageTitle({ groupBy, metrics: metricsFilter, countBy, dispatch });

		if (!loading) {
			loading = true;
			const response = await request({ url: apis.KPIData, params, history, dispatch, jsonPayload: true });
			if (response) {
				dispatch({
					type: actions.SET_KPI_DATA,
					kpiData: {
						totalCount: response.totalRecords,
						data: response.records,
						groupBy: groupBy,
						metrics: metricsFilter,
						where: params.where,
						countBy,
						pageTitle: pageTittleLocal,
						timeString: Date.now()
					},
				});
			}
			loading = false;
		}
	},
	genPageTitle({ groupBy, metrics, countBy, dispatch }) {
		const groupByName = [], metricsName = [];
		for (const element of groupBy) {
			if (element.value === true) {
				groupByName.push(element.dataKey);
			}
		}
		for (const element of metrics) {
			if (element.checked === true) {
				metricsName.push(element.label);
			}
		}
		dispatch({
			type: actions.SET_KPI_PAGE_TITLE,
			pageTitle: {
				title: `${countBy.split('.')[0]} - ${groupByName.join()} - ${metricsName.join()} `,
				desc: `${countBy.split('.')[0]} group by '${groupByName.join()}', Metrics - '${metricsName.join()}' `
			},
		});

		return `${countBy.split('.')[0]} - ${groupByName.join()} - ${metricsName.join()} `;
	},
	getCurrencyFormat(number) {
		let currNum = Math.round(number);
		currNum = currNum.toLocaleString();
		return currNum;
	},
	TOpts: () => {
		const { t: translate, i18n } = useTranslation()
		const tOpts = { t: translate, i18n };
		return tOpts
	},
	t(sentence, i18nNext, options = {}) {
		if (!i18nNext) {
			return sentence;
		}
		if (!sentence) {
			return
		}
		const { t, i18n } = i18nNext;
		if (!(t || i18n)) {
			return sentence;
		}
		const isIE = /*@cc_on!@*/false || !!document.documentMode;
		const isEdge = !isIE && !!window.StyleMedia;

		// Additional condition added for Edge and Firefox as they do not return only en instead return en-IN
		if (i18n?.language === "en" || i18n?.language.split('-')[0] === "en") {
			return t(sentence, options);
		}
		const optionKeys = Object.keys(options);
		let loweredSentence = [];
		// In case of non en language do not lowercase the variable , as lowercase it will result in value not updating in string
		if (optionKeys.length) {
			loweredSentence = sentence.split(" ");
			loweredSentence = loweredSentence.map((item) => {
				if (item.includes("{{") && item.includes("}}") && isEdge) {
					return item;
				} else {
					return item.toLowerCase();
				}
			})
		}
		const tString = loweredSentence.length ? loweredSentence.join(" ") : sentence.toString().toLowerCase();
		const transformed = t(tString, options);
		if (sentence === sentence.toString().toUpperCase()) {
			return transformed.toString().toUpperCase()
		} else if (sentence === sentence.toString().toLowerCase()) {
			return transformed.toString().toLowerCase();
		} else {
			return transformed[0].toUpperCase() + transformed.substring(1)
		}
	},

	formatDataGridFilters(gridFilters) {
		const fieldName = {}
		if (gridFilters.fieldName == "EndDate") {
			if (gridFilters.operatorId == "DATE_RANGE") {
				fieldName["startDate"] = gridFilters.values[0];
				fieldName["endDate"] = gridFilters.values[1];
				fieldName["OperatorId"] = "<=";
			} else {
				fieldName["OperatorId"] = utils.operatorSign[gridFilters.operatorId];
				fieldName["endDate"] = gridFilters.values[0];
			}
		} else {
			fieldName[gridFilters.fieldName] = gridFilters.values[0];
		}
		return fieldName;
	},

	filterChecker(items, filterIsEmpty) {
		let newItems = items;
		if (filterIsEmpty) {
			newItems = items.filter((item) => {
				if (emptyOperatorFilters.includes(item.operatorValue) || emptyOperatorFilters.includes(item.operator)) {
					item.value = ['', null];
				}
				return (item.value || emptyOperatorFilters.includes(item.operatorValue) || emptyOperatorFilters.includes(item.operator));
			});
		} else {
			newItems = items.filter((item) => {
				if (emptyOperatorFilters.includes(item.operator)) {
					item.value = ['', null];
				}
				return item?.value ? item : null;
			});
		}
		return newItems;
	},

	checkForDateValue(item) {
		const newDate = new Date(item);
		if (newDate.toString() === "Invalid Date") {
			return false;
		} else {
			return true;
		}
	},
	createFilter(modelFilter, typeExport = false, filterIsEmpty = true) {
		if (!modelFilter || !modelFilter.items) {
			return false;
		}
		const items = this.filterChecker(modelFilter.items, filterIsEmpty);
		let filters = {}, operator = modelFilter.logicalOperator || modelFilter.logicOperator || modelFilter.linkOperator;
		operator = operator.toUpperCase();

		if (typeExport) {
			const newFilters = items.map((item) => {
				const isValueADate = this.checkForDateValue(item.value);
				const newItem = {};
				newItem[item?.columnField || item.field] = item.value;
				newItem["operatorValue"] = item?.operatorValue || item?.operator || "";
				newItem["isValueADate"] = isValueADate;
				return newItem
			})
			return newFilters;
		}

		let applyFilter = true;

		items.map((item, key) => {
			const isEmptyFilter = emptyOperatorFilters.includes(item.operator);
			if (!filterIsEmpty) {
				if (item.value === '' || item.value === null || item.value === undefined) {
					applyFilter = false;
				}
				if (isEmptyFilter) {
					applyFilter = true;
				}
			}
			if (applyFilter) {
				const newItem = { fieldName: item.columnField || item.field, operatorId: this.filterType[item.operatorValue || item.operator], convert: false, values: Array.isArray(item.value) ? item.value : [item.value] }
				if (!items[1]) {
					filters = newItem;
					return;
				}
				switch (key) {
					case 0:
						filters["left"] = newItem;
						break;
					case 1:
						filters["logicalOperator"] = operator;
						filters["right"] = newItem;
						break;
					default:
						let newFilter = {};
						if (!filters["right"] && filters["left"]) {
							newFilter = { ...filters };
							newFilter["logicalOperator"] = operator;
							newFilter["right"] = newItem;
						} else if (filters["right"] && filters["left"]) {
							newFilter = { ...filters };
							newFilter["left"] = filters;
							newFilter["logicalOperator"] = operator;
							newFilter["right"] = newItem;
						}
						filters = { ...newFilter };
						break;
				}
			}
		})
		return filters;
	},
	addToFilter(filter, item, operator) {
		let newFilter = {};
		operator = operator.toUpperCase()
		if (Object.keys(filter).length === 0) {
			return item;
		} else if (!filter["right"] && filter["left"]) {
			newFilter = { ...filter };
			newFilter["logicalOperator"] = operator;
			newFilter["right"] = item;
		} else if (filter["right"] && filter["left"]) {
			newFilter = { ...filter };
			newFilter["left"] = filter;
			newFilter["logicalOperator"] = operator;
			newFilter["right"] = item;
		} else {
			newFilter["left"] = { ...filter };
			newFilter["logicalOperator"] = operator;
			newFilter["right"] = item;
		}
		return newFilter;
	},
	IS_ACTIVE: "IsActive",
	BOOLEAN_FIELDS: ["IsActive", "IsForeign", "IsChestCooler", "IsSmart"],
	valueMappings: {
		"yes": true,
		"no": false,
		"vertical": false,
		"horizontal": true
	},
	DEFAULT_PAGE: 0,
	DEFAULT_PAGE_SIZE: 10,
	defaultFilter: { where: {}, isGridFilter: false, sort: {}, linkOperator: '', pagination: { page: 0, pageSize: 10 } },
	sqlTypeMappings: {
		"Latitude": "int",
		"Longitude": "int",
		"IsActive": "boolean",
		"IsForeign": "boolean",
		"IsChestCooler": "boolean",
		"Shelves": "int",
		"Columns": "int",
		"AssignedAssetsCount": "int",
		"Facings": "int",
		"FacingsDistinct": "int",
		"CreatedOn": "date",
		"ModifiedOn": "date",
		"lastDelivery": "date",
		"TotalCaseSize": "int",
		"OrderSize": "int",
		"TotalCase": "int",
		"TotalOrder": "int",
		"Schedule": 'date',
		"ExecutedOn": "date",
		"EventTime": "date",
		"Installation": "date",
		"AlertAt": "date",
		"IsSmart": "boolean"
	},
	sortMappings: {
		"Address": "Street"
	},
	allowedNullValueOperators: ["IS", "IS NOT"],
	allowedTypesForNull: ["number", "boolean"],
	onFilterModelChange({ setFilters, setFilterModal, filterModalP, ...rest }) {
		return (filterModel) => {
			if (setFilters && setFilterModal && !Array.isArray(filterModel)) {
				setFilterModal({ ...filterModalP, [rest.item]: filterModel })
			}
			const where = {};
			if (!filterModel) return;
			let extra = {};
			if (Array.isArray(filterModel)) {
				const model = filterModel[0];
				if (!model) {
					extra = { sort: {}, isGridFilter: true, ...rest }
				} else {
					const { sort, field } = model || {};
					extra = { sort: { field: this.sortMappings[field] || field, sort }, isGridFilter: true, ...rest }
				}
			} else {
				const { linkOperator = "AND", items = [], pagination, returnOnlyValue = false } = filterModel || {};
				for (let item of items) {
					let { columnField, value, operatorValue } = item;
					let { operator = operatorValue, value: tempValue } = utils.gridFilterOperatorMappings[operatorValue] || {};
					if (this.BOOLEAN_FIELDS.includes(columnField)) {
						tempValue = typeof value === "boolean" ? value : this.valueMappings[value?.toLowerCase()]
					} else {
						tempValue = tempValue && value ? tempValue.replace(/@value/, value) : value;
					}
					if (!this.allowedNullValueOperators.includes(operator) && !tempValue && !this.allowedTypesForNull.includes(typeof tempValue)) {
						continue;
					}
					if (!where[columnField]) {
						where[columnField] = []
					}
					where[columnField].push({ value: tempValue, operator, sqlType: this.sqlTypeMappings[columnField] });
				}
				extra = { where, linkOperator, isGridFilter: true, ...rest }
				if (pagination) {
					extra.pagination = pagination;
				}
				if (returnOnlyValue) {
					return extra;
				}
			}

			if (rest?.item) {
				const { item } = rest;
				setFilters(prev => ({ ...prev, item, [item]: { ...(prev[item] || {}), ...extra } }));
				return;
			}
			setFilters(prev => ({ ...prev, ...extra }));
		}
	},
	getDateFilter(selectedValue, dateFilterMaxDate) {
		const result = { fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - Number(1)), toDate: dateFilterMaxDate ? dateFilterMaxDate : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) }, tempSplit = selectedValue.split(':');
		const value = tempSplit[0], range = tempSplit[1];
		switch (range) {
			case "day":
				result.fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - Number(value));
				break;
			case "year":
				result.fromDate = new Date(new Date().getFullYear() - Number(value), new Date().getMonth(), new Date().getDate());
				break;
			case "currentWeek":
				let current = new Date(), dayOfWeek = current.getDay();
				result.fromDate = new Date(current.setDate(current.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 0)));
				break;
			case "currentMonth":
				result.fromDate = new Date(new Date().getFullYear(), dateFilterMaxDate.getMonth(), 1);
				break;
			case "currentYear":
				result.fromDate = new Date(new Date().getFullYear(), 0, 1);
				break;
			case "currentQuarter":
				const currentMonth = (new Date()).getMonth();
				const yyyy = (new Date()).getFullYear();
				const start = (Math.floor(currentMonth / 3) * 3) + 1;
				result.fromDate = new Date(start + '-01-' + yyyy);
				break;
			default:
		}
		return result;
	},
	metricCategory: {
		ShareOfShelf: "Share of Shelf",
		ProductAssortment: "Product Assortment",
		ProductAvailability: "Product Availability",
		ShelfLayout: "Shelf Layout",
		SalesAndShopper: "Sales And Shopper",
		Value: "Value",
		Cases: "Cases",
		Sales: "Sales",
		Compliance: "Compliance"
	},
	metricsCategoryMappings() {
		const metricCategory = utils.metricCategory;
		return {
			"KPI6": metricCategory.ProductAvailability,
			"KPI10": metricCategory.ShareOfShelf,
			"ForeignProductPercentage": metricCategory.ShareOfShelf,
			"EmptyProductPercentage": metricCategory.ShareOfShelf,
			"AssortmentDepth": metricCategory.ProductAvailability,
			"TargetPortfolioCompliance": metricCategory.Compliance,
			"PlanogramCompliance": metricCategory.Compliance,
			"FacingCompliance": metricCategory.Compliance,
			"CaseSuggested": metricCategory.Cases,
			"DoorOpenDailyCount": metricCategory.Sales,
			"ValueSuggested": metricCategory.Value,
		}
	},
	deepCloneObject(object) { //https://developer.mozilla.org/en-US/docs/Glossary/Deep_copy
		return JSON.parse(JSON.stringify(object));
	},
	getCookie(cname) {
		const name = cname + "=";
		const decodedCookie = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}

			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	},
	operationalStatusImages: {
		"Installed": require(`../assets/images/installed.png`),
		"NotInstalled": require(`../assets/images/notInstalled.png`),
		"ModemNotPinging": require(`../assets/images/modem.png`),
		"CameraNotPinging": require(`../assets/images/camera.png`),
		"CameraNotTakingImages": require(`../assets/images/takePhoto.png`),
		"PlanogramNotAssigned": require(`../assets/images/freezerWithProduct.png`),
		"Working": require(`../assets/images/checked.png`)
	},
	distanceBTWCoordinates(p1, p2) {
		const rad = function (x) {
			return x * Math.PI / 180;
		}

		p1.latitude = p1.lat ? p1.lat() : p1.Latitude ? p1.Latitude : p1.latitude || 0;
		p1.longitude = p1.lng ? p1.lng() : p1.Longitude ? p1.Longitude : p1.longitude || 0;
		p2.latitude = p2.lat ? p2.lat() : p2.Latitude ? p2.Latitude : p2.latitude || 0;
		p2.longitude = p2.lng ? p2.lng() : p2.Longitude ? p2.Longitude : p2.longitude || 0;

		const radius = 6378137, // Earth’s mean radius in meter,
			dLat = rad((p2.latitude) - (p1.latitude)),
			dLong = rad((p2.longitude) - (p1.longitude)),
			area = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
				Math.cos(rad(p1.latitude)) * Math.cos(rad(p2.latitude)) *
				Math.sin(dLong / 2) * Math.sin(dLong / 2),
			calc = 2 * Math.atan2(Math.sqrt(area), Math.sqrt(1 - area)),
			distance = radius * calc;
		return distance; // returns the distance in meter
	},
	columnsDataTypes: {
		"Latitude": "numeric",
		"Longitude": "numeric",
		"IsActive": "boolean",
		"IsForeign": "boolean",
		"IsChestCooler": "boolean",
		"Shelves": "numeric",
		"Columns": "numeric",
		"AssignedAssetsCount": "numeric",
		"Facings": "numeric",
		"FacingsDistinct": "numeric",
		"CreatedOn": "date",
		"lastDelivery": "date",
		"TotalCaseSize": "numeric",
		"OrderSize": "numeric",
		"TotalCase": "numeric",
		"TotalOrder": "numeric",
		"IsSmart": "boolean"
	},
	operatorMappings: {
		"contains": {
			operator: "like"
		},
		"startsWith": {
			operator: "like"
		},
		"endsWith": {
			operator: "like"
		},
		"equals": {
			operator: "eq"
		},
		"isAnyOf": {
			operator: "eq"
		},
		"isNotEmpty": {
			operator: "eq"
		},
		"isEmpty": {
			operator: "IS"
		},
		"IsActive": "equals"
	},
	getFilter: function (filterValues) {
		const updatedFilter = [];
		filterValues.forEach(({ columnField, id, operatorValue, value }) => {
			let operator = this.operatorMappings[operatorValue];
			operator = operator && operator.operator || "eq"
			updatedFilter.push({
				property: columnField,
				operator,
				value,
				type: this.columnsDataTypes[columnField] || "string"
			});
		});
		return updatedFilter;
	},
	listDefaultParams: {
		action: "list",
		asArray: 0,
		start: 0,
		limit: 10,
		filter: []
	},
	getMergedFilters: function ({ params, globalFilter = {} }) {
		const filters = {};
		const keys = Object.keys(globalFilter);
		keys.forEach(key => {
			const value = globalFilter[key];
			switch (key) {
				case "classificationIds":
					Object.assign(filters, { ClassificationId: value });
					break;
				case "marketIds":
					Object.assign(filters, { MarketId: value });
					break;
				case "channelIds":
					Object.assign(filters, { LocationTypeId: value });
					break;
				case "managerSelectedValue":
					Object.assign(filters, { SalesRepId: value });
					break;
				case "routeSelectedValue":
					Object.assign(filters, { RouteId: value });
					break;
				default:
					Object.assign(filters, { [key]: value });
					break;
			}
		});

		delete filters["changedKey"];
		return { ...params, ...filters };
	},
	isModuleExists(userData, module) {
		const userDataL = userData || {}, modules = userDataL.modules || {};
		return modules[module] && modules[module].Module;
	},
	generateUUID() { // Public Domain/MIT
		var d = new Date().getTime();//Timestamp
		var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16;//random number between 0 and 16
			if (d > 0) {//Use timestamp until depleted
				r = (d + r) % 16 | 0;
				d = Math.floor(d / 16);
			} else {//Use microseconds since page-load if supported
				r = (d2 + r) % 16 | 0;
				d2 = Math.floor(d2 / 16);
			}
			return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
	},
	productGridFilterOptions: { operator: "equals" },
	filterAllIssues(callback) {
		return <Button variant="text" onClick={callback} className='grid-toolbar-button'><ErrorOutlineIcon className='grid-toolbar-icon' />Filter All Issues</Button>
	},
	autoExpendRow(gridRef, editProductRowId, setExpandedRowId) {
		const rows = gridRef.current.state.rows.idRowsLookup;
		for (const key in rows) {
			const row = rows[key];
			if (row.RowNumber === editProductRowId) {
				setExpandedRowId(key)
				break;
			}
		}
	},
	fileExport({ form, params, columns, currencySymbol, format = "XLSX", title, fileName = title }) {
		let _params = utils.deepCloneObject(params);
		let additionalParams = "forExport=true";
		form.setExportParams({
			ExportCols: utils.getExportColumn(currencySymbol, 'Amount', JSON.parse(JSON.stringify(columns))),
			filters: _params,
			title: title,
			fileName: fileName,
			format: format,
			additionalParams: additionalParams
		});
	},
	updateFilter(payload = {}, filterModel, globalFilter, overviewRecordSelect, filterIsEmpty = true) {
		payload.filter = utils.createFilter(filterModel, false, filterIsEmpty);
		if (overviewRecordSelect && overviewRecordSelect.code) {
			payload.assetOrLocationName = overviewRecordSelect.code
		}
		const _payload = utils.getMergedFilters({ params: payload, globalFilter });
		return _payload;
	},
	AssessmentType: {
		Radio: 'radio',
		Checkbox: 'checkbox',
		Text: 'text',
		Textarea: 'textarea',
		Select: 'select',
		File: 'file',
		MultiFile: 'multiFile',
		Number: 'number',
		barcode: 'barcode',
		Scene: 'scene',
		MultiFileStitch: 'multiFileStitch'
	},

	SurveyCreator_AssessmentType: {
		Radiogroup: 'radiogroup',
		Checkbox: 'checkbox',
		Text: 'text',
		Comment: 'comment',
		Dropdown: 'dropdown',
		File: 'file',
		MultiFile: 'multiFile',
		Number: 'number',
		Barcode: 'barcode',
		Scene: 'scene',
		MultiFileStitch: 'multiFileStitch'
	},
	convertToSurveyCreatorJSON: function (jsonConfg) {
		const surveyJsConfig = {};
		let sections = jsonConfg["sections"];
		const surveyJSSection = [];

		for (const sec of sections) {

			const questions = sec["questions"];
			const sectionElements = [];
			let surveyJSQ = {};
			for (const q of questions) {
				const dependency = q.dependentQuestion;
				let visibleIf = [];
				if (dependency && dependency.length) {
					for (const dep of dependency) {

						questions.map((q, index) => {
							if (q.name === dep.questionName) {

								const allAsnswersKey = q.options.map(op => op.key);
								const dependencykeys = dep.answerkey;
								allAsnswersKey.map((key) => {

									if (dependencykeys.indexOf(key) === -1) {
										visibleIf.push(`{${q.name}} = ${key}`)
									}
								})
							}
						});
					}


				}

				const options = q.options;
				const type = q.type;
				surveyJSQ = {
					"customId": q.id,
					"name": q.name,
					"title": q.text,
					"validators": q?.validators || [],
					"isRequired": q.required,
					"info": q.info,
					"dynamic": q.dynamicOptions && q.dynamicOptions[0].items,
					"dependentQuestion": q.dependentQuestion && JSON.stringify(q.dependentQuestion),
					"options": JSON.stringify(options)
				}

				if (options && options.length) {
					surveyJSQ["choices"] = [];
					for (const op of options) {
						surveyJSQ["choices"].push({ "text": op.value, "value": op.key });
					}
				}
				// if (visibleIf && visibleIf.length) {
				// 	surveyJSQ["visibleIf"] = visibleIf.toString().replace(/,/gi, " or ");
				// }
				switch (type) {

					case this.AssessmentType.Radio:
						surveyJSQ["type"] = "radiogroup";
						break;
					case this.AssessmentType.Checkbox:
						surveyJSQ["type"] = "checkbox";
						break;
					case this.AssessmentType.Text:
						surveyJSQ["type"] = "text";
						break;
					case this.AssessmentType.Textarea:
						surveyJSQ["type"] = "comment";
						break;
					case this.AssessmentType.Select:
						surveyJSQ["type"] = "dropdown";
						break;
					case this.AssessmentType.File:
						surveyJSQ["type"] = "file";
						break;
					case this.AssessmentType.MultiFile:
						surveyJSQ["type"] = "file";
						surveyJSQ["multiFile"] = true;
						break;
					case this.AssessmentType.Number:
						surveyJSQ["type"] = "text";
						surveyJSQ["inputType"] = "number";
						break;
					case this.AssessmentType.barcode:
						surveyJSQ["type"] = "barcode";
						break;
					case this.AssessmentType.Scene:
						surveyJSQ["type"] = "scene";
						surveyJSQ["scene"] = true;
						break;
					case this.AssessmentType.MultiFileStitch:
						surveyJSQ["type"] = "file";
						surveyJSQ["pos"] = true;
						break;
					default:
						break;

				}
				sectionElements.push(surveyJSQ);
			}
			surveyJSSection.push({
				"id": sec.id,
				"type": "panel",
				"title": sec.title,
				"state": "expanded",
				"repeatOptions": (typeof (sec?.items) === "object") ? sec.items[0]["AssetType"] : sec?.items,
				"elements": sectionElements
			})
		}

		surveyJsConfig["elements"] = surveyJSSection;
		return surveyJsConfig;
	},


	convertToCoolRJSON: function (jsonConfig) {
		const CoolrConfig = {};
		let sections = jsonConfig["elements"];
		const CoolrSection = [];

		if (!sections?.length) {
			return;
		}

		for (const sec of sections) {

			const questions = sec["elements"];
			const questionElements = [];
			let CoolrQ = {};
			if (!questions?.length) {
				return;
			}
			for (const q of questions) {
				let options;
				try {
					options = options && JSON.parse(q?.options)
				} catch (error) {
					console.error(error);
				}
				const choices = q.choices;
				const type = q.type;
				const dynamic = q.dynamic;
				const dependency = q.hasDependency;
				let dependentQuestion = q.dependentQuestion;

				CoolrQ = {
					"id": q?.customId || "",
					"name": `sq_${this.generateRandomId()}`,
					"text": q?.title || "",
					"validators": q?.validators || [],
					"required": q?.isRequired,
				}

				if (dependentQuestion) {
					if (dependentQuestion["behaviour"] === "show") {
						CoolrQ["defaultDisplay"] = "hidden";
					}
					CoolrQ["dependentQuestion"] = dependentQuestion;
				}

				if (choices && choices.length) {
					CoolrQ["options"] = options;
					CoolrQ["options"] = [];
					// eslint-disable-next-line array-callback-return
					// eslint-disable-next-line no-loop-func
					choices.map((ch, id) => {
						let text;
						if ((typeof (ch) !== "string") && (typeof (ch) !== "number")) {
							text = ch.text;
						} else {
							text = ch;
						}
						const optionObj = { "value": text, "key": id + 1 };
						if (dependency) {
							optionObj.dependency = true;
						}
						CoolrQ["options"].push(optionObj);
					});
				}

				if (dynamic) {
					let key, value, items;

					key = "${" + dynamic + "Id}";
					value = "${" + dynamic + "}";
					switch (dynamic) {
						case "Assets":
							items = "Assets"
							break;
						case "Product":
							items = "Products"
							break;
						default:
							break;
					}
					CoolrQ["dynamicOptions"] = {
						"items": items,
						"layout": {
							"key": key,
							"value": value
						}
					}

				}


				switch (type) {

					case this.SurveyCreator_AssessmentType.Radiogroup:
						CoolrQ["type"] = "radio";
						break;
					case this.SurveyCreator_AssessmentType.Checkbox:
						CoolrQ["type"] = "checkbox";
						break;
					case this.SurveyCreator_AssessmentType.Text:

						if (q["inputType"] === "number") {
							CoolrQ["type"] = "number";
						} else {
							CoolrQ["type"] = "text";
						}
						break;
					case this.SurveyCreator_AssessmentType.Comment:
						CoolrQ["type"] = "textarea";
						break;
					case this.SurveyCreator_AssessmentType.Dropdown:
						CoolrQ["type"] = "select";
						break;
					case this.SurveyCreator_AssessmentType.File:
						if (q["scene"]) {
							CoolrQ["type"] = "scene";
							break;
						} else if (q["posCapture"]) {
							CoolrQ["type"] = "multiFileStitch";
							break;
						} else if (q["multiFile"]) {
							CoolrQ["type"] = "multiFile";
						} else {
							CoolrQ["type"] = "file";
						}
						break;
					case this.SurveyCreator_AssessmentType.Barcode:
						CoolrQ["type"] = "barcode";
						break;
					case this.SurveyCreator_AssessmentType.Scene:
						CoolrQ["type"] = "scene";
						break;
					default:
						break;
				}
				questionElements.push(CoolrQ);
			}

			let secItems = null;
			if (sec.repeatOptions === "UserAction") {
				secItems = [{
					"id": 1,
					"SerialNumber": sec.name,
					"AssetType": "UserAction"
				}];
			} else {
				secItems = sec.repeatOptions;
			}

			CoolrSection.push({
				"id": sec.id || sec.name,
				"title": sec.title || "",
				"items": secItems || [{ id: 1, "SerialNumber": "" }],
				"questions": questionElements
			})

		}
		CoolrConfig["sections"] = { "sections": CoolrSection };
		CoolrConfig["survey"] = { SurveyName: jsonConfig.title, TypeId: jsonConfig.SurveyType, SurveyId: jsonConfig.SurveyId, CategoryId: jsonConfig.SurveyCategory }
		return CoolrConfig;
	},

	localStorageName: "coolrsurvey",
	creatorOptions: {
		questionTypes: ["text", "comment", "checkbox", "radiogroup", "dropdown", "singleFile", "multiFile", "scene", "barcode"],
		showPagesToolbox: false,
		showJSONEditorTab: false,
		showSidebar: false,
		pageEditMode: "single",
	},
	dayjsFormatLocal: function ({ value, format, tOpts, lang }) {
		if (!value) {
			return '-';
		}
		const langL = lang ? lang : tOpts?.i18n?.language?.slice(0, 2);
		const dateShow = ((typeof value === 'string') && value.includes('T')) ? dayjs(value).locale(langL).utc().format(format) : dayjs(value).locale(langL).format(format)
		return dateShow;
	},
	numberWithCommas(num) {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	},
	scrollToBottom() {
		window.scroll({
			top: document.body.offsetHeight,
			left: 0,
			behavior: 'smooth',
		});
	},
	systemDateTimeFormat(isDateFormatOnly, showOnlyDate) {
		let reduxStore = store?.getState()?.appReducer;
		let userData = reduxStore.userData ? reduxStore.userData.tags : {};
		let userDateFormat = isDateFormatOnly ? utils.dateWithoutTimeFormat : utils.dateWithTimeFormat;
		if (userData.DateTimeFormat) {
			userDateFormat = userData.DateTimeFormat.split(" ");
			userDateFormat[0] = userDateFormat[0].toUpperCase();
			if (!isDateFormatOnly) {
				if (showOnlyDate) {
					userDateFormat = userDateFormat[0].toUpperCase();
				} else {
					userDateFormat[1] += ':ss';
					userDateFormat = userDateFormat.join(" ");
				}
			} else {
				userDateFormat = userDateFormat[0];
			}
		};
		return userDateFormat;
	},
	getUserDateFormat(showHours = false) {
		let reduxStore = store?.getState()?.appReducer;
		let userData = reduxStore.userData ? reduxStore.userData.tags : {};
		let userDateFormat = showHours ? utils.dateWithTime : utils.dateWithoutTime;
		if (userData.DateTimeFormat) {
			userDateFormat = userData.DateTimeFormat.split(" ");
			userDateFormat[0] = userDateFormat[0];
			if (showHours) {
				userDateFormat[1] += ':ss';
				userDateFormat = userDateFormat.join(" ");
			} else {
				userDateFormat = userDateFormat[0];
			}
		};
		return userDateFormat;
	},
	formatDate(value, useSystemFormat, showOnlyDate = false) {
		if (value) {
			const format = utils.systemDateTimeFormat(useSystemFormat, showOnlyDate);
			return dayjs(value).format(format);
		}
		return '-';
	},
	exportAction: "export",
	generateRandomId: function () {
		const uniq = Math.random().toFixed(4).split(".")[1];
		return uniq;
	},
	getRecurrenceName(FrequencyType) {
		switch (FrequencyType) {
			case "V":
				return "Visit";
			case "O":
				return "Cycle";
			case "W":
				return "Week";
			case "M":
				return "Month";
			case "Q":
				return "Quarter";
			case "Y":
				return "Year";
			default:
				return "Cycle";
		}
	},
	QuestionnairePanelTypes: [
		{ type: 'None' },
		{ type: 'Assets' },
		{ type: 'Products (Context Sensitive)' },
		{ type: 'Product All' },
		{ type: 'Products in Store Planogram' }
	],
	questionTypes: [
		{
			"Single Choice": [
				{
					type: QUESTION_TYPES.RADIO, //"radio",
					subType: "",
					displayName: CREATOR_QUESTION_DISPLAYNAME.BOOLEAN,  //"boolean",
					name: "",
					text: "",
					options: [
						{
							"key": 1,
							"value": "",
							"isDeleted": false,
							"dependency": true
						},
						{
							"key": 0,
							"value": "",
							"isDeleted": false,
							"dependency": true
						}
					],
					IsDeleted: 0,
					required: false
				},
				{
					name: "",
					displayName: CREATOR_QUESTION_DISPLAYNAME.RADIO, //"radio",
					subType: "Single Choice",
					type: QUESTION_TYPES.RADIO, //"radio",
					text: "",
					options: [
						{
							"key": 0,
							"value": "",
							"isDeleted": false,
							"dependency": true
						},
						{
							"key": 1,
							"value": "",
							"isDeleted": false,
							"dependency": true
						}
					],
					IsDeleted: 0,
					required: false
				},
				{
					name: "",
					displayName: CREATOR_QUESTION_DISPLAYNAME.DROPDOWN, //"Dropdown",
					type: QUESTION_TYPES.SELECT, //"Dropdown",
					subType: "Single Choice",
					text: "",
					options: [],
					IsDeleted: 0,
					required: false
				},

			]
		},
		{
			"Multiple Choice": [
				{
					name: "",
					displayName: CREATOR_QUESTION_DISPLAYNAME.CHECKBOX, //"checkbox",
					type: QUESTION_TYPES.CHECKBOX, //"checkbox",
					subType: "Multiple Choice",
					text: "",
					options: [
						{
							"key": 0,
							"value": "",
							"isDeleted": false,
							"dependency": true
						},
						{
							"key": 1,
							"value": "",
							"isDeleted": false,
							"dependency": true
						}
					],
					IsDeleted: 0,
					required: false
				},
				{
					name: "",
					displayName: CREATOR_QUESTION_DISPLAYNAME.DROPDOWN, //"Dropdown",
					type: QUESTION_TYPES.SELECT, //"Dropdown",
					subType: "Multiple Choice",
					text: "",
					options: [],
					IsDeleted: 0,
					required: false
				},
			]
		},
		{
			"Input Box": [
				{
					name: "",
					displayName: CREATOR_QUESTION_DISPLAYNAME.NUMBER, //"number",
					type: QUESTION_TYPES.NUMBER, //"number",
					subType: "",
					text: "",
					IsDeleted: 0,
					required: false
				},
				{
					name: "",
					displayName: CREATOR_QUESTION_DISPLAYNAME.DATE, //"date",
					type: QUESTION_TYPES.DATE, //"date",
					subType: "",
					text: "",
					IsDeleted: 0,
					required: false
				},
				{
					name: "",
					displayName: CREATOR_QUESTION_DISPLAYNAME.SINGLE_LINE_INPUT, //"Text Input (Single line)",
					type: QUESTION_TYPES.TEXTAREA, //"textarea",
					subType: "",
					text: "",
					IsDeleted: 0,
					required: false
				},
				{
					name: "",
					displayName: CREATOR_QUESTION_DISPLAYNAME.MULTI_LINE_INPUT, //"Text Input (Multiple lines)",
					type: QUESTION_TYPES.TEXTAREA, //"textarea",
					subType: "",
					text: "",
					IsDeleted: 0,
					required: false
				}
			]
		},
		{
			"Camera": [
				{
					name: "",
					displayName: CREATOR_QUESTION_DISPLAYNAME.GENERAL_CAPTURE, //"General Image Capture",
					type: QUESTION_TYPES.MULTI_FILE, //"multiFile",
					subType: "",
					text: "",
					IsDeleted: 0,
					required: false
				},
				{
					name: "",
					displayName: CREATOR_QUESTION_DISPLAYNAME.BARCODE,  //"Asset Barcode Capture",
					type: QUESTION_TYPES.BARCODE, //"barcode",
					subType: "",
					text: "",
					IsDeleted: 0,
					required: false,
					validateFromAsset: true
				},
				{
					name: "",
					displayName: CREATOR_QUESTION_DISPLAYNAME.PRODUCT_CAPTURE, //"Product Image Capture",
					type: QUESTION_TYPES.SCENE, //"scene",
					subType: "",
					text: "",
					IsDeleted: 0,
					required: false,
					checkTargetPortfolio: true
				},
				{
					name: "",
					displayName: CREATOR_QUESTION_DISPLAYNAME.POS_CAPTURE,  //"POS materials image capture",
					type: QUESTION_TYPES.MULTI_FILE_STITCH, //"multiFileStitch",
					subType: "",
					text: "",
					IsDeleted: 0,
					required: false,
					validateFromPosMaterial: true
				}
			]
		},
	],
	USER_REPEAT_COUNT_SEPARATOR: "~~",
	REPETITION_TYPES: {
		Asset: 1,
		Product: 2,
		Promotion: 3,
		AssetProduct: 4,
		StoreProduct: 5
	},
	PREFIX_FOR_PRODUCT_ID: "P",
	PREFIX_FOR_ASSET_ID: "A",
	ProductContentSensitive: 'For Products in Asset',
	AssetBarCodeCapture: 'Asset Barcode Capture',
	questionnaireAssetText: 'For Assets in Store',

	SmartDeviceType: { CoolRWIFIVista: 79, CoolRWIFIVistaZ: 86 },
	isWIFIVistaOrVistaZ(id) {
		return (id === utils.SmartDeviceType.CoolRWIFIVistaZ || id === utils.SmartDeviceType.CoolRWIFIVista);
	},
	fetchPreferencesAndApplyDefault: async ({ preferenceName, history, dispatch, gridRef, setIsGridPreferenceFetched }) => {
		let params = {
			action: 'list',
			id: preferenceName
		}
		const defaultCoolrPref = {
			"prefName": "CoolR Default",
			"prefId": 0,
			"GridId": preferenceName,
			"GridPreferenceId": 0,
			"prefValue": tablePreferenceEnums[preferenceName],
		}
		const response = await request({ url: apis.Preference, params, history, dispatch });
		let preferences = response?.preferences ? [defaultCoolrPref, ...response?.preferences] : [defaultCoolrPref];
		const indexValue = preferences?.findIndex(ele => ele.isDefault);
		let prefName = 'CoolR Default';
		if (indexValue > -1) {
			prefName = preferences[indexValue].prefName;
			let userPreferenceCharts = preferences[indexValue]?.prefValue ? JSON.parse(preferences[indexValue].prefValue) : tablePreferenceEnums[preferenceName];
			if (userPreferenceCharts && gridRef?.current) {
				userPreferenceCharts?.gridColumn.forEach(ele => {
					if (gridRef.current.getColumnIndex(ele.field) !== -1) {
						gridRef.current.setColumnWidth(ele.field, ele.width);
					}
				})
				gridRef.current.setColumnVisibilityModel(userPreferenceCharts.columnVisibilityModel);
				gridRef.current.setPinnedColumns(userPreferenceCharts.pinnedColumns);
				gridRef.current.setSortModel(userPreferenceCharts.sortModel || []);
				gridRef.current.setFilterModel(userPreferenceCharts?.filterModel);
			}
		}
		dispatch({ type: actions.SET_CURRENT_PREFERENCE_NAME, model: preferenceName, currentPreference: prefName });
		dispatch({ type: actions.UDPATE_PREFERENCES, model: preferenceName, preferences: preferences, totalPreferences: response?.preferences?.length });
		if (setIsGridPreferenceFetched) {
			setIsGridPreferenceFetched(true);
		}
	},
	removeCurrentPreferenceName: ({ dispatch, preferenceName }) => {
		dispatch({ type: actions.SET_CURRENT_PREFERENCE_NAME, model: preferenceName, currentPreference: null });
	},
	getCleanedId(id, section) {
		if (id.includes("~${item.AssetId}~${item.ProductId}")) {
			return id.split("~${item.AssetId}~${item.ProductId}")[0];
		} else if (id.includes("~${item.AssetId}")) {
			return id.split("~${item.AssetId}")[0];
		} else if (id.includes("~${item.ProductId}")) {
			return id.split("~${item.ProductId}")[0];
		} else if (id.includes("~${item.RowNumber}")) {
			return id.split("~${item.RowNumber}")[0];
		} else {
			if (Array.isArray(section?.items)) {
				return id.split("~").join("~");
			} else {
				return id;
			}
		}
	},
	getSortedData(data, toggleState, sortProperty = 'y', topN = 10, bottomN = 10) {
		const sortedData = data.sort((a, b) => b[sortProperty] - a[sortProperty]);
		switch (toggleState) {
			case 'top10':
				return sortedData.slice(0, topN);
			case 'bottom10':
				return sortedData.length > bottomN ? sortedData.slice(-bottomN) : sortedData;
			case 'all':
			default:
				return sortedData;
		}
	},
	getGuid(link) {
		if (!link) {
			return null;
		}
		const guidParam = 'guid=';
		const startIndex = link.indexOf(guidParam);
		if (startIndex === -1) {
			return null;
		}
		const startOfGuid = startIndex + guidParam.length;
		const endOfGuid = link.indexOf('&', startOfGuid);
		if (endOfGuid === -1) {
			return link.substring(startOfGuid);
		}
		return link.substring(startOfGuid, endOfGuid);
	},
	getUniqueCategories(data1, data2) {
		let mergedData = [...data1, ...data2];
		let categories = [...new Set(mergedData.map(point => point.x))];
		return categories;
	},
	calculateAverages(items, fields) {
		if (items.length === 1) {
			let singleItemValues = {};
			fields.forEach(field => {
				singleItemValues[field] = items[0][field] !== undefined ? items[0][field] : 0;
			});
			return singleItemValues;
		}
		let sums = fields.reduce((acc, field) => ({ ...acc, [field]: 0 }), {});
		let counts = fields.reduce((acc, field) => ({ ...acc, [field]: 0 }), {});

		items.forEach(item => {
			fields.forEach(field => {
				if (item[field] !== undefined) {
					sums[field] += item[field];
					counts[field]++;
				}
			});
		});
		return Object.keys(sums).reduce((acc, field) => {
			acc[field] = counts[field] > 0 ? Number((sums[field] / counts[field]).toFixed(2)) : 0;
			return acc;
		}, {});
	},
	processData(dataArray, questionGuids, dataType) {
		const uniqueProducts = new Map();
		dataArray
			.filter(item => questionGuids.includes(item.UniqueGuid))
			.forEach(item => {
				const productName = item.Product;
				const dataValue = item[dataType];
				if (!uniqueProducts.has(productName)) {
					uniqueProducts.set(productName, { x: productName, y: dataValue });
				}
			});
		return Array.from(uniqueProducts.values());
	},
	initialAnswerResponse: {
		questionOption: {},
		answerCounts: {},
		options: {},
		rawImagesUrl: [],
		comparatorNumbers: {},
		comparatorCategories: [],
		dateRangeCounts: {
			minDate: null,
			maxDate: null,
			averageDate: null,
			counts: 0,
			dateOccurrences: {}
		}
	},
	getDisplayValues(data, lookupData, lookupType) {
		if (!data || !lookupData || !lookupType) {
			return [];
		}
		const lookupArray = lookupData[lookupType] || [];
		return data.map(id => {
			const foundItem = lookupArray.find(item => {
				if (typeof id !== 'string') {
					id = id.toString();
				}
				return item.LookupId.toString() === id;
			});
			return foundItem?.DisplayValue ?? id;
		});
	},
	analysisColorCodes: {
		blue: '#112388',
		orange: '#FF8A00',
		lightBlue: '#5C71EA',
		lightOrange: '#FFB966',
		green: '#35A35C',
		lightGreen: '#57C980',
		pink: '#F72585',
		lightPink: '#FA7CB6',
		purple: '#9C3BC3',
		lightPurple: '#C489DB'
	},
	resultsAnalysisColors: ['blue', 'orange', 'lightBlue', 'lightOrange', 'green', 'lightGreen', 'pink', 'lightPink', 'purple', 'lightPurple'],
	getAnalysisComboData: async (history) => {
		const [reportingToolCombo] = await Promise.all([
			request({ url: apis.LoadCombo, params: { comboList: analysisCombos }, history, dispatch: store.dispatch, jsonPayload: true }),
		]);

		if (reportingToolCombo) {
			store.dispatch({ type: actions.SET_COMBO_DATA_RESULT_ANALYSIS, resultAnalysisComboData: { ...reportingToolCombo?.combos } });
		}
	},
	openPopUp: (e, url, index = 0, self = []) => {
		const { src, alt } = e.target;
		if (self.length > 1) {
			self = self.map((value) => {
				return { src: `${value?.url}&width=1000&height=1000` }
			})
			store.dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, isMultiImages: true, url: self, useSingleDisplay: true, showIndex: index, isFromSurveyAnalysis: true, activeIndex: index } })
			return;
		}
		store.dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: `${src}&width=1000&height=1000` } })
	},
	/**
	 * The applyMultiClientFilters function applies multiple client-specific filters to a payload based on a global filter.
	 * 
	 * @param {Object} payload - The initial data to which the filters are to be applied.
	 * @param {Object} globalFilter - Contains the clientIds for which the filters are to be applied.
	 * @param {Array} multiClientFilters - An array of filter objects. Each filter object has a ClientId property and other properties that represent the filters for that client.
	 * 
	 * @returns {Object} - Returns a new object that includes the properties of payload and the applied filters.
	 * 
	 * The function works as follows:
	 * - It first creates an empty filters object to store the final filters.
	 * - It then iterates over each clientId in globalFilter.clientIds.
	 * - For each clientId, it filters out the corresponding filters from multiClientFilters and removes the ClientId property from the filtered objects.
	 * - If there are any filters left after this operation, it adds them to the filters object. 
	 * - If a key already exists in the filters object, the new value is appended to the existing value (which are both wrapped in an array). 
	 * - If the key does not exist, a new key-value pair is added to the filters object, with the value wrapped in an array.
	 * - Finally, it merges payload and filters into a new object and returns it.
	 */
	applyMultiClientFilters(payload, globalFilter) {
		if ((!globalFilter.uiClientIds?.length && payload.uiClientIds == null) || globalFilter.uiClientIds.toString() === '0') {
			delete payload.uiClientIds;
			return payload;
		}
		payload.uiClientIds = payload?.uiClientIds?.toString() || "";
		payload.isForMultiClientFilterOnly = true;
		return payload;
	},
	chartsTabsEnum: [
		{ text: 'Sales Rep', value: 'SalesRepName' },
		{ text: 'City', value: 'City' },
		{ text: 'State', value: 'State' },
		{ text: 'Market', value: 'MarketName' },
		{ text: 'Distributor', value: 'Distributor' },
		{ text: 'Channel', value: 'LocationTypeName' },
		{ text: 'Pre Seller Route', value: 'PreSellerRouteName' }
	],
	salesHistoryChartsTabsEnum: [
		{ text: 'Sales Rep', value: 'SalesRepName' },
		{ text: 'City', value: 'City' },
		{ text: 'State', value: 'State' },
		{ text: 'Market', value: 'MarketName' },
		{ text: 'Distributor', value: 'Distributor' },
		{ text: 'Channel', value: 'Channel' },
		{ text: 'Pre Seller Route', value: 'PreSellerRouteName' }
	],
	chartCountLabelEnum: {
		Orders: 'Orders',
		Value: 'Value',
		Cases: 'Cases',
		OrderCount: 'Orders > 2 days',
		AverageDays: 'Average Days'
	},
	chartOptionEnum: {
		potentialColor: '#34A853',
		pendingColor: '#EA4335',
		potentialName: 'Value Of Orders',
		pendingName: 'Average Days'
	},
	coverageFilters: [
		{ value: 'City', label: 'City' },
		{ value: 'StateId', label: 'State' },
		{ value: 'MarketId', label: 'Market' },
		{ value: 'DistributorId', label: 'Distributor' },
		{ value: 'LocationTypeId', label: 'Channel' },
		{ value: 'ClassificationId', label: 'Classification' }
	],
	submissionsPerFilters: [
		{ value: 'user', label: 'User' },
		{ value: 'outlet', label: 'Outlet' }
	],
	orderSubmissionsPer: [
		{ value: 'top20', label: 'Top 20' },
		{ value: 'bottom20', label: 'Bottom 20' }
	],
	filteredLookup(option) {
		if (option?.LookupId) {
			option.DisplayValue = option?.DisplayValue?.substring((option.DisplayValue.indexOf('|') + 1), option.DisplayValue.length).trim() || '';
		}
		return option;
	},
	isValidIdUrl(id) {
		const isValidUrl = /^\d+$/.test(id);
		return isValidUrl;
	},
	appendIdTag(str, tag) {
		return str && str.includes(tag) ? str : str + tag;
	},
	removeProductIdTag(str) {
		return str && str.includes(constants.productIdTag) ? str.replace(constants.productIdTag, '') : str;
	},
	removeAssetIdTag(str) {
		return str && str.includes(constants.assetIdTag) ? str.replace(constants.assetIdTag, '') : str;
	},

	removeAllIdTags(str) {
		str = str && str.includes(constants.assetIdTag) ? str.replace(constants.assetIdTag, '') : str;
		return str && str.includes(constants.productIdTag) ? str.replace(constants.productIdTag, '') : str;
	},
	cleanGeneralSectionTitle(str) {
		const escapeRegex = (text) => text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

		const serialRegex = new RegExp(escapeRegex(constants.SERIAL_NUMBER_TEXT), 'g');
		const modelRegex = new RegExp(escapeRegex(constants.MODEL_TYPE_TEXT), 'g');
		const productRegex = new RegExp(escapeRegex(constants.PRODUCT_NAME_TEXT), 'g');

		return str.replace(serialRegex, '').replace(modelRegex, '').replace(productRegex, '').trim();
	},
	combineProducts(products) {
		const skuCaseSizeUnitsMap = {};
		let combinedKey;
		for (const item of products) {
			const { SKU, UnitsNeeded, CaseSize } = item;
			combinedKey = `${SKU}_${CaseSize}`;
			skuCaseSizeUnitsMap[combinedKey] = skuCaseSizeUnitsMap[combinedKey] || { ...item, UnitsNeeded: 0 };
			skuCaseSizeUnitsMap[combinedKey].UnitsNeeded += UnitsNeeded;
			skuCaseSizeUnitsMap[combinedKey].UnitsNeeded = Math.min(skuCaseSizeUnitsMap[combinedKey].UnitsNeeded, maximumQuantityToOrder);
		}
		return Object.values(skuCaseSizeUnitsMap);
	},
	getFormattedCategory({ category, lookupData, comparatorSelected }) {
		if (lookupData && comparatorSelected) {
			const fData = lookupData[comparatorSelected] || [];
			const lookupItem = fData.find(item => item.LookupId === Number(category));
			return lookupItem ? lookupItem.DisplayValue : category;
		}
		return category;
	},
	matchLabels: (pieData, stackedData) => {
		return pieData.map(pieItem => {
			const matchingStackedItem = stackedData.find(stackedItem => stackedItem.name.includes(pieItem.label.split(' - ')[0]));
			return matchingStackedItem;
		});
	},
	getCustomFilterItems: (filters) => {
		const items = [];
		for (const key in filters) {
			if (key === 'startDate' || key === 'endDate') {
				items.push(filters[key])
			} else {
				if (filters.hasOwnProperty(key)) {
					const operator = Array.isArray(filters[key]) && filters[key].length > 0 ? 'isAnyOf' : 'equals';
					const type = Array.isArray(filters[key]) && filters[key].every(item => typeof item === 'number') ? 'number' : 'string';
					const newObj = {
						field: key,
						value: filters[key],
						operator: operator,
						type: type
					};
					items.push(newObj);
				}
			}
		}
		return items;
	},
	updateTempFilterModel: (field, value, prevModel) => {
		const startDate = field === 'startDate';
		const updatedField = {
			[field]: {
				field: prevModel[field]?.field || 'SurveyDateTime',
				operator: startDate ? prevModel[field]?.operator || 'onOrAfter' : prevModel[field]?.operator || 'onOrBefore',
				type: prevModel[field]?.type || 'date',
				value: prevModel[field]?.type === 'datetime' ? dayjs(value).toISOString() : dayjs.format(constants.dateFormat.yymmdd)
			}
		};

		const updatedModel = {
			...updatedField
		};
		return updatedModel;
	},
	copyUpdate: ({ sections, tag }) => {
		let tempSections = typeof sections === 'object' ? JSON.parse(JSON.stringify(sections)) : sections;
		const oldToNewIdMap = new Map();
		const updateSections = (section) => {
			const sectionId = nanoid();
			section.id = sectionId;
			const newQuestionIds = [];
			section.questions = section.questions.map(question => {
				const oldQuestionId = question.id;
				const questionId = nanoid();
				oldToNewIdMap.set(oldQuestionId, questionId);
				const { dependentQuestion } = question;
				question.id = questionId;
				question.questionUniqueId = questionId;
				// Dependent Questions Logic Update
				if (Array.isArray(dependentQuestion) && dependentQuestion.length > 0) {
					const dq = dependentQuestion[0];
					const oldParentQuestionId = dq?.parentQuestionUniqueId === undefined || dq.parentQuestionUniqueId.includes('undefined') ? dq.question : dq.parentQuestionUniqueId;
					const newParentQuestionId = oldToNewIdMap.get(oldParentQuestionId);
					dq.question = newParentQuestionId;
					dq.childQuestionUniqueId = questionId;
					dq.parentQuestionUniqueId = newParentQuestionId;
				}
				if (Array.isArray(question.repeat)) {
					question.repeat.forEach(id => newQuestionIds.push(oldToNewIdMap.get(id)));
					question.repeat = newQuestionIds
				}
				return question;
			});

			return section;
		};
		if (Array.isArray(tempSections)) {
			tempSections = tempSections.map(updateSections);
		} else {
			tempSections = updateSections(tempSections);
		}
		return tempSections;
	},
	getFilteredLookups: async function ({ filterValues = {}, tagsClientIds, portalCombos, dispatch, history, isFromDataPage = false }) {
		if (filterValues?.changedKey && filterValues?.changedKey !== "uiClientIds") {
			return;
		}
		const isMultiClientsAssigned = tagsClientIds.split(',').length > 1 && tagsClientIds.trim() !== '0';
		filterValues.marketIds = '';
		filterValues.classificationIds = '';
		filterValues.channelIds = '';
		filterValues.managerSelectedValue = '';
		filterValues.routeSelectedValue = '';
		if (!isMultiClientsAssigned && !isFromDataPage) {
			dispatch({ type: actions.SET_MULTICLIENT_COMBO_DATA, comboData: portalCombos });
			return;
		}
		if (!filterValues.uiClientIds?.toString()) {
			dispatch({ type: actions.SET_MULTICLIENT_COMBO_DATA, comboData: { Market: [], LocationClassification: [], LocationType: [], SalesPerson: [], RouteType: [] } });
			return;
		}
		const { combos } = await request({ url: apis.FilteredLookups, params: { clientIds: filterValues.uiClientIds?.toString() }, disableLoader: false, jsonPayload: true, history, dispatch });
		const comboData = combos;
		dispatch({ type: actions.SET_MULTICLIENT_COMBO_DATA, comboData });
	},
	getRandomColor: () => {
		const letters = '0123456789ABCDEF';
		let color = '#';
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	},
	generateComplianceSeries: (numberOfBars) => {
		const redData = new Array(numberOfBars).fill(30);
		const orangeData = new Array(numberOfBars).fill(40);
		const greenData = new Array(numberOfBars).fill(30);
		return [
			{
				type: 'bar',
				stack: 'background',
				layout: 'horizontal',
				color: 'red',
				data: redData,
			},
			{
				type: 'bar',
				layout: 'horizontal',
				stack: 'background',
				color: 'orange',
				data: orangeData,
			},
			{
				type: 'bar',
				layout: 'horizontal',
				stack: 'background',
				color: 'green',
				data: greenData,
			},
		];
	},
	removeBackButton: (dispatch) => {
		dispatch({
			type: actions.SET_PAGE_BACK_BUTTON,
			pageBackButton: { status: false, backRoute: '' },
		});
	},
	totalCostFormatter: (value) => {
		const formattedValue = new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}).format(value);
		return formattedValue;
	},
	createTicket: ({ row, tab, userData, snackbar, IssueType }) => async (event) => {
		if (event) event.preventDefault();
		const errorMessage = 'Your ticket was not created successfully, please try after some time.';
		try {
			const params = {
				email: `${userData?.tags?.Username}`,
				Client: row.ClientName,
				OutletCode: row.Code,
				OutletName: row.LocationName,
				OrderSuggestion: dayjs(row.OrderSuggestion).format(utils.systemDateTimeFormat(true)),
				CaseSize: row.TotalCase,
				Value: row.Currency?.replace('{0}', row.TotalOrder),
				MOQ: row.FinalMoQ,
				MOV: row.FinalMoV,
				DateMetMOQMOV: row.LastMOVAndMOQDateTime ? dayjs(row.LastMOVAndMOQDateTime).format(utils.systemDateTimeFormat(true)) : '',
				LoggedInUserName: userData?.tags?.Username,
				ActiveTab: tab,
				IssueType: IssueType
			};

			const headers = {};
			const payload = {
				url: apis.CreateTicket,
				method: 'POST',
				data: params,
				headers: {
					"Content-Type": "application/json",
					...headers
				},
			};
			const result = await transport(payload);
			if (result?.data?.success) {
				snackbar.showMessage('Your ticket has been created');
			} else {
				snackbar.showError(errorMessage);
			}
		} catch (error) {
			window.onerror(error)
			snackbar.showError(errorMessage);
		}
	},
	sortDates: (categories) => {
		const catSet = Array.from(new Set(categories));
		const sortedCat = catSet
			.map(item => {
				const [day, month, year] = item.split('-');
				const date = new Date(`${year}-${month}-${day}`);
				return { original: item, date };
			})
			.sort((a, b) => a.date - b.date)
			.map(item => item.original);
		const cat = sortedCat.map(item => {
			const [day, month, year] = item.split('-');
			const date = new Date(`${year}-${month}-${day}`);
			return isNaN(date.getTime()) ? new Date() : date;
		});
		return cat;
	},
	getSavedUserData: () => {
		const { appReducer: { userData } } = store.getState();
		const { Username: userName, ClientUserId: userId, ClientName: clientName, ClientId: clientId } = userData?.tags || {};
		return { userName, userId, clientName, clientId };
	},
	getMoreFilters: ({ filterValues, finalWhere }) => {
		const filterMap = {
			marketIds: "MarketId",
			channelIds: "LocationTypeId",
			classificationIds: "ClassificationId",
			DistributorId: "DistributorId",
			managerSelectedValue: "SalesRepId",
			routeSelectedValue: "PreSellerRouteId",
			casesRange: "TotalCase",
			orderRange: "TotalOrder"
		};
		Object.keys(filterValues || {}).forEach(key => {
			if (filterMap[key] && filterValues[key] && filterValues[key] !== '-1') {
				finalWhere[filterMap[key]] = {
					value: filterValues[key],
					fieldName: filterMap[key],
					operator: '='
				};
			}
		});

		return finalWhere;
	},
	getChartDimensions: (dataLength) => {
		if (!dataLength) dataLength = 0;
		const minHeight = 450;
		const maxHeight = 980;
		const minMarginTop = 108;
		const maxMarginTop = 405;
		const heightFactor = 8;
		const marginFactor = 6;
		let height = minHeight + (dataLength * heightFactor);
		let marginTop = minMarginTop + (dataLength * marginFactor);
		height = Math.min(height, maxHeight);
		marginTop = Math.min(marginTop, maxMarginTop);
		return {
			height,
			margin: {
				top: marginTop
			}
		};
	},
	generateColunms: (columns) => {
		let expoColumns = {};
		columns.forEach(column => {
			expoColumns[column.field] = {
				field: column.field,
				width: column.width,
				headerName: column.headerName,
				type: column.type,
				keepUTC: column.keepUTC === true
			};
		});
		return expoColumns;
	},
	handleSubmit: async (event, { currentRow, currentTab, IssueType, issueDetails, userData, snackbar, t, tOpts, handleClose, setIssueDetails, setIssueType, dispatch }) => {
		event.preventDefault();
		dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
		try {
			handleClose();
			const createTicketFn = utils.createTicket({
				row: currentRow,
				tab: currentTab,
				userData,
				snackbar,
				IssueType: IssueType === 'Others' ? issueDetails : IssueType
			});
			await createTicketFn();
			setIssueDetails('');
			setIssueType('');
			dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
		} catch (error) {
			snackbar.showError(t('Your ticket was not created successfully, please try after some time.', tOpts));
		}
	}
}

export default utils;
