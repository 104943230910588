import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Grid
} from "@material-ui/core";
import AssetTabs from './assetTab';
import { TableChart, Compare, Image } from '@mui/icons-material';
import VistaDetailCard from '../VistaLocationDetailsCardList/VistaDetailCard';
import OutletVistaDetailsCard from '../VistaLocationDetailsCardList/OutletVistaDetailsCard';
import useMobile from '../../utils/useMobile';
import utils from '../../utils';
const { t } = utils;


const VistaTab = ({ outletCard, onClose, selectedTabIndex, selectedAssetId, showTabs, assetImages, setSelectedTabIndex, assetsList, orderingTabSelection, toggleAssetCollapse, collapseStates, replenishData }) => {
    const isMobile = useMobile();
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const [selectedTab, setSelectedTab] = useState('image'); // 'image', 'table', or 'compare'
    const [showImage, setShowImage] = useState(true);
    const [showTable, setShowTable] = useState(false);

    const changeTab = (props) => {
        setShowImage(props?.showImage)
        setShowTable(props?.showTable)
        setSelectedTab(props?.active)
    }

    return (
        <>
            {isMobile.mobile ?
                <div>
                    {outletCard.vistaData.length > 0 ? outletCard.vistaData.map((vistaData, index) => {
                        return <VistaDetailCard key={"VD-Card-" + index} manualData={vistaData} hideHeaderFilter={true} hidePrevNext={false} isCardContentOnly={true} isOutletCard={true} onClose={onClose} />
                    }) : <div className='text-center mt-5'>{t("No Image/Data found", tOpts)}</div>}
                </div>
                :
                <>
                    <AssetTabs selectedTabIndex={selectedTabIndex} setSelectedTabIndex={setSelectedTabIndex} assetsList={assetsList} orderingTabSelection={orderingTabSelection} assetImages={assetImages} toggleAssetCollapse={toggleAssetCollapse} collapseStates={collapseStates} />
                    <div key={selectedAssetId}>
                        {showTabs ?
                            <Grid container >
                                <Grid item xs={1} className='d-flex align-item-center'>
                                    <Grid container direction="column" alignItems="center" justifyContent="center">
                                        <Grid onClick={() => changeTab({ showImage: true, showTable: false, active: 'image' })} className='outlet-card-image-icon' item style={{ backgroundColor: selectedTab === 'image' ? '#172EB5' : '' }}>
                                            <Image htmlColor={selectedTab === 'image' ? 'white' : ''} />
                                        </Grid>
                                        <Grid onClick={() => changeTab({ showImage: false, showTable: false, active: 'compare' })} className='outlet-card-compare-icon' item style={{ backgroundColor: selectedTab === 'compare' ? '#172EB5' : '' }}>
                                            <Compare htmlColor={selectedTab === 'compare' ? 'white' : ''} />
                                        </Grid>
                                        <Grid item onClick={() => changeTab({ showImage: false, showTable: true, active: 'table' })} className='outlet-card-table-icon' style={{ backgroundColor: selectedTab === 'table' ? '#172EB5' : '' }}>
                                            <TableChart htmlColor={selectedTab === 'table' ? 'white' : ''} />
                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid item xs={11}>
                                    {assetImages.length > 0 ? (
                                        assetImages?.map((vistaData, index) => (
                                            <OutletVistaDetailsCard
                                                key={"VD-Card-" + index}
                                                manualData={vistaData}
                                                hideHeaderFilter={true}
                                                showImageOnly={showImage}
                                                replenishmentData={replenishData}
                                                showTable={showTable}
                                                hidePrevNext={false}
                                                isCardContentOnly={true}
                                                isOutletCard={true}
                                                onClose={onClose}
                                            />
                                        ))
                                    ) : (
                                        <div className='text-center mt-5 d-flex justify-content-center outletcard-no-data'>{t("No Image/Data found for this asset", tOpts)}</div>
                                    )}
                                </Grid>
                            </Grid>
                            :
                            <Grid container >
                                <Grid item xs={showTabs ? 11 : 12}>
                                    {assetImages.length > 0 ? (
                                        assetImages?.map((vistaData, index) => (
                                            <VistaDetailCard
                                                key={"VD-Card-" + index}
                                                manualData={vistaData}
                                                hideHeaderFilter={true}
                                                showImageOnly={showImage}
                                                replenishmentData={replenishData}
                                                showTable={showTable}
                                                hidePrevNext={false}
                                                isCardContentOnly={true}
                                                isOutletCard={true}
                                                onClose={onClose}
                                            />
                                        ))
                                    ) : (
                                        <div className='text-center mt-5 d-flex justify-content-center outletcard-no-data'>{t("No Image/Data found for this asset", tOpts)}</div>
                                    )}
                                </Grid>
                            </Grid>
                        }
                    </div >
                </>
            }

        </>

    )
}


export default VistaTab;