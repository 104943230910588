import { getGridStringOperators } from '@mui/x-data-grid-premium';
import { AcUnit, CameraAlt, GpsFixed, GridOff, GridOn } from '@mui/icons-material';
import UiModel from './ui-model';
import { useSelector } from 'react-redux';
import constants from '../utils/constants';
const { groupByMappings } = constants;

const stringFilterOperators = getGridStringOperators().filter((operator) => ['contains', 'startsWith', 'endsWith', 'equals'].includes(operator.value));

const RenderGroupBy = ({ row }) => {
    const groupBy = useSelector(state => state.appReducer.dataGroupBy);
    return row[groupByMappings[groupBy]];
}

const dataPivotModel = new UiModel({
    idProperty: 'SerialNumber',
    readOnly: true,
    defaultSort: 'SerialNumber ASC',
    customAPI: 'pivot/pivotData',
    module: 'DataPage',
    isPivotGrid: true,
    addCreatedModifiedColumns: false,
    showHeaderFilters: true,
    hasCustomHeaderComponent: true,
    preferenceId: 'playbookDataPivot',
    useGroupByPrimaryKey: true,
    modelPermissions: { add: false, edit: false, export: false, delete: false, filter: true },
    columns: [
        {
            field: "SerialNumber", label: "Name", useCustomFilterField: true, filterOperators: stringFilterOperators, width: 200, pinnable: false, renderCell: (params) => {
                const { row } = params;
                return <RenderGroupBy row={row} />
            }
        },
        { field: "AssetId", label: "Assets", type: "number", width: 130, pinnable: false, sortable: false, filterable: false },
        {
            field: "DisplacementAboveThreshold", type: "number", label: "GPS", sortable: false, filterable: false, renderHeader: (params) => {
                return <div className='display-flex'>
                    <div className='data-page-icons'>
                        <GpsFixed />
                    </div>
                    <span style={{ fontWeight: 500 }}>{'GPS'}</span>
                </div>
            }, width: 150, pinnable: false
        },
        {
            field: "TemperatureAboveThreshold", type: "number", label: "Temperature", sortable: false, filterable: false, renderHeader: (params) => {
                return <div className='display-flex'>
                    <div className='data-page-icons'>
                        <AcUnit />
                    </div>
                    <span style={{ fontWeight: 500 }}>{'Temperature'}</span>
                </div>
            }, width: 150, pinnable: false
        },
        {
            field: "EmptySosAboveThreshold", type: "number",  label: "Empty SOS", sortable: false, filterable: false, renderHeader: (params) => {
                return <div className='display-flex'>
                    <div className='data-page-icons'>
                        <GridOff />
                    </div>
                    <span style={{ fontWeight: 500 }}>{'Empty SOS'}</span>
                </div>
            }, width: 150, pinnable: false
        },
        {
            field: "ForeignSosAboveThreshold", type: "number", label: "Foreign SOS", sortable: false, filterable: false, renderHeader: (params) => {
                return <div className='display-flex'>
                    <div className='data-page-icons'>
                        <GridOn />
                    </div>
                    <span style={{ fontWeight: 500 }}>{'Foreign SOS'}</span>
                </div>
            }, width: 150, pinnable: false
        },
        {
            field: "AssetStatusNotWorking", type: "number", label: "Hardware", sortable: false, filterable: false, renderHeader: (params) => {
                return <div className='display-flex'>
                    <div className='data-page-icons'>
                        <CameraAlt />
                    </div>
                    <span style={{ fontWeight: 500 }}>{'Hardware'}</span>
                </div>
            }, width: 150, pinnable: false
        },
    ]
});

export default dataPivotModel;