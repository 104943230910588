import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/PageTitle'
import actions from '../../redux/actions';
import { TrueDashboard } from '../../components/TrueGateWayComponents'
import utils from "../../utils";
const { t } = utils;

const trueDashboardEnum = {
  title: 'True Gateway',
  description: 'True Gateway'
}

export default function BusinessKPI() {
  const dispatch = useDispatch();
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };

  useEffect(() => {
    dispatch({
      type: actions.SET_PAGE_TITLE_DETAILS,
      pageTitleDetails: <PageTitle titleHeading={t(trueDashboardEnum.title, tOpts)} titleDescription={t(trueDashboardEnum.description, tOpts)} icon="" title={t(trueDashboardEnum.description, tOpts)} />
    });

    return () => {
      dispatch({
        type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
      })
    }
  }, [])

  return (<>
    <TrueDashboard />
  </>)
}
