import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Grid
} from "@material-ui/core";
import Collapse from '@material-ui/core/Collapse';
import { Tooltip } from '@mui/material';
import AssetTabs from './assetTab';
import { TableChart, Compare, Image, ShoppingCart } from '@mui/icons-material';
import EditOrder from '../Replenishment/EditOrder';
import OrderSummary from './orderSummary';
import useMobile from '../../utils/useMobile';
import utils from '../../utils';
import OutletOrderingDetailsCard from '../VistaLocationDetailsCardList/OutletOrderingDetailsCard';
const { t } = utils;


const OrderingTab = React.memo((props) => {
    const { selectedTabIndex, setSelectedTabIndex, assetsList, assetImages, selectedAssetId, showOrderingTabs, outletDataForOrderingTab, replenishData, code, gridData, onClose, onConfirm,
        orderStatusId,
        editOrder,
        outletData,
        orderingTableScrollRef,
        onScheduledIgnoreClick } = props;
    const [showImageOrdering, setShowImageOrdering] = useState(true)
    const [showTableOrdering, setShowTableOrdering] = useState(false)
    const [showShoppingCart, setShowShoppingCart] = useState(false)
    const [orderingTabSelection, setOrderingTabSelection] = useState('image');
    const [collapseStates, setCollapseStates] = useState({
        image: true,
        table: true,
        compare: true,
        shoppingCart: true
      });

    const isMobile = useMobile();
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    let mobile = isMobile.mobile;
    const changeTab = (props) => {
        setShowImageOrdering(props?.showImage);
        setShowTableOrdering(props?.showTable);
        setShowShoppingCart(props?.showShoppingCart);
        setOrderingTabSelection(props?.active);
    };

    const toggleAssetCollapse = () => {
        setCollapseStates(prevStates => ({
          ...prevStates,
          [orderingTabSelection]: !prevStates[orderingTabSelection]
        }));
      };
    

 
    return (
        <div>
                {showOrderingTabs && outletDataForOrderingTab?.ProductInfo ?
                    <>
                        {!assetImages.length && (
                            <div>
                                <AssetTabs
                                    selectedTabIndex={selectedTabIndex}
                                    setSelectedTabIndex={setSelectedTabIndex}
                                    assetsList={assetsList}
                                    orderingTabSelection={orderingTabSelection}
                                    assetImages={assetImages}
                                    toggleAssetCollapse={toggleAssetCollapse}
                                    collapseStates={collapseStates}
                                />
                                <div className='text-center mt-5 d-flex justify-content-center outletcard-no-data'>
                                    {t("No Data/Image found for this asset", tOpts)}
                                </div>
                            </div>
                        )}
                        {!!assetImages.length && (
                            <Grid container>
                                <Grid item xs={mobile ? 12 : 1} className='d-flex align-item-center mb-1 mt-2 outlet-left-container-image' >
                                    <Grid container direction={mobile ? "row" : "column"} alignItems="center" justifyContent="center" className='left-container-outlet-data'>
                                        <Tooltip title={t("Image", tOpts)}>
                                            <Grid onClick={() => changeTab({ showImage: true, showTable: false, showShoppingCart: false, active: 'image' })} className={mobile ? "outlet-card-icon-mobile-left" : 'outlet-card-image-icon'} item style={{ backgroundColor: orderingTabSelection === 'image' ? '#172EB5' : '' }}>
                                                <Image htmlColor={orderingTabSelection === 'image' ? 'white' : ''} />
                                            </Grid>
                                        </Tooltip>
                                        <Tooltip title={t("Recognition", tOpts)}>
                                            <Grid onClick={() => changeTab({ showImage: false, showTable: false, showShoppingCart: false, active: 'compare' })} className='outlet-card-compare-icon' item style={{ backgroundColor: orderingTabSelection === 'compare' ? '#172EB5' : '' }}>
                                                <Compare htmlColor={orderingTabSelection === 'compare' ? 'white' : ''} />
                                            </Grid>
                                        </Tooltip>
                                        <Tooltip title={t("Recognition Table", tOpts)}>
                                            <Grid item onClick={() => changeTab({ showImage: false, showTable: true, showShoppingCart: false, active: 'table' })} className='outlet-card-compare-icon' style={{ backgroundColor: orderingTabSelection === 'table' ? '#172EB5' : '' }}>
                                                <TableChart htmlColor={orderingTabSelection === 'table' ? 'white' : ''} />
                                            </Grid>
                                        </Tooltip>
                                        <Tooltip title={t("Suggested Order Table", tOpts)}>
                                            <Grid item onClick={() => changeTab({ showImage: false, showTable: false, showShoppingCart: true, active: 'shoppingCart' })} className={mobile ? "outlet-card-icon-mobile-right" : 'outlet-card-table-icon'} style={{ backgroundColor: orderingTabSelection === 'shoppingCart' ? '#172EB5' : '' }}>
                                                <ShoppingCart htmlColor={orderingTabSelection === 'shoppingCart' ? 'white' : ''} />
                                            </Grid>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid item xs={mobile ? 12 : 6} className={mobile ? '' : ''} id="mdidle-container-outlet-data">
                                    <div style={{ margin: '2% 0% 2% 0%' }}>
                                        <AssetTabs
                                            selectedTabIndex={selectedTabIndex}
                                            setSelectedTabIndex={setSelectedTabIndex}
                                            assetsList={assetsList}
                                            orderingTabSelection={orderingTabSelection}
                                            assetImages={assetImages}
                                            toggleAssetCollapse={toggleAssetCollapse}
                                            collapseStates={collapseStates}
                                        />
                                        <div key={selectedAssetId}>
                                        {assetImages.length > 0 ? (
                                            assetImages?.map((vistaData, index) => (
                                                <Collapse in={collapseStates[orderingTabSelection]} timeout="auto" unmountOnExit>
                                                    <OutletOrderingDetailsCard
                                                        key={"VD-Card-" + index}
                                                        manualData={vistaData}
                                                        hideHeaderFilter={true}
                                                        showImageOnly={showImageOrdering}
                                                        replenishmentData={replenishData}
                                                        showTable={showTableOrdering}
                                                        orderSummaryTab={true}
                                                        showShoppingCart={showShoppingCart}
                                                        hidePrevNext={false}
                                                        isCardContentOnly={true}
                                                        outletCode={code}
                                                        outletDataForOrderingTab={gridData?.data?.records ? gridData?.data?.records[0] : []}
                                                        isOutletCard={true}
                                                        onClose={onClose}
                                                    />
                                                </Collapse>
                                            ))
                                        ) : (
                                            <div className='text-center mt-5 d-flex justify-content-center outletcard-no-data'>{t("No order found for this location", tOpts)}</div>
                                        )}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={mobile ? 12 : 5} className='order-product-container-box-outlet' >
                                    {outletDataForOrderingTab.ProductInfo ?
                                        <div className='h-500'>
                                            <OrderSummary
                                                onConfirm={onConfirm}
                                                ref={orderingTableScrollRef}
                                                products={gridData && gridData.data.records.length ? gridData.data.records[0].ProductInfo : null}
                                                orderStatusId={orderStatusId}
                                                editOrder={editOrder}
                                                outletData={outletData}
                                                t={t}
                                                tOpts={tOpts}
                                                gridData={gridData}
                                                onScheduledIgnoreClick={onScheduledIgnoreClick}
                                            />
                                        </div>
                                        :
                                        <div className='text-center mt-5 d-flex justify-content-center outletcard-no-data'>{t("No order found for this location", tOpts)}</div>
                                    }
                                </Grid>
                            </Grid>
                        )}
                        <EditOrder isForOrderingTab={true} />
                    </>
                    :
                    <>
                        <AssetTabs
                            selectedTabIndex={selectedTabIndex}
                            setSelectedTabIndex={setSelectedTabIndex}
                            assetsList={assetsList}
                            orderingTabSelection={orderingTabSelection}
                            assetImages={assetImages}
                            toggleAssetCollapse={toggleAssetCollapse}
                            collapseStates={collapseStates}
                        />
                        <div className='text-center mt-5 d-flex justify-content-center outletcard-no-data'>{t("No order found for this location", tOpts)}</div>
                    </>
                }
            </div >
    )
})


export default OrderingTab;