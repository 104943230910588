import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import swal from 'sweetalert';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import MultiSelectDropdown from '../mutliSelectDropdown';
import actions from '../../redux/actions';
import PageTitle from '../PageTitle';
import missedOpportunityOutletModel from '../../pages/missed-opportunity-outlet';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const config = {
    label: "Clients",
    titleMessage: 'Select & Unselect All',
    multiSelectLabel: 'Multiple Clients',
}

const HorizontalBars = React.memo(function HorizontalBars() {
    const userData = useSelector(state => state.appReducer.userData) || {};
    const { ClientId } = userData?.tags || {};
    const [selectedClients, setSelectedClients] = React.useState([Number(ClientId)]);
    const [activeClients, setActiveClients] = React.useState([]);
    const [dateDiff, setDateDiff] = React.useState(30);
    const [dateValue, setDateValue] = React.useState([dayjs().subtract(30, 'day'), dayjs()]);
    const [gridFilters, setGridFilters] = React.useState([]);
    const clientsList = useSelector(state => state.appReducer.clientsList) || {};
    const dispatch = useDispatch();

    React.useEffect(() => {
        let gridFilter = [];
        if (dateValue) {
            gridFilter = [{ value: dayjs(dateValue[0]).format('YYYY-MM-DD'), field: "PurityDateOnly", pivotFilterField: "StartDate", operator: ">=" }, { value: dayjs(dateValue[1]).format('YYYY-MM-DD'), field: "PurityDateOnly", pivotFilterField: "EndDate", operator: "<=" }];
        }
        if (gridFilter) {
            setGridFilters(gridFilter);
        }
    }, []);


    React.useEffect(() => {
        dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle icon="" titleHeading='Missed Opportunity' titleDescription='Missed Opportunity is the value of products being unavailable in stores, achieve this with a calculation on the sales rate of each product on each store and the projection of sales based on time the product were unavailable.' title='Missed Opportunity' /> });
    }, [])

    const handleClientSelectChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === -1) {
            setSelectedClients(selectedClients.length === clientsList.length ? [] : clientsList?.map(client => client.Key));
            return;
        }
        setSelectedClients(value);
    };

    function onResetClick() {
        setSelectedClients();
        setDateValue([dayjs().subtract(30, 'day'), dayjs()]);
        setActiveClients();
    }

    function onApplyFilterClick() {
        if (dayjs(dateValue[0]).isValid() && dayjs(dateValue[1]).isValid()) {
            const selectedDate = dayjs(dateValue[0]).format('YYYY-MM-DD');
            const currentDate = dayjs(dateValue[1]).format('YYYY-MM-DD');
            const differenceInDays = dayjs(currentDate).diff(dayjs(selectedDate), 'day');
            setDateDiff(differenceInDays);
            let gridFilter = [];
            if (dateValue) {
                gridFilter = [{ value: dayjs(dateValue[0]).format('YYYY-MM-DD'), field: "PurityDateOnly", operator: ">=", pivotFilterField: 'StartDate' }, { value: dayjs(dateValue[1]).format('YYYY-MM-DD'), field: "PurityDateOnly", pivotFilterField: "EndDate", operator: "<=" }];
            }
            if (gridFilter) {
                setGridFilters(gridFilter);
            }
            setActiveClients(selectedClients);
        }
        else {
            swal({ text: "Invalid Date, please pass date in correct format", icon: "error" });
        }
    }

    const gridPivotFilter = React.useMemo(() => {
        const grFilter = [];
        if (selectedClients?.length) {
            grFilter.push({ value: selectedClients, field: "selectedClients" });
        }
        if (dateValue?.length) {
            grFilter.push({ value: dayjs(dateValue[0]).format('YYYY-MM-DD'), field: "StartDate" });
            grFilter.push({ value: dayjs(dateValue[1]).format('YYYY-MM-DD'), field: "EndDate" });
        }
        return grFilter;
    });

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item>
                        <div style={{ fontSize: 'large', fontWeight: 500, color: 'black', marginTop: 15, marginBottom: 10 }}>
                            Missed Opportunity {dateDiff ? `- Last ${dateDiff} Days` : null}
                        </div>
                    </Item>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Item>
                        <div style={{ fontSize: 'large', fontWeight: 500, color: 'black', marginBottom: 10 }}>
                            Filters
                        </div>
                        <div style={{ display: "flex" }}>
                            <Grid item xs={6}>
                                <div>
                                    <DateRangePicker value={dateValue} onChange={(newValue) => setDateValue(newValue)} />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ marginLeft: 15 }} className="missed-opportunity-client-select">
                                    <MultiSelectDropdown config={config} options={clientsList} selectedOptions={selectedClients} handleChange={handleClientSelectChange} addMargin={false} width={220} subStringlength={45} />
                                </div>
                            </Grid>
                        </div>
                        <div style={{ marginTop: 10, display: 'flow-root' }}>
                            <Button variant="text" style={{ float: 'left' }} onClick={() => onResetClick()}>Reset</Button>
                            <Button variant="text" style={{ float: 'right' }} onClick={() => onApplyFilterClick()}>Apply Filter</Button>
                        </div>
                    </Item>
                </Grid>
            </Grid>
            <Grid sx={{ marginTop: 5 }}>
                <missedOpportunityOutletModel.Grid additionalFilters={gridFilters} reRenderKey={gridFilters} selectedClients={activeClients} gridPivotFilter={gridPivotFilter} />
            </Grid>
        </Box>
    );
})

export default HorizontalBars;