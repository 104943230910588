import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import utils from '../../utils';

const DialogComponent = ({ open, onConfirm, title = "Confirm", onCancel, okText, cancelText, yesNo = false, hideCancelButton = false, children }) => {
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    okText = okText ? okText : (yesNo ? 'Yes' : 'Ok');
    cancelText = cancelText ? cancelText : (yesNo ? 'No' : 'Cancel');
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t(title, tOpts)}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t(children, tOpts)}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {!hideCancelButton && <Button onClick={onCancel}>{t(cancelText, tOpts)}</Button>}
                <Button onClick={onConfirm} autoFocus>{t(okText, tOpts)}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogComponent;