import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import { Chip } from "@material-ui/core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import "react-calendar/dist/Calendar.css";
import { useTranslation } from "react-i18next";
import constants from "../../utils/constants";
const { dateFormat } = constants;

dayjs.extend(utc);

const today = new Date(),
  tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

function tileContent(events) {
  return ({ date, view }) => {
    // Add class to tiles in month view only
    const actualDate = new Date(date);
    if (view === "month") {
      let returnValue = null;
      for (const event of events) {
        if (isSameDay(event.date, actualDate)) {
          returnValue = (
            <div className="calendar-bubble">
              <Chip id="red-chip" label={event.value} />
            </div>
          );
        }
      }
      if (returnValue) {
        return returnValue;
      } else {
        return <div className="calendar-bubble"> </div>;
      }
    }
  };
}

function isSameDay(firstDate, secondDate) {
  firstDate = dayjs(firstDate).format(dateFormat.ddmmyy);
  secondDate = dayjs(secondDate).format(dateFormat.ddmmyy);
  return firstDate === secondDate;
}

function tileClassName(e, changed) {
  const { date, view } = e;
  // Add class to tiles in month view only
  const actualDate = new Date(date);
  if (isSameDay(today, actualDate) && !isSameDay(changed, today)) {
    return "calendar-current-day";
  }

  if (view === "month") {
    const day = actualDate.getDay();
    if ([0, 6].includes(day)) {
      return "calendar-weekends";
    }
  }
}

export default function AppCalendar({
  events = [],
  data,
  filterModel,
  setFilterModel,
  defaultState,
  setDefaultState,
  setFilterReady,
}) {
  const { t, i18n } = useTranslation();
  const [value, onChange] = useState(new Date());
  const [changed, setChanged] = useState("");

  useEffect(() => {
    if (defaultState || filterModel.items.length === 0) {
      onChange(new Date());
      setDefaultState(false);
    }
  }, [data, filterModel]);

  const handleChange = (date) => {
    onChange(date);
    setChanged(date);
    if (setFilterModel) {
      setFilterReady(true);
      setFilterModel({
        linkOperator: "AND",
        items: [
          {
            columnField: "ScheduleDate",
            value: `${dayjs(date).format(dateFormat.yymmdd)}`,
            operatorValue: "is",
          },
        ],
      });
    }
  };

  return (
    <Calendar
      className={"calendar"}
      onChange={(date) => handleChange(date)}
      value={value}
      calendarType="US"
      tileClassName={(e) => tileClassName(e, changed)}
      tileContent={tileContent(data?.calendar || [])}
      locale={i18n.language}
    />
  );
}
