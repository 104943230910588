import React, { useEffect, useCallback, useMemo, useState } from 'react';
import {
    Tabs, Tab, Button
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import enums from '../../utils/constants'

const { imageGridBox } = enums;




const AssetTabs = React.memo((props) => {
    const { selectedTabIndex, setSelectedTabIndex, assetsList, orderingTabSelection, assetImages, toggleAssetCollapse, collapseStates } = props;

    const handleChangeTab = (event, newValue) => {
        setSelectedTabIndex(newValue);
    };


    return (
        <div className='d-flex'>
            <Tabs
                className='mb-2 ordering-assest-tab-scroll-container'
                value={selectedTabIndex}
                onChange={handleChangeTab}
                variant={"scrollable"}
                scrollButtons={'on'}
            >
                {assetsList.map((tab, tabKey) => (
                    <Tab
                        key={tabKey}
                        index={tabKey}
                        className="pt-0"
                        style={{ textTransform: 'none' }}
                        value={tab.AssetId}
                        label={tab.AssetSerialNumber}
                    />

                ))}
            </Tabs>
            {orderingTabSelection !== imageGridBox && !(assetImages.length < 1) && (
                <Button
                    className="expand-more-less-icon-asset"
                    onClick={toggleAssetCollapse}
                >
                    {collapseStates[orderingTabSelection] ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </Button>
            )}
        </div>
    );
});

export default AssetTabs;