import UiModel from './ui-model';

const planogramsListModel = new UiModel({
    title: "Master Data",
    defaultSort: 'PlanogramName DESC',
    titleDescription: "Check how your master data is set",
    idProperty: 'PlanogramId',
    readOnly: true,
    api: 'MasterPlanogram',
    addCreatedByColumn: false,
    addModifiedOnColumn: false,
    addModifiedByColumn: false,
    preferenceId: 'playbookMasterPlanogram',
    columns: [
        { field: "PlanogramName", label: "Planogram Name", type: 'string', width: 200 },
        { field: "AssignedAssetsCount", label: "Assigned Assets", type: 'number', width: 150 },
        { field: "Shelves", label: "Shelves", type: 'number', width: 100 },
        { field: "Facings", label: "Total SKUs", type: 'number', width: 100 },
        { field: "FacingsDistinct", label: "Unique SKUs", type: 'number', width: 150 }
    ]
});

export default planogramsListModel;