import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import enums from '../../utils/constants';
import FormGenerator from '../FormGenerator';
import utils from '../../utils';

const { t } = utils;
const { booleanCombo } = enums;

const InformationTab = ({
    outletData, outletClientId, locationId
}) => {
    const filterValues = useSelector(state => state.appReducer.filterValues);
    const multiClientFilters = useSelector(state => state.appReducer.filters);
    const formConfig = [
        {
            active: true,
            mapping: [{
                heading: "Main",
                active: true,
                felids: [
                    { name: 'Name', label: 'Name', type: "text", },
                    { name: 'Code', label: 'Code', type: "text", },
                ]
            }]
        },
        {
            active: true,
            mapping: [{
                heading: "Categories",
                active: true,
                felids: [
                    { name: 'MarketId', label: 'Market', type: "select", comboKey: 'Market' },
                    { name: 'LocationTypeId', label: 'Channel', type: "select", comboKey: 'LocationType' },
                    { name: 'ClassificationId', label: 'Classification', type: "select", comboKey: 'LocationClassification' },
                ]
            }]
        },
        {
            active: true,
            mapping: [{
                heading: "Location",
                active: true,
                felids: [
                    { name: 'Street', label: 'Street', type: "text" },
                    { name: 'City', label: 'City', type: "text" },
                    { name: 'StateId', label: 'State', type: "select", comboKey: 'State' },
                    { name: 'CountryId', label: 'Country', type: "select", comboKey: 'Country' },
                    { name: 'PostalCode', label: 'Postal code', type: "text", },
                    { name: 'Latitude', label: 'Latitude', type: "text", },
                    { name: 'Longitude', label: 'Longitude', type: "text", },
                ]
            }]
        },
        {
            active: true,
            mapping: [{
                heading: "Configuration",
                active: true,
                felids: [
                    { name: 'IsSkipOrder', label: 'Suggested Ordering Paused', type: "select", comboKey: 'BooleanCombo' },
                    { name: 'IsActive', label: 'Is Active', type: "select", comboKey: 'BooleanCombo', },
                ]
            }]
        },
        {
            active: true,
            mapping: [{
                heading: "Hardware",
                active: true,
                felids: [
                    { name: 'AssetsCount', label: 'Assets', type: "text", disabled: true },
                    { name: 'CamerasCount', label: 'Cameras', type: "text", disabled: true },
                ]
            }]
        },
    ]
    const comboArray = ["State", "Country", "Market", "LocationType", "LocationClassification"]
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };

    return Object.keys(outletData).length > 0 ?
        <FormGenerator
            formConfig={formConfig}
            moduleName={"OutletCard"}
            id={locationId}
            api={"Location"}
            comboArray={comboArray}
            additionalData={{
                AssetsCount: outletData?.assets ? (outletData?.assets.length + ' ' + (outletData?.assets?.length > 0 ? `(${outletData?.assets.join(', ')})` : '')) : 0,
                CamerasCount: outletData?.cameras ? (outletData?.cameras.length + ' ' + (outletData?.cameras?.length > 0 ? `(${outletData?.cameras.join(', ')})` : '')) : 0
            }}
            localComboData={{ "BooleanCombo": booleanCombo }}
            isEditEnabled={false}
            filterValues={filterValues}
            multiClientFilters={multiClientFilters}
            outletClientId={outletClientId}
            reduceMargin={true}
            cardClassName='outlet-card-border'
        /> :
        <div className='w-100 text-center'>{t("Loading data ...", tOpts)}</div>
}

export default InformationTab;