import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import * as React from 'react';
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExportContainer,
    getGridDateOperators,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    getGridStringOperators,
    getGridNumericOperators
} from '@mui/x-data-grid-premium';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import {
    GridActionsCellItem,
    useGridApiRef
} from '@mui/x-data-grid-premium';

import { useMemo, useEffect, memo, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import utils from '../../utils';
import { useSnackbar } from "@durlabh/dframework-ui";
import DialogComponent from "../Dialog";

import { getList, getRecord, deleteRecord } from '../crud-helper';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import constants from "../../utils/constants";
import { useDispatch } from "react-redux";
import actions from "../../redux/actions";
import PageTitle from "../PageTitle";
import Footer from "./footer";
import template from "../../utils/template";
import LocalizedDatePicker from "../LocalizedDatePicker";
import { Tooltip } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@material-ui/core";
import { STRINGS_CONST } from '../../constants';
import CustomDropdownmenu from "../CustomDropdownmenu";
import GridPreferences from "../GridPreference";
import DialogModal from '../Common/DialogModel';
import ManageData from "../UnileverComponents/ManageData";
import ChildGridComponent from "../ChildGridComponent";
import dbUtil from "../../pages/RenderSurveyAnswer/dbUtil";
import { v4 as uuidv4 } from 'uuid';

const t = utils.t;
const defaultPageSize = 10;
const sortRegex = /(\w+)( ASC| DESC)?/i;
const recordCounts = 60000;

const actionTypes = {
    Copy: "Copy",
    Edit: "Edit",
    Delete: "Delete"
};


const { EXCEL_FORMAT } = STRINGS_CONST;

const booleanIconRenderer = (params) => {
    if (params.value) {
        return <CheckIcon style={{ color: 'green' }} />;
    } else {
        return <CloseIcon style={{ color: 'gray' }} />;
    }
}

const gridColumnTypes = {
    "radio": {
        "type": "singleSelect",
        "valueOptions": "lookup"
    },
    "date": {
        "valueFormatter": ({ value }) => (
            utils.formatDate(value, true)
        ),
        "filterOperators": LocalizedDatePicker({ columnType: "date" }),
    },
    "dateTime": {
        "valueFormatter": ({ value }) => (
            utils.formatDate(value, false)
        ),
        "filterOperators": LocalizedDatePicker({ columnType: "datetime" }),
    },
    "dateTimeLocal": {
        "valueFormatter": ({ value }) => (
            utils.formatDate(value, false)
        ),
        "filterOperators": LocalizedDatePicker({ type: "dateTimeLocal", convert: true }),
    },
    "boolean": {
        renderCell: booleanIconRenderer
    }
}

const useStyles = makeStyles({
    buttons: {
        margin: '6px !important'
    }
})

const convertDefaultSort = (defaultSort) => {
    const orderBy = [];
    if (typeof defaultSort === 'string') {
        const sortFields = defaultSort.split(',');
        for (const sortField of sortFields) {
            sortRegex.lastIndex = 0;
            const sortInfo = sortRegex.exec(sortField);
            if (sortInfo) {
                const [, field, direction = 'ASC'] = sortInfo;
                orderBy.push({ field: field.trim(), sort: direction.trim().toLowerCase() });
            }
        }
    }
    return orderBy;
};

const ExportMenuItem = ({ handleExport, contentType, type, isPivotExport = false, isDetailsExport = false }) => {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };

    return (
        <MenuItem
            onClick={handleExport}
            data-type={type}
            data-content-type={contentType}
            data-is-pivot-export={isPivotExport}
            data-is-details-export={isDetailsExport}
        >
            {t("Export", tOpts)} {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}
        </MenuItem>
    );
};

ExportMenuItem.propTypes = {
    hideMenu: PropTypes.func
};

const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
        {props?.showOnlyExcelExport !== true && <ExportMenuItem {...props} type="csv" contentType="text/csv" />}
        {props.hideExcelExport === false && <ExportMenuItem {...props} type="excel" contentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />}
        {props.showExportWithDetails && <ExportMenuItem {...props} type="excel Details" contentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" isDetailsExport={true} />}
        {props.showPivotExportBtn && <ExportMenuItem {...props} type="excel With Pivot" contentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" isPivotExport={true} />}
        {props?.showOnlyExcelExport !== true && <>

            {props.hideXmlExport === false && <ExportMenuItem {...props} type="xml" contentType="text/xml" />}
            {props.hideHtmlExport === false && <ExportMenuItem {...props} type="html" contentType="text/html" />}
            {props.hideJsonExport === false && <ExportMenuItem {...props} type="json" contentType="application/json" />}
        </>}
    </GridToolbarExportContainer>
);

const areEqual = (prevProps = {}, nextProps = {}) => {
    let equal = true;
    for (const o in prevProps) {
        if (prevProps[o] !== nextProps[o]) {
            equal = false;
            console.error({ o, prev: prevProps[o], next: nextProps[o] });
        }
    }
    for (const o in nextProps) {
        if (!prevProps.hasOwnProperty(o)) {
            equal = false;
            console.error({ o, prev: prevProps[o], next: nextProps[o] });
        }
    }
    return equal;
}


const GridBase = memo(({
    useLinkColumn = true,
    model,
    columns,
    api,
    defaultSort,
    setActiveRecord,
    parentFilters,
    parent,
    where,
    selectedClients = null,
    title,
    permissions = constants.defaultPermissions,
    selected,
    assigned,
    available,
    disableCellRedirect = false,
    onAssignChange,
    customStyle,
    onCellClick,
    showRowsSelected,
    chartFilters,
    clearChartFilter,
    showFullScreenLoader,
    customFilters,
    onRowDoubleClick,
    baseFilters,
    onRowClick = () => { },
    gridStyle,
    reRenderKey,
    additionalFilters,
    importUrl,
    exportUrl,
    exportNote,
    disable,
    openModal,
    setOpenModal,
    manageDataName,
    showExportOption,
    setFetchData,
    setIsRowClicked,
    onAssignChanged,
    childTabTitle,
    customHeaderComponent,
    renderField,
    gridPivotFilter
}) => {
    const [paginationModel, setPaginationModel] = useState({ pageSize: defaultPageSize, page: 0 });
    const [data, setData] = useState({ recordCount: 0, records: [], lookups: {} });
    const [isLoading, setIsLoading] = useState(false);
    const forAssignment = !!onAssignChange;
    const rowsSelected = showRowsSelected;
    const [selection, setSelection] = useState([]);
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    const [visibilityModel, setVisibilityModel] = useState({ CreatedOn: model?.showHiddenColumn || false, CreatedByUser: model?.showHiddenColumn || false, ...model?.columnVisibilityModel });
    const [isDeleting, setIsDeleting] = useState(false);
    const [record, setRecord] = useState(null);
    const snackbar = useSnackbar();
    const isClient = model.isClient === true ? 'client' : 'server';
    const [errorMessage, setErrorMessage] = useState('');
    const customExportRef = useRef();
    const userData = useSelector(state => state.appReducer.userData);
    const { ClientId } = userData && userData.tags ? userData.tags : 0;
    const { Username } = userData?.tags || {};
    const currentPreference = useSelector(state => state.appReducer.currentPreference);
    const [isGridPreferenceFetched, setIsGridPreferenceFetched] = useState(false);
    const [sortModel, setSortModel] = useState(convertDefaultSort(defaultSort || model?.defaultSort));
    const [parentGridFilter, setParentGridFilter] = useState({});
    const [childGridTitle, setChildGridTitle] = useState();
    const [showChildGrids, setShowChildGrids] = useState(false);
    const groupBy = useSelector(state => state.appReducer.dataGroupBy);

    const initialFilterModel = { ...constants.gridFilterModel }
    if (model.defaultFilters) {
        initialFilterModel.items = [];
        model.defaultFilters.forEach((ele) => {
            initialFilterModel.items.push(ele);
        })
    }
    const [filterModel, setFilterModel] = useState({ ...initialFilterModel });

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const apiRef = useGridApiRef();

    const { idProperty = "id", showHeaderFilters = true, disableRowSelectionOnClick = true, createdOnKeepLocal = false, hideBackButton = false, hideTopFilters = true, updatePageTitle = true, isElasticScreen = false, isPivotGrid = false, modelPermissions = permissions, showCreateButton, showCopy, addBackButton, hideExcelExport = false, hideXmlExport = false, hideHtmlExport = false, hideJsonExport = false } = model;
    const isReadOnly = model.readOnly === true;
    const isDoubleClicked = model.doubleClicked === false;
    const dataRef = useRef(data);
    const showAddIcon = model.showAddIcon === true;
    const toLink = model.columns.some(item => item.link === true);
    const onClick = model.columns.map(item => item.onClick);

    const classes = useStyles();

    useEffect(() => {
        dataRef.current = data;
    }, [data]);

    useEffect(() => {

        if (customFilters && Object.keys(customFilters) != 0) {
            if (customFilters.clear) {
                let filterObject = {
                    items: [],
                    logicOperator: "and",
                    quickFilterValues: [],
                    quickFilterLogicOperator: "and"
                }
                setFilterModel(filterObject)
                return
            } else {
                const newArray = utils.getCustomFilterItems(customFilters);
                let filterObject = {
                    items: newArray,
                    logicOperator: "and",
                    quickFilterValues: [],
                    quickFilterLogicOperator: "and"
                }
                setFilterModel(filterObject)
            }
        }
    }, [customFilters]);

    const lookupOptions = ({ row, field, id, ...others }) => {
        const lookupData = dataRef.current.lookups || {};
        return lookupData[lookupMap[field].lookup] || [];
    };

    useEffect(() => {
        if (hideTopFilters) {
            dispatch({
                type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                    filterButton: null,
                    hidden: { search: true, operation: true, export: true, print: true, filter: true }
                }
            });
        }
    }, []);

    const { gridColumns, pinnedColumns, lookupMap } = useMemo(() => {
        const baseColumnList = columns || model?.gridColumns || model?.columns;
        const pinnedColumns = { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field], right: [] };
        const finalColumns = [];
        const lookupMap = {};
        for (const column of baseColumnList) {
            const overrides = {};
            if (column.headerName === null) {
                continue;
            }
            if (parent && column.lookup === parent) {
                continue;
            }
            if (column.type === 'oneToMany') {
                if (column.countInList === false) {
                    continue;
                }
                overrides.type = 'number';
                overrides.field = column.field.replace(/s$/, 'Count');
            }
            if (column.type === 'decimal') {
                const newFilterOperator = [...getGridNumericOperators()];
                overrides.filterOperators = newFilterOperator;
            }

            if (gridColumnTypes[column.type]) {
                Object.assign(overrides, gridColumnTypes[column.type]);
            }
            if (overrides.valueOptions === "lookup") {
                overrides.valueOptions = lookupOptions;
                let lookupFilters = [...getGridDateOperators(), ...getGridStringOperators()].filter((operator) => ['is', 'not', 'isAnyOf'].includes(operator.value))
                overrides.filterOperators = lookupFilters.map((operator) => ({
                    ...operator,
                    InputComponent: operator.InputComponent ? (params) => (
                        <CustomDropdownmenu
                            column={{
                                ...column,
                                dataRef: dataRef
                            }}
                            {...params}
                            autoHighlight
                        />
                    ) : undefined
                }));
            }
            if (column.linkTo) {
                overrides.cellClassName = "mui-grid-linkColumn";
            }
            if (column.link) {
                overrides.cellClassName = "mui-grid-linkColumn";
            }
            finalColumns.push({ headerName: column.headerName || t(column.label, tOpts), ...column, ...overrides });
            if (column.pinned) {
                pinnedColumns[column.pinned === 'right' ? 'right' : 'left'].push(column.field);
            }
            lookupMap[column.field] = column;
            column.label = t(column?.label, tOpts)
        }

        const auditColumns = model.standard === true;

        if (auditColumns && model?.addCreatedModifiedColumns !== false) {
            if (model?.addCreatedOnColumn !== false) {
                finalColumns.push(
                    {
                        field: "CreatedOn", type: "dateTime", headerName: t("Created On", tOpts), width: 200, filterOperators: LocalizedDatePicker({ columnType: "date" }), valueFormatter: gridColumnTypes.dateTime.valueFormatter, keepUTC: createdOnKeepLocal
                    }
                );
            }
            if (model?.addCreatedByColumn !== false) {
                finalColumns.push(
                    { field: "CreatedByUser", type: "string", headerName: t("Created By", tOpts), width: 200 },
                );
            }
            if (model?.addModifiedOnColumn !== false) {
                finalColumns.push(
                    {
                        field: "ModifiedOn", type: "dateTime", headerName: t("Modified On", tOpts), width: 200, filterOperators: LocalizedDatePicker({ columnType: "date" }), valueFormatter: gridColumnTypes.dateTime.valueFormatter

                    }
                );
            }
            if (model?.addModifiedByColumn !== false) {
                finalColumns.push(
                    { field: "ModifiedByUser", type: "string", headerName: t("Modified By", tOpts), width: 200 }
                );
            }
        }

        if (!forAssignment && !isReadOnly) {
            const actions = [];
            if (modelPermissions.edit) {
                actions.push(<GridActionsCellItem icon={<Tooltip title="Edit">   <EditIcon /></Tooltip>} data-action={actionTypes.Edit} label="Edit" color="primary" />);
            }
            if (modelPermissions.add && !showCopy) {
                actions.push(<GridActionsCellItem icon={<Tooltip title="Copy"><CopyIcon /> </Tooltip>} data-action={actionTypes.Copy} label="Copy" color="primary" />);
            }
            if (modelPermissions.delete) {
                actions.push(<GridActionsCellItem icon={<Tooltip title="Delete"><DeleteIcon /> </Tooltip>} data-action={actionTypes.Delete} label="Delete" color="error" />);
            }
            if (actions.length > 0) {
                finalColumns.push({
                    field: t('actions', tOpts),
                    type: 'actions',
                    label: '',
                    width: actions.length * 50,
                    getActions: () => actions,
                });
            }
            pinnedColumns.right.push(t('actions', tOpts));
        }

        return { gridColumns: finalColumns, pinnedColumns, lookupMap };
    }, [columns, model, parent, permissions, forAssignment]);

    const fetchData = (action = "list", extraParams = {}, contentType, columns, isPivotExport, isElasticExport, isDetailsExport, fromSelfServe = false) => {
        const { pageSize, page } = paginationModel;
        let gridApi = api || model?.api;
        let controllerType = model?.controllerType;
        if (isPivotExport) {
            gridApi = model?.pivotAPI;
            controllerType = 'cs';
        }
        if (assigned || available) {
            extraParams[assigned ? "include" : "exclude"] = Array.isArray(selected) ? selected.join(',') : selected;
        }
        let filters = { ...filterModel }, finalFilters = { ...filterModel };
        if (chartFilters?.items?.length > 0) {
            let { columnField: field, operatorValue: operator } = chartFilters.items[0];
            field = constants.chartFilterFields[field];
            const chartFilter = [{ field: field, operator: operator, isChartFilter: false }];
            filters.items = [...chartFilter];
            if (JSON.stringify(filterModel) !== JSON.stringify(filters)) {
                setFilterModel({ ...filters });
                finalFilters = filters;
                chartFilters.items.length = 0;
            }
        }
        if (additionalFilters) {
            finalFilters.items = [...finalFilters.items, ...additionalFilters];
        }
        getList({
            action,
            page: !contentType ? page : 0,
            pageSize: !contentType ? pageSize : 1000000,
            sortModel,
            filterModel: finalFilters,
            controllerType: controllerType,
            api: gridApi,
            setIsLoading,
            setData,
            gridColumns,
            modelConfig: model,
            parentFilters,
            extraParams,
            setError: snackbar.showError,
            contentType,
            columns,
            template: isPivotExport ? model?.template : null,
            configFileName: isPivotExport ? model?.configFileName : null,
            dispatch,
            showFullScreenLoader,
            history,
            baseFilters,
            isElasticExport,
            fromSelfServe: fromSelfServe ? true : false,
            isDetailsExport: isDetailsExport,
            setFetchData,
            selectedClients,
            isChildGrid: model?.isChildGrid,
            groupBy: isPivotGrid ? [groupBy] : '',
            isPivotGrid,
            isPivotExport,
            gridPivotFilter
        });
    };

    const openForm = (id, { mode } = {}) => {
        if (setActiveRecord) {
            getRecord({ id, api: api || model?.api, setIsLoading, setActiveRecord, modelConfig: model, parentFilters, where });
            return;
        }
        let path = location.pathname
        if (!path.endsWith("/")) {
            path += "/";
        }
        if (mode === "copy") {
            path += "0-" + id;
        } else {
            path += id;
        }
        history.push({
            pathname: path,
            state: { mode: `${mode}` }
        });
    };

    const onCellClickHandler = async (cellParams, event, details) => {
        if (onCellClick && constants.OnCellCustomClickModels.includes(model?.gridSubTitle)) {
            onCellClick({ row: cellParams?.row });
            return;
        }
        if (!isReadOnly) {
            if (onCellClick) {
                const result = await onCellClick({ cellParams, event, details });
                if (typeof result !== "boolean") {
                    return;
                }
            }
            const { row: record } = cellParams;
            const columnConfig = lookupMap[cellParams.field] || {};
            if (columnConfig.linkTo) {
                history.push({
                    pathname: template.replaceTags(columnConfig.linkTo, record)
                });
                return;
            }
            let action = useLinkColumn && cellParams.field === model.linkColumn ? actionTypes.Edit : null;
            if (!action && cellParams.field === t('actions', tOpts)) {
                action = details?.action;
                if (!action) {
                    const el = event.target.closest('button');
                    if (el) {
                        action = el.dataset.action;
                    }
                }
            }
            if (action === actionTypes.Edit) {
                return openForm(record[idProperty]);
            }
            if (action === actionTypes.Copy) {
                return openForm(record[idProperty], { mode: 'copy' });
            }
            if (action === actionTypes.Delete) {
                setIsDeleting(true);
                setRecord({ name: record[model?.linkColumn], id: record[idProperty] });
            }
        }
        if (isReadOnly && toLink) {
            if (model?.isAcostaController && onCellClick && cellParams.colDef.customCellClick === true) {
                onCellClick(cellParams.row);
                return;
            }
            const { row: record } = cellParams;
            const columnConfig = lookupMap[cellParams.field] || {};
            let historyObject = {
                pathname: template.replaceTags(columnConfig.linkTo, record),
            }
            /* addRecordToState is only used on survey inbox: since on refresh the state is cleared, saving on local database */
            if (model.addRecordToState) {
                dbUtil.set(model.recordKey, record)
            }

            history.push(historyObject);
        }
        if (model?.childTabLinkingKey?.length) {
            const { row: record } = cellParams;
            const includeFilter = {}, applyFilterFields = [];
            let title = [];
            for (const column of model?.columns) {
                const keyName = model?.childParentKeyMapping[column.field];
                if (model.childTabLinkingKey.includes(keyName)) {
                    includeFilter[keyName] = record[keyName];
                    title.push(record[column.field]);
                    if (column.field === cellParams.field) {
                        break;
                    }
                }
            }

            title = title.join(' - ');
            setParentGridFilter(includeFilter);
            if (!showChildGrids) {
                setShowChildGrids(true);
            }
            setChildGridTitle(title);
        }
    };

    const handleDelete = async function () {
        const result = await deleteRecord({ id: record?.id, api: api || model?.api, setIsLoading, setError: snackbar.showError, setErrorMessage });
        if (result === true) {
            setIsDeleting(false);
            snackbar.showMessage(t('Record Deleted Successfully.', tOpts));
            fetchData();
        } else {
            setTimeout(() => {
                setIsDeleting(false);
            }, 200);
        }
    }

    const clearError = () => {
        setErrorMessage(null);
        setIsDeleting(false);
    };
    const onCellDoubleClick = (event) => {
        const { row: record } = event;
        if ((!isReadOnly && !isDoubleClicked) && !disableCellRedirect) {
            openForm(record[idProperty]);
        }

        if (isReadOnly && model.rowRedirectLink) {
            let historyObject = {
                pathname: template.replaceTags(model.rowRedirectLink, record),
            }

            if (model.addRecordToState) {
                dbUtil.set(model.recordKey, record)
            }
            history.push(historyObject);
        }

        if (onRowDoubleClick) {
            onRowDoubleClick(event);
        }
    };

    const onAdd = () => {
        openForm(0);
    };

    const clearFilters = () => {
        if (filterModel?.items?.length > 0) {
            const filters = JSON.parse(JSON.stringify(constants.gridFilterModel));
            setFilterModel(filters);
            if (clearChartFilter) {
                clearChartFilter();
            }
        }
    }

    const updateAssignment = ({ unassign, assign }) => {
        const assignedValues = Array.isArray(selected) ? selected : (selected.length ? selected.split(',') : []);
        const finalValues = unassign ? assignedValues.filter(id => !unassign.includes(parseInt(id))) : [...assignedValues, ...assign];
        onAssignChange(typeof selected === 'string' ? finalValues.join(',') : finalValues);
    }

    const onAssign = () => {
        updateAssignment({ assign: selection });
    }

    const onUnassign = () => {
        updateAssignment({ unassign: selection });
    }

    useEffect(() => {
        utils.removeCurrentPreferenceName({ dispatch, preferenceName: model.preferenceId });
        utils.fetchPreferencesAndApplyDefault({ preferenceName: model.preferenceId, history, dispatch, Username, gridRef: apiRef, setIsGridPreferenceFetched });
    }, [])

    const CustomToolbar = function (props) {

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                {model.hasCustomHeaderComponent && customHeaderComponent}
                {model.gridSubTitle && <Typography variant="h6" component="h3" textAlign="center" sx={{ ml: 1 }}> {t(model.gridSubTitle, tOpts)}</Typography>}
                {(currentPreference?.[model.preferenceId] && model.preferenceId) && <Typography className="preference-name-text" variant="h6" component="h6" textAlign="center" sx={{ ml: 1 }} >{t('Applied Preference', tOpts)} - {currentPreference?.[model.preferenceId]}</Typography>}
                {(isReadOnly || (!modelPermissions.add && !forAssignment)) && <Typography variant="h6" component="h3" textAlign="center" sx={{ ml: 1 }} > {isReadOnly ? "" : t(model.title, tOpts)}</Typography>}
                {!forAssignment && modelPermissions.add && !isReadOnly && !showAddIcon && !showCreateButton && <Button startIcon={!showAddIcon ? null : <AddIcon />} onClick={onAdd} size="medium" variant="contained" className={classes.buttons} >{model?.customAddTextTitle ? t(model.customAddTextTitle, tOpts) : ` ${!showAddIcon ? "" : `${t("Add", tOpts)}`} ${t(model.title, tOpts)}`}</Button>}
                {available && <Button startIcon={!showAddIcon ? null : <AddIcon />} onClick={onAssign} size="medium" variant="contained" className={classes.buttons}  >{t("Assign", tOpts)}</Button>}
                {assigned && !(model?.childTabs || model?.isChildGrid) && <Button startIcon={!showAddIcon ? null : <RemoveIcon />} onClick={onUnassign} size="medium" variant="contained" className={classes.buttons}  >{t("Remove", tOpts)}</Button>}

                <GridToolbarContainer {...props}>
                    <GridToolbarColumnsButton />
                    {modelPermissions.filter && <GridToolbarFilterButton />}
                    {modelPermissions.filter && <Button startIcon={<FilterListOffIcon />} onClick={clearFilters} size="small">{t(constants.clearFilterText, tOpts)}</Button>}
                    {modelPermissions.export && (
                        <CustomExportButton handleExport={handleExport} showPivotExportBtn={model?.showPivotExportBtn} showOnlyExcelExport={model.showOnlyExcelExport} showExportWithDetails={model?.showExportWithDetails} hideExcelExport={hideExcelExport} hideXmlExport={hideXmlExport} hideHtmlExport={hideHtmlExport} hideJsonExport={hideJsonExport} />
                    )}
                    {model.preferenceId &&
                        <GridPreferences preferenceName={model.preferenceId} gridRef={apiRef} columns={gridColumns} setIsGridPreferenceFetched={setIsGridPreferenceFetched} />
                    }
                </GridToolbarContainer>
            </div >
        );
    };

    const getGridRowId = (row) => {
        const idValue = row[idProperty];
        return idValue && idValue.toString().trim() !== "" ? idValue : uuidv4();
    };

    const handleExport = (e) => {
        if (data?.recordCount > recordCounts) {
            snackbar.showMessage(t('Cannot export more than 60k records, please apply filters or reduce your results using filters', tOpts));
            return;
        }
        else {
            const { orderedFields, columnVisibilityModel, lookup } = apiRef.current.state.columns;
            let columns = {};
            const isPivotExport = e.target.dataset.isPivotExport === 'true';
            const isDetailsExport = e.target.dataset.isDetailsExport === 'true';
            const hiddenColumns = Object.keys(columnVisibilityModel).filter(key => columnVisibilityModel[key] === false);
            const visibleColumns = orderedFields.filter(ele => !hiddenColumns?.includes(ele) && ele !== '__check__' && ele !== 'actions');
            if (visibleColumns?.length === 0) {
                snackbar.showMessage(t('You cannot export while all columns are hidden... please show at least 1 column before exporting', tOpts));
                return;
            }
            visibleColumns.forEach(ele => {
                columns[ele] = { field: ele, width: lookup[ele].width, headerName: lookup[ele].headerName, type: lookup[ele].type, keepUTC: lookup[ele].keepUTC === true, isParsable: lookup[ele]?.isParsable };
            })
            if (model?.customExportColumns && isDetailsExport) {
                columns = model?.customExportColumns;
            }
            fetchData(isPivotExport ? 'export' : undefined, undefined, e.target.dataset.contentType, columns, isPivotExport, isElasticScreen, isDetailsExport);
        }
    };

    useEffect(() => {
        if (isGridPreferenceFetched) {
            fetchData();
        }
    }, [paginationModel, sortModel, filterModel, api, gridColumns, model, parentFilters, assigned, selected, available, chartFilters, isGridPreferenceFetched, reRenderKey, selectedClients, gridPivotFilter])

    useEffect(() => {
        if (forAssignment || !updatePageTitle) {
            return;
        }
        if (model?.pageTitle || model?.title) {
            dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle icon="" titleHeading={model?.pageTitle || model?.title} titleDescription={model?.titleDescription} title={model?.title} /> });
        }
    }, [])

    useEffect(() => {
        let backRoute = location.pathname;

        // we do not need to show the back button for these routes
        if (hideBackButton || constants.routesWithNoChildRoute.includes(backRoute)) {
            dispatch({
                type: actions.SET_PAGE_BACK_BUTTON,
                pageBackButton: { status: false, backRoute: '' },
            });
            return;
        }
        backRoute = backRoute.split("/");
        backRoute.pop();
        backRoute = backRoute.join("/");
        dispatch({
            type: actions.SET_PAGE_BACK_BUTTON,
            pageBackButton: { status: true, backRoute: backRoute },
        });
        return () => {
            if (!addBackButton) {
                utils.removeBackButton(dispatch);
            }
        }
    }, [isLoading]);

    useEffect(() => {
        setFilterModel((prevFilterModel) => {
            const updatedItems = prevFilterModel.items.map((item) => {
                if (item.field === constants.groupByMappings.SerialNumber) {
                    return {
                        ...item,
                        filterField: renderField
                    };
                }
                return item;
            });

            return {
                ...prevFilterModel,
                items: updatedItems,
            };
        });

        setSortModel((prevSortModel) => {
            const updatedSortItems = prevSortModel.map((item) => {
                if (item.field === constants.groupByMappings.SerialNumber) {
                    return {
                        ...item,
                        filterField: renderField === constants.groupByMappings.PreSellerRouteId ? constants.PreSellerRouteId : renderField,
                    };
                }
                return item;
            });

            return updatedSortItems;
        });
    }, [renderField]);

    const updateFilters = (e) => {
        const { items } = e;
        const updatedItems = items.map(item => {
            const { field, operator, type, value } = item;
            const column = gridColumns.find(col => col.field === field);

            if (column?.useCustomFilterField) {
                item.filterField = renderField;
            } else {
                item.filterField = null
            }
            const isNumber = column?.type === constants.filterFieldDataTypes.Number || column?.type === constants.filterFieldDataTypes.Decimal;

            const isValidValue = (constants.emptyIsAnyOfOperatorFilters.includes(operator)) || (isNumber && !isNaN(value)) || (!isNumber);

            if (field === constants.OrderSuggestionHistoryFields.OrderStatus) {
                const { filterField, ...newItem } = item;
                return newItem;
            }

            if (isValidValue) {
                const isKeywordField = isElasticScreen && gridColumns.filter(element => element.field === item.field)[0]?.isKeywordField;
                if (isKeywordField) {
                    item.filterField = `${item.field}.keyword`;
                }
                if (column.useFilterField) {
                    item.filterField = column.filterField;
                }
                return item;
            }
            return { field, operator, type, value: isNumber ? null : value };
        });
        e.items = updatedItems;
        setFilterModel(e);
        handleChartFilterClearing(e, clearChartFilter);
    };

    const handleChartFilterClearing = (updatedFilters, clearChartFilter) => {
        const isClearChartFilter = !updatedFilters?.items?.some(ele => ele.isChartFilter && !(['isEmpty', 'isNotEmpty'].includes(ele.operator)));
        if (isClearChartFilter) {
            clearChartsFilters(clearChartFilter);
        } else if (chartFilters?.items?.length > 0) {
            if (updatedFilters.items.length === 0) {
                clearChartsFilters(clearChartFilter);
            } else {
                const chartFilterIndex = chartFilters?.items.findIndex(ele => ele.columnField === updatedFilters.items[0].field);
                if (chartFilterIndex > -1) {
                    clearChartsFilters(clearChartFilter);
                }
            }
        }
    };

    const clearChartsFilters = (clearChartFilter) => {
        if (clearChartFilter) {
            clearChartFilter();
        }
    }


    const updateSort = (e) => {
        const sort = e.map((ele) => {
            const column = gridColumns.find(col => col.field === ele.field);
            if (column?.useCustomFilterField) {
                return { ...ele, filterField: renderField === constants.groupByMappings.PreSellerRouteId ? constants.PreSellerRouteId : renderField }
            }
            const isKeywordField = isElasticScreen && gridColumns.filter(element => element.field === ele.field)[0]?.isKeywordField
            return { ...ele, filterField: isKeywordField ? `${ele.field}.keyword` : ele.field };
        })
        setSortModel(sort);
    }

    const isChildParentGrid = model?.childTabs?.length > 0;
    let parentChildGridClassName = isChildParentGrid ? 'parent-grid' : '';

    return (
        <>
            {childTabTitle ? <div className="child-tab-title">{childTabTitle}</div> : null}
            <div style={gridStyle || customStyle}>
                <div className={parentChildGridClassName}>
                    <DataGridPremium
                        sx={{
                            "& .MuiTablePagination-selectLabel": {
                                marginTop: 2
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginTop: 2
                            },
                            "& .MuiDataGrid-columnHeader .MuiInputLabel-shrink": {
                                display: "none"
                            }
                        }}
                        unstable_headerFilters={showHeaderFilters}
                        checkboxSelection={forAssignment}
                        loading={isLoading}
                        className="pagination-fix"
                        onCellClick={onCellClickHandler}
                        onCellDoubleClick={onCellDoubleClick}
                        columns={gridColumns}
                        paginationModel={paginationModel}
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        onPaginationModelChange={setPaginationModel}
                        pagination
                        rowCount={data.recordCount || data.totalRecords}
                        rows={data.records}
                        sortModel={sortModel}
                        paginationMode={isClient}
                        sortingMode={isClient}
                        filterMode={isClient}
                        keepNonExistentRowsSelected
                        onSortModelChange={updateSort}
                        onFilterModelChange={updateFilters}
                        rowSelection={selection}
                        onRowSelectionModelChange={setSelection}
                        filterModel={filterModel}
                        getRowId={getGridRowId}
                        onRowClick={onRowClick}
                        slots={{
                            headerFilterMenu: false,
                            toolbar: CustomToolbar,
                            footer: Footer
                        }}
                        slotProps={{
                            footer: {
                                pagination: true,
                                tOpts,
                                apiRef
                            },
                            panel: {
                                placement: "bottom-end"
                            },
                        }}
                        hideFooterSelectedRowCount={rowsSelected}
                        density="compact"
                        disableDensitySelector={true}
                        apiRef={apiRef}
                        disableAggregation={true}
                        disableRowGrouping={true}
                        disableRowSelectionOnClick={disableRowSelectionOnClick}
                        autoHeight
                        initialState={{
                            columns: {
                                columnVisibilityModel: visibilityModel
                            },
                            pinnedColumns: pinnedColumns
                        }}
                    />
                </div>
                {model?.childTabs ?
                    <div>
                        <ChildGridComponent tabs={model?.childTabs} selected={parentGridFilter} hideChildGrids={() => setShowChildGrids(false)} childGridTitle={childGridTitle} showChildGrids={showChildGrids} />
                    </div>
                    : null}
                {errorMessage && (<DialogComponent open={!!errorMessage} onConfirm={clearError} onCancel={clearError} title="Info" hideCancelButton={true} > {errorMessage}</DialogComponent>)
                }
                {isDeleting && !errorMessage && (<DialogComponent open={isDeleting} onConfirm={handleDelete} onCancel={() => setIsDeleting(false)} title="Confirm Delete"> {`${t('Are you sure you want to delete', tOpts)} ${record?.name}?`}</DialogComponent>)}
                {openModal &&
                    <DialogModal open={openModal} onClose={() => { setOpenModal(false) }} dialogTitle={t(`Manage Data - ${manageDataName}`, tOpts)} footerTitle={t(`You can edit, add data to the file and import it back to see the updates`, tOpts)} maxWidth="md" dividers={true} hideFooter={true} >
                        <ManageData importUrl={importUrl} exportUrl={exportUrl} action={manageDataName} exportNote={exportNote} disable={disable} fetchData={fetchData} apiRef={apiRef} data={data} showExportOption={showExportOption} />
                    </DialogModal>}
            </div >
        </>
    );
}, areEqual);

export default GridBase;