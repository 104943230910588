'use strict'
const ReactHighcharts = require('highcharts');

module.exports = {
    line: {
        exporting: {
            enabled: true, filename: 'Line Charts', buttons: {
                contextButton: {
                    menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
                }
            }
        },
        chart: { plotShadow: false, type: 'line' },
        legend: { align: 'center', borderColor: 'darkgray', borderWidth: 1, verticalAlign: 'top', y: 30 },
        credits: { enabled: false },
        xAxis: { categories: [] },
        yAxis: [{
            min: 0,
            labels: {
                enabled: true,
                rotation: -90,
                textAlign: 'center',
                verticalAlign: 'top',
                x: 4
            },
            title: {
                text: '',
                style: {
                    color: "#1999FF"
                }
            }
        },
        {
            title: {
                text: '',
                style: {
                    color: "#434d5c"
                }
            },
            opposite: true
        }],
        title: { text: 'Line chart' },
        tooltip: {
            pointFormat: 'Series "{series.name}" Value {point.y}'
        },
        colorAxis: {
            minColor: '#6275e7', 
            maxColor: '#172EB5' 
        },
        series: [{
            data: [],
            type: '',
            name: '',
            dataLabels: {
                enabled: true,
                color: '#000000',
                align: 'center',
                format: '{point.y}', 
                y: -10 
            }
        }]
    },
    pie: {
        exporting: {
            enabled: true, filename: 'Pie Charts', buttons: {
                contextButton: {
                    menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
                }
            }
        },
        chart: { plotShadow: false, type: 'pie', marginTop: 30 },
        credits: { enabled: false },
        colorAxis: {
            minColor: '#6275e7', 
            maxColor: '#172EB5' 
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                dataLabels: {
                    style: {
                        color: (ReactHighcharts.theme && ReactHighcharts.theme.contrastTextColor) || '#000000'
                    },
                    enabled: true,
                    formatter: function () {
                        return Math.round(this.percentage * 100) / 100 + ' %';
                    }
                },
                showInLegend: true,
                cursor: 'pointer'
            }
        },
        title: { text: 'Pie chart' },
        tooltip: { pointFormat: '<b>{series.name}:</b> {point.y} ({point.percentage:.2f}%)' },
        series: [{
            name: '',
            colorByPoint: true,
            data: [],
            dataLabels: {
                enabled: true,
                color: '#000000',
                align: 'center',
                format: '{point.y}', 
                y: -10 
            }
        }]
    },
    column: {
        exporting: {
            enabled: true, filename: 'Column Charts', buttons: {
                contextButton: {
                    menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
                }
            }
        },
        chart: { plotShadow: false, type: 'column' },
        legend: { align: 'center', borderColor: 'darkgray', borderWidth: 1 },
        credits: { enabled: false },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        title: { text: 'column chart' },
        tooltip: { pointFormat: '<b>{series.name}:</b> <b>{point.y}</b> ' },
        colorAxis: {
            minColor: '#6275e7', 
            maxColor: '#172EB5' 
        },
        xAxis: {
            categories: []
        },
        yAxis: [{
            min: 0,
            title: {
                text: '',
                style: {
                    color: "#1999FF"
                }
            }
        },
        {
            title: {
                text: '',
                style: {
                    color: "#434d5c"
                }
            },
            opposite: true
        }],
        series: [{
            data: [],
            type: '',
            name: '',
            colorByPoint: true,
            dataLabels: {
                enabled: true,
                color: '#000000',
                align: 'center',
                format: '{point.y}', 
                y: -10 
            }
        }]
    },
    combine: {
        exporting: {
            enabled: true, filename: 'Combine Charts', buttons: {
                contextButton: {
                    menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
                }
            }
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: true,
            borderColor: 'darkgray',
            borderWidth: 1,
            verticalAlign: 'top',
            y: 30
        },
        title: { style: { 'font-weight': 'bold', 'font-family': 'Segoe UI Light', 'font-size': 16 }, text: '' },
        tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: '"{point.name}" : {point.y}',
            shared: false
        },
        plotOptions: {
            bubble: {
                minSize: '5',
                maxSize: '8',
                tooltip: {
                    pointFormat: '"{point.name}" : {point.y}',
                    shared: false
                }
            },
            series: {
                stickyTracking: false,
                trackByArea: false
            }
        },
        colorAxis: {
            minColor: '#6275e7', 
            maxColor: '#172EB5' 
        },
        xAxis: {
            categories: [],
            labels: {
                align: 'right',
                rotation: -45,
                useHTML: true
            }
        },
        yAxis: [{
            min: 0,
            labels: {
                enabled: true,
                rotation: -90,
                textAlign: 'center',
                verticalAlign: 'top'
            },
            title: {
                text: '',
                style: {
                    color: "#1999FF"
                }
            }
        },
        {
            title: {
                text: '',
                style: {
                    color: "#434d5c"
                }
            },
            opposite: true
        }],
        series: [{
            data: [],
            colorByPoint: true,
            type: '',
            name: ''
        }]
    },
    bar: {
        exporting: {
            enabled: true, filename: 'Bar Charts', buttons: {
                contextButton: {
                    menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
                }
            }
        },
        chart: {
            type: 'bar'
        },
        title: {
            text: '',
            'font-size': 16
        },
        xAxis: {
            categories: [],
            title: {
                text: null
            }
        },
        yAxis: [{
            min: 0,
            labels: {
                enabled: true,
                rotation: -90,
                textAlign: 'center',
                verticalAlign: 'top',
                x: 4
            },
            title: {
                text: '',
                style: {
                    color: "#1999FF"
                }
            }
        },
        {
            title: {
                text: '',
                style: {
                    color: "#434d5c"
                }
            },
            opposite: true
        }],
        tooltip: {
            valueSuffix: ' '
        },
        colorAxis: {
            minColor: '#6275e7', 
            maxColor: '#172EB5' 
        },
        plotOptions: {
            bar: {
                colorByPoint: true,
                dataLabels: {
                    enabled: true
                }
            }
        },
        legend: {
            layout: 'horizontal',
            align: 'center',
            borderWidth: 1,
            borderColor: 'darkgray'
        },
        credits: {
            enabled: false
        },
        series: [{
            data: [],
            type: '',
            name: ''
        }]
    }
};
