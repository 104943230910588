import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PageTitle } from '../../components';
import ReportsList from './ReportsList';
import utils from '../../utils';
import actions from '../../redux/actions';
const t = utils.t;

const Reports = () => {

    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch({
        type: actions.SET_PAGE_TITLE_DETAILS,
        pageTitleDetails: <PageTitle
          titleHeading={t("Installations", tOpts)}
          titleDescription={t("Keep track of your installations", tOpts)}
          icon=""
          title={t("Installations", tOpts)}
          classTitle="home-header-container" />
      });
      dispatch({
        type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
          filterButton: "",
          filterButtonName: "",
          printButtonName: "",
          clear: null,
          apply: null,
          hidden: { search: true, operation: true, export: true, print: true, filter: true }
        }
      });
  
      return () => {
        dispatch({
          type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
        })
      }
    }, [])
  


  return (
    <div>
        <ReportsList/>
    </div>
  )
}

export default Reports