import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import { Checkbox, Box, FormControlLabel, Tooltip, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import CustomizedLabel from '../Common/CustomizedLabel';
import { useMedia } from 'the-platform';
import utils from '../../utils';
import { withTranslation } from 'react-i18next';
const t = utils.t;

const checkboxStyle = {
    color: '#172EB5',
    '&$checked': { color: '#172EB5' },
}

const BlueCheckbox = withStyles({
    root: checkboxStyle,
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ShowPlanogramInStore = memo(function ShowPlanogramInStore({ index, label, coolerProductPerc, t: translate, i18n, isOutletCard }) {

    const formatLongText = (text, maxLen, textColor) => {
        const newText = text?.length > maxLen ? `${text.substring(0, maxLen)}...` : text;
        return <Tooltip title={text?.length > maxLen ? text : ""} placement="bottom">
            <span> {newText}</span>
        </Tooltip>;
    }

    const dispatch = useDispatch();
    const smallDevice = useMedia('(max-width: 1366px)');
    const loginData = useSelector(state => state.appReducer.userData) || {};
    const { UnileverUK } = loginData && loginData.modules ? loginData.modules : {};
    const { IsSuperAdmin } = loginData && loginData.tags ? loginData.tags : {};
    const isAdminOrSuperAdmin = utils.isAdminORSuperAdmin(IsSuperAdmin);
    const skinkHeader = UnileverUK.Module && smallDevice;
    const tOpts = { t: translate, i18n };

    const [isPlanogram, setIsPlanogram] = useState(true);
    const [isInStore, setIsInStore] = useState(true);

    useEffect(() => {
        dispatch({ type: 'SET_PLANOGRAM_CHECKBOX', isPlanogram: { key: index, value: isPlanogram } });
        dispatch({ type: 'SET_INSTORE_CHECKBOX', isInStore: { key: index, value: isInStore } });
    }, [dispatch, index, isPlanogram, isInStore]);

    const handleCheckboxChangePlanogram = useCallback((e) => {
        const isChecked = e.target.checked;
        setIsPlanogram(isChecked);
        dispatch({ type: 'SET_PLANOGRAM_CHECKBOX', isPlanogram: { key: index, value: isChecked } });
    }, [dispatch, index]);

    const handleCheckboxChangeInStore = useCallback((e) => {
        const isChecked = e.target.checked;
        setIsInStore(isChecked);
        dispatch({ type: 'SET_INSTORE_CHECKBOX', isInStore: { key: index, value: isChecked } });
    }, [dispatch, index]);

    return (
        <Box style={{ padding: skinkHeader ? '0.5rem' : '1.25rem' }} className={`planogra-custom-box ${skinkHeader ? "" : "px-3"} d-flex align-items-center justify-content-between ${utils.isMobile() ? "hide-component" : ""}`}>
            <div className="d-flex" style={{ height: '2rem' }}>
                <h5 style={{ marginBottom: 0 }} className={`${skinkHeader ? "" : "pr-4"} card-header-text`}>{t("Comparison", tOpts)}</h5>
                <div style={{ height: 0, marginTop: '-2px',display:'contents' }}>
                    <FormControlLabel
                        control={<BlueCheckbox
                            name="Planned"
                            className={skinkHeader ? "m-0 p-0" : "p-0 pr-1"}
                            color="primary"
                            checked={isPlanogram}
                            onChange={handleCheckboxChangePlanogram}                            
                        />}
                        label={formatLongText(t('Planogram', tOpts), 12)}
                        className="Plan"
                    />
                    <FormControlLabel
                        control={<BlueCheckbox
                            name="in store"
                            className={skinkHeader ? "m-0 p-0" : "p-0 pr-1"}
                            color="primary"
                            checked={isInStore}
                            onChange={handleCheckboxChangeInStore}
                        />}    
                        label={formatLongText(t('Recognized', tOpts), 20)}
                    />
                </div>
            </div>
            {(UnileverUK.Module && !isAdminOrSuperAdmin) ?
                <CustomizedLabel label={`${Math.round(coolerProductPerc)}% ${t("Share of Shelves", tOpts)}`} /> :
                isOutletCard ? '' : <CustomizedLabel label={`${label}%POG`} className="customized-label" />}
        </Box>
    );
});

export default withTranslation()(ShowPlanogramInStore);
