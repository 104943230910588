import React, { useEffect } from 'react';
import PageTitle from '../../components/PageTitle'
import CardRouter from '../../components/GenericCard';
import { useDispatch } from 'react-redux';
import actions from '../../redux/actions';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import enums from '../../utils/constants'
import utils from "../../utils"
import useMobile from '../../utils/useMobile';
const { childRoutes } = enums;
const { t } = utils;

const Operations = () => {

  const dispatch = useDispatch();
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const childPages = childRoutes.Survey;
  const isMobile = useMobile();

  useEffect(() => {
    dispatch({
      type: actions.SET_PAGE_TITLE_DETAILS,
      pageTitleDetails: <PageTitle titleHeading={t("StoreBuddy", tOpts)} titleDescription={"Create & configure all key elements to run surveys"} icon="" title={t("StoreBuddy", tOpts)} classTitle="home-header-container" />
    });
    dispatch({
      type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
        filterButton: "",
        filterButtonName: "",
        printButtonName: "",
        clear: null,
        apply: null,
        hidden: { search: true, operation: true, export: true, print: true, filter: true }
      }
    });

    return () => {
      dispatch({
        type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
      })
    }
  }, [])

  return (
    <Grid className={`mb-1 ${!isMobile.tablet ? (isMobile.portrait ? "mt-2" : "") : (isMobile.tablet && isMobile.portrait ? "mt-1" : "mt-n2")}`} container spacing={1}>

      {Object.keys(childPages).map((childPage, index) => <React.Fragment key={index}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography className={(isMobile.mobile && !isMobile.tablet && isMobile.portrait) ? "ml-4" : ''} variant="h6">{t(childPage, tOpts)}</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CardRouter childObj={childPages[childPage]} />
        </Grid></React.Fragment>)}

    </Grid>
  )
}

export default Operations