import React, { Fragment, useMemo, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Badge, Menu, Button, List, ListItem, Divider, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import swal from 'sweetalert';
import { apis } from "../../httpUtil/";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../redux/actions';
import ClientSelect from "../../pages/Login/ClientSelect"
import HeaderSearch from '../HeaderSearch';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//icons
import Person from '@material-ui/icons/Person';
import ChangeLanguage from '../ChangeLanguage';
import utils from '../../utils';
import { useTranslation } from 'react-i18next';
const t = utils.t;


const StyledBadge = React.memo(withStyles({
    badge: {
        backgroundColor: 'var(--success)',
        color: 'var(--success)',
        boxShadow: '0 0 0 2px #fff',
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""'
        }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0
        }
    }
})(Badge));

export default function HeaderUserBox() {
    const userData = useSelector(state => state.appReducer.userData);
    const { FirstName = "", LastName = "", ClientName = '' } = userData && userData.tags ? userData.tags : "";
    const clientLabel = ClientName !== '' ? `(${ClientName})` : '';
    const [anchorEl, setAnchorEl] = React.useState(null);
    let history = useHistory();
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    const dispatch = useDispatch();
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        const transport = axios.create({ withCredentials: true })
        swal({
            title: t("Are you sure you want to log out?", tOpts), icon: "info", buttons: true, dangerMode: true, buttons: [t("Cancel", tOpts), t("OK", tOpts)]
        }).then((isConfirm) => {
            if (isConfirm) {
                let formData = new FormData();
                formData.append("signout", true);
                let params = {
                    method: 'POST', url: apis.login, withCredentials: true, data: formData
                };
                transport(params).then((response) => {
                    if (response && response.status === 200) {
                        dispatch({ type: actions.RESET_STATE });
                        history.push('/login');
                        // unsubscribing the subscription if it's subscribed and then re subscribing after login.
                        navigator.serviceWorker.ready.then((reg) => {
                            reg.pushManager.getSubscription().then((subscription) => {
                                subscription && subscription.unsubscribe();
                            });
                        });
                    }
                });
            }
        });
    }

    const memorizedStyledBadge = useMemo(
        () => <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
            <Avatar sizes="44"><Person fontSize='large' /> </Avatar>
        </StyledBadge>, [])

    return (
        <Fragment>
            <Hidden smDown>
                <Box className="group-name">{FirstName} {LastName}</Box>
            </Hidden>
            <Button color="inherit" onClick={handleClick} className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
                <Box>
                    {memorizedStyledBadge}
                </Box>
                <span className="pl-1 pl-xl-3">
                    <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
                </span>
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                open={Boolean(anchorEl)}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                onClose={handleClose}
                className="ml-2">
                <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                    <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
                        <Box>
                            {memorizedStyledBadge}
                        </Box>
                        <div className="pl-3 ">
                            <div className="font-weight-bold text-center pt-2 line-height-1">{FirstName} {LastName}</div>
                            <span className="text-black-50 text-center">{t("CoolR Group User", tOpts)}</span>
                        </div>
                        <Divider className="w-100 mt-2" />
                        <Divider className="w-100" />
                        <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 lang-util-drop-down"><ClientSelect fromAppBar={true} history={history} /></ListItem>
                        <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 lang-util-drop-down"><ChangeLanguage fromAppBar={true} /></ListItem>
                        <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
                            <Button color="default" onClick={handleLogout}>
                                <span> <ExitToAppIcon /></span>
                                <span className="btn-wrapper--icon"> {t("Log Out", tOpts)} </span>
                            </Button>
                        </ListItem>
                    </List>
                </div>
            </Menu>
        </Fragment>
    );
}