import dayjs from 'dayjs';
import UiModel from './ui-model';

const filterDate = dayjs().subtract(30, 'day').format("YYYY-MM-DD");

const salesHistoryModel = new UiModel({
    title: "Sales History",
    defaultSort: 'ScheduledOrderId DESC',
    titleDescription: 'Check the sales order history ',
    idProperty: 'ScheduledOrderStatusHistoryId',
    readOnly: true,
    addCreatedModifiedColumns: false,
    module: 'SalesHistory',
    defaultFilters: [{ field: 'ModifiedOn', operator: 'onOrAfter', value: filterDate }],
    showPivotExportBtn: true,
    pivotAPI: 'SalesHistory.ashx',
    template: 'sales-history-pivot',
    configFileName: 'sales-history',
    preferenceId: 'playbookSalesHistory',
    columns: [
        { field: "ScheduledOrderId", type: 'number', width: 150, label: "Order Id", pinned: true },
        { field: "Channel", type: 'string', width: 150, label: "Channel", hide: true },
        { field: "Classification", type: 'string', width: 150, label: "Classification", hide: true },
        { field: "OutletName", type: 'string', width: 150, label: "Outlet Name" },
        { field: "OutletCode", type: 'string', width: 150, label: "Outlet Code" },
        { field: "City", type: 'string', width: 150, label: "City", hide: true },
        { field: "Postal", type: 'string', width: 150, label: "Postal Code", hide: true },
        { field: "TotalOrder", type: 'number', width: 150, label: "Value" },
        { field: "TotalCase", type: 'number', width: 100, label: "Cases", hide: true },
        { field: "ModifiedOn", type: 'date', width: 150, label: "Order Date", keepUTC: true },
        { field: "Username", type: 'string', width: 200, label: "User" }
    ]
});

export default salesHistoryModel;