import React, { useEffect, useState } from 'react';
import {
    Card, CardContent, Tabs, Tab, Tooltip
} from "@material-ui/core";
import PageTitle from '../../components/PageTitle'
import { InfoRounded } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router";
import actions from '../../redux/actions';
import ProductReports from '../../components/UnileverComponents/productReports'
import { useTranslation } from 'react-i18next';
import utils from '../../utils';
import { apis, request } from "../../httpUtil";
import useMobile from '../../utils/useMobile';
const t = utils.t;

const configObj = {
    "1": {
        "report": "PDC",
        "reportKey": "productAvailability",
        "reportName": "Product Distribution Compliance",
        "dataTypes": ['PWA'],
        "components": ['dataGrid', 'infoBar'],
        "route": apis.ProductAvailabilityDash,
        'filterKeys': {
            classification: "Location.ClassificationId",
            market: "Location.MarketId",
            channel: "Location.LocationTypeId",
            distributor: "Location.DistributorId",
            productCategory: "Product.ProductCategoryId"
        }
    },
    "0": {
        "report": "PDD",
        "reportKey": "productDistribution",
        "reportName": "Product Distribution",
        "dataTypes": ['PDT'],
        "components": ['dataGrid', 'infoBar'],
        "route": apis.ProductDistribution,
        'filterKeys': {
            classification: "Location.ClassificationId",
            market: "Location.MarketId",
            channel: "Location.LocationTypeId",
            distributor: "Location.DistributorId",
            productCategory: "Product.ProductCategoryId"
        }
    }
}

function ProductReport(props) {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const [config, setConfig] = useState({});
    const [mappedFilterValue, setMappedFilterValue] = useState({});
    const { t: translate, i18n } = useTranslation();
    const isMobile = useMobile();
    const history = useHistory();
    const tOpts = { t: translate, i18n };

    useEffect(() => {
        dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle titleHeading={t("Product Report", tOpts)} titleDescription="" icon={null} title={t("Product Report", tOpts)} /> });

        const newConfig = configObj[activeTab + ''];
        newConfig.selectedReport = activeTab + '';
        setConfig(newConfig)

        request({ url: apis.KPIFilter, params: { useProductCategory: true }, history, dispatch, jsonPayload: true })
            .then((result) => {
                if (!result.error) {
                    setMappedFilterValue(result);
                }
            })

        return () => {
            dispatch({
                type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
            })

            utils.removeBackButton(dispatch);
        }
    }, [])

    const handleChangeTab = (event, newValue) => {
        if (newValue === activeTab) {
            return;
        }
        const newConfig = configObj[newValue + ''];
        newConfig.selectedReport = newValue + '';
        setConfig(newConfig)
        setActiveTab(newValue)
    }

    return (
        <>
            <Card className={`mb-1 p-0 ${!isMobile.tablet ? (isMobile.portrait ? "mt-4" : "mt-2") : (isMobile.tablet && isMobile.portrait ? "merchandising-subheader-gap" : "mt-0")}`} >
                <CardContent className="m-0 p-0">
                    <Tabs value={activeTab} onChange={handleChangeTab} centered variant="fullWidth">
                        <Tab icon={<img src={require("../../assets/images/productDistribution.png")} height='30' alt="" />} iconPosition="start"
                            label={<span>{t("Product Distribution", tOpts)} <Tooltip title={t('What are the overall distribution levels of my own products?', tOpts)} placement="right">
                                <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                            </Tooltip></span>} />
                    </Tabs>
                </CardContent>
            </Card>

            {/* Tabs body */}
            <div>
                {Object.keys(config).length > 1 ?
                    <ProductReports key={config.selectedReport} config={config} mappedFilterValue={mappedFilterValue} /> :
                    <>{t('No Data, Kindly select a Tab', tOpts)}</>}
            </div>
        </>
    )
}

export default ProductReport;