import { makeStyles } from "@material-ui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    toolTip: {
        whiteSpace: 'pre-line'
    }
}));

export const InfoText = ({info}) => {
    const classes = useStyles();
    return (
        <Typography className={classes.toolTip}>
            {info}
        </Typography>
    )
}