import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, FormControl, MenuItem, Hidden } from '@material-ui/core';
import { List, AutoSizer, InfiniteLoader, CellMeasurerCache } from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once
import { request, apis } from "../../httpUtil";
import actions from '../../redux/actions';
import { useDispatch, connect } from 'react-redux';
import ScheduleTime from '../ScheduleTime/ScheduleTime'
import { useDebounce } from 'use-debounce';
import { useMedia } from 'the-platform';
import { useTranslation } from "react-i18next"

import VistaCard from './VistaCard';
import VistaSearch from './VistaSearch';
import NeedsAttention from './NeedsAttention'
import utils from "../../utils"

const t = utils.t

let history, dispatch, loading = false, initialLimit = { startIndex: 0, stopIndex: 10 };

// let startIndexUpdate, stoptIndexUpdate;
const cache = new CellMeasurerCache({ defaultHeight: 550, fixedWidth: true, fixedHeight: false });

const VistLocationListCard = React.memo(function VistLocationListCard({ vistaSearch, vistaData, userData, vistaNeedsAttention }) {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n }; 
    const smallDevice = useMedia('(max-width: 450px)');
    const mediumDevice = useMedia({ minWidth: 401, maxWidth: 960 });

    const listRef = useRef(null);
    dispatch = useDispatch();
    history = useHistory();

    // State   
    const [sortValue, setSortValue] = useState("PurityDateTime DESC");
    const [fetchIndex, setFetchIndex] = useState(0);

    let { ClientId } = userData && userData.tags ? userData.tags : "";
    const [searchDebounce] = useDebounce(vistaSearch, 800)

    function isRowLoaded({ index }) {
        return !!vistaData.records[index];
    }

    const getFilter = useCallback(
        (params) => {
            let locationAssetFilter = {}, locationOrPuritySortFilter = {};
            if (vistaSearch) {
                locationAssetFilter = { assetOrLocationName: vistaSearch, isForRank: true };
                Object.assign(params, locationAssetFilter);
            }
            if (sortValue) {
                let sortArray = sortValue.split(' ');
                locationOrPuritySortFilter = { sort: sortArray[0], dir: sortArray[1] };
                Object.assign(params, locationOrPuritySortFilter);
            }
            if (vistaNeedsAttention) {
                Object.assign(params, { ImageStatus: 2, showAllImages: false });
            }
            return params;
        },
        [vistaSearch, sortValue, vistaNeedsAttention],
    )

    const resetAll = useCallback(() => {
        cache.clearAll();
        listRef && listRef.current && listRef.current.recomputeRowHeights();
    }, [listRef])

    //fetch data
    const getData = useCallback(
        async ({ startIndex = 0, stopIndex }) => {
            if (!loading) {
                loading = true;

                if (startIndex !== 0 && fetchIndex === startIndex) { return }
                setFetchIndex(startIndex)
                let params = {
                    action: "list", asArray: 0, limit: 10, start: startIndex, showAllImages: false, isFetchCoolerData: true
                };
                params = getFilter(params);
                if (Number(ClientId) > 0) {
                    Object.assign(params, { clientId: Number(ClientId) });
                }
                const response = await request({ url: apis.assetLatestImages, params, history, dispatch });
                if (response && response.records) {
                    let isNeedsAttention = false;
                    let firstFilter = false;
                    if ((vistaSearch || sortValue || vistaNeedsAttention) && !startIndex) {
                        firstFilter = true;
                        dispatch({ type: actions.RESET_VISTA_DATA, vistaData: { records: [], recordCount: 0, TotalCount: 0, NeedAttentionCount: 0 } })

                    }
                    if (vistaNeedsAttention) {
                        isNeedsAttention = true;
                    }
                    dispatch({ type: actions.SET_VISTA_DATA, vistaData: { records: response.records, recordCount: response.recordCount, TotalCount: response.TotalCount, NeedAttentionCount: response.NeedAttentionCount, startIndex, firstFilter, isNeedsAttention } })
                }
                resetAll()
                loading = false;
            }
        },
        [vistaSearch, sortValue, vistaNeedsAttention, ClientId, getFilter, fetchIndex, resetAll],
    )

    // resize virtual window
    const _onResize = () => cache.clearAll();

    //reset All cache
    // vistaData && resetAll();
    // vistaData && setTimeout(() => resetAll(), 3000);

    useEffect(() => {
        if (searchDebounce || searchDebounce === '' || sortValue || vistaNeedsAttention) {
            getData(initialLimit);
        }
    }, [searchDebounce, sortValue, vistaNeedsAttention]);

    // Set Short State
    const setSortValueFun = useCallback((event) => setSortValue(event.target.value), [getData]);


    let recordCount = vistaData && vistaData.recordCount ? vistaData.recordCount : 1
    let recordCountLst = vistaData && vistaData.records.length ? vistaData.records.length : 1;

    const memoizedHidden = useMemo(() => <Hidden smDown>
        <div className="vista-search-wrapper">
            <VistaSearch />
            <div className="vista-search-select">
                <FormControl variant="outlined">
                    <Select className="search-select" value={sortValue} onChange={setSortValueFun} autoWidth={true}>
                        <MenuItem key={"LocationASC"} value={"LocationName ASC"}> {t("Sort by A to Z", tOpts)}</MenuItem>
                        <MenuItem key={"LocationDESC"} value={"LocationName DESC"}> {t("Sort by Z to A", tOpts)}</MenuItem>
                        <MenuItem key={"ImageASC"} value={"PurityDateTime ASC"}> {t("Image Taken ASC", tOpts)}</MenuItem>
                        <MenuItem key={"ImageDESC"} value={"PurityDateTime DESC"}>{t("Image Taken DESC", tOpts)}</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <NeedsAttention />
        </div>
    </Hidden>, [sortValue])

    return (<div className="virtual-list-wrapper">
        <ScheduleTime />
        {memoizedHidden}
        <InfiniteLoader
            loadMoreRows={getData}
            isRowLoaded={isRowLoaded}
            rowCount={recordCount}>
            {({ onRowsRendered, registerChild }) => {
                return <AutoSizer onResize={_onResize}>
                    {({ height, width }) => {
                        return (
                            <div ref={registerChild}>
                                <List
                                    ref={(listref) => {
                                        registerChild(listref);
                                        listRef.current = listref;
                                    }}
                                    onRowsRendered={onRowsRendered}
                                    deferredMeasurementCache={cache}
                                    height={height - (smallDevice ? 0 : mediumDevice ? 24 : 62)}
                                    rowCount={recordCountLst}
                                    rowHeight={smallDevice ? 538 : cache.rowHeight}
                                    rowRenderer={({ key, index, style, parent }) => <VistaCard key={index} keyCard={key} index={index} style={style} parent={parent} cache={cache} />}
                                    width={width}
                                    overscanRowCount={0}
                                />
                            </div>
                        )
                    }}
                </AutoSizer>
            }}
        </InfiniteLoader>
    </div>
    );
});

const mapStateToProps = state => ({
    vistaSearch: state.appReducer.vistaSearch,
    vistaData: state.appReducer.vistaData,
    userData: state.appReducer.userData,
    vistaNeedsAttention: state.appReducer.vistaNeedsAttention
});

export default connect(mapStateToProps)(VistLocationListCard);
