import UiModel from './ui-model'
import { RenderColorDotCell, RenderProgressBar } from '../components/Common/CustomRenderCell';
import { apis } from '../httpUtil';

const operationalModel = new UiModel({
    title: 'Operational Dashboard',
    titleDescription: 'Visualize how users and outlets are being surveyed',
    defaultSort: 'SurveyName ASC',
    module: "Survey",
    idProperty: 'SurveyMasterId',
    addCreatedModifiedColumns: false,
    readOnly: true,
    includeColumns: true,
    customApi: apis.SurveyOperationsList,
    columns: [
        { field: "SurveyMasterId", type: 'number', width: 80, hide: true, label: "Id", fieldLabel: null },
        { field: "SurveyName", type: "string", label: "Survey Name", width: 200 },
        { field: "Color", type: "string", label: "Color", renderCell: params => { return <RenderColorDotCell {...params} /> }, width: 50, filterable: false },
        { field: "SurveyStatus", type: "boolean", label: "Status", width: 70, defaultValue: true },
        { field: "DaysLeft", type: 'number', width: 90, label: "Days Left" },
        { field: "Submissions", type: 'number', width: 100, label: "Submissions" },
        { field: "TotalOutlets", type: 'number', width: 100, label: "Total Outlets" },
        { field: "CoveredOutlets", type: 'number', width: 130, label: "Covered Outlets" },
        { field: "AverageSubmissionPerOutlet", type: 'number', width: 150, label: "Average Submissions Per Outlet" },
        { field: "RemainingOutlets", type: 'number',  width: 100, label: "Remaining Outlets" },
        { field: "Coverage", type: 'number',  width: 90, label: "Coverage %", renderCell: params => { return <RenderProgressBar progress={params.value} /> }}
    ]
});

export default operationalModel;