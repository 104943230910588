import UiModel from './ui-model';

const monthlyAssetsStatusModel = new UiModel({
    title: "Invoicing",
    defaultSort: 'Date DESC',
    titleDescription: 'Check your monthly invoices',
    idProperty: 'id',
    readOnly: true,
    gridSubTitle: 'Fleet',
    addModifiedOnColumn: false,
    addModifiedByColumn: false,
    module: 'Invoicing',
    api: 'MonthlyAssetsStatus',
    showHiddenColumn: true,
    disableRowSelectionOnClick: false,
    addCreatedModifiedColumns: false,
    updatePageTitle: false,
    hideBackButton: true,
    isElasticScreen: true,
    preferenceId: 'playbookMonthlyAssetsStatus',
    columns: [
        {
            field: "AssetSerial", type: 'string', width: 200, label: "Parent Asset", renderCell: (param) => {
                const { value } = param;
                return (
                    <div className="custom-button">
                        {value || ""}
                    </div>
                );
            }
        },
        { field: "SerialNumber", width: 200, label: "Camera Serial Number", sortable: false, filterable: false },
        { field: "SubscriptionModelId", width: 370, label: "Subscription Model", type: 'radio', lookup: 'SubscriptionModelType' },
        { field: "InvoicingDays", type: 'number', width: 300, label: "Invoice Days" },
        { field: "NonInvoicingDays", type: 'number', width: 400, label: "Non Invoice Days" }
    ]
});

export default monthlyAssetsStatusModel;