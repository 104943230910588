import React from 'react';
import { Typography, Grid, Card, List, ListItem } from '@mui/material';
import SuffixTextField from './SuffixTextField';
import { useTranslation } from 'react-i18next';
import utils from '../../utils';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        width: '70%',
        padding: theme.spacing(2),
    },
    formListContainer: {
        textAlign: 'center'
    },
    boldTypography: {
        fontWeight: 'bold !important',
    },
    centerAlign: {
        textAlign: 'center',
    },
    suffixTextField: {
        '& .MuiInputBase-input': {
            textAlign: 'center',
        },
    }
}));

const FormGenerator = ({ kpiValues, setFieldValue, errors, touched }) => {
    const t = utils.t;
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    const classes = useStyles();

    const handleFieldChange = (index, field, event) => {
        setFieldValue(`kpiValues[${index}].${field}`, event);
    };
    return (
        <Card className={classes.cardContainer}>
            <List className={`pt-0 pb-0 text-break ${classes.formListContainer}`} dense={true} disablePadding={true}>
                <ListItem className={`form-list-container ${classes.formListContainer}`} dense={true} disablePadding={true}>
                    <Grid container spacing={2}>
                        <Grid item xs={5}><Typography className={classes.boldTypography}>{t("KPIs", tOpts)}</Typography></Grid>
                        <Grid item xs={4}><Typography className={classes.boldTypography}>{t("Is", tOpts)}</Typography></Grid>
                        <Grid item xs={1.5}><Typography className={`${classes.boldTypography} ${classes.centerAlign}`}>{t("Value", tOpts)}</Typography></Grid>
                        <Grid item xs={1.5}><Typography className={`${classes.boldTypography} ${classes.centerAlign}`}>{t("Weight", tOpts)}</Typography></Grid>
                    </Grid>
                </ListItem>
                {kpiValues?.map((kpi, index) => (
                    <ListItem className={`form-list-container ${classes.formListContainer}`} dense={true} disablePadding={true} key={index}>
                        <Grid container spacing={2}>
                            <Grid item xs={5}><Typography>{kpi.kpi}</Typography></Grid>
                            <Grid item xs={4}><Typography>{kpi.is}</Typography></Grid>
                            <Grid item xs={1.5}>
                                <SuffixTextField
                                    value={kpi.value}
                                    onChange={(e) => handleFieldChange(index, 'value', e)}
                                    variant="standard"
                                    suffix={kpi.suffix}
                                    inputProps={{ maxLength: 3 }}
                                    className={classes.suffixTextField}
                                    error={touched && touched[index]?.value && Boolean(errors && errors[index]?.value)}
                                    helperText={touched && touched[index]?.value && errors && errors[index]?.value}
                                />
                            </Grid>
                            <Grid item xs={1.5}>
                                <SuffixTextField
                                    value={kpi.weight}
                                    onChange={(e) => handleFieldChange(index, 'weight', e)}
                                    variant="standard"
                                    suffix="%"
                                    inputProps={{ maxLength: 3 }}
                                    className={classes.suffixTextField}
                                    error={touched && touched[index]?.weight && Boolean(errors && errors[index]?.weight)}
                                    helperText={touched && touched[index]?.weight && errors && errors[index]?.weight}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
        </Card >
    );
};

export default FormGenerator;
