import React, { useEffect } from 'react';
import PageTitle from '../../components/PageTitle'
import { useDispatch } from 'react-redux';
import actions from '../../redux/actions';
import MasterData from '../../components/UnileverComponents/masterData'

export default function MasterDataDashboard() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: actions.SET_PAGE_BACK_BUTTON, pageBackButton: { status: false, backRoute: '' } });
        dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle titleHeading="Master Data" titleDescription="" icon='' title="Master Data" /> });

        return () => {
            dispatch({
                type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
            })
        }
    }, [])

    return (
        <>
            <MasterData />
        </>
    )
}