import { Card, CardContent, CardHeader, Grid, makeStyles, Paper } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { apis, request } from "../../httpUtil";
import actions from "../../redux/actions";
import utils from "../../utils";
import GridBase from "../GridBase";
import CloseIcon from '@material-ui/icons/Close';
import dayjs from "dayjs";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import RenderSurveyAnswers from "../../pages/RenderSurveyAnswer";
import dbUtil from "../../pages/RenderSurveyAnswer/dbUtil";
import { defaultTableConfigMenuOptions, DefaultTableMenu, SummaryTable } from "../UnileverComponents/merchandising";
import DialogModal from "../Common/DialogModel";
import FilterPanel from "../filterPanel";
import { LABELMAPPINGS, LABELPOSITION, PERIODDATEMAPPINGS } from "../../constants";
import date from "../../utils/date";
import constants from '../../utils/constants'
import useMobile from "../../utils/useMobile";
import PageTitle from "../PageTitle";

const { groupByOptions, periods } = constants;
const dateFilterMaxDate = new Date(new Date().setDate(new Date().getDate() - 1));
const labelMappings = LABELMAPPINGS;
const labelPositions = LABELPOSITION
const periodDateMappings = PERIODDATEMAPPINGS
const defaultScreen = "main";
const labelRegex = /\${[a-zA-z]+}/gi;
const t = utils.t;
const valueGetter = {
    "number": (params) => {
        return params.value || 0
    },
    "SurveyDateTime": (params) => {
        return dayjs(params.value).format(utils.systemDateTimeFormat())
    },
    "string": (params) => {
        return params.value || "N/A"
    }
}
const menuOptions = defaultTableConfigMenuOptions.filter((value) => value.useInSurvey);
const metrics = [
    {
        checked: true,
        dataKey: "MarketId",
        label: "Market",
        metricsValue: "Count",
        ranges: []
    },
    {
        checked: true,
        dataKey: "ClassificationId",
        label: "Classification",
        metricsValue: "Count",
        ranges: []
    },
    {
        checked: true,
        dataKey: "LocationTypeId",
        label: "Channels",
        metricsValue: "Count",
        ranges: []
    },
    {
        checked: true,
        dataKey: "LocationId",
        label: "Location",
        metricsValue: "Count",
        ranges: []
    }
]
const config = (t,tOpts) => ({
    "main": {
        gridConfig: [],
        label: "Survey Report (Summary)",
        defaultGrigConfig: {
            minWidth: 100
        },
        action: null,
        defaultMetrics: [
            {
                checked: true,
                dataKey: "vwSurveyList.ClientId",
                label: "Client",
                metricsValue: "Count",
                ranges: []
            }
        ],
        countBy: "vwSurveyList.ClientId",
        data: [],
        keysToClear: ["location", "surveyList", "surveyAnswerList", "summary"],
        useCloseIcon: false,
        configKey: "summary",
        useTarget: true,
        useCard: true,
        getConfig: (params) => {
            getMainConfig({ ...params, tableConfig: getDefaultTableConfig() })
        },
        useTable: true

    },
    "summary": {
        gridConfig: [],
        label: "Survey Report Group By ${label}",
        defaultGrigConfig: {
            minWidth: 100
        },
        action: "summaryDrillDown",
        data: [],
        keysToClear: ["summary", "location", "surveyList", "surveyAnswerList"],
        useCloseIcon: false,
        configKey: "location",
        metric: "Location",
        filter: []
    },
    "location": {
        gridConfig: [],
        label: `${t('Survey Report Group By Location For',tOpts)}` + " ${label}",
        defaultGrigConfig: {
            minWidth: 100
        },
        action: "locationDrillDown",
        data: [],
        keysToClear: ["location", "surveyList", "surveyAnswerList"],
        useCloseIcon: false,
        filter: [],
        key: 'LocationId',
        customFunc: setLocation,
        nameKey: 'Name',
        countBy: "LocationId",
        configKey: "surveyList",

    },
    "surveyList": {
        gridConfig: [],
        label: `${t('Survey List For',tOpts)}` + " ${label}",
        defaultGrigConfig: {
            minWidth: 100
        },
        action: "surveyListDrillDown",
        defaultMetrics: [],
        countBy: "",
        data: [],
        customFunc: customFunc,
        configKey: "surveyAnswerList",
        keysToClear: ["surveyList", "surveyAnswerList"],
        useCloseIcon: false,
        filter: []
    },
    "surveyAnswerList": {
        gridConfig: [],
        label: `${t('Survey Answers For',tOpts)}` + " ${label}",
        defaultGrigConfig: {
            minWidth: 100
        },
        action: "",
        useModal: true,
        defaultMetrics: [],
        countBy: "",
        data: [],
        keysToClear: [],
        useCloseIcon: false,
        filter: [],
        Component: RenderSurveyAnswers
    }
})
const defaultActivePeriods = ['WTD', 'MTD'];
const excludeKeys = ["ClientId", "LocationId", "SurveyId", "SurveyTypeId", "MarketId", "LocationTypeId", "ClassificationId"]
const clientDates = {
    startOfWeek: dayjs().startOf('week'),
    startOfMonth: dayjs().startOf('month'),
    startOfQuarter: dayjs().startOf('quarter'),
    startOfYear: dayjs().startOf('year'),
    todayDate: dayjs(new Date().setHours(0, 0, 0, 0))
}

const getDefaultTableConfig = () => {
    for (let menu of menuOptions) {
        let items = [];
        for (let item of menu.items) {
            if (!item.useInSurvey) {
                continue;
            }
            items.push(item)
        }
        menu.items = items
    }
    return {
        headersData: [
            {
                colSpan: 0,
                data: [
                    {
                        colspan: 0,
                        label: ''
                    }
                ]
            },
            {
                colSpan: 0,
                data: [
                    {
                        colspan: 0,
                        label: ''
                    }
                ]
            }
        ],
        contentData: [
            {
                colSpan: 0,
                key: "Market",
                groupBy: "MarketId",
                data: [
                    {
                        colspan: 0,
                        key: "ClientName",
                        externalStyle: 'text-left',
                        customLabel: ({ kpiName, kpiDisplayName, ...rest }) => {
                            return <DefaultTableMenu  {...rest} kpiName={kpiName} kpiDisplayName={kpiDisplayName} label={kpiName} menuOptions={menuOptions} />
                        },
                        displayName: 'Client',
                    }
                ]
            },
        ]
    }
}
const getMainConfig = ({ tableConfig, config, data = {}, setState, key, activePeriods = [], dispatch, onDrillDown, fromDate, toDate }) => {
    const defaultConfig = tableConfig
    const { contentData, headersData: headerData } = defaultConfig;
    for (let col of activePeriods) {
        headerData[0].data?.push({ colspan: 0, label: labelMappings[col] || col, hasBorders: true })
        headerData[1].data?.push({ colspan: 0, label: 'Total Surveys', hasBorders: true })
    }
    if (Object.keys(data).includes("Custom")) {
        const from = dayjs(fromDate).format(utils.systemDateTimeFormat(true));
        const to = dayjs(toDate).format(utils.systemDateTimeFormat(true));
        const label = `${from} to ${to}`;
        headerData[0].data?.push({ colspan: 0, label, hasBorders: true })
        headerData[1].data?.push({ colspan: 0, label: 'Total Surveys', hasBorders: true })
    }
    for (let content of contentData) {
        const items = content.data;
        for (const period of activePeriods) {
            items.push({ colspan: 0, key: period, label: data[period] || 0, kpiName: content.key })
        }
        if (Object.keys(data).includes("Custom")) {
            items.push({ colspan: 0, key: "Custom", label: data["Custom"] || 0, kpiName: content.key })
        }
        for (let item of items) {
            item.label = item.customLabel ? <span class="d-flex">{item.customLabel({ kpiName: content.key, kpiDisplayName: item.label || data[item.key], onDrillMenu: (event) => onDrillDown({ event, dispatch, activePeriods, useTarget: true, configKey: config?.configKey }), componentConfig: { drillDown: true } })}</span> : item.label
        }
        config.gridConfig = defaultConfig;
        setState(prev => ({ ...prev, [key]: config }))
    }
}

const groupByMappings = {
    "ClassificationId": 'ClassificationName',
    "MarketId": "MarketName",
    "LocationTypeId": "Name"
}

const defaultDaysFilter = ["0:currentYear", "0:currentQuarter", "0:currentMonth"]

export default function SurveyDashboardComponent() {
    const dispatch = useDispatch();
    const filterValues = useSelector(state => state.appReducer.filterValues) || {};
    const [listOfComponents, setListOfComponents] = useState({})
    const history = useHistory();
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const [openModal, setOpenModal] = useState(null);
    const userData = useSelector(state => state.appReducer.userData);
    const comboData = useSelector(state => state.appReducer.comboData);
    const componentList = Object.keys(listOfComponents);
    const isMobile = useMobile()
    const location = useLocation();

    const filtersConfig = [
        {
            name: "Filters",
            visible: true,
            config: [
                {
                    name: "marketIds",
                    label: "Market",
                    type: "select",
                    multiSelect: true,
                    checkUncheck: true,
                    options: comboData.Market,
                    size: 'small',
                    hidden: false
                },
                {
                    name: "classificationIds",
                    label: "Classification",
                    type: "select",
                    multiSelect: true,
                    checkUncheck: true,
                    options: comboData.LocationClassification,
                    size: 'small',
                    hidden: false
                },
                {
                    name: "channelIds",
                    label: "Channel",
                    type: "select",
                    multiSelect: true,
                    checkUncheck: true,
                    options: comboData.LocationType,
                    size: 'small',
                    hidden: false
                },

                {
                    name: "date",
                    type: "date",
                    size: 'small',
                    maxDate: dateFilterMaxDate,
                    hidden: false
                }
            ],
            openDefault: true
        },
        {
            name: "Data Periods",
            config: [
                {
                    name: "activePeriods",
                    label: "Periods",
                    type: "select",
                    multiSelect: true,
                    checkUncheck: true,
                    options: periods,
                    size: 'small'
                }
            ],
            visible: true,
            openDefault: false
        },
        {
            name: "Group By",
            config: [
                {
                    name: "groupBy",
                    label: "Group By",
                    type: "checkBox",
                    multiSelect: false,
                    options: groupByOptions,
                    size: 'small',
                }
            ],
            visible: true,
            openDefault: false
        }
    ]

    useEffect(() => {
        defaultFilter()
    }, [])

    useEffect(() => {
        return () => {
            dispatch({
                type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                    filterButton: null,
                    filterButtonName: "",
                    clear: null,
                    apply: null,
                    hidden: { search: true, operation: true, export: true, print: true, filter: true }
                }
            });
        }
    }, [])

    const defaultFilter = function () {
        dispatch({
            type: actions.SET_FILTER_VALUES, filterValues: {
                activePeriods: defaultActivePeriods,
                daysFilter: "0:currentMonth",
                fromDate: new Date(new Date().getFullYear(), new Date(dateFilterMaxDate).getMonth(), 1),
                toDate: dateFilterMaxDate,
                groupBy: 'MarketId',
                drillDown: true
            }
        });
    }

    const getDateRange = ({ fromDate, isCustom }) => {
        const { activePeriods = defaultActivePeriods } = filterValues;
        const length = activePeriods.length - 1;
        let period = clientDates[periodDateMappings[activePeriods[length]]];
        if (isCustom) {
            const fromD = dayjs(fromDate);
            if (!period.isBefore(fromD)) {
                period = fromD;
            }
        }
        const SurveyStartTime = period;
        const SurveyEndTime = clientDates["todayDate"]
        return { SurveyStartTime, SurveyEndTime }
    }
    useEffect(() => {
        if (Object.keys(comboData).length <= 0) {
            utils.getComboData(undefined, userData);
        }
        const filteredConfig = filtersConfig.filter((value) => value.visible);
        dispatch({
            type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                filterButton: <FilterPanel filtersConfig={filteredConfig} getData={null} daysDropDown={utils.daysDropDown} dateFilterMaxDate={dateFilterMaxDate} customDate={true} />,
                filterButtonName: t("Options", tOpts),
                clear: defaultFilter,
                apply: getData,
                hidden: { search: true, operation: true, export: true, print: true, filter: false }
            }
        });
    }, [comboData, filterValues])



    useEffect(() => {
        if (filterValues.drillDown) {
            getData({});
        }
    }, [filterValues])

    const clearState = () => {
        if (!Object.keys(filterValues).length) return;
        dispatch({
            type: actions.SET_FILTER_VALUES, filterValues: {
                ...filterValues,
                drillDown: false,
                configKey: null,
                label: null,
                where: {}

            }
        });
    }
    const getData = useCallback(async function () {
        const { activePeriods = defaultActivePeriods, where = {}, configKey = defaultScreen, label, props = {}, groupBy, metrics, marketIds, classificationIds, channelIds, daysFilter, fromDate, toDate, ...rest } = filterValues;
        const conf = config(t,tOpts);
        let action = ''
        const mainConfig = conf[configKey || defaultScreen];
        const { keysToClear, useInternalUI, getConfig, useModal } = mainConfig;
        mainConfig.label = mainConfig.label.replaceAll(/\${[a-zA-z]+}/gi, label || "N/A");
        mainConfig.props = props;
        if (!mainConfig.key) {
            mainConfig.key = groupBy
            mainConfig.nameKey = groupByMappings[groupBy]
        }
        if (useModal) {
            setOpenModal({ open: true, ...mainConfig, props });
            clearState();
            return;
        }
        for (let key of keysToClear) {
            delete listOfComponents[key]
            setListOfComponents(prev => ({ ...prev, ...listOfComponents }))
        }
        if (useInternalUI) {
            delete listOfComponents[configKey]
            setListOfComponents(prev => ({ ...prev, ...listOfComponents }))
            setListOfComponents(prev => ({ ...prev, [configKey]: mainConfig }))
            clearState();
            return;
        }
        const filters = utils.getFilters({
            kpiFilters: { marketIds, classificationIds, channelIds },
            groupBy: [],
            metrics: mainConfig.defaultMetrics || metrics,
            countBy: mainConfig.countBy || groupBy
        });
        let datePeriod = [];
        let custom = {};
        if (!defaultDaysFilter.includes(daysFilter)) {
            datePeriod.push("Custom");
            custom = {
                fromDate,
                toDate
            }
        }
        const params = {
            ...filters,
            where: {
                ...filters?.where,
                ...where,
                ...getDateRange({ fromDate, isCustom: datePeriod.length })
            },
            ...custom,
            action: action || mainConfig.action,
            activePeriods: [...activePeriods, ...datePeriod],
            clientDates
        }
        const result = await request({ url: apis.SurveyDashboard, params, history, dispatch, jsonPayload: true });
        if (result?.record) {
            const sample = result?.record[0], config = [], lastColumns = {};
            if (getConfig) {
                clearState();
                return getConfig({ setState: setListOfComponents, key: defaultScreen, data: sample, config: mainConfig, activePeriods, dispatch, onDrillDown, fromDate, toDate })
            }
            for (let key in sample) {
                if (excludeKeys.includes(key)) {
                    continue;
                }
                if (key.includes("Custom")) {
                    continue;
                }
                if (labelPositions.includes(key)) {
                    lastColumns[key] = key;
                    continue;
                }
                config.push({
                    id: key,
                    label: labelMappings[key] || key,
                    valueGetter: valueGetter[key] || valueGetter["string"],
                    ...mainConfig.defaultGridConfig
                })
            }
            for (let item of activePeriods) {
                if (lastColumns[item]) {
                    config.push(
                        {
                            id: item,
                            label: labelMappings[item] || item,
                            valueGetter: valueGetter["number"],
                            ...mainConfig.defaultGridConfig
                        }
                    )
                }
            }
            if (datePeriod.length && Object.keys(sample).includes("Custom")) {
                const from = dayjs(fromDate).format("YYYY-MM-DD");
                const to = dayjs(toDate).format("YYYY-MM-DD");
                const label = `${from} to ${to}`;
                config.push(
                    {
                        id: "Custom",
                        label,
                        valueGetter: valueGetter["number"],
                        ...mainConfig.defaultGridConfig
                    }
                )
            }
            mainConfig.gridConfig = config;
            mainConfig.data = result.record;
            mainConfig.label = mainConfig.label.replaceAll(labelRegex, label);
            setListOfComponents(prev => ({ ...prev, [configKey]: mainConfig }));
            clearState();

        }
    }, [filterValues])

    async function onDrillDown({ event, useTarget = false, fromMenu = false, metric = '', configKey = '', key, nameKey, customFunc }) {
        let groupBy = 'MarketId', label = '', nextConfig = '', localMetrics = [], where = {}, extra = {};
        if (useTarget) {
            groupBy = event?.target?.filters?.groupBy || filterValues.groupBy;
            localMetrics = metrics.filter((value) => value.dataKey === groupBy);
            label = localMetrics[0].label;
        } else {
            const value = event.row[key];
            label = event.row[nameKey]
            where = {
                [key]: {
                    value: value || 0
                }
            }
            localMetrics = metrics.filter((value) => value.label === metric);
        }
        if (customFunc && typeof customFunc === 'function') {
            extra = await customFunc({ event,t,tOpts }) || {}
        }

        dispatch({
            type: actions.SET_FILTER_VALUES, filterValues: {
                ...filterValues,
                groupBy,
                metrics: localMetrics,
                where,
                label,
                configKey,
                ...extra,
                drillDown: true
            }
        });
    }
    useEffect(() => {
        dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle icon="" titleHeading={"Survey Dashboard Overview"} titleDescription={"Survey Dashboard Overview"} title={"Survey Dashboard Overview"} /> });
        return () => {
            dispatch({
                type: actions.SET_PAGE_TITLE_DETAILS,
                pageTitleDetails: null,
            });
        };
    }, [])
    useEffect(() => {
        let backRoute = location.pathname;
        backRoute = backRoute.split("/");
        backRoute.pop();
        backRoute = backRoute.join("/");
        dispatch({
            type: actions.SET_PAGE_BACK_BUTTON,
            pageBackButton: { status: true, backRoute: backRoute }
        });
        return () => {
            utils.removeBackButton(dispatch);
        }
    }, []);
    return <div className={`mb-1 p-0  ${isMobile.portrait ? "mt-4" : "mt-2"}`} >
        {componentList.map((value, key) => {
            const { gridConfig, data, label, closeIcon = null, onCellDoubleClick, useCloseIcon, useCard = false, useInternalUI, Component, props, useTable, customFunc, useTarget = false, metric, configKey, key: keys, nameKey } = listOfComponents[value];
            const onCellFunc = onCellDoubleClick || onDrillDown;
            const Container = useCard ? CardComponent : GridComponent;
            return <Container key={key} label={label} closeIcon={closeIcon} useCloseIcon={useCloseIcon} indexName={value} >
                {useInternalUI ?
                    <Component {...props} /> :
                    useTable ?
                        <SummaryTable tableConfig={gridConfig} borderOnIndex={1} onDrillDown={(event) => { onCellFunc({ event, useTarget, customFunc, metric, configKey, key: keys, nameKey }) }} /> :
                        <GridBase columns={gridConfig} data={data} onCellDoubleClick={(event) => { onCellFunc({ event, useTarget, customFunc, metric, configKey, key: keys, nameKey }) }} movePagination={false} otherOptions={{ page: 0 }} />
                }
            </Container>
        })
        }
        {
            openModal && // For Prevent Rerendering
            <DialogModal open={openModal.open} onClose={() => setOpenModal(null)} dialogTitle={openModal.label} maxWidth="xl" hideCancel={true}>
                <openModal.Component  {...openModal.props} />
            </DialogModal>
        }
    </div>

}



async function customFunc({ event,t,tOpts }) {
    const LocationId = event.row.LocationId;
    const SurveyId = event.row.SurveyId;
    const LocationInfo = await dbUtil.get("Location") || {};
    const SurveyDateTime = dayjs(event.row.SurveyDateTime).format(utils.systemDateTimeFormat());
    const name = `(${t("Survey Type",tOpts)} - ${event.row.SurveyType}, ${t("Survey Date Time",tOpts)} - ${SurveyDateTime}, ${t("User",tOpts)} - ${event.row.ModifiedByUser})`
    await dbUtil.set("surveyData", {
        SurveyId: event.row.SurveyId,
        SurveyTypeId: event.row.SurveyTypeId,
        SurveyType: event.row.SurveyType,
        ...LocationInfo
    });

    return {
        props: { LocationId, fromDashboard: true, SurveyId },
        label: name,
        SurveyId
    }
}

async function setLocation({ event }) {
    await dbUtil.set("Location", {
        LocationName: event.row?.Name || ''
    })
}


const useStyles = makeStyles({
    root: {
        width: '100%'
    }
});
function CardComponent({ label, children }) {
    const classes = useStyles()
    const trans = useTranslation();

    const tOpts = { t: trans.t, i18n: trans.i18n };
    const t = utils.t
    return <Card className={clsx(classes.root, 'kpi-cards')}>
        <CardHeader
            title={t(`${label}`, tOpts)}
            subheader={`${t('Showing data up to', tOpts)} ${dayjs().subtract(1, 'days').format(utils.systemDateTimeFormat(true))}`} />
        <CardContent>
            <div>
                {children}
            </div>
        </CardContent>
    </Card>
}

function GridComponent({ label, closeIcon, useCloseIcon, children, indexName }) {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    return <Paper elevation={2} sx={{ maxWidth: 500 }} className="kpi-cards pl-3 pr-3 pb-3 mt-2">
        <Grid container className="mt-3 p-3">
            <Grid className="p-1" xs={10} sm={10} md={10} lg={10}>
                <span className="survey-label  ">{t(label, tOpts)}</span>
            </Grid>
            {useCloseIcon ? <Grid item xs={2} sm={2} md={2} lg={2} className="text-align-right mt-2">
                <CloseIcon onClick={() => closeIcon(indexName)} className=" no-print icon-search cursor_pointer" />
            </Grid> : null
            }
        </Grid>
        {children}
    </Paper>
}