import { Card, CardContent, CardHeader, Divider, FormControlLabel, FormGroup, Grid, Switch, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import React from "react";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import constants from "../../utils/constants";


export const Questions = (props) => {
  const { question, index, handleMenuOpen, moreActions, classes, t, tOpts, answerDetail, toggleStates, handleToggleChange, questionTypeArray, textTotalCount, textDistinctCount, questionWithDynamicOptions, minDate, maxDate, handleViewChange, analyticsQuestionTypes, tabValue, showImages = {} } = props;
  const isChecked = Object.keys(showImages).some(key => key.includes(question?.id) && showImages[key] === 'images');
  return (
    <div >
      <CardHeader
        title={
          <Grid className={classes.mainHeader}>
            <Typography className={`${classes.surveyDetailsItems} question-text`} >{`${index + 1}. ${question?.text}`}</Typography>
            <div className={classes.SectionIconMain}>
              <Tooltip title="Responses">
                <div className={classes.dtnSectionIconSecondary}>
                  <SpeakerNotesIcon />
                  <Typography style={{ marginLeft: '5px' }}>{t(question?.totalCount || 0, tOpts)}</Typography>
                </div>
              </Tooltip>
              <MoreVertIcon sx={{ cursor: 'pointer' }} onClick={(e) => handleMenuOpen(e, question)} />
              {moreActions}
            </div>
          </Grid>
        }
      />
      < Divider orientation='horizontal' sx={{ mb: 1 }
      } />
      < Grid container spacing={2} className={classes.mainHeader}>
        <Grid item xs>
          {question.type === 'text' || question.type === 'textarea' ? (
            <div className={classes.questionsContent}>
              <Typography>{t(`Total Comments: ${textTotalCount || 0}`, tOpts)}</Typography>
              <Typography>{t(`Distinct Comments: ${textDistinctCount || 0}`, tOpts)}</Typography>
            </div>
          ) : null}
          {question.type === 'date' ? (
            <div className={classes.questionsContent}>
              <Typography >{t(`Date Range`, tOpts)}</Typography>
              <Typography>{t(`${minDate || null} - ${maxDate || null}`, tOpts)}</Typography>
            </div>
          ) : null
          }
        </Grid>
        <Grid item xs>
          {(question.validatedFromAsset ||
            questionTypeArray?.includes(question?.type) ||
            questionWithDynamicOptions) && (
              <div className={classes.toggleSection}>
                {analyticsQuestionTypes?.includes(question?.type) &&
                  [1, 2, 3].includes(question?.beforeAfterImage) && (
                    <div className="toggle-btn" >
                      <Typography>
                        {t("Analytics", tOpts)}
                      </Typography>

                      <Switch
                        onChange={(event, newValue) =>
                          handleViewChange(event, newValue, question.id)
                        }
                        checked={isChecked}
                        defaultChecked={question?.checkTargetPortfolio === undefined ? false : !question?.checkTargetPortfolio}
                      />
                      <Typography>
                        {t("Images", tOpts)}
                      </Typography>
                    </div>
                  )}
                <ToggleButtonGroup
                  size="small"
                  value={toggleStates[question.id] || "top10"}
                  exclusive
                  onChange={(event, newValue) =>
                    handleToggleChange(event, newValue, question.id)
                  }
                  aria-label="toggle-button-group"
                >
                  <ToggleButton value="all" aria-label="all values">
                    {t("All values", tOpts)}
                  </ToggleButton>
                  <ToggleButton value="top10" aria-label="top 10 values">
                    {t("Top 10", tOpts)}
                  </ToggleButton>
                  <ToggleButton value="bottom10" aria-label="bottom 10 values">
                    {t("Bottom 10", tOpts)}
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            )}
          {question.type === constants.QUESTION_TYPES.MULTI_FILE_STITCH
            && tabValue === '1'
            && (
              <div className={classes.toggleSection}>
                <FormControlLabel
                  control={<Switch className="hide-switch" />}
                  labelPlacement="start"
                  label={
                    <Typography className="outlet-switch-label">
                      {t("Analytics", tOpts)}
                    </Typography>
                  }
                />
                <Switch
                  className="space-outlet-switch"
                  defaultChecked={question?.validateFromPosMaterial === undefined ? false : !question?.validateFromPosMaterial}
                  onChange={(event, newValue) =>
                    handleViewChange(event, newValue, question.id)
                  }
                  checked={isChecked}
                />
                <FormControlLabel
                  control={<Switch className="hide-switch" />}
                  labelPlacement="end"
                  label={
                    <Typography className="outlet-switch-label">
                      {t("Images", tOpts)}
                    </Typography>
                  }
                />
                <ToggleButtonGroup
                  size="small"
                  value={toggleStates[question.id] || "top10"}
                  exclusive
                  onChange={(event, newValue) =>
                    handleToggleChange(event, newValue, question.id)
                  }
                  aria-label="toggle-button-group"
                >
                  <ToggleButton value="all" aria-label="all values">
                    {t("All values", tOpts)}
                  </ToggleButton>
                  <ToggleButton value="top10" aria-label="top 10 values">
                    {t("Top 10", tOpts)}
                  </ToggleButton>
                  <ToggleButton value="bottom10" aria-label="bottom 10 values">
                    {t("Bottom 10", tOpts)}
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            )}
        </Grid>
      </Grid >
      <CardContent>
        {answerDetail}
      </CardContent>
    </div >
  )
}