import { Card, CardContent, Paper, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { GoogleMap, Marker, MarkerClusterer, useLoadScript } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import utils from "../../../utils";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import DialogModal from "../../Common/DialogModel";
import * as JsError from "../../../JsError";

const clusterMarker = require('../../../assets/images/map/cluster-marker.png');
const outletMarker = require('../../../assets/images/map/outlet-marker.png');
const mapStyles = { height: "100vh", width: "100% !important" };
const clusterStyle = [{ url: clusterMarker, width: 50, height: 50, textSize: 14, textColor: "white", className: 'map-cluster-text' }];

const useStyles = makeStyles((theme) => ({
  customCardContent: {
    width: '100% !important',
    marginTop: '20px !important'
  },
  tableRowStyle: {
    cursor: 'pointer',
    height: '10px'
  },
  tableCellStyle: {
    padding: '8px !important',
    height: '10px'
  },
  cellClusterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  clusterSubmissions: {
    marginLeft: 'auto'
  }
}));

const PopoverList = ({ items, isOpen, onClose, t, tOpts, classes }) => {
  return (
    <DialogModal
      dialogTitle={`${t("Outlets", tOpts)}`}
      maxWidth="sm"
      dividers={true}
      onClose={onClose}
      className={'vista-model-bx-sm'}
      open={isOpen}
      hideFooter={true}
      contentStyle={{ display: 'block', padding: '10px' }}
    >
      <Table>
        <TableBody>
          {items.map((item, index) => (
            <TableRow
              key={index}
              hover
              className={classes.tableRowStyle}
            >
              <TableCell className={classes.tableCellStyle}>
                <div className={classes.cellClusterContainer}>
                  <div>{item.Code}, {item.outletName}</div>
                  <div className={classes.clusterSubmissions}>{item.LocationSubmissions}</div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DialogModal>
  );
};

const SurveyMap = ({ locations, t, tOpts }) => {
  const { t: translate, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mapMarkers, setMapMarkers] = useState([]);
  const [clusteredOutletsList, setClusteredOutletsList] = useState([]);
  const [isClusteredBoxOpen, setIsClusteredBoxOpen] = useState(false);
  const { isLoaded, } = useLoadScript({
    googleMapsApiKey: utils.Google_ApiKey,
    language: i18n.language
  });

  useEffect(() => {
    const mapMarkersGenerator = async () => {
      try {
        if (isLoaded && locations.length > 0) {
          const localMarkerArray = [], localMarkerMap = [];
          for (let i = 0; i < locations.length; i++) {
            let latitude = locations[i].Latitude,
              longitude = locations[i].Longitude,
              key = latitude + ":" + longitude;

            //if latitude and longitude is 0 do not render markers on map  
            if (latitude != 0 || longitude != 0) {
              localMarkerArray.push({
                lat: latitude,
                lng: longitude,
                count: locations[i].LocationSubmissions,
                id: locations[i].Code,
                name: locations[i].LocationName,
                locations: locations[i]
              });
              localMarkerMap.push(key);
            }
            setMapMarkers(localMarkerArray);
          }
        }
      } catch (error) {
        JsError.handleGlobalError('Error loading map component', '', 111, 0, error)
      }
    }
    mapMarkersGenerator();
  }, [locations]);

  function clusterPriority(markers, icons) {
    let highestPriorityColor = 1;
    return {
      text: markers.length,
      index: highestPriorityColor
    };
  }

  const onLoad = React.useCallback(function callback(map) {
    map.setCenter({ lat: 0, lng: 0 });
    map.setZoom(3);
    setTimeout(() => {
      dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false })
    }, 5000)
  }, []);

  const handleClusterClick = (cluster) => {
    const zoomLevel = cluster.getMap().getZoom();
    if (zoomLevel > 21) {
      const outlets = cluster.markers.map(el => {
        let outlet = {}
        const parts = el.getTitle().split(', ');
        outlet.Code = parts[0];
        outlet.outletName = parts[1];
        outlet.LocationSubmissions = parts[2];
        return outlet
      })
      setClusteredOutletsList(outlets)
      setIsClusteredBoxOpen(true)
    }
  };

  return (
    <Paper xs={12}>
      {isClusteredBoxOpen &&
        <PopoverList items={clusteredOutletsList} isOpen={isClusteredBoxOpen} onClose={() => setIsClusteredBoxOpen(false)} t={t} tOpts={tOpts} classes={classes} />
      }
      <CardContent className={classes.customCardContent}>
        {isLoaded && locations?.length > 0 ?
          <GoogleMap
            mapContainerStyle={mapStyles}
            onLoad={onLoad}
            zoom={3}
            options={{ gestureHandling: 'greedy', minZoom: 0.2, language: i18n.language }}>
            <MarkerClusterer
              averageCenter={true}
              calculator={clusterPriority}
              onClick={handleClusterClick}
              styles={clusterStyle}
              zoomOnClick={!isClusteredBoxOpen}
            >
              {(clusterer) =>
                mapMarkers.map((location) => (
                  <Marker
                    key={location.id}
                    position={new window.google.maps.LatLng(location.lat, location.lng)}
                    title={`${location.id}, ${location.locations.LocationName}, ${location.locations.LocationSubmissions}`}
                    clusterer={clusterer}
                    animation={"BOUNCE"}
                    icon={{ url: outletMarker, scaledSize: new window.google.maps.Size(50, 50) }}
                  />
                ))
              }
            </MarkerClusterer>
          </GoogleMap> : <div className='no-map-location'>{t("No map location found", tOpts)}</div>
        }
      </CardContent>
    </Paper>
  );
}

export default SurveyMap